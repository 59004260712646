import { asBlob as html2docx } from 'html-docx-js-typescript';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import utils from "@/common/utils.js";
import { documentExport as sharedDocExportFncts } from "@/../../shared/functions";
import axios from "axios";

export default {
	...sharedDocExportFncts,

  async generateDocumentExport(format, doc, params){
    let useServerExport = doc.exportConfig !== null || (format === "PDF" && params);
    if(!useServerExport){
      switch(format){
        case "DOCX":
          await this.generateDOCX(doc, params);
          break;
        case "RTF":
          await this.generateRTF(doc, params);
          break;
        case "PDF":
          await this.generatePDF(doc, params);
          break;
      }
      return;
    } else {

      let data = {
        doc_id: doc.system_number,
        format: format,
        include_notes: format === "DOCX" && params === true
      }

      await axios.post("document/generateExport", data, { responseType: 'arraybuffer' })
      .then((resp) => {   
        let mimeType = this.getMimeType(format), 
            docName = `${doc.system_number}-${doc.doc_name}-${doc.doc_type}.${format.toLowerCase()}`;     
        if (format === "DOCX")
            docName = doc.system_number + " - " + doc.doc_name + (params ? " - FULL" : " - " + doc.doc_type) + ".docx";
        utils.downloadFile(resp.data, docName, mimeType);
      })
      .catch((error) => {
        console.log(error);
        this.previewLoading = false;
      });
    }
  },  
	async docToClipboard(doc, section, format = 'HTML') {
    let htmlValue = "";
		let textValue = "";
		switch (format) {
			case "Text":
				textValue = await this.docToHTML(doc, section, 'TXT');
				textValue = this.stripTags(textValue);
				break;
			case "RTF":
				textValue = this.convertHtmlToRtf(await this.docToHTML(doc, section, 'RTF'), true);
				break;
      case "FormattedText":
        htmlValue = await this.docToHTML(doc, section, 'HTML');
        textValue = this.stripTags(htmlValue);
        break;
			case "HTML":
			default:
				textValue = await this.docToHTML(doc, section, 'HTML');
				break;
		}

    this.copyDataToClipboard(htmlValue, textValue, format);
	},
  async copyDataToClipboard(htmlValue, textValue, format) {
    try {
      if (htmlValue) {
        await navigator.clipboard.write([new ClipboardItem({
          'text/plain': new Blob([textValue], {type: 'text/plain'}),
          'text/html': new Blob([htmlValue], {type: 'text/html'})
        })]);
      } else {
        await navigator.clipboard.writeText(textValue);
      }
    } catch (e) {
      console.log("Error calling HTML5 clipboard API", e);
      this.copyTextToClipboard(textValue, format);
    }
  },
  copyTextToClipboard(textValue, format) {
    let container;
		let dialog = document.getElementsByClassName(
			"v-dialog--active v-dialog--fullscreen"
		);
		if (dialog && dialog.length) container = dialog[0];
		else container = document.body;

    const textarea = document.createElement("textarea");
    textarea.value = textValue;
    if (format === "Text") {
      textarea.innerHTML = textValue;
      textarea.value = textarea.innerText;
    }
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    container.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    container.removeChild(textarea);
  },
	async generateDOCX(doc, full) {
    //legacy export mechanism based on tmpl_part export_format
    let html = await this.generateExport(doc, 'DOCX', full);
    html2docx(html, {
      orientation: "portrait",
      margins: { top: 820, bottom: 820 },
    }).then(data => {
      utils.downloadFile(
        data,
        doc.system_number + " - " + doc.doc_name + (full ? " - FULL" : " - " + doc.doc_type) + ".docx",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
    });
	},
	async generateRTF(doc, section) {
    //legacy export mechanism based on tmpl_part export_format
    doc = doc.doc_view_config ? this.filterPartsByView(doc) : doc;    
		let rtfText = this.convertHtmlToRtf(await this.docToHTML(doc, section, 'RTF'), true);
		utils.downloadFile(
			rtfText,
			doc.system_number + " - " + doc.doc_name + " - " + doc.doc_type + ".rtf",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
		);
		this.exportMenu = false; // close export menu
	},
	async generatePDF(doc) {
    //legacy export mechanism based on tmpl_part export_format
    var pdfSource = document.createElement("div");
    pdfSource.className = "pdfsection";
    let html = await this.generateExport(doc, 'PDF', false);
    pdfSource.innerHTML = html;
    document.body.appendChild(pdfSource);
    let w = pdfSource.offsetWidth;
    let h = pdfSource.offsetHeight;
    html2canvas(pdfSource, {
      dpi: 300, // Set to 300 DPI
      scale: 3, // Adjusts your resolution
    }).then((canvas) => {
      let img = canvas.toDataURL("image/jpeg", 1);
      let pdf = new jsPDF("p", "pt", (w > h) ? "a4" : [w, h]);
      if (w > h) w -= 100;
      pdf.addImage(img, "JPEG", 0, 0, w, h);
      pdf.save(doc.system_number + " - " + doc.doc_name + ".pdf");
      pdfSource.innerHTML = "";
      document.body.removeChild(pdfSource);
    });

    /*let data = {
			doc_id: doc.system_number,
			format: "PDF"
		}

		if (serverSideGeneration) {
			await axios.post("document/generateExport", data, { responseType: 'arraybuffer' })
				.then((resp) => {
					utils.downloadFile(
						resp.data,
						doc.system_number + " - " + doc.doc_name + ".pdf",
						"application/pdf"
					);
				})
				.catch((error) => {
					console.log(error);
					this.previewLoading = false;
				});
		} else {
			var pdfSource = document.createElement("div");
			pdfSource.className = "pdfsection";

			if (doc.exportConfig !== null) {
				await axios
					.post("document/generateExportHtml", data)
					.then((resp) => {
						let html = resp.data.body;
						pdfSource.innerHTML = html;
						document.body.appendChild(pdfSource);
						let w = pdfSource.offsetWidth;
						let h = pdfSource.offsetHeight;
						html2canvas(pdfSource, {
							dpi: 300, // Set to 300 DPI
							scale: 3, // Adjusts your resolution
						}).then((canvas) => {
							let img = canvas.toDataURL("image/jpeg", 1);
							let pdf = new jsPDF("p", "pt", (w > h) ? "a4" : [w, h]);
							if (w > h) w -= 100;
							pdf.addImage(img, "JPEG", 0, 0, w, h);
							pdf.save(doc.system_number + " - " + doc.doc_name + ".pdf");
							pdfSource.innerHTML = "";
							document.body.removeChild(pdfSource);
						});
					})
					.catch((error) => {
						console.log(error);
						this.previewLoading = false;
					});
			} else {

        let html = await this.generateExport(doc, 'PDF', false);
				pdfSource.innerHTML = html;
				document.body.appendChild(pdfSource);
				let w = pdfSource.offsetWidth;
				let h = pdfSource.offsetHeight;
				html2canvas(pdfSource, {
					dpi: 300, // Set to 300 DPI
					scale: 3, // Adjusts your resolution
				}).then((canvas) => {
					let img = canvas.toDataURL("image/jpeg", 1);
					let pdf = new jsPDF("p", "pt", (w > h) ? "a4" : [w, h]);
					if (w > h) w -= 100;
					pdf.addImage(img, "JPEG", 0, 0, w, h);
					pdf.save(doc.system_number + " - " + doc.doc_name + ".pdf");
					pdfSource.innerHTML = "";
					document.body.removeChild(pdfSource);
				});
			}
		}*/

		return true;
	},
	generateJobShareTemplate(doc) {
		// axios
		//   .get("roleflex/document/" + doc.documentId)
		//   .then((resp) => {
		//     if (resp.data.Status === "OK") {
		//       const roleFlex = resp.data.Data;
		//       const availabilityAndResponsiveness = roleFlex.sections.find(s => s.name == "Availability and Responsiveness");
		//       const workflowRegularity = roleFlex.sections.find(s => s.name == "Workflow Regularity");
		//}
		// })
		// .catch((err) => {
		//   this.$emit("sessionExpired", err);
		//   console.log(err);
		// });
		const requirementsPartType = doc.parttypes.find(
			(pt) => pt.type === "Requirement"
		);
		const requirementParts =
			requirementsPartType && requirementsPartType.parts
				? requirementsPartType.parts.filter((p) => p.text && p.is_essential)
				: [];
		const requirements = requirementParts.length ? `<ul>${requirementParts.reduce((p, c) => {
			return p + `<li>${c.text}</li>`;
		}, "")}</ul>` : "&nbsp;";
		const responsibilityPartType = doc.parttypes.find(
			(pt) => pt.type === "Responsibility"
		);
		const hasActivities =
			responsibilityPartType &&
			responsibilityPartType.childPartTypes &&
			responsibilityPartType.childPartTypes.length;
		const responsibilityParts =
			responsibilityPartType && responsibilityPartType.parts
				? responsibilityPartType.parts.filter((p) => p.text)
				: [];
		const outcomesAndKpiPartType = doc.parttypes.find(
			(pt) => pt.type === "KPI Outcome"
		);
		const outcomesAndKpiParts = outcomesAndKpiPartType && outcomesAndKpiPartType.parts
			? outcomesAndKpiPartType.parts.filter((p) => p.text)
			: [];
		const outcomesAndKpi = outcomesAndKpiParts.length
			? `<ul>${outcomesAndKpiParts.reduce((p, c) => {
				return p + `<li>${c.text}</li>`;
			}, "")}</ul>`
			: "&nbsp;";
		const understandingTheJob = `
      <h2><span class="page_heading">Understanding the Job</span></h2>
      <table style="width: 100%" class="content">
        <thead>
          <tr>
              <th style="width: 25%">Responsibility Segments</th>
              ${hasActivities ? '<th style="width: 25%">Activities</th>' : ""}
              <th style="width: 25%">Objectives, Outputs and KPIs</th>
              <th style="width: 25%">Role Holder Requirements (Knowledge, Experience, Skills & Capabilities)</th>
          </tr>
        </thead>
        <tbody>${responsibilityParts.reduce((pn, p) => {
			return (
				pn +
				`<tr>
                <td>${p.text}</td>
                ${hasActivities ? "<td><ul>" : ""}
                ${p.childParts
					.filter((c) => c.text)
					.reduce((cn, c) => {
						return cn + `<li>${c.text}</li>`;
					}, "")}
                ${hasActivities ? "</ul></td>" : ""}
                <td>${outcomesAndKpi}</td>
                <td>${requirements}</td>
            </tr>`
			);
		}, "")}</tbody></table>`;

		const natureOfTheJob = `
      <h2 class="pagebreak"><span class="page_heading">Nature of the Job</span></h2>
      <table style="width: 100%" class="content">
        <thead>
          <tr>
            <th style="width: 16%">Responsibility Segments</th>
            <th style="width: 16%">Availability & Responsiveness H/M/L</th>
            <th style="width: 16%">Workflow Regularity H/M/L</th>
            <th style="width: 16%">Deliverables, Reporting & Critical Deadlines</th>
            <th style="width: 16%">Key Stakeholders, Meetings & Communications</th>
            <th style="width: 16%">Unexpected emergencies</th>
          </tr>
        </thead>
        <tbody>${responsibilityParts.reduce((pn, p) => {
			return (
				pn +
				`<tr>
                <td>${p.text}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>`
			);
		}, "")}</tbody></table>`;

		const designConsiderations = `
      <h2 class="pagebreak"><span class="page_heading">Job Share Design Considerations</span></h2>
      <table style="width: 100%" class="content">
        <thead>
          <tr>
            <th style="width: 20%">Responsibility Segments</th>
            <th style="width: 20%">Job Share Design - Pure/Hybrid/Split</th>
            <th style="width: 20%">Logistics of how the job share could work</th>
            <th style="width: 20%">Handover Considerations</th>
            <th style="width: 20%">Non-Work Day Considerations</th>
          </tr>
        </thead>
        <tbody>${responsibilityParts.reduce((pn, p) => {
			return (
				pn +
				`<tr>
                <td>${p.text}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>`
			);
		}, "")}</tbody></table>`;

		let html = `<!DOCTYPE  html><html><body>
      <style>
          .pagebreak { page-break-before: always !important; }
          h1 { text-align: center; font-weight: bold; font-size: 16pt; font-family: calibri; color: #CACACA }
          h2 { font-weight: bold; font-size: 14pt; font-family: calibri; }
          table, th, td { border: 1px solid black; border-collapse: collapse; }
          td { padding: 5px; vertical-align: top; }
          table.content th { font-weight: bold; font-size: 10pt; background-color: #9BBB59; color: white; }
          table.content td { font-size: 10pt; color: gray; }
          table.content ul { margin-left: -1.5em; }
          table.notes, table.notes th, table.notes td { border: 3px solid white; }
          table.notes th { font-weight: bold; font-size: 10pt; background-color: #099AD4; color: white; }
          table.notes td { font-size: 10pt; background-color: #F2F2F2; color: gray; }
      </style>
      <h1 class="doc_heading">${doc.doc_name} - Job Share Design</h1>
      ${understandingTheJob}
      ${natureOfTheJob}
      ${designConsiderations}
      <h1 class="pagebreak"><span class="doc_heading">Job Share Design - Guidance Notes</span></h1>
      <h2><span class="page_heading">Step 1: Understanding the Job</span></h2>
      <table style="width: 100%" class="notes">
        <thead>
          <tr>
            <th>Responsibility Segments</th>
            <th>Activities</th>
            <th>Role Holder Requirements</th>
            <th>Objectives, Outputs and KPIs</th>
          </tr>
        <thead>
        <tbody>
          <tr>
            <td>What are the core areas of responsibility of the role?<br /><br />E.g. team management</td>
            <td>What are the key activities within this core area of responsibility?<br /><br />E.g. for team management: <br />1a managing workload, <br />1b appraisals and career planning, <br />1c performance management, <br />1d recruitment<br />1e ad hoc issues and requests<br /><br />What happens on a daily basis, weekly, monthly, quarterly, annually, ad hoc</td>
            <td>What are the specific role requirements to perform well in this role? <br />e.g.<ul><li>Knowledge</li><li>Experience</li><li>Skills & Capabilities</li></ul>Focus on essential only (not desired)<br />Beware of biases! </td>
            <td>What are the objectives of this responsibility?<br /><br />What are the key outputs?<br /><br />How is this area of responsibility / activity measured?<br /><br />What are you accountable for?</td>
          </tr>
        </tbody>
      </table>
      <h2 class="pagebreak"><span class="page_heading">Step 2: The “Nature of the Job”</span></h2>
      <table style="width: 100%" class="notes">
        <thead>
          <tr>
            <th>Responsibility Segments</th>
            <th>Availability & Responsiveness H/M/L</th>
            <th>Workflow Regularity H/M/L</th>
            <th>Deliverables, Reporting & Critical Deadlines</th>
            <th>Key Stakeholders, Meetings & Communications</th>
            <th>Unexpected emergencies</th>
          </tr>
        <thead>
        <tbody>
          <tr>
            <td>E.g. Team Management</td>
            <td>What is the level of Availability & Responsiveness for this responsibility?<br /><br />Availability & Responsiveness = HIGH if:<br /><br />The flow of work for the responsibility segment is unpredictable and the role holder needs to be available for and highly responsive to stakeholders / customers / colleagues<br /><br />Availability & Responsiveness = MEDIUM if:<br /><br />There is some degree of predictability to the flow of work for this responsibility segment.  Furthermore at times there is a requirement to be available and responsive immediately but on other occasions responding within the same day or week will be acceptable to stakeholders / customers / colleagues without delivery levels being adversely impacted.<br /><br />Availability & Responsiveness = LOW if:<br /><br />The flow of work for this responsibility segment is always predictable and, within reason, the role holder has control over when they respond to stakeholders / customers / colleagues.                  </td>
            <td>What is the Workflow Regularity for this responsibility?<br /><br />Workflow Regularity = HIGH if: <br /><br />The workflow for this responsibility segment is consistent throughout the year with no peaks and troughs.<br /><br />Workflow Regularity = MEDIUM if:<br /><br />For this responsibility segment there are sometimes peaks and troughs to the flow of work and / or the work is sometimes project-based with the clear starts and stops and breaks in between.<br /><br />Workflow Regularity = LOW if:<br /><br />For this responsibility segment there are peaks and troughs to the flow of work and / or the work is project-based with the clear starts and stops and breaks in between</td>
            <td>What are the key deliverables, reporting requirements and critical deadlines within this core area of responsibility?<br /><br />E.g. weekly team meetings, performance reporting, annual appraisals<br /><br />What critical deadlines and ad hoc meetings are there for this area of responsibility/activity?<br /><br /></td>
            <td>Who are the key stakeholders for this area of responsibility? <br /><br />What is the expected level of communication?<br /><br />What is the expected level of responsiveness to each key stakeholder?<br /><br />What and when are the regular / fixed meetings for this area of responsibility?<br /><br /></td>
            <td>What can happen unexpectedly? <br /><br />What is the expected level of responsiveness required? <br /><br />Can you foresee any issues that your key stakeholders may present if you propose a job share arrangement?<br /><br /></td>
          </tr>
        </tbody>
      </table>
      <h2 class="pagebreak"><span class="page_heading">Step 3: Job Share Design Considerations</span></h2>
      <table style="width: 100%" class="notes">
        <thead>
          <tr>
            <th>Responsibility Segments</th>
            <th>Job Share Design - Pure/Hybrid/Split</th>
            <th>Logistics of how the job share could work</th>
            <th>Handover Considerations</th>
            <th>Non-Work Day Considerations</th>
          </tr>
        <thead>
        <tbody>
          <tr>
            <td>E.g. Team Management</td>
            <td>Clarify if this responsibility could/should be split/shared or managed as a hybrid <br /><br />Provide details on how the role can be divided and responsibilities will be owned. <br /><br />What could be divided/split?<br />What could/needs to be shared?<br /><br />Is there an opportunity to divide the role and play to different strengths and expertise?</td>
            <td>How will you guarantee full responsibility for the role?<br /><br />Are there strategy, planning, mtgs, comms elements to the role? If so, how will these be shared and managed? <br /><br />How will stakeholders be divided / managed?<br /><br />How will you deal with new projects as they come in?</td>
            <td>How will this responsibility be handed over? <br /><br />How will you guarantee full responsibility for the role?<br /><br />How will you ensure seamless communication?<br /><br />                 Are there strategy and planning elements to the role? If so, how will these be shared and managed?<br /><br />Explain how it could work and address any concerns head on. </td>
            <td>What will happen on the days you are not working? <br /><br />How will deadlines be managed? <br /><br />How will unexpected emergencies be managed? <br /><br />How will communications/expected level of responsiveness be managed?</td>
          </tr>
        </tbody>
      </table>
      </body></html>`;
		html = this.escapeSpecialChars(html);

		html2docx(html, {
			orientation: "landscape",
			margins: { top: 520, bottom: 520, left: 500, right: 500 },
		}).then(data => {
			utils.downloadFile(
				data,
				doc.system_number + " - " + doc.doc_name + " - Job Share Template.docx",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			);
		});
	},
	generateInterviewTemplate(doc) {
		let n_s = '<p style="color: gray;"><i>',
			n_e = "</i></p>";
		let html = `<!DOCTYPE  html><html><body>
	  <style>
		.pagebreak { page-break-before: always !important; }
    .cell { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; }
		.cell_p1 { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; min-width: 30%; max-width: 30%; width: 30%;}
		.cell_p4 { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; min-width: 70%; max-width: 70%; width: 70%; }
		.cell_5 { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; min-width: 20%; max-width: 20%; width: 20%; }
		.page_heading { font-weight: bold; font-size: 20pt; font-family: calibri; }
		.cell_heading { font-weight: bold; font-size: 13pt; }
		.ivSection { font-weight: bold; font-size: 13pt; font-family: calibri; min-height: 500px; }
		.cell_hint { font-style: italic; color: gray; }
		table, th, td { border: 1px solid black; border-collapse: collapse; }
		td { padding: 5px; }
	  </style>
	  <span class="page_heading">Interview Template - ${doc.doc_name}</span>
	  <div><br/><br/><br/><br/></div>
	  <table style="width: 100%">
	  <tbody>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Name of Candidate:</span></td>
			<td class="cell_p4">&nbsp;</td>
		</tr>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Job Role:</span></td>
			<td class="cell_p4"><span class="cell_heading">${doc.doc_name}</span></td>
		</tr>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Interviewer(s):</span></td>
			<td class="cell_p4">&nbsp;</td>
		</tr>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Date / Time of Interview:</span></td>
			<td class="cell_p4">&nbsp;</td>
		</tr>
	  </tbody>
	  </table>`;
		let reqSummary = `
	  	<p class="pagebreak"><span class="page_heading">Interview Template - ${doc.doc_name}</span></p>
	  	<table style="width: 100%"><caption>Overall Summary</caption>
			<tr>
				<td colspan="8">&nbsp;</td>
				<td><span class="cell_heading">Rating</span></td>
			</tr>`;
		doc.parttypes
			.find((pt) => pt.type === "Requirement")
			.parts.filter((p) => p.is_essential)
			.forEach((p) => {
				reqSummary += `
			<tr>
				<td colspan="8">${p.text}</td>
				<td>&nbsp;</td>
			</tr>`;
				html += `
	  <p class="pagebreak"><span class="page_heading">Interview Template - ${doc.doc_name
					}</span></p>
	  <table>
		<tr>
			<td><span class="cell_heading">Requirement:</span><br/><span class="cell_hint">(Any skill/knowledge/experience that is essential for the role)</span></td>
			<td colspan="3"><span class="cell_heading">${p.text}</span></td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Indicators:</span> <span class="cell_hint">(what does good look like for this requirement)</span>
			<ul class="ivSection">
      ${p.childParts
						.filter((c) => c.dp_name === "Indicator")
						.reduce((p, c) => {
							return p + `<li>${c.text}</li>`;
						}, "")}
			</ul></td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Interview Questions:</span>
			<ul class="ivSection">
      ${p.childParts
						.filter((c) => c.dp_name === "Question")
						.reduce((p, c) => {
							return p + `<li>${c.text}</li>`;
						}, "")}
			</ul></td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Evidence:</span> <span class="cell_hint">(summarise evidence from your notes)</span><br/>&nbsp;<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;</td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Rating</span></td>
		</tr>
		<tr>
			<td style="width: 25%; text-align: center;">1</td>
			<td style="width: 25%; text-align: center;">2</td>
			<td style="width: 25%; text-align: center;">3</td>
			<td style="width: 25%; text-align: center;">4</td>
		</tr>
		<tr>
			<td style="width: 25%; text-align: center;">${n_s}No evidence (not competent or experienced)${n_e}</td>
			<td style="width: 25%; text-align: center;">${n_s}Some evidence (some competence or experience)${n_e}</td>
			<td style="width: 25%; text-align: center;">${n_s}Good evidence (good competence or experience)${n_e}</td>
			<td style="width: 25%; text-align: center;">${n_s}Excellent evidence (excellent competence or experience)${n_e}</td>
		</tr>
	  </table>`;
			});
		reqSummary += `
			<tr>
				<td colspan="8" style="text-align: right;">TOTAL</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td colspan="9" >Additional notes:<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;</td>
			</tr>
			<tr>
				<td colspan="9" >Decision:<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;</td>
			</tr>
			</table>`;
		html +=
			reqSummary +
			`
	  	<p class="pagebreak"><span class="page_heading">How to use this Interview Template</span></p>
		<table style="width: 100%">
	  <tbody>
		<tr>
			<td style="width: 34%;"><span class="cell_heading">Preparing for the Interview</span></td>
			<td style="width: 22%;"><span class="cell_heading">Conducting the Interview</span></td>
			<td style="width: 22%;"><span class="cell_heading">Scoring the Interview</span></td>
			<td style="width: 22%;"><span class="cell_heading">Avoiding Bias</span></td>
		</tr>
		<tr>
			<td style="vertical-align: top; font-size: 10pt; width: 34%;">
				1.	Decide on which of the essential requirements for the role you are going to assess in the interview<br/><br/>
				2.	Write interview questions for each requirement and add these to the interview template.  These should consist of an opening question and probing questions. <br/><br/>
				3.	Think about what good looks like for this requirement and add these to the interview template as indicators<br/>
				<br/>
				For example:<br/>
				<b>Requirement:</b> <br/>
				"Experience managing complex global projects"<br/>
				<br/>
				<b>Indicators:</b><br/>
				-	Has managed multiple, complex projects previously<br/>
				-	Can describe each stage of the project management lifecycle<br/>
				-	Demonstrates various methods to keep stakeholders updated<br/>
				<br/>
				<b>Opening question:</b> <br/>
				"Tell me about a time when you have managed a complex project with multiple stakeholders"<br/>
				<br/>
				<b>Probing questions:</b><br/>
				"What was your specific role on the project?"<br/>
				"How did you manage the complexity?"<br/>
				"How did you communicate with the stakeholders?"<br/>
				"What feedback did you receive?"<br/>
			</td>
			<td style="vertical-align: top; font-size: 10pt; width: 22%;">
				<b>At the start of the interview:</b>
				<ul>
					<li>Meet and greet the candidate and introduce those present</li>
					<li>Explain that the interview is a two -way exchange of information.</li>
					<li>Indicate how long the interview is likely to last</li>
					<li>Explain that the interview is structured around essential requirements for the job and that you will be looking for specific examples of what the candidate has done</li>
					<li>Explain that you will be taking notes</li>
				</ul>
				<b>During the interview:</b>
				<ul><li>Take notes on evidence relating to each requirement and question</li></ul>
				<b>At the end of the interview:</b>
				<ul><li>Give the candidate the opportunity to ask any questions and explain to them the next steps in the process</li></ul>
			</td>
			<td style="vertical-align: top; font-size: 10pt; width: 22%;">
				<ul>
					<li>As soon as possible after the interview, review your notes and confirm any evidence against each requirement</li>
					<li>Assign a rating to each requirement</li>
					<li>If more than one interviewer is present, each person should score the interview separately</li>
					<li>Use the ratings from the interview to decide whether the candidate should go forward to the next stage of the process</li>
					<li>Make sure that your notes explain the reason for your decision</li>
				</ul>
			</td>
			<td style="vertical-align: top; font-size: 10pt; width: 22%;">
				<ul>
					<li>Make sure you have an understanding of the essential requirements for the role and that you are basing your interview around these</li>
					<li>If more than one interviewer is present, each person should score the interview first before discussing to avoid priming / Groupthink</li>
					<li>Be aware of our unconscious tendency to favour people who remind us of ourselves (in-group bias)</li>
					<li>Try not to put too much weight on first impressions, whether these are negative or positive.  Make sure that evidence from the whole interview is taken into account</li>
					<li>Assessors allow the same time and stick to the script to avoid 'intuition' or 'gut' feel</li>
					<li>Don’t compare candidates to each other.  Make sure you are assessing each candidate fairly against the requirements for the job, not against each other</li>
				</ul>
			</td>
		</tr>
	  </tbody>
		</table>
	  </body></html>`;
		html = html.replace(/’/g, "'");
		html = html.replace(/•/g, "");

		html2docx(html, {
			orientation: "landscape",
			margins: { top: 520, bottom: 520, left: 500, right: 500 },
		}).then(data => {
			utils.downloadFile(
				data,
				doc.system_number + " - " + doc.doc_name + " - Interview Template.docx",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			);
		});
	},
	generateIntakeConduent(doc) {
		//let hdrColour = "#366091";
		//let lblColour = "#DBE5F1";

		let html = `<!DOCTYPE  html>
      <html><body>
      <style>
        .pagebreak { page-break-before: always !important; }
        .cell_p0 { min-width: 15%; max-width: 15%; width: 15%;}
        .cell_p1 { padding: 5px; pmin-width: 20%; max-width: 20%; width: 20%;}
        .cell_p2 { padding: 5px; min-width: 25%; max-width: 25%; width: 25%;}
        .cell_p3 { padding: 5px; min-width: 30%; max-width: 30%; width: 30%;}
        .cell_p4 { padding: 5px; min-width: 50%; max-width: 50%; width: 50%; }
        .cell_p75 { padding: 5px; min-width: 75%; max-width: 75%; width: 75%; }
        .cell_p80 { padding: 5px; min-width: 80%; max-width: 80%; width: 80%; }
        .cell_across { padding: 0; min-width: 100%; max-width: 100%; width: 100%; }
        .page_heading { font-weight: bold; font-size: 14pt; text-decoration: underline; text-align: center;}
        .section_heading { padding-top: 8px; padding-bottom: 8px; padding-left: 15px; margin:0; font-weight: bold; text-align: center;}
        .cell_heading { padding:0; font-weight: bold; background-color: #BFBFBF; }
        .ivSection { font-weight: bold; font-family: calibri; min-height: 500px; }
        .cell_hint { font-style: italic; color: gray; }
        .bold { font-weight: bold;  }
        table, th, td { border: 1px solid black; border-collapse: collapse; margin-top: 20px; padding-top: 20px; }
        td { padding: 0 5px; }
        * { font-family: calibri; font-size: 9pt; }
      </style>
      <div class="page_heading">INTAKE TEMPLATE</div> 
      <br/>
      <table style="width: 100%;">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">POSITION INFORMATION</div>
        </td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Requisition ID</div></td>
        <td class="cell_p75"></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Recruiter:</div></td>
        <td class="cell_p75">${doc.recruiter}</td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Hiring Manager</div></td>
        <td class="cell_p75"></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Internal Job Title:</div></td>
        <td class="cell_p75">${doc.parttypes.find((pt) => pt.type === "Internal Job Title")
				?.parts[0].text || doc.doc_name
			}</td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Advertising Job Title:</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">What job title should be used to post externally (what will be recognised in the external market)?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Salary and Package:</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Salary Band. Min, Max Salary. Package Details.</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Reason for Hire:</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">E.g. replacement for leaver, replacement for internal move, newly created role</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Reporting to:</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Who the role will report to</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Training information</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Is there training? Duration of training. Onsite/ virtual/hybrid?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Probationary Period</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Is there a formal probationary period and, if so, how long is it?</div></td>
      </tr>
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">ABOUT THE JOB</div>
        </td>
      </tr>`;

		if (doc.parttypes.some((pt) => pt.type === "Job Purpose"))
			html += `<tr style="height:20px">
          <td class="cell_p2 bold"><div class="bold">Job USP Copy</div></td>
          <td class="cell_p75">${doc.parttypes.find((pt) => pt.type === "Job Purpose")
					.parts[0].text
				}</td>
        </tr>`;

		html += `<tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Job Information</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Add any more insight into the Job. Additional unique selling points. Why should anyone apply for the position? What will the role holder take ownership for? What is the opportunity to make a difference?</div></td>
      </tr>	
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">RESPONSIBILITIES</div>
        </td>
      </tr>`;

		doc.parttypes
			.find((pt) => pt.type === "Responsibility")
			?.parts.forEach((p) => {
				html += `
            <tr style="height:20px">
              <td class="cell_p4 bold">${p.text}</td>
              <td class="cell_p4 cell_hint"><div class="cell_hint">Add any more information to bring the day to day role to life</div></td>
            </tr>`;
			});

		html += `</table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">JOB POSTING & SCREENING REQUIREMENTS</div>
        </td>
      </tr>`;

		doc.parttypes
			.find((pt) => pt.type === "Requirement")
			?.parts.filter((p) => p.is_essential)
			.forEach((p) => {
				html += `
            <tr style="height:20px">
              <td class="cell_p4 bold">${p.text}</td>
              <td class="cell_p4 cell_hint"><div class="cell_hint">Additional information to understand this requirement and gather insights on screening questions</div></td>
            </tr>`;
			});

		html += `</table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">FLEXIBLE WORKING, LOCATION & TRAVEL REQUIREMENTS</div>
        </td>
      </tr>`;

		let flexParts = doc.parttypes.filter(
			(pt) =>
				pt.type === "Working Pattern" ||
				pt.type === "Flex Work Details" ||
				pt.type === "Work Pattern Details"
		);
		if (flexParts.length > 0) {
			flexParts.forEach((fp) => {
				fp.parts.forEach((p) => {
					html += `
                <tr style="height:20px">
                  <td class="cell_p4 bold">${p.text.replace(
						/[\u{0080}-\u{FFFF}]/gu,
						""
					)}</td>
                  <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
                </tr>`;
				});
			});
		}

		html += `
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Technology, Certification, requirements (etc.)</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Location:</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Travel Requirement:</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
      </tr>		
      </table>
      <p class="pagebreak">&nbsp;</p>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="5" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEW PROCESS</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Stage</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Format</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Duration</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Location</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Dates Available</td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="4" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEWERS</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td class="cell_p0" style="background-color: #BFBFBF; color: white;">No</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Name</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Position</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Stage Involved</td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p0">1</td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p0">2</td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p0">3</td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p0">4</td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">COMMUNICATION PLAN (HIRING MANAGER & RECRUITER) </div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td class="cell_p0">Agreed Plan</td>
        <td class="cell_p1 cell_hint">
          <div class="cell_hint">
          Frequency (e.g weekly update)<br/>
          Method (e.g. set time to update or email) <br/>
          Agreed turnaround time for reviewing CVs <br/>
          Hold dates for interview
          </div>
        </td>
      </tr>	
      </table>
      </body></html>`;
		html = html.replace(/’/g, "'");
		html = html.replace(/•/g, "");

		html2docx(html, {
			orientation: "portrait",
			margins: { top: 500, bottom: 500, left: 500, right: 500 },
		}).then(data => {
			utils.downloadFile(
				data,
				doc.system_number + " - " + doc.doc_name + " - Intake Template.docx",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			);
		});
	},
	generateIntakeFlow(doc, client) {
		if (client === "Conduent" || client === "RoleMapper") {
			return this.generateIntakeConduent(doc);
		}
		let hdrColour = "#366091";
		let lblColour = "#DBE5F1";

		if (client === "Elsevier") {
			hdrColour = "orange";
			lblColour = "#F1F0EF";
		}

		let html = `
        <!DOCTYPE  html><html><body>
          <style>
            .pagebreak { page-break-before: always !important; }
            .cell_p0 { min-width: 15%; max-width: 15%; width: 15%;}
            .cell_p1 { padding: 5px; pmin-width: 20%; max-width: 20%; width: 20%;}
            .cell_p2 { padding: 5px; min-width: 25%; max-width: 25%; width: 25%;}
            .cell_p3 { padding: 5px; min-width: 30%; max-width: 30%; width: 30%;}
            .cell_p4 { padding: 5px; min-width: 50%; max-width: 50%; width: 50%; }
            .cell_across { padding: 0; min-width: 100%; max-width: 100%; width: 100%; }
            .page_heading { font-weight: bold; font-size: 14pt; text-decoration: underline; text-align: center;}
            .section_heading { padding-top: 8px; padding-bottom: 8px; padding-left: 15px; margin:0; font-weight: bold; text-align: center;}
            .cell_heading { padding:0; font-weight: bold; background-color: ${lblColour} }
            .ivSection { font-weight: bold; font-family: calibri; min-height: 500px; }
            .cell_hint { font-style: italic; color: gray; }
            table, th, td { border: 1px solid black; border-collapse: collapse; }
            td { padding: 0 5px; }
            * { font-family: calibri; font-size: 9pt; }
          </style>`;
		if (
			client === "Akamai" ||
			client === "staging"
		) {
			html += `<div class="page_heading">Launch Meeting Plan</div>`;
		} else {
			if (client === "Elsevier") {
				html += `<div class="page_heading">Attraction Strategy Meeting</div>`;
			} else {
				html += `<div class="page_heading">Intake Flow</div>`;
			}
		}
		html += ` <table style="width: 100%">
            <tr style="height: 14px">
              <td class="cell_across" colspan="4" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">POSITION INFORMATION</div>
              </td>
            </tr>
            <tr style="height:20px">`;
		if (client === "Elsevier") {
			html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Reason for the Position:</td>`;
		} else {
			html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Date of Call/Meeting:</td>`;
		}
		html += `<td class="cell_p2">&nbsp;</td>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Recruiter:</td>
              <td class="cell_p2">${this.$loginState.user.name}</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Position Title:</td>
              <td class="cell_p2">${doc.doc_name}</td>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Requisition #:</td>
              <td class="cell_p2">&nbsp;</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Job Posting Title:</td>
              <td class="cell_p2">${doc.parttypes.find(
			(pt) =>
				pt.type === "Advert Job Title" ||
				pt.type === "Job Posting Title" ||
				pt.type === "Título de Trabajo del Anuncio" ||
				pt.type === "Título de Trabajo"
		)?.parts[0].text || doc.doc_name
			}</td>
              <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="2">Is the Job title appropriate & relevant for attraction?</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Hiring Manager:</td>
              <td class="cell_p2">&nbsp;</td>`;
		if (client === "Elsevier") {
			html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Position Type:</td>`;
		} else {
			html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Business Group/Function:</td>`;
		}
		html += `<td class="cell_p2">&nbsp;</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">`;
		if (
			client === "Akamai" ||
			client === "staging"
		) {
			html += `Mid-point budget:</td>
              <td class="cell_p2">&nbsp;<br></td>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Sales roles: % split, bonus % & type</td>
              <td class="cell_p2">&nbsp;<br></td>`;
		} else {
			if (client === "Elsevier") {
				html += `Salary and Package:<br>Range Min/Max<br>Package Details</td>
              <td class="cell_p2" colspan="3">&nbsp;<br><br><br></td>`;
			} else {
				html += `Salary:</td>
              <td class="cell_p2" colspan="3">&nbsp;<br></td>`;
			}
		}
		html += `
            </tr>
            <tr style="height:50px">`;
		if (client === "Elsevier") {
			html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Relocation and Right to Work:</td>
              `;
		} else {
			html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Relocations Considered?</td>
              `;
		}
		html += `<td class="cell_p2" colspan="3">&nbsp;<br></td>
            </tr>`;

		if (doc.parttypes.some((pt) => pt.type === "Job USP"))
			html += `<tr style="height: 10px">
              <td class="cell_across" colspan="4" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">JOB USP</div>
              </td>
            </tr>
            <tr style="height:90px">
              <td colspan="4">${doc.parttypes.find((pt) => pt.type === "Job USP")
					.parts[0].text
				}</td>
            </tr>`;

		html += `<tr style="height:60px">
              <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="2">Additional unique selling points (to build on above to discuss with candidate) - why should anyone apply for the position?</td>
              <td colspan="2">&nbsp;</td>
            </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="6" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">FURTHER NOTES/INFORMATION</div>
          </td>
        </tr>
        <tr style="height:80px">
          <td colspan="6">&nbsp;</td>
        </tr>
          </table>
          <p class="pagebreak">&nbsp;</p>
          <table style="width: 100%">
            <tr style="height: 14px">
              <td class="cell_across" colspan="10" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">RESPONSIBILITIES</div>
              </td>
            </tr>
            <tr>
              <td class="cell_p4 cell_heading" colspan="7" style="background-color: ${lblColour}">Responsibilities</td>
              <td class="cell_p4 cell_heading" colspan="3" style="background-color: ${lblColour}">Any more information to bring the role to life</td>
            </tr>`;
		let respSummary = "";
		let noResps = doc.parttypes.find(
			(pt) => pt.type === "Responsibility"
		)?.parts.length || 0;
		doc.parttypes
			.find((pt) => pt.type === "Responsibility")
			?.parts.forEach((p) => {
				respSummary += `
            <tr>
              <td colspan="7">${p.text}</td>
            </tr>`;
			});
		html +=
			respSummary +
			`
              <tr style="height:60px">`;
		if (client === "Elsevier") {
			html += `
        <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="7">Describe what you look for in a candidate?</td>`;
		} else {
			html += `
        <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="7">What are the key challenges someone may face in this role in the first 3-6 months?</td>`;
		}
		html += `
                <td colspan="3">&nbsp;</td>
              </tr>`;
		if (noResps < 6) {
			html += `
            <tr style="height: 10px">
              <td class="cell_across" colspan="10" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">FURTHER NOTES/INFORMATION</div>
              </td>
            </tr>
            <tr style="height:100px">
              <td colspan="10">&nbsp;</td>
            </tr>`;
		}
		html += `
          </tbody>
          </table>
          <p class="pagebreak">&nbsp;</p>
          <table style="width: 100%">`;

		if (this.document.parttypes.some((pt) => pt.type === "About the Team"))
			html += `<tr style="height: 14px">
              <td class="cell_across" colspan="6" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">ABOUT THE TEAM</div>
              </td>
            </tr>
            <tr style="height:70px">
              <td colspan="6">${doc.parttypes.find(
				(pt) => pt.type === "About the Team"
			).parts[0].text
				}</td>
            </tr>`;

		html += `<tr style="height:30px">
              <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="3">`;
		if (client === "Elsevier") {
			html += `Additional Team Info:</td>
              <td colspan="3">&nbsp;</td>
            </tr>`;
		} else {
			html += `Who are the key stakeholders / team / department this person will work with and in what capacity?</td>
              <td colspan="3">&nbsp;</td>
            </tr>
            <tr style="height:30px">
              <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="3">Department/Team Structure</td>
              <td colspan="3">&nbsp;</td>
            </tr>`;
		}
		html += `
            <tr style="height: 10px">
              <td class="cell_across" colspan="6" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">POSITION REQUIREMENTS</div>
              </td>
            </tr>
            <tr>
              <td class="cell_p4 cell_heading" colspan="3" style="background-color: ${lblColour}">Essential Requirements</td>
              <td class="cell_p4 cell_heading" colspan="3" style="background-color: ${lblColour}">`;
		if (client === "Elsevier") {
			html += `Critical Screening Questions (associated with Requirement)`;
		} else {
			html += `Telephone/Pre-Screening Criteria / Questions`;
		}
		html += `</td>
            </tr>`;
		let reqSummary = "";
		let noReqs = doc.parttypes.find(
			(pt) => pt.type === "Requirement"
		)?.parts.length || 0;
		doc.parttypes
			.find((pt) => pt.type === "Requirement")
			?.parts.filter((p) => p.is_essential)
			.forEach((p) => {
				reqSummary += `
        <tr>
          <td colspan="3">${p.text}</td>
          <td colspan="3">&nbsp;</td>
        </tr>`;
			});
		html += reqSummary;
		if (noReqs < 6) {
			html += `
            <tr style="height: 10px">
              <td class="cell_across" colspan="10" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">FURTHER NOTES/INFORMATION</div>
              </td>
            </tr>
            <tr style="height:150px">
              <td colspan="10">&nbsp;</td>
            </tr>`;
		}
		html += `
          </table>
          <p class="pagebreak">&nbsp;</p>`;
		if (client === "Elsevier") {
			html += `
      <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="12" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">FLEXIBLE WORKING</div>
          </td>
        </tr>
        <tr>
          <td colspan="6" class="cell cell_heading" style="background-color: ${lblColour}">
            Flexible Working Patterns
          </td>
          <td class="cell cell_heading" style="background-color: ${lblColour}" colspan="6">Any additional comments on Flexible Working Patterns (Mon-Fri, Shifts etc)</td>
        </tr>
        <tr>
          <td colspan="6" style="min-height:50px">${doc.parttypes
					.find((pt) => pt.type === "Working Pattern")
					// eslint-disable-next-line vue/no-parsing-error
					?.parts[0].text.replace(/[\u{0080}-\u{FFFF}]/gu, "") || ""}</td>
          <td colspan="6">&nbsp;</td>
        </tr>
        <tr>
          <td colspan="6" class="cell cell_heading" style="height:45px;background-color: ${lblColour}">Location and Travel Requirement</td>
          <td colspan="6">&nbsp;</td>
        </tr>
      </table>
      <br>
      <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="12" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">SOURCING STRATEGY</div>
          </td>
        </tr>
        <tr>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Internals and Referrals</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Alternate Job Titles and Keywords</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Qualification</td>
        </tr>
        <tr style="height:70px">
          <td colspan="4">
            <ul>
              <li>Internal Applicants</li>
              <li>Referrals</li>
              <li>Internal Communities</li>
              <li>Promotion through ERG’s</li>
              <li>Hot Jobs/New Jobs</li>
            </ul>
          </td>
          <td colspan="4">
            <ul>
              <li>Internal Applicants</li>
              <li>Referrals</li>
              <li>Internal Communities</li>
              <li>Promotion through ERG’s</li>
              <li>Hot Jobs/New Jobs</li>
            </ul>
          </td>
          <td colspan="4">
            <ul>
              <li>Internal Applicants</li>
              <li>Referrals</li>
              <li>Internal Communities</li>
              <li>Promotion through ERG’s</li>
              <li>Hot Jobs/New Jobs</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Target Competitors</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Job Boards</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Associations and Social Media Networks</td>
        </tr>
        <tr style="height:70px">
          <td colspan="4">
            <ul>
              <li>Opex Exhibitions Services</li>
              <li>Afar Exhibition Services</li>
              <li>Barclays</li>
              <li>BBC</li>
              <li>HSBC</li>
              <li>CNN</li>
              <li>Department for Transport</li>
            </ul>
          </td>
          <td colspan="4">
            <ul>
              <li>Indeed.com</li>
              <li>CareerBuilder Diversity</li>
              <li>Vercida</li>
              <li>LinkedIn</li>
            </ul>
          </td>
          <td colspan="4">
            <ul>
              <li>Warrier Forum</li>
              <li>Inbound.org</li>
              <li>Moz Community</li>
              <li>Growth Hackers</li>
              <li>Social Media channel search using key words / title / alternative titles</li>
            </ul>
          </td>
        </tr>
      </table>
      <p class="pagebreak">&nbsp;</p>
      <div style="color:orange; font-weight:bold">Interview Panel</div>
      <table style="width: 100%">
        <tr>
          <td class="cell cell_heading" colspan="1" style="background-color: ${lblColour}">No.</td>
          <td class="cell cell_heading" colspan="3" style="background-color: ${lblColour}">Name</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Position</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Stage Involved</td>
        </tr>
        <tr>
          <td class="cell" colspan="1">1</td>
          <td class="cell" colspan="3">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell" colspan="1">2</td>
          <td class="cell" colspan="3">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell" colspan="1">3</td>
          <td class="cell" colspan="3">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell" colspan="1">4</td>
          <td class="cell" colspan="3">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
        </tr>
      </table>
      <br>
      <div style="color:orange; font-weight:bold">Agreed Interview Process</div>
      <table style="width: 100%">
        <tr>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Stage</td>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Format</td>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Duration</td>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Location</td>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Dates Available</td>
        </tr>
        <tr>
          <td class="cell_5" style="max-width:20%">Interview 1 (Hiring Manager Interview)</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell_5" style="max-width:20%">Interview 2 (Technical Interview)</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell_5" style="max-width:20%">Interview 3 (Validation Interview)</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
        </tr>
      </table>
      <br>
      <div style="color:orange; font-weight:bold">Communication Plan</div>
      <table style="width: 100%">
        <tr>
          <td class="cell cell_heading" colspan="2" style="background-color: ${lblColour}">&nbsp;</td>
          <td class="cell cell_heading" colspan="1" style="background-color: ${lblColour}">Frequency</td>
          <td class="cell cell_heading" colspan="2" style="background-color: ${lblColour}">Day(s)</td>
          <td class="cell cell_heading" colspan="1" style="background-color: ${lblColour}">Time</td>
          <td class="cell cell_heading" colspan="2" style="background-color: ${lblColour}">Method</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Contents</td>
        </tr>
        <tr>
          <td class="cell" colspan="2">Best Practice</td>
          <td class="cell" colspan="1">Weekly</td>
          <td class="cell" colspan="2">Mon or Fri</td>
          <td class="cell" colspan="1">Optional</td>
          <td class="cell" colspan="2">Email followed by a phone call</td>
          <td class="cell" colspan="4">
            <ul>
              <li>Sourcing Update</li>
              <li>Any Challenges</li>
              <li>Market Insight</li>
              <li>Role Alignment</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td class="cell" colspan="2">Agreed Plan</td>
          <td class="cell" colspan="1"></td>
          <td class="cell" colspan="2"></td>
          <td class="cell" colspan="1"></td>
          <td class="cell" colspan="2"></td>
          <td class="cell" colspan="4">
            &nbsp;
          </td>
        </tr>
      </table>
      `;
		} else {
			html += `
      <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">INSIGHT AND MARKET INTELLIGENCE</div>
          </td>
        </tr>
        <tr style="height:60px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">
            Are there alternative job titles / common in competitor market? TA to make suggestions
          </td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height:50px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">
            Any movements in the market that you are aware of? Any opportunity? 
          </td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">SOURCING & ATTRACTION STRATEGY</div>
          </td>
        </tr>
        <tr style="height:70px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">Please provide any details of target competitors/ local communities/groups</td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height:70px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">Have you asked for referrals from relevant team/networks? Discuss LinkedIn, sharing posts etc.</td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">ANY OTHER COMMENTS/NOTES</div>
          </td>
        </tr>
        <tr style="height:150px">
          <td colspan="2">&nbsp;</td>
        </tr>
      </table>
      <p class="pagebreak">&nbsp;</p>
      <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">INTERVIEW & ASSESSMENT</div>
          </td>
        </tr>
        <tr style="height:150px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">
          How many interviews will there be and what format will these be in (phone, face-to-face, presentation)?<br>
          How long will each interview last?<br>
          Have you already considered who you would like in the interview process and what’s the diversity of the panel?<br>
          Do they know they will be involved?<br>
        `;
		}
		if (
			client === "Akamai" ||
			client === "staging"
		) {
			html += `<b>If appropriate</b> - please discuss if HackerRank should support this recruitment (this link should be sent as pre work)
                  https://docs.google.com/presentation/d/15LPYM5ZelFaN1DV1JXZziyD62iW6VENdkBXNRIfL6lc/edit?usp=sharing`;
		}
		if (client !== "Elsevier") {
			html += `</td>
            <td>&nbsp;</td>
          </tr>
          <tr style="height: 10px">
            <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
              <div class="section_heading">ANY OTHER COMMENTS/NOTES</div>
            </td>
          </tr>
          <tr style="height:200px">
            <td colspan="2">&nbsp;</td>
          </tr>
          </table>
        </body></html>`;
		} else {
			html += `</body></html>`;
		}
		html = html.replace(/’/g, "'");
		html = html.replace(/•/g, "");

		html2docx(html, {
			orientation: "landscape",
			margins: { top: 500, bottom: 500, left: 500, right: 500 },
		}).then(data => {
			utils.downloadFile(
				data,
				doc.system_number + " - " + doc.doc_name + " - Intake Flow.docx",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			);
		});
	},
	generateScreeningTemplate(doc, client) {
		let html = `<!DOCTYPE  html>
      <html><body>
      <style>
        .pagebreak { page-break-before: always !important; }
        .cell_p0 { min-width: 15%; max-width: 15%; width: 15%;}
        .cell_p1 { padding: 5px; pmin-width: 20%; max-width: 20%; width: 20%;}
        .cell_p2 { padding: 5px; min-width: 25%; max-width: 25%; width: 25%;}
        .cell_p3 { padding: 5px; min-width: 30%; max-width: 30%; width: 30%;}
        .cell_p4 { padding: 5px; min-width: 50%; max-width: 50%; width: 50%; }
        .cell_p75 { padding: 5px; min-width: 75%; max-width: 75%; width: 75%; }
        .cell_p80 { padding: 5px; min-width: 80%; max-width: 80%; width: 80%; }
        .cell_across { padding: 0; min-width: 100%; max-width: 100%; width: 100%; }
        .page_heading { font-weight: bold; font-size: 14pt; text-decoration: underline; text-align: center;}
        .section_heading { padding-top: 8px; padding-bottom: 8px; padding-left: 15px; margin:0; font-weight: bold; text-align: center;}
        .cell_heading { padding:0; font-weight: bold; background-color: #BFBFBF; }
        .ivSection { font-weight: bold; font-family: calibri; min-height: 500px; }
        .cell_hint { font-style: italic; color: gray; }
        .bold { font-weight: bold;  }
        table, th, td { border: 1px solid black; border-collapse: collapse; margin-top: 20px; padding-top: 20px; }
        td { padding: 0 5px; }
        * { font-family: calibri; font-size: 9pt; }
      </style>
      <div class="page_heading">${client} Screening Template</div> 
      <div class="page_heading">${doc.doc_name}</div> 
      <br/>
      <table style="width: 100%;">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEW INFORMATION</div>
        </td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Recruiter:</div></td>
        <td class="cell_p75">${doc.recruiter}</td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Candidate Name</div></td>
        <td class="cell_p75"></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Source of Candidate</div></td>
        <td class="cell_p75"></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Date of Interview</div></td>
        <td class="cell_p75 cell_hint"></td>
      </tr>
      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTRODUCTION</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td colspan="2">
        <div class="cell_hint">
        Explain the structure of the interview <br/>
        About the Company: Do they know the company? If not explain at a high level what the company does<br/>
        About the Team and the Role: provide high level overview of the team, the role and reason for hire (scroll to bottom on template to see information)
        </div>
        </td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">CURRENT SITUATION</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">What are your drivers for new role? What would motivate you to make a change?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">OTHER OPPORTUNITIES</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Are you looking at other opportunities? Are you interviewing elsewhere? If yes, what stage are you at and how you feel about the opportunity? Compared to this one?</div> </td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">SALARY</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">What are your salary/compensation expectations? </div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">NOTICE PERIOD</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">What is your availability to start a new role? What is your notice period?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">NON-COMPETE</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Do you know of any active non-compete or non-solicitation clauses that might hinder you from joining the company?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">RIGHT TO WORK</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Do you have the relevant right to work in the country of hiring?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">LOCATION & TRAVEL</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Provide any information on location and travel commitments (scroll to bottom on template to see information) </div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">WORKING PATTERN</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Provide any information on flexible working pattern available and ask candidate for preferred way of working </div></td>
      </tr>
      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">CANDIDATE PROFILE</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td colspan="2">
        
        </td>
      </tr>		
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">CAREER SUMMARY</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Ask the candidate to spend 5 minute to bring their career history to life (talk through career history and job roles at a high level, probe on why left each role)</div></td>
      </tr>
      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">JOB RELATED EXPERIENCE</div>
        </td>
      </tr>`;

		doc.parttypes
			.find((pt) => pt.type === "Requirement")
			.parts.filter((p) => p.is_essential)
			.forEach((p) => {
				html += `
            <tr style="height:20px">
              <td class="cell_p4 bold">${p.text}</td>
              <td class="cell_p4 cell_hint"><div class="cell_hint">Additional information to understand this requirement and gather insights on screening questions</div></td>
            </tr>
            <tr style="height:20px">
              <td colspan="2" class="cell_p4 cell_hint"><div class="cell_hint">Ask the candidate to provide examples of relevant experience or capabilities aligned to specific screening requirements above</div></td>
            </tr>`;
			});

		html += `

      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEW & NEXT STEPS</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td colspan="2">
        <div class="cell_hint">
        Explain the next steps and, if they were successful, what the format of the interview process would be  <br/>
        (scroll to bottom on template to see information) 
        </div>
        </td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">AVAILABILITY FOR INTERVIEW</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">What is your availability to interview? Are there any dates that will not work for you?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">REASONABLE ADJUSTMENTS</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Ask the candidate if they require any reasonable adjustments for the interview</div></td>
      </tr>
      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">ABOUT THE JOB</div>
        </td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Job USP Copy</div></td>
        <td class="cell_p75">${doc.parttypes.find((pt) => pt.type === "Job USP").parts[0]
				.text
			}</td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Job Information</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Add any more insight into the Job. Additional unique selling points. Why should anyone apply for the position? What will the role holder take ownership for? What is the opportunity to make a difference?</div></td>
      </tr>	
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">RESPONSIBILITIES</div>
        </td>
      </tr>`;

		doc.parttypes
			.find((pt) => pt.type === "Responsibility")
			.parts.forEach((p) => {
				html += `
            <tr style="height:20px">
              <td class="cell_p4 bold">${p.text}</td>
              <td class="cell_p4 cell_hint"><div class="cell_hint">Add any more information to bring the day to day role to life</div></td>
            </tr>`;
			});

		html += `</table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">FLEXIBLE WORKING, LOCATION & TRAVEL REQUIREMENTS</div>
        </td>
      </tr>`;

		let flexParts = doc.parttypes.filter(
			(pt) =>
				pt.type === "Working Pattern" ||
				pt.type === "Flex Work Details" ||
				pt.type === "Work Pattern Details"
		);
		if (flexParts.length > 0) {
			flexParts.forEach((fp) => {
				fp.parts.forEach((p) => {
					html += `
                <tr style="height:20px">
                  <td class="cell_p4 bold">${p.text.replace(
						/[\u{0080}-\u{FFFF}]/gu,
						""
					)}</td>
                  <td class="cell_p4 cell_hint"><div class="cell_hint">Additional information to understand the flexible working options or bring it to life.</div></td>
                </tr>`;
				});
			});
		}

		html += `
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Location:</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Travel Requirement:</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the travel requirements for the role</div></td>
      </tr>		
      </table>	
      <p class="pagebreak">&nbsp;</p>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="5" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEW PROCESS</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Stage</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Format</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Duration</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Location</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Dates Available</td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      </table>
      </body></html>`;

		html2docx(html, {
			orientation: "portrait",
			margins: { top: 500, bottom: 500, left: 500, right: 500 },
		}).then(data => {
			utils.downloadFile(
				data,
				doc.system_number + " - " + doc.doc_name + " - Screening Template.docx",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			);
		});
	},
	generateDownload(download_format, document) {
		if (document) {
			utils
				.fetchDocumentData(document.system_number)
				.then((resp) => {
					switch (download_format) {
						case "PDF":
							this.generatePDF(resp.document);
							break;
						case "WORD_NOTES":
							this.generateDOCX(resp.document, true);
							break;
						case "RTF":
							this.generateRTF(resp.document, null);
							break;
						case "INTERVIEW_TMPL":
							this.generateInterviewTemplate(resp.document);
							break;
						case "INTAKE_FLOW":
							this.generateIntakeFlow(
								resp.document,
								this.$loginState.user.client
							);
							axios
								.post("client/recordTransaction/", {
									transaction_type: "intake_flow",
									attribute: "doc_id",
									attribute_id: document.doc_id,
								})
								.then((resp) => {
									if (resp.data.Status !== "OK") {
										resp.data.Message;
										this.response = resp.data;
									}
									this.isLoading = false;
								})
								.catch((err) => {
									if (err.response && err.response.status === 401) {
										this.$emit("sessionExpired", err);
									} else {
										console.log(err);
										this.response = err.response
											? err.response.data
											: { message: "Unexpected Error" };
									}
									this.isLoading = false;
								});
							break;
						case "JOBSHARE_TMPL":
							this.generateJobShareTemplate(resp.document, null);
							break;
						case "SCREENING_TMPL":
							this.generateScreeningTemplate(
								resp.document,
								this.$loginState.user.client
							);
							break;
						case "WORD":
						default:
							this.generateDOCX(resp.document);
							break;
					}
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						this.$emit("sessionExpired", err);
					} else {
						console.log(err);
						this.response = err.response
							? err.response.data
							: { message: "Unexpected Error" };
					}
				});
		}
	},
};
