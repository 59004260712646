<template>
  <div>
    <v-navigation-drawer
      v-if="!$loginState.noAccess && qaActions.length > 1"
      v-model="menu"
      app
      temporary
      width="300"
      class="no-print"
    >
      <v-list class="pb-0">
        <v-list-item class="logo">
          <v-list-item-avatar>
            <img
              width="40"
              height="40"
              src="@/assets/rolemapper-icon-new.png"
              alt="RM"
            />
          </v-list-item-avatar>
          <v-list-item-title>
            <img
              height="25"
              src="@/assets/rolemapper-headline.png"
              alt="RoleMapper"
            />
            <v-btn icon aria-label="Close Navigation Menu" class="float-right" style="margin-top: -7px" @click="menu = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list nav dense class="menu" ref="navMenu">
        <div v-for="n in menuItems" :key="n.name">
          <v-list-item class="pl-2 ml-0" :class="n.text.split(' ').join('')" link v-if="!n.subItems" :to="n.name">
            <v-list-item-icon>
              <v-icon :class="n.iconClass">{{ n.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ n.text }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-else :prepend-icon="n.icon" v-model="n.expanded">
            <template v-slot:activator>
              <v-list-item-title>{{ n.text }}</v-list-item-title>
            </template>
              <v-list-item
                class="pl-2 ml-0"
                link
                v-for="si in n.subItems"
                :key="si.name"
                :to="si.name"
              >
                <v-list-item-icon>
                  <v-icon :class="si.iconClass">{{ si.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ si.text }}</v-list-item-title>
              </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      flat
      app
      dense clipped-left
      class="no-print"
      v-if="$loginState.isAuthenticated"
    >
      <v-app-bar-nav-icon
        v-if="!$loginState.noAccess && qaActions.length > 1"
        @click="menu = !menu"
        class="mr-2"
        title="Navigation Menu"
        :aria-expanded="menu.toString()"
      />
      <v-btn
        v-if="
          $vuetify.breakpoint.smAndUp &&
          $loginState.user.createDocumentDialogOptions.length &&
          !($route.name === 'home' || $route.meta.hideHeaderCreate)
        "
        :title="'Create New ' + ($loginState.user.createDocumentDialogOptions.length === 1 ? $loginState.user.createDocumentDialogOptions[0].tmpl_name : 'Document')"
        @click="$emit('addDocument')"
        color="primary"
        small
        outlined
      >
        <span>Create</span>
        <!-- <v-icon>mdi-plus</v-icon> -->
      </v-btn>

      <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp" class="ml-0 pl-4">
        {{ toolbarTitle }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
          v-if="$support.status"
          small color="#DB0066" 
          class="userPilotFeedbackBtn" @click="triggerFeedback"
        >
          Feedback
        </v-btn>        
      <v-toolbar-title class="ml-0 pr-4 clientName" v-if="showClient">{{
        $loginState.user.client
      }}</v-toolbar-title>
      <v-toolbar-items>
        <v-btn icon v-if="!showSearch" @click="showSearch = true" title="Search">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-expand-x-transition v-if="showSearch">
          <HeaderSearch
            @close="showSearch = false"
            @openDocument="openDocument"
          />
        </v-expand-x-transition>
        <v-menu
          offset-y
          transition="slide-y-transition"
          v-if="!$loginState.noAccess"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" title="Recent Documents" class="histBtn">
              <v-icon v-if="recentDocs.length">mdi-history</v-icon>
              <v-icon v-else color="grey lighten-2">mdi-history</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              @click="$emit('addDocument')"
              v-if="$loginState.user.createDocumentDialogOptions.length"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>add</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                >Create New
                {{
                  $loginState.user.createDocumentDialogOptions.length === 1
                    ? $loginState.user.createDocumentDialogOptions[0].tmpl_name
                    : "Document"
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="subtitle-1"
                >Recently Viewed</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              v-for="d in recentDocs"
              :key="d.id"
              @click="$emit('openDocument', d)"
            >
              <v-list-item-icon class="mr-3">
                <v-avatar class="jobTypeAvatar" size="25">
                  <span class="subtitle-2">{{ getAbbreviation(d.type) }}</span>
                </v-avatar>
              </v-list-item-icon>
              <v-list-item-title>
                <span v-html="d.id.substring(3, 12) + ' - ' + d.title"></span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="$support.status"
          :title="$support.status == 'open' ? 'Hide Help' : 'Request Help'"
          icon
          @click="$support.toggle()"
        >
          <v-icon class="outlined">live_help</v-icon>
        </v-btn>


        <v-menu
          v-model="gatewayLog"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="useGateway"
              icon
              v-bind="attrs" v-on="on"
              title="Messages"
            >
              <v-badge
                :content="messages"
                :value="messages"
                overlap
                dot
              >
                <v-icon :color="getConnectionState">sensors</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <v-card width="450">
            <v-card-text>
              <v-row dense>
                <v-col class="d-flex align-center justify-space-between" cols="8">
                  <h4>Gateway Event Log</h4>
                </v-col>
                <v-col class="align-right" cols="4" align="right">
                  <v-icon color="red" v-if="!$eventGateway.connectionState">
                    power_off
                  </v-icon>
                  <v-icon color="green" v-if="$eventGateway.connectionState">
                    power
                  </v-icon>
                </v-col>
              </v-row>  
              <v-row dense v-for="(event,idx) in $eventGateway.events.slice(0,5)" :key="idx">
                <v-col cols="12">{{event.event_type}}</v-col>
              </v-row>            
            </v-card-text>
            <v-card-actions>
              <v-row dense>
                <v-col cols="12" align="right">    
                  <v-btn dense small color="primary" @click="goToGatewayLog">View More...</v-btn>
                </v-col>
              </v-row>      
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn
          icon
          v-if="
            $loginState.user &&
            $loginState.user.settings.some(
              (s) =>
                s.setting === 'user_settings' && s.value.indexOf('allow') === 0
            )
          "
          @click.stop="$emit('openUserSettings')"
          title="Settings"
          :aria-expanded="settingsExpanded.toString()"
        >
          <v-icon title="Settings"> settings </v-icon>
        </v-btn>
        <v-menu
          v-model="account"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon title="Account" v-bind="attrs" v-on="on">
              <v-avatar :class="!$loginState.impersonating ? 'secondary-background' : ''" :color="$loginState.impersonating ? 'success darken-2' : ''" size="32">
                <span v-if="$loginState.impersonating" class="white--text body-2">{{ $loginState.user.impersonatedInitials }}</span>
                <span v-else class="body-2">{{ $loginState.user.userInitials }}</span>
              </v-avatar>
              <!-- <v-icon>mdi-account</v-icon> -->
            </v-btn>
          </template>
          <v-card width="450">
            <v-card-text>
              <v-row>
                <v-col class="d-flex align-center justify-space-between">
                  <h2>{{ $loginState.user.client }}</h2>
                  <v-btn v-if="!$loginState.sso" text color="primary" @click="logout">
                    Logout
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-start">
                  <v-avatar color="primary" dark>
                    <span class="white--text headline">{{ $loginState.user.userInitials }}</span>
                  </v-avatar>
                  <div class="pl-3">
                    <h2 class="mt-1 mb-0">{{ $loginState.user.impersonator_name || $loginState.user.name }}</h2>
                    <p>{{ $loginState.user.impersonator_email || $loginState.user.email }}</p>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="$loginState.impersonating">
                <v-col cols="12" class="d-flex align-center justify-space-between">
                  <h2>Impersonating</h2>
                  <v-btn color="primary" text @click="impersonateRevert">
                    Revert
                  </v-btn>
                </v-col>
                <v-col class="d-flex justify-start">
                  <v-avatar color="success darken-2" dark>
                    <span class="white--text headline">{{ $loginState.user.impersonatedInitials }}</span>
                  </v-avatar>
                  <div class="pl-3">
                    <h2 class="mt-1 mb-0">{{ $loginState.user.name }}</h2>
                    <p>{{ $loginState.user.email }}</p>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="$loginState.canImpersonate">
                <v-col cols="12">
                  <h2> {{ $loginState.impersonating ? $loginState.isInternal ? 'Switch Customer/User' : 'Switch User' : 'Impersonation' }}</h2>
                </v-col>
                <v-col cols="6" v-if="$loginState.isInternal">
                  <v-select
                    v-if="$loginState.isInternal"
                    label="Customer"
                    v-model="customer"
                    :items="customerList"
                    item-text="client_name"
                    return-object
                    dense
                    outlined
                    hide-details
                    ></v-select>
                </v-col>
                <v-col :cols="$loginState.isInternal ? 6 : 12">
                  <v-combobox
                    label="Select User" outlined
                    :items="impersonateUsers"
                    dense
                    item-text="name"
                    item-value="user_id"
                    return-object
                    v-model="impersonateUser"
                    hide-details
                    >
                  </v-combobox>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    label="Reason"
                    outlined
                    v-model="impersonateReason"
                    dense
                    hide-details
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="d-flex align-center justify-end">
                  <v-btn color="primary"
                    :disabled="!impersonateUser"
                    @click="impersonate"
                    >
                    {{ $loginState.impersonating ? 'Switch' : 'Impersonate' }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import HeaderSearch from "@/components/cHeaderSearch";

export default {
  name: "HeaderNew",
  components: {
    HeaderSearch,
  },
  props: {
    recentDocs: { type: Array, required: true },
    qaActions: { type: Array, required: true },
    settingsExpanded: { type: Boolean, default: false }
  },
  data: function () {
    return {
      menu: false,
      account: false,
      showSearch: false,
      menuItems: [],
      customer: null,
      impersonateUsers: [],
      impersonateUser: null,
      impersonateReason: "",
      useGateway: false,
      messages: 0,
      gatewayLog:false
    };
  },
  created() {
    this.menuInit();
  },
  updated() {},
  watch: {
    qaActions() {
      this.menuInit();
    },
    account(val) {
      if (val) {
        this.impersonateUser = null;
        this.impersonateReason = "";
        this.customer = null;

        if (this.$loginState.isInternal)
          this.customer = this.customerList.find(c => c.client_id === this.$loginState.user.client_id);
        
        if (!this.customer)
          this.loadImpersonationUsers();
      }
    },
    customer(val) {
      if (this.$loginState.isInternal
        && val && val.internal_user_id) {
        
        this.loadImpersonationUsers();
        if (this.$loginState.user.user_id !== val.internal_user_id) {
          const internalUser = {
            user_id: val.internal_user_id,
            name: val.internal_user_name
          };
          this.impersonateUser = internalUser;
          this.impersonateUsers.push(internalUser);
        }
      }
    },
    menu(val) {
      if (val) {
        const section = this.menuItems.find(
          (i) => i.name == this.$route.meta.section && i.subItems
        );
        if (section) section.expanded = true;
        setTimeout(() => {
          this.$refs.navMenu?.$el?.querySelector("a.v-list-item--link.v-list-item--active")?.focus();
        }, 200);
      }
    }
  },
  mounted () {
    window.addEventListener('keydown', this.escKeyHandler);
  },
  destroyed () {
    window.removeEventListener('keydown', this.escKeyHandler);
  },
  computed: {
    getConnectionState(){
      let state = this.$eventGateway.connectionState;
      return state ? "green":"red";
    },
    toolbarTitle: function () {
      if (!this.$loginState.isAuthenticated) return "RoleMapper";
      if (this.$route.meta.dashboardId) return "";
      switch (this.$route.name) {
        case "home":
          return this.screenName;
        default:
          return "";
      }
    },
    showClient: function () {
      return (
        this.$vuetify.breakpoint.mdAndUp &&
        this.$loginState.user.client !== "Scottish Parliament" &&
        this.$loginState.user.client !== "Akamai" &&
        this.$loginState.user.client !== "Baker Hughes"
      );
    },
    ...mapState({
      screenName: (state) => state.header.screenName,
      customerList: (state) => state.customers.customerList,
      docTypes: (state) => state.hierarchies.docTypes,
    }),
  },
  methods: {
    triggerFeedback(){
      if(window.userpilot){
        window.userpilot.trigger('survey:f5KzPV_rS5');
      }
    },
    goToGatewayLog(){
      this.gatewayLog = false;
      this.$router.push({ name: 'eventsGateway'}); 
    },
    toggleSearch() {
      if (this.showSearch) {
        this.showSearch = false;
      } else {
        this.showSearch = true;
      }
    },
    openDocument(doc) {
      this.showSearch = false;
      this.$emit("openDocument", doc);
    },
    getAbbreviation(docType) {
      return this.docTypes.find(dt => dt.tmpl_name === docType)?.docAbbrev || this.getInitials(docType) || "JA";
    },
    getInitials(input) {
      if (!input) return "";

      const names = input.split(" ");
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    menuInit() {
      if (!this.qaActions || this.qaActions.length == 0)
        this.menuItems.splice(0);

      this.menuItems = this.qaActions
        .filter(
          (a) =>
            !this.$router.menuSections.some((s) => s.name == a.meta.section)
        )
        .map((i) => {
          const iconSplit = i.meta?.icon?.split(" ") || [];
          return {
            name: i.name,
            path: i.path,
            icon: iconSplit[0],
            iconClass: iconSplit.length ? iconSplit[1] : "",
            text: i.meta.text,
          };
        });

      this.$router.menuSections.forEach((s) => {
        const sectionItems = this.qaActions
          .filter((a) => s.name == a.meta.section)
          .map((i) => {
            const iconSplit = i.meta?.icon?.split(" ") || [];
            return {
              name: i.name,
              path: i.path,
              icon: iconSplit[0],
              iconClass: iconSplit.length ? iconSplit[1] : "",
              text: i.meta.text,
            };
          });
        if (sectionItems.length == 1) {
          this.menuItems.push(sectionItems[0]);
        } else if (sectionItems.length > 1) {
          this.menuItems.push({
            name: s.name,
            icon: s.icon,
            iconClass: s.iconClass,
            text: s.name,
            expanded: sectionItems.some((i) => i.name == this.$route.name),
            subItems: sectionItems,
          });
        }
      });

      this.useGateway = this.$loginState.user.settings.some(s => s.setting === 'use_event_gateway' && s.value === 'true');
      if(this.useGateway){
        //alert(this.$gatewayPlugin.state.connectionState);
        this.$emit('handleGatewayEvent',"connect");
      }
    },
    escKeyHandler (event) {
      if (event.code === 'Escape') {
        this.menu = false;
      }
    },
    logout() {
      this.account = false;
      this.$emit('logout');
    },
    loadImpersonationUsers() {
      this.impersonateUsers.splice(0);
      axios
        .get(`user/impersonationUsers/${this.customer ? this.customer.client_id : this.$loginState.user.client_id}`)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.impersonateUsers.push(...resp.data.Data);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    impersonate() {
      if (this.impersonateUser && this.impersonateUser.user_id) {
        this.account = false;
        this.$emit("impersonate", {
          impersonateUserId: this.impersonateUser.user_id,
          impersonateReason: this.impersonateReason
        });
      }
    },
    impersonateRevert() {
      this.account = false;
      this.impersonateUser = null;
      this.$emit("impersonateRevert");
    }
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/vars";

.logo {
  min-height: 60px;
  padding: 0 8px;
  .v-list-item__title {
    padding-top: 10px;
    padding-left: 6px;
  }
}
.menu ::v-deep {
  .v-list-group__header.v-list-item--active {
    background-color: transparent !important;
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #54616c !important;
  }
  .theme--light.v-list-item:not(.v-list-item--active) .v-icon {
    color: #7d93b4;
  }
  .theme--light.v-list-item:focus {
    background-color: #F6F6F6 !important;
  }
}
.clientName {
  font-weight: 600;
  font-size: 0.625rem;
  text-transform: uppercase;
}
.v-application.theme--light {
  .clientName {
    color: $input-icon-color;
  }
}
</style>