import { render, staticRenderFns } from "./cHeaderOld.vue?vue&type=template&id=92e442f8&scoped=true"
import script from "./cHeaderOld.vue?vue&type=script&lang=js"
export * from "./cHeaderOld.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92e442f8",
  null
  
)

export default component.exports