<template>
  <Landing
    v-if="pageModel"
    v-model="pageModel"
    :qaActions="qaActions"
    @rowEdit="$emit('rowEdit', $event)"
    @editTile="$emit('editTile', $event)"
    @documentCreated="$emit('documentCreated', $event)"
    @sessionExpired="$emit('sessionExpired', $event)"
    @openDocument="$emit('openDocument', $event)"
    @copyDocument="$emit('copyDocument', $event)"
    @documentChangedHandler="$emit('documentChangedHandler', $event)"
    @addDocument="$emit('addDocument', $event)"
  >
  </Landing>
</template>

<script>
import Landing from "@/components/landingPages/cLanding";
import landingUtils from "@/components/landingPages/landingUtils.js";

export default {
  name: "vLandingHolder",
  components: {
    Landing,
  },
  props: {
    qaActions: { type: Array }
  },
  data: function () {
    return {
      isLoading: false,
      response: null,
      pageModel: null
    };
  },
  mounted() {},
  created() {
    this.init();
  },
  watch: {
    $route() {
      this.pageModel = null;
      const that = this;
      this.$nextTick(() => {
         that.init();
      });
    }
  },
  computed: {
  },
  methods: {
    init() {
      const config = this.$loginState.getLandingConfig(this.$route.name);
      if (config && config.definition) {
        const definition = JSON.parse(config.definition);

        landingUtils.prepLandingPageDefinition(definition, this.docTypes);

        definition.tabs.forEach((t) => {
          t.cols.forEach((c) => {
            c.rows = c.rows || [];
            c.rows.forEach((r) => {
              r.links = r.links?.map((l) => {
                l.isCreateLink = l.type === "createdoc";
                if (l.isCreateLink) {
                  l.isAccessible = true;
                  delete l.url;
                } else {
                  l.isExternal = l.url && l.url.startsWith("http");
                  if (!l.isExternal && l.url && l.url.charAt(0) === "/")
                    l.url = l.url.substring(1);
                  l.isAccessible =
                    l.isExternal ||
                    !l.url ||
                    !this.qaActions ||
                    this.qaActions.some(
                      (a) =>
                        a.name.toLowerCase() === l.url.toLowerCase().split("?")[0]
                    );
                }
                return l;
              })
              .filter((l) => l.isAccessible) || [];
            })
          })
        });

        this.pageModel = definition;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
</style>