<template>
  <div style="height:100%;max-height:100%; padding: 0 !important; margin: 0 !important;">
  <v-card flat style="height:100%;max-height:100%; padding: 0 !important; margin: 0 !important;">
    <v-card-title v-if="visibleDatabaseTabs.length > 1">
      <div>
        <v-tabs v-model="databaseTab" small class="databaseTabs">
          <v-tab v-for="tab in databaseTabs" :key="tab.id" :class="tab.class" v-show="tab.show">
            <span v-html="tab.name"></span>
          </v-tab>
        </v-tabs>
      </div>

      <v-spacer></v-spacer>
      <v-btn small icon @click="closeDatabasePanel()">
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title v-else class="pb-0">
      Database
      <v-spacer></v-spacer>
      <v-btn small icon @click="closeDatabasePanel()">
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="docView">
      <v-tabs-items v-model="databaseTab">
        <v-tab-item v-for="tab in databaseTabs" :key="tab.id">
          <template v-if="tab.id === 'relatedContent'">
            <v-row v-if="!relatedDoc.loaded && !databaseLoading && !fileLoading">
              <v-col
                cols="12"
                v-if="databaseViewMode === 'list'"
                style="padding-right: 3px"
              >
                <v-data-table
                  dense
                  :footer-props="{
                    'items-per-page-options': [15, 50, 100, -1],
                  }"
                  :headers="databaseRelatedHeaders"
                  :items="databaseRelatedDocs"
                  item-key="id"
                  style="width: 100%; margin-right: 3px"
                  class="elevation-0 row-click"
                  @click:row="viewDatabaseDoc"
                >
                  <template v-slot:[`item.source`]="{ item }">
                    <v-chip
                      small
                      class="status-chip"
                      label
                      :color="getSourceColor(item)"
                      >{{ item.source }}</v-chip
                    >
                  </template>
                  <template v-slot:[`item.relationship`]="{ item }">
                    <span style="white-space: nowrap;">{{ item.relationship }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-menu  offset-y bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="float-right"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click="viewDatabaseDoc(item)">
                          <v-list-item-icon>
                            <v-icon>visibility</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            View
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.file_id" @click="nlpUtils.downloadFile(item.file_id)" >
                          <v-list-item-icon>
                            <v-icon>mdi-download</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Download
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.source === 'Upload' && ($loginState.user.canDeleteAnyFile || item.can_delete == 1)" @click="deleteDatabaseDoc(item)" >
                          <v-list-item-icon>
                            <v-icon>delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Delete
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <div
              v-if="databaseLoading || fileLoading"
              class="d-flex text-center align-center mt-6 flex-column"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                style="top: 45%"
                class="mb-3"
              ></v-progress-circular>
              <p class="body-1">
                Hey there, your content is on its way!<br />
                Hang tight and get ready to be amazed
              </p>
            </div>
            <v-row v-else-if="relatedDoc.loaded">
              <v-col cols="12" dense class="d-flex">
                <v-btn
                  v-if="relatedDoc.loaded === true"
                  small
                  @click="toggleDatabaseDisplay"
                  color="secondary"
                  class="userPilotReturnToListBtn"
                >
                  <v-icon left>mdi-chevron-double-left</v-icon>
                  Return to List
                </v-btn>
                <v-spacer></v-spacer>

                <span
                  v-if="
                    databaseSelectionState.dragStart &&
                    databaseSelectionState.data === ''
                  "
                  style="color: orange"
                  ><v-icon small color="orange"
                    >mdi-close-octagon-outline</v-icon
                  >No Data selection</span
                >
                <span
                  dense
                  :title="databaseSelectionState.data"
                  style="color: green"
                  v-else-if="databaseSelectionState.data !== ''"
                  ><v-icon small color="green"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  Data Selected Succesfully</span
                >
                <div v-else-if="relatedDoc.item" class="db-file-name">
                  <h3>{{ relatedDoc.item.file_name }}</h3>
                  <v-btn small icon title="Download File" v-if="relatedDoc.item.file_id" @click="nlpUtils.downloadFile(relatedDoc.item.file_id)" class="float-right">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </div>
                
                <v-spacer></v-spacer>
                <template v-if="config.contentGenerationConfig.enableOptimize || config.contentGenerationConfig.enableSkillsExtract">
                  <v-btn color="primary" small
                    v-if="relatedDoc && relatedDoc.optimiseText && relatedDoc.showOptimise"
                    @click="relatedDoc.showOptimise = false"
                    class="userPilotShowOriginalBtn"
                  >
                    Show Original
                  </v-btn>
                  <template v-else>
                    <v-btn v-if="(multidoc || document.state.canEditDocParts) && config.contentGenerationConfig.enableOptimize" color="primary" small @click="generateContent('optimise')"
                      class="ml-2 userPilotOptimiseBtn">
                      Optimise
                      <v-icon right>mdi-creation</v-icon>
                    </v-btn>
                    <v-btn v-if="(multidoc || document.state.canEditDocParts) && config.contentGenerationConfig.enableSkillsExtract" color="primary" small @click="generateContent('skills_extract')"
                      class="ml-2 userPilotSkillsExtractBtn">
                      Extract Skills
                      <v-icon right>mdi-download</v-icon>
                    </v-btn>
                  </template>
                </template>
              </v-col>
              <div
                @mousemove="makeSelection($event)"
                @mousedown="registerClick($event)"
                @mouseup="mouseUpEvent($event, true)"
                class="pl-4 pb-4"
              >
                <div class="draggableText"
                  @dragstart="dragStart"
                  @dragend="dragEnd"
                >
                  <AIContent v-if="relatedDoc.optimiseText && relatedDoc.showOptimise" v-model="relatedDoc.optimiseText"></AIContent>
                  <span v-else-if="relatedDoc.originalDocText" v-html="relatedDoc.originalDocText"></span>
                </div>
              </div>
            </v-row>
          </template>
          <template v-else-if="tab.id === 'library'">
            <v-row v-if="!libraryDoc.loaded && !databaseLoading && !fileLoading">
              <v-col cols="4">
                <v-select 
                  dense
                  outlined 
                  placeholder="Source"
                  v-model="databaseDocSource"
                  multiple
                  :items="databaseSourceItems"
                  @change="filterDatabaseDocs(true)"
                  :menu-props="{
                    closeOnContentClick: false,
                    maxHeight: '400',
                    'offset-y': true,
                  }"
                  hide-details
                >
                  <template v-slot:selection="{ item, index }">
                    {{ getSourceFilterText(item, index) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  dense
                  outlined
                  append-icon="mdi-magnify"
                  placeholder="Search"
                  v-model="databaseLibraryFilter"
                  @keyup="filterDatabaseDocs(false, true)"
                  @click:append="filterDatabaseDocs(false)"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="!libraryDoc.loaded && !databaseLoading && !filesLoading && !fileLoading">
              <v-col
                cols="12"
                v-if="databaseViewMode === 'list'"
                style="padding-right: 3px"
              >
                <v-data-table
                  dense
                  :footer-props="{
                    'items-per-page-options': [15, 50, 100, -1],
                  }"
                  :headers="databaseDocHeaders"
                  :items="databaseFilteredDocs"
                  item-key="id"
                  style="width: 100%; margin-right: 3px"
                  class="elevation-0 row-click"
                  @click:row="viewDatabaseDoc"
                >
                  <template v-slot:[`item.source`]="{ item }">
                    <v-chip
                      small
                      class="status-chip"
                      label
                      :color="getSourceColor(item)"
                      >{{ item.source }}</v-chip
                    >
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-menu  offset-y bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="float-right"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click="viewDatabaseDoc(item)">
                          <v-list-item-icon>
                            <v-icon>visibility</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            View
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.file_id" @click="nlpUtils.downloadFile(item.file_id)" >
                          <v-list-item-icon>
                            <v-icon>download</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Download
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.source === 'Upload' && ($loginState.user.canDeleteAnyFile || item.can_delete == 1)" @click="deleteDatabaseDoc(item)" >
                          <v-list-item-icon>
                            <v-icon>delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Delete
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <div
              v-if="databaseLoading || filesLoading || fileLoading"
              class="d-flex text-center align-center mt-6 flex-column"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                style="top: 45%"
                class="mb-3"
              ></v-progress-circular>
              <p class="body-1">
                Hey there, your content is on its way!<br />
                Hang tight and get ready to be amazed
              </p>
            </div>
            <v-row v-else-if="libraryDoc.loaded">
              <v-col cols="12" dense class="d-flex">
                <v-btn
                  small
                  @click="toggleDatabaseDisplay"
                  color="secondary"
                  class="userPilotReturnToListBtn"
                >
                  <v-icon left>mdi-chevron-double-left</v-icon>
                  Return to List
                </v-btn>
                <v-spacer></v-spacer>

                <span
                  v-if="
                    databaseSelectionState.dragStart &&
                    databaseSelectionState.data === ''
                  "
                  style="color: orange"
                  ><v-icon small color="orange"
                    >mdi-close-octagon-outline</v-icon
                  >No Data selection</span
                >
                <span
                  dense
                  :title="databaseSelectionState.data"
                  style="color: green"
                  v-else-if="databaseSelectionState.data !== ''"
                  ><v-icon small color="green"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  Data Selected Succesfully</span
                >
                <div v-else-if="libraryDoc.item" class="db-file-name">
                  <h3>{{ libraryDoc.item.file_name }}</h3>
                  <v-btn small icon title="Download File" v-if="libraryDoc.item.file_id" @click="nlpUtils.downloadFile(libraryDoc.item.file_id)">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </div>
                
                <v-spacer></v-spacer>
                <template v-if="config.contentGenerationConfig.enableOptimize || config.contentGenerationConfig.enableSkillsExtract">
                  <v-btn color="primary" small
                    v-if="libraryDoc && libraryDoc.optimiseText && libraryDoc.showOptimise"
                    @click="libraryDoc.showOptimise = false"
                    class="userPilotShowOriginalBtn"
                  >
                    Show Original
                  </v-btn>
                  <template v-else>
                    <v-btn v-if="(multidoc || document.state.canEditDocParts) && config.contentGenerationConfig.enableOptimize" color="primary" small @click="generateContent('optimise')"
                      class="ml-2 userPilotOptimiseBtn">
                      Optimise
                      <v-icon right>mdi-creation</v-icon>
                    </v-btn>
                    <v-btn v-if="(multidoc || document.state.canEditDocParts) && config.contentGenerationConfig.enableSkillsExtract" color="primary" small @click="generateContent('skills_extract')"
                      class="ml-2 userPilotSkillsExtractBtn">
                      Extract Skills
                      <v-icon right>mdi-download</v-icon>
                    </v-btn>
                  </template>
                </template>
              </v-col>
              <div
                @mousemove="makeSelection($event)"
                @mousedown="registerClick($event)"
                @mouseup="mouseUpEvent($event, true)"
                class="pl-4 pb-4"
              >
                <div class="draggableText"
                  @dragstart="dragStart"
                  @dragend="dragEnd"
                >
                  <AIContent v-if="libraryDoc.optimiseText && libraryDoc.showOptimise" v-model="libraryDoc.optimiseText"></AIContent>
                  <span v-else-if="libraryDoc.originalDocText" v-html="libraryDoc.originalDocText"></span>
                </div>
              </div>
            </v-row>
          </template>
          <template v-else-if="tab.id === 'jdGenerator'">
            <div
              v-if="!contentGenerationSettings"
              class="d-flex text-center align-center mt-6 flex-column"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                style="top: 45%"
                class="mb-3"
              ></v-progress-circular>
            </div>
            <v-row v-else class="mt-2">
              <v-col cols="6" class="pt-0">
                <v-text-field
                  dense
                  outlined
                  label="Job Title"
                  v-model="generateRequest.jobTitle"
                  v-on:keyup.enter="generateContent('description')"
                  hide-details
                  clearable
                  class="userPilotOAIJDGenInput"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0" v-if="enableGenerateJobFamily">
                <v-text-field
                  dense
                  outlined
                  label="Job Family"
                  placeholder="Any"
                  v-model="generateRequest.jobFamily"
                  v-on:keyup.enter="generateContent('description')"
                  hide-details
                  clearable
                  class="userPilotOAIJDGenJobFamily"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0" v-if="enableGenerateLevelling">
                <v-select
                  dense
                  outlined
                  label="Job Level"
                  placeholder="Any"
                  v-model="generateRequest.jobLevel"
                  v-on:keyup.enter="generateContent('description')"
                  :items="contentGenerationSettings.levels"
                  hide-details
                  clearable
                  class="userPilotOAIJDGenLevel"
                ></v-select>
              </v-col>
              <v-col class="pt-0 d-flex justify-end">
                <v-btn
                  color="primary"
                  @click="generateContent('description')"
                  :disabled="databaseLoading"
                  class="userPilotOAIJDGenBtn"
                >
                  Generate
                </v-btn>
              </v-col>
            </v-row>
            <div
              v-if="databaseLoading"
              class="d-flex text-center align-center mt-6 flex-column"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                style="top: 45%"
                class="mb-3"
              ></v-progress-circular>
              <p class="body-1" v-html="config.contentGenerationConfig.disclaimer">
              </p>
            </div>
            <v-row v-else-if="generatedData && generatedData.description"
              class="mx-2 mt-4" dense
              @mousemove="makeSelection($event)"
              @mousedown="registerClick($event)"
              @mouseup="mouseUpEvent($event, true)"
            >
              <div class="draggableText"
                @dragstart="dragStart"
                @dragend="dragEnd"
              >
                <AIContent v-model="generatedData.description"></AIContent>
              </div>
            </v-row>
          </template>
          <template v-else-if="tab.id === 'skillsGenerator'">
            <div
              v-if="!contentGenerationSettings"
              class="d-flex text-center align-center mt-6 flex-column"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                style="top: 45%"
                class="mb-3"
              ></v-progress-circular>
            </div>
            <v-row v-else class="mt-2">
              <v-col cols="6" class="pt-0">
                <v-text-field
                  dense
                  outlined
                  label="Job Title"
                  v-model="generateRequest.jobTitle"
                  v-on:keyup.enter="generateContent('description')"
                  hide-details
                  clearable
                  class="userPilotOAISkillsInput"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0" v-if="enableGenerateJobFamily">
                <v-text-field
                  dense
                  outlined
                  label="Job Family"
                  placeholder="Any"
                  v-model="generateRequest.jobFamily"
                  v-on:keyup.enter="generateContent('description')"
                  hide-details
                  clearable
                  class="userPilotOAISkillsJobFamily"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0" v-if="enableGenerateLevelling">
                <v-select
                  dense
                  outlined
                  label="Job Level"
                  placeholder="Any"
                  v-model="generateRequest.jobLevel"
                  v-on:keyup.enter="generateContent('description')"
                  :items="contentGenerationSettings.levels"
                  hide-details
                  clearable
                  class="userPilotOAISkillsLevel"
                ></v-select>
              </v-col>
              <v-col class="pt-0 d-flex justify-end">
                <v-btn
                  color="primary"
                  @click="generateContent('skills')"
                  :disabled="databaseLoading"
                  class="userPilotOAISkillsBtn"
                >
                  Generate
                </v-btn>
              </v-col>
            </v-row>
            <div
              v-if="databaseLoading"
              class="d-flex text-center align-center mt-6 flex-column"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                style="top: 45%"
                class="mb-3"
              ></v-progress-circular>
              <p class="body-1" v-html="config.contentGenerationConfig.disclaimer">
              </p>
            </div>
            <v-row v-else-if="generatedData && generatedData.skills"
              class="mx-2 mt-4" dense
              @mousemove="makeSelection($event)"
              @mousedown="registerClick($event)"
              @mouseup="mouseUpEvent($event, true)"
            >
              <div class="draggableText"
                @dragstart="dragStart"
                @dragend="dragEnd"
              >
                <AIContent v-model="generatedData.skills"></AIContent>
              </div>
            </v-row>
          </template>
          <template v-else-if="tab.id === 'externalContent'">
            <v-row v-if="!externalDoc.loaded">
              <v-col cols="10">
                <v-text-field
                  dense
                  outlined
                  append-icon="mdi-magnify"
                  placeholder="Search"
                  v-model="databaseExternalFilters.text"
                  @keyup="loadExternalContentList(false, true)"
                  @click:append="loadExternalContentList(false)"
                  hide-details
                  ref="databaseExternalFilterText"
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end">
                <v-menu
                  offset-y left
                  :close-on-content-click="false"
                  v-if="externalContentSource !== 'local'"
                  v-model="databaseExternalFilters.showMenu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" 
                      v-on="on"
                      icon>
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="overflow-hidden pa-3" width="500">
                    <v-row dense>
                      <v-col cols="6">
                        <v-select
                          v-model="databaseExternalFilters.includedContractTypes"
                          dense
                          outlined
                          label="Contract Types"
                          multiple
                          :items="databaseExternalFilters.availableContractTypes"
                          item-text="text"
                          item-value="value"
                          :menu-props="{ offsetY: true }"
                          v-on:keyup.enter="loadExternalContentList(false)"
                          hide-details
                          menu-off>
                        </v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="databaseExternalFilters.includedPTFT"
                          dense
                          outlined
                          label="Part/Full time"
                          multiple
                          :items="databaseExternalFilters.availablePTFT"
                          item-text="text"
                          item-value="value"
                          :menu-props="{ offsetY: true }"
                          v-on:keyup.enter="loadExternalContentList(false)"
                          hide-details
                          menu-off>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field
                          v-model="databaseExternalFilters.minSalary"
                          dense
                          outlined
                          label="Min Salary"
                          type="Number"
                          v-on:keyup.enter="loadExternalContentList(false)"
                          hide-details>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="databaseExternalFilters.maxSalary"
                          dense
                          outlined
                          label="Max Salary"
                          type="Number"
                          v-on:keyup.enter="loadExternalContentList(false)"
                          hide-details>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field
                          v-model="databaseExternalFilters.location"
                          dense
                          outlined
                          label="Location"
                          v-on:keyup.enter="loadExternalContentList(false)"
                          hide-details>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="databaseExternalFilters.distance"
                          dense
                          outlined
                          label="Distance"
                          item-text="text"
                          item-value="value"
                          v-on:keyup.enter="loadExternalContentList(false)"
                          :items="databaseExternalFilters.distances"
                          hide-details>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" class="d-flex justify-end align-center">
                        <v-btn color="primary" @click="loadExternalContentList(false)">
                          Search
                          <v-icon right>mdi-magnify</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="!externalDoc.loaded && !databaseLoading">
              <v-col
                cols="12"
                v-if="databaseViewMode === 'list'"
                style="padding-right: 3px"
              >
                <v-data-table
                  dense
                  :footer-props="{
                    'items-per-page-options': [15, 50, 100, -1],
                  }"
                  :headers="databaseDocHeaders"
                  :items="databaseExternalDocs"
                  item-key="doc_id"
                  style="width: 100%; margin-right: 3px"
                  class="elevation-0 row-click"
                  @click:row="viewDatabaseDoc"
                >
                  <template v-slot:[`item.source`]="{ item }">
                    <v-chip
                      small
                      class="status-chip"
                      label
                      :color="getSourceColor(item)"
                      >{{ item.source }}</v-chip
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <div
              v-if="databaseLoading"
              class="d-flex text-center align-center mt-6 flex-column"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                style="top: 45%"
                class="mb-3"
              ></v-progress-circular>
              <p class="body-1">
                Hey there, your content is on its way!<br />
                Hang tight and get ready to be amazed
              </p>
            </div>
            <v-row v-else-if="externalDoc.loaded">
              <v-col cols="12" dense class="d-flex">
                <v-btn
                  small
                  @click="toggleDatabaseDisplay"
                  color="secondary"
                  class="userPilotReturnToListBtn"
                >
                  <v-icon left>mdi-chevron-double-left</v-icon>
                  Return to List
                </v-btn>
                <v-spacer></v-spacer>

                <span
                  v-if="
                    databaseSelectionState.dragStart &&
                    databaseSelectionState.data === ''
                  "
                  style="color: orange"
                  ><v-icon small color="orange"
                    >mdi-close-octagon-outline</v-icon
                  >No Data selection</span
                >
                <span
                  dense
                  :title="databaseSelectionState.data"
                  style="color: green"
                  v-else-if="databaseSelectionState.data !== ''"
                  ><v-icon small color="green"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  Data Selected Succesfully</span
                >
                <div v-else-if="externalDoc.item" class="db-file-name">
                  <h3>{{ externalDoc.item.file_name }}</h3>
                  <v-btn small icon title="Download File" v-if="externalDoc.item.file_id" @click="nlpUtils.downloadFile(externalDoc.item.file_id)">
                    <v-icon>download</v-icon>
                  </v-btn>
                </div>
                
                <v-spacer></v-spacer>
                <template v-if="config.contentGenerationConfig.enableOptimize">
                  <v-btn color="primary" small
                    v-if="externalDoc && externalDoc.optimiseText && externalDoc.showOptimise"
                    @click="externalDoc.showOptimise = false"
                    class="userPilotShowOriginalBtn"
                  >
                    Show Original
                  </v-btn>
                  <template v-else>
                    <v-btn v-if="(multidoc || document.state.canEditDocParts) && config.contentGenerationConfig.enableOptimize" color="primary" small @click="generateContent('optimise')"
                      class="ml-2 userPilotOptimiseBtn">
                      Optimise
                      <v-icon right>mdi-creation</v-icon>
                    </v-btn>
                    <v-btn v-if="(multidoc || document.state.canEditDocParts) && config.contentGenerationConfig.enableSkillsExtract" color="primary" small @click="generateContent('skills_extract')"
                      class="ml-2 userPilotSkillsExtractBtn">
                      Extract Skills
                      <v-icon right>mdi-download</v-icon>
                    </v-btn>
                  </template>
                </template>
              </v-col>
              <div
                @mousemove="makeSelection($event)"
                @mousedown="registerClick($event)"
                @mouseup="mouseUpEvent($event, true)"
                class="pl-4 pb-4"
              >
                <div class="draggableText"
                  @dragstart="dragStart"
                  @dragend="dragEnd"
                >
                  <AIContent v-if="externalDoc.optimiseText && externalDoc.showOptimise" v-model="externalDoc.optimiseText"></AIContent>
                  <template v-else-if="externalDoc.originalDocText">
                    <v-alert v-if="externalContentSimilarity > config.externalContentConfig.maxSimilarity && config.externalContentConfig.similarityError" text type="error">
                      <span v-html="config.externalContentConfig.similarityError"></span>
                      <span style="float:right; margin-right: 5px">Similarity: {{ Math.round(externalContentSimilarity * 100) }}&percnt;</span>
                    </v-alert>
                    <v-alert v-else-if="config.externalContentConfig.disclaimer" text type="warning">
                      <span v-html="config.externalContentConfig.disclaimer"></span>
                      <span style="float:right; margin-right: 5px">Similarity: {{ Math.round(externalContentSimilarity * 100) }}&percnt;</span>
                    </v-alert>

                    <span
                      @contextmenu="show"
                      @dragstart="dragStart"
                      @dragend="dragEnd"
                      v-html="externalDoc.originalDocText"
                    ></span>
                  </template>
                </div>
              </div>
            </v-row>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
  <v-dialog
    v-model="deleteFile.show"
    max-width="600px"
  >
    <v-card>
      <v-card-title class="justify-right">
        Confirm
        <v-spacer></v-spacer>
        <v-btn icon class="btn-background" @click="deleteFile.show = false"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <p>Permanently delete {{ deleteFile.file_name }} ?</p>
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-btn color="primary" outlined @click="deleteFile.show = false">Cancel</v-btn>
        <v-spacer></v-spacer>
        
        <v-btn class="error" @click="confirmDelete"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>
  
<script>
import axios from "axios";
import nlpUtils from "@/common/nlpUtils.js";
import stringSimilarity from "string-similarity";
// import utils from "@/common/utils.js";

import AIContent from "@/components/document/cAIContent.vue";
import { mapState } from "vuex";

export default {
    name: "ContentDatabase",
    props: {
      document: Object,
      isNew: Boolean,
      multidoc: Boolean,
      sourceFile: Object
    },
    data: function () {
        return {
          nlpUtils: nlpUtils,
          databaseFilteredDocs: [],
          databaseLoading: false,
          fileLoading: false,
          selectDoc: true,
          originalDocText: "",
          unmodifiedDocText: "",    
          filteredSuggestions: [],
          databaseSelectionState: {
            mouseDown: false,
            mouseUp: false,
            mouseMove: false,
            dragStart: false,
            dragEnd: false,
            data: "",
          },
          databaseSourceItems: [
            "Internal",
            "Upload"
          ],
          filteredFileItems: [],
          databaseDocSource: [ "Internal", "Upload" ],
          databaseViewExternalDocs: false,
          suggestionSourceItems: [
            "Internal"
          ],
          databaseDocTypeFilter: [],
          suggestionsSourceIncluded: [],
          externalContentSource: "local",
          documentLanguage: { text: "English", value: "en", flagCode: "gb" },
          databaseTab: 0,
          contentGenerationSettings: null,
          generateRequest: {
            jobTitle: "",
            jobFamily: "",
            jobLevel: ""
          },
          generatedData: {
            optimise: null,
            showOptimise: false,
            description: null,
            skills: null
          },
          databaseFilterTimeout: null,
          databaseDocHeaders: [
            { text: "Source", value: "source" },
            { text: "Job Title", value: "doc_name" },
            { text: "", value: "actions", sortable: false },
          ],
          databaseViewMode: "list",    
          
          databaseItem: null,
          deleteFile: {
            show: false,
            file_name: '',
            file_id: null
          },
          relatedDoc: {
            loaded: false,
            item: null,
            originalDocText: "",
            unmodifiedDocText: "",
            optimiseText: null,
            showOptimise: false,
            optimiseContentType: ""
          },
          libraryDoc: {
            loaded: false,
            item: null,
            originalDocText: "",
            unmodifiedDocText: "",
            optimiseText: null,
            showOptimise: false,
            optimiseContentType: ""
          },
          externalDoc: {
            loaded: false,
            item: null,
            originalDocText: "",
            unmodifiedDocText: "",
            optimiseText: null,
            showOptimise: false,
            optimiseContentType: ""
          },        
          databaseRelatedDocs: [],  
          databaseExternalFilters: {
            text: "",
            showMenu: false,
            includedContractTypes: [],
            availableContractTypes: [
              { text: "Permanent", value: "permanent" },
              { text: "Contract", value: "contract" },
              { text: "Temp", value: "temp" }
            ],
            includedPTFT: [],
            availablePTFT: [
              { text: "Full Time", value: "fullTime" },
              { text: "Part Time", value: "partTime" }
            ],
            minSalary: "",
            maxSalary: "",
            location: "",
            distance: 10,
            distances: [
              { text: "10 miles", value: 10 },
              { text: "20 miles", value: 20 },
              { text: "50 miles", value: 50 },
              { text: "50 miles", value: 100 }
            ]
          },
          databaseRelatedHeaders: [
            { text: "Source", value: "source" },
            { text: "Type", value: "relationship" },
            { text: "Job Title", value: "doc_name" },
            { text: "", value: "actions", sortable: false },
          ],           
          databaseExternalDocs: [],    
          databaseLibraryFilter: "",   
        };
    },
    components: { AIContent },
    created() {
        this.init();
    },
    watch: {
      filesLoading(val) {
        if (!val) {
          this.filteredFileItems = [...this.fileItems];
          this.setDocsLinked();
          this.filterDatabaseDocs();
          this.extDocsLoading = false;
          this.isLoading = false;
        }
      },
      'relatedDoc.loaded'(val) {
        if (val === false) {
          this.relatedDoc.item = null,
          this.relatedDoc.originalDocText = "",
          this.relatedDoc.unmodifiedDocText = "",
          this.relatedDoc.optimiseText = null;
          this.relatedDoc.showOptimise = false;
        }
      },
      'libraryDoc.loaded'(val) {
        if (val === false) {
          this.libraryDoc.item = null,
          this.libraryDoc.originalDocText = "",
          this.libraryDoc.unmodifiedDocText = "",
          this.libraryDoc.optimiseText = null;
          this.libraryDoc.showOptimise = false;
        }
      },
      'externalDoc.loaded'(val) {
        if (val === false) {
          this.externalDoc.item = null,
          this.externalDoc.originalDocText = "",
          this.externalDoc.unmodifiedDocText = "",
          this.externalDoc.optimiseText = null;
          this.externalDoc.showOptimise = false;
        }  
      }  ,   
      sourceFile(val) {
        if (val) {
          this.loadRelatedDocs();
          this.compareFileInline(val);
        }
      },
      databaseTabObj(val) {
        if (val.id === "externalContent" && this.databaseExternalDocs.length === 0) {
          this.loadExternalContentList();
        } else if (["jdGenerator", "skillsGenerator"].includes(val.id) && !this.contentGenerationSettings) {
          axios
            .get("externalContent/generateSettings/" + (this.document ? this.document.doc_id : 0))
            .then((resp) => {
              if (resp.data.Status === "OK" && resp.data.Data) {
                const settings = resp.data.Data;
                this.generateRequest.jobTitle = settings.defaultJobTitle;
                this.generateRequest.jobFamily = settings.defaultJobFamily;
                this.generateRequest.jobLevel = settings.defaultJobLevel;
                this.contentGenerationSettings = settings;
              }
            })
            .catch((err) => {
              if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
            });
        }   
      }     
    },
    computed: {
      ...mapState({
        config: (state) => state.settings.config,
        newUi: (state) => state.theme.newUi,
        statuses: (state) => state.hierarchies.statuses,
        docTypeSummary: (state) => state.docs.docTypeSummary,
        docStatusSummary: (state) => state.docs.docStatusSummary,
        cleanItems: (state) => state.docs.docsList,
        fileItems: (state) => state.files.list,
        filesInitialised: (state) => state.files.initialised,
        filesLoading: (state) => state.files.loading,
      }),
      databaseTabs() {
        return [
          { id: "relatedContent", position: 0, class: "userPilotRelatedTab", name: "Related<br />Content", show: !!this.databaseRelatedDocs.length },
          { id: "library", position: 1, class: "userPilotLibraryTab", name: "Library", show: true },
          { id: "jdGenerator", position: 2, class: "userPilotOAIJDGenTab", name: "JD<br />Generator", show: (this.multidoc || this.document?.state?.canEditDocParts) && this.config.contentGenerationConfig?.enableGenerateJD },
          { id: "skillsGenerator", position: 3, class: "userPilotOAISkillsTab", name: "Skills<br />Generator", show: (this.multidoc || this.document?.state?.canEditDocParts) && this.config.contentGenerationConfig?.enableGenerateSkills },
          { id: "externalContent", position: 4, class: "userPilotExternalContentTab", name: "External<br />Content", show: (this.multidoc || this.document?.state?.canEditDocParts) && this.databaseViewExternalDocs }
        ];
      },
      visibleDatabaseTabs() {
        return this.databaseTabs.filter(t => t.show).sort((a,b) => a.position - b.position);
      },
      databaseTabObj() {
        return this.databaseTabs.length > this.databaseTab ? this.databaseTabs[this.databaseTab] : {};
      },
      databaseTabDoc() {
        if (this.databaseTabObj.id === "relatedContent") {
          return this.relatedDoc;
        } else if (this.databaseTabObj.id === "library") {
          return this.libraryDoc;
        } else if (this.databaseTabObj.id === "externalContent") {
          return this.externalDoc;
        }
        return null;
      },
      databaseFileTypes() {
        const param = this.config.settings.find(s => s.setting === "document_database_file_types")?.value || "docx";
        return param.split("|");
      },
      enableGenerateJobFamily() {
        return this.contentGenerationSettings && this.contentGenerationSettings.enableJobFamily;
      },
      enableGenerateLevelling() {
        return this.contentGenerationSettings && this.contentGenerationSettings.enableLevelling && this.contentGenerationSettings.levels && this.contentGenerationSettings.levels.length;
      },
      externalContentSimilarity() {
        if (!this.externalDoc.loaded || !this.externalDoc.originalDocText) {
          return 0;
        }

        const externalText = this.externalDoc.originalDocText;
        const docEditableText = this.document.parttypes
          .filter(pt => !pt.tmpl_part_metadata.tp_locked)
          .map(pt => pt.parts.filter(p => p.text).map(p => p.text).join("\n"))
          .join("\n");
        
        return stringSimilarity.compareTwoStrings(externalText, docEditableText);
      },
    },
    methods: {  
      loadRelatedDocs() {
        if (this.multidoc)
          return;

        let possibleError = false;
        this.databaseLoading = true;
        axios
          .get("document/getRelatedDocs/" + this.document.doc_id)
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.databaseRelatedDocs = resp.data.Data.map(d => {
                d.id = d.file_id ? `f${d.file_id}` : `d${d.doc_id}`;
                return d;
              });
            }
            if (this.databaseRelatedDocs.length === 0 && this.databaseTab === 0)
              this.databaseTab = 1;
            this.databaseLoading = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.databaseLoading = false;
          });
      },      
      loadExternalContentList(reset, debounce){
        if (debounce) {
          //throttle so as not to run search too frequently
          if (this.databaseFilterTimeout) {
            clearTimeout(this.databaseFilterTimeout);
            this.databaseFilterTimeout = null;
          }

          this.databaseFilterTimeout = setTimeout(() => this.loadExternalContentList(reset, false), 500);
          return;
        }

        this.databaseExternalFilters.showMenu = false;
        if (reset) {
          this.databaseExternalFilters.includedContractTypes.splice(0);
          this.databaseExternalFilters.includedPTFT.splice(0);
          this.databaseExternalFilters.minSalary = "";
          this.databaseExternalFilters.maxSalary = "";
          this.databaseExternalFilters.location = "";
          this.databaseExternalFilters.distance = 10;
        }

        this.databaseExternalDocs.splice(0);
        let possibleError = false;
        this.databaseLoading = true;
        axios
          .get("externalContent/list", { 
            params: {
              jobTitle: this.databaseExternalFilters.text || this.document.doc_name,
              contractTypes: this.databaseExternalFilters.includedContractTypes,
              ptft: this.databaseExternalFilters.includedPTFT,
              minSalary: this.databaseExternalFilters.minSalary,
              maxSalary: this.databaseExternalFilters.maxSalary,
              location: this.databaseExternalFilters.location,
              distance: this.databaseExternalFilters.distance
            }
          })
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              this.databaseExternalDocs.push(...resp.data.Data.data);
              this.externalContentSource = resp.data.Data.source;
            }
            this.databaseLoading = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.databaseLoading = false;
          });
      },
      loadExternalFileList() {
        if (this.filesInitialised && !this.filesLoading) {
          this.filteredFileItems = [...this.fileItems];
          this.setDocsLinked();
          this.filterDatabaseDocs();
        } else {
          this.extDocsLoading = true;
          this.isLoading = true;
          this.$store.dispatch("files/init");
        }
      },   
      initSettings(){
        this.databaseViewEnabled = false;
        let setting = this.$loginState.user.settings.find(
          (s) => s.setting === "document_use_database_view"
        );
        let loadLinksSetting = this.$loginState.user.settings.some(
          (s) =>
            s.setting === "document_database_view_load_links" &&
            s.value === "true"
        );

        this.databaseViewExternalDocs = this.$loginState.user.settings.some(
          (s) =>
            s.setting === "document_database_view_allow_external" &&
            s.value === "true"
        );

        if (!this.multidoc && this.sourceFile)
          this.compareFileInline(this.sourceFile);

        if (!loadLinksSetting) {
          this.showDatabasePanel = false;
        }

        if (!this.multidoc && setting !== undefined) {
          let docTypes = setting.value.split("|").map((a) => {
            let parts = a.split(":");
            if (parts.length === 2) {
              return { doc_type: parts[0], value: parts[1] };
            }
          });

          let currentDoc = docTypes.find(
            (x) => x.doc_type === this.document.doc_type
          );
          if (currentDoc !== undefined) {
            this.databaseViewEnabled = currentDoc.value === "true";
          }
        }
      },
      init(){
        this.initSettings();
        this.showDatabase();
      },
      closeDatabasePanel(){
        this.$emit("closeDatabasePanel");
      },
      filterDatabaseDocs(reset, debounce) {
        if (debounce) {
          //throttle so as not to run search too frequently
          if (this.databaseFilterTimeout) {
            clearTimeout(this.databaseFilterTimeout);
            this.databaseFilterTimeout = null;
          }

          this.databaseFilterTimeout = setTimeout(() => this.filterDatabaseDocs(reset, false), 500);
          return;
        }

        this.databaseFilteredDocs.splice(0);

        let currentUser = this.$loginState.user.user_id;
        let docTypes = this.databaseDocTypeFilter.map((dbDoc) => dbDoc.docType);
        
        if(this.databaseDocSource.includes("Internal")) {
          let statuses = ['approved'];
          if (this.config.settings.some(s => s.setting === "document_database_view_doc_statuses"))
            statuses = JSON.parse(this.config.settings.find(s => s.setting === "document_database_view_doc_statuses").value).map(x => x.toLowerCase());

          this.databaseFilteredDocs.push(...this.cleanItems.filter((x) => {
            let docTypeIncl = docTypes.indexOf(x.doc_type) > -1;
            let docStatusIncl = statuses.includes(x.doc_status_text.toLowerCase());
            let matchesText = true;
            let addFile = false;

            if (x.doc_type === "External File") {
              //let externalFile = false;
              let isPrivate =
                x.file_data !== undefined && x.file_data.private === 1;
              let wasCreatedByUser = x.file_data.created_by === currentUser;
              if (!isPrivate || (isPrivate && wasCreatedByUser)) {
                addFile = true;
                //todo
              }
            } else {
              addFile = true;
            }

            if (this.databaseLibraryFilter !== "") {
              let searchText = this.databaseLibraryFilter
                .split(" ")
                .filter((x) => x.length)
                .map((x) => x.toLowerCase());

              matchesText = searchText.every(
                (s) => x.match.toLowerCase().indexOf(s, 0) >= 0
              );
            }

            if (docTypeIncl && docStatusIncl && matchesText && addFile) {
              return x;
            }
          }).map(x => {
            return {
              ...x,
              source: "Internal"
            }
          }));
        }
        
        if(this.databaseDocSource.includes("Upload")) {
          if(this.fileItems.length){
            this.databaseFilteredDocs.push(...this.fileItems.filter(f => {
              let matchesText = true;
              if (this.databaseLibraryFilter !== "") {
                matchesText = f.file_name.toLowerCase().indexOf(this.databaseLibraryFilter.toLowerCase()) > -1;
              }

              if (matchesText && this.databaseFileTypes.includes(f.file_extension)) {
                return f;
              }

            }).map(f => {
              return {
                ...f,
                doc_name: f.file_name,
                source: "Upload"
              }
            }));
          }
        }
      },
      async showDatabase() {
        //this.setupUploadData();
        this.databaseUploadDisabled = true;
        this.databaseUploadMulti = "false";
        this.$nextTick(() => {
          this.databaseUploadDisabled = false;
          this.databaseUploadMulti = "true";
        });
        this.loadDatabaseDocs();
        this.loadRelatedDocs();
        this.databaseTab = this.multidoc ? 1 : 0;
        await this.initNlpOptions();
        //this.resetRightPanel();
        this.showDatabasePanel = true;
      },
      setDatabaseDocItem(item) {
        if (this.databaseTabDoc)
          this.databaseTabDoc.item = item;
      },
      viewDatabaseDoc(item){
        this.tempNlpText = "";
        this.tempNlpHtml = "";
        this.setDatabaseDocItem(item);
        if(item.source === "Upload"){
          this.compareFile(item.file_id,"External File",item.row_id);
        } else if (item.source === "Internal") {
          this.compareFile(item.system_number, item.doc_type);
        } else if(item.source === "External") {
          this.getExternalContentDetail(item.id);
        }
      },
      deleteDatabaseDoc(item) {
        this.deleteFile.file_name = item.file_name;
        this.deleteFile.file_id = item.file_id;
        this.deleteFile.show = true;
      },
      confirmDelete() {
        if (!this.deleteFile.file_id)
          return;
        
        this.deleteFile.processing = true;
        axios
          .post("file/delete", [this.deleteFile.file_id])
          .then((resp) => {
            if(resp.data.Status == "OK") {
              this.$store.dispatch("files/reload");
              if (this.sourceFile?.file_data?.file_id === this.deleteFile.file_id)
                 this.$emit("sourceFileDeleted");
              this.loadRelatedDocs();
              this.loadDatabaseDocs();
            }
            this.deleteFile.processing = false;
            this.deleteFile.show = false;
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.$emit("triggerNotification", "Unexpected Error", "error");
            }
            this.deleteFile.processing = false; 
          });
      },
      getExternalContentDetail(id) {
        this.databaseLoading = true;
        axios
          .get("externalContent/detail/" + id)
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.externalDoc.originalDocText = resp.data.Data.data;
              this.externalDoc.loaded = true;
            }
            this.databaseLoading = false;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.$emit("triggerNotification", "Unexpected Error", "error");
            }
            this.isLoading = false;
            this.databaseLoading = false;
          });
      },   
      setDatabaseDocContent(sourceText) {
        if (this.databaseTabDoc) {
          this.databaseTabDoc.loaded = true;
          this.databaseTabDoc.originalDocText = sourceText;
          this.databaseTabDoc.unmodifiedDocText = sourceText;
        }
      },         
      async compareFile(id, type, row_id) {
        this.fileLoading = true;
        if (type === "External File") {
          await nlpUtils.getDocText(id, row_id).then((response) => {
            if (response.status == 200 && (response.data.rawText || response.data.rawData)) {
              let nlpOptions = nlpUtils.getNlpActions(
                this.$loginState.user.settings
              );

              this.nlpOptions = [...new Set(nlpOptions)];
              let sourceText = (response.data.rawText?.match(/<[a-z][\s\S]*>/i)?.length > 0 ?  response.data.rawText :  response.data.rawData)
                ?.replaceAll("<em>", "")
                ?.replaceAll("</em>", "");
              if (sourceText) {
                this.setDatabaseDocContent(sourceText);
              }
            } else {
              this.$emit("triggerNotification", "Text could not be extracted from this document", "error");
            }

            //this.selectDoc = false;
            //this.selectRelatedDoc = false;
            this.fileLoading = false;
            this.isLoading = false;
          });
        } else {
          let data = {
            doc: id,
          };

          axios
            .post("document/getDocHtml/", data)
            .then((resp) => {
              this.setDatabaseDocContent(resp.data);
              this.fileLoading = false;
              this.isLoading = false;
            })
            .catch((err) => {
              if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                console.log(err);
                this.$emit("triggerNotification", "Unexpected Error", "error");
              }
              this.isLoading = false;
              this.fileLoading = false;
            });
        }
      },   
      getSourceColor(doc){
        switch (doc.source) {
          case "Internal":
            return "green";
          case "Upload":
            return "blue";
          case "External":
          default:
            return "orange";
        }
      },
      getChipColor(doc) {
        switch (doc.doc_status) {
          case "APPROVED":
            return "green";
          case "PENDING_APPROVAL":
            return "red";
          case "DRAFT":
          case "PRE-DRAFT":
            return "orange";
          case "ARCHIVED":
          case "TEMPLATE":
            return "blue";
          default:
            return "gray";
        }
      },   
      reloadSource() {
        if (this.sourceDoc)
          this.compareFileInline(this.sourceDoc);
      },         
      compareFileInline(item) {
        this.tempNlpText = "";
        this.tempNlpHtml = "";
        if (item.doc_type === "External File") {
          this.compareFile(
            item.file_data.file_id,
            "External File",
            item.file_data.row_id
          );
        } else {
          this.compareFile(item.system_number, item.doc_type);
        }
      },      
      loadDatabaseDocs() {
        this.databaseLibraryFilter = "";
        this.generateRequest.jobTitle = this.multidoc ? "" : this.document.doc_name;
        this.databaseExternalFilters.text = this.multidoc ? "" : this.document.doc_name;
        this.generatedData.optimise = null;
        this.generatedData.description = null;
        this.generatedData.skills = null;

        if (this.databaseDocTypeFilter.length === 0) {
          let docTypes = this.getDatabaseViewDocTypes();
          let databaseAvailableDocTypes = this.docTypeSummary.filter(
            (t) =>
              docTypes.indexOf(t.docType) > -1 || t.docType === "External File"
          );
          this.databaseAvailableDocTypes = JSON.parse(
            JSON.stringify(databaseAvailableDocTypes)
          );
          this.databaseDocTypeFilter = JSON.parse(
            JSON.stringify(databaseAvailableDocTypes)
          );
        }
        
        this.loadExternalFileList();
        
        if(!this.selectDoc){
          this.reloadSource();
        }
      },  
      setDocsLinked() {
        if (this.multidoc)
          return;

        this.document.linkedDocs.forEach((doc) => {
          if (doc.tmpl_name == "External File") {
            let tmpId = doc.file_data.file_id;
            let fileItem = this.fileItems.find((x) => x.file_id == tmpId);
            if (fileItem) {
              fileItem.selected = true;
              fileItem.disabled = true;
            }
          }
        });
      },   
      async initNlpOptions() {
        this.displayDocMode = false;
        this.nlpOptions - [];
        let nlpOptions = nlpUtils.getNlpActions(this.$loginState.user.settings);

        this.nlpOptions = [...new Set(nlpOptions)];
      },       
      getSourceFilterText(item, index){
        if (item && index > 0) return "";
        if (this.databaseDocSource.length === this.databaseSourceItems.length)
          return this.databaseSourceItems.length > 0 ? "All Sources" : "None";

        if (this.databaseDocSource.length > 1)
          return `${this.databaseDocSource.length} Sources`;

        return this.databaseDocSource.map((i) => i).join(", ");
      },
      getDatabaseViewDocTypes() {
        let setting = this.$loginState.user.settings.find(
          (s) => s.setting === "document_database_view_doc_types"
        );
        if (setting !== undefined) {
          return setting.value.split("|");
        } else {
          return [];
        }
      },      
      getDocTypeFilterText(item, index) {
        if (item && index > 0) return "";
        if (this.databaseDocTypeFilter.length === this.docTypeSummary.length)
          return this.docTypeSummary.length > 0 ? "All" : "None";

        if (this.databaseDocTypeFilter.length > 2)
          return `${this.databaseDocTypeFilter.length} items`;

        return this.databaseDocTypeFilter.map((i) => i.docType).join(", ");
      },
      getSuggestionTypeFilterText(item, index) {
        if (item && index > 0) return "";
        if (this.suggestionPartFilter.length === this.suggestionTypes.length)
          return this.suggestionTypes.length > 0 ? "All" : "None";

        if (this.suggestionPartFilter.length > 2)
          return `${this.suggestionPartFilter.length} items`;

        return this.suggestionPartFilter.map((i) => i).join(", ");
      },
      makeSelection(event) {
      switch (event.which) {
        case 1:
          this.databaseSelectionState.mouseMove = true;
          setTimeout(() => {
            if (
              !this.databaseSelectionState.dragStart &&
              this.databaseSelectionState.mouseDown
            ) {
              this.databaseSelectionState.data = "";
            }
          }, 50);

          break;
      }
    },
    registerClick(event) {
      switch (event.which) {
        case 1:
          this.databaseSelectionState.mouseDown = true;
          this.databaseSelectionState.mouseUp = false;
          this.databaseSelectionState.dragStart = false;
          this.databaseSelectionState.dragEnd = false;
          break;
      }
    },
    registerNewSelection() {
      this.databaseSelectionState = {
        mouseDown: false,
        mouseUp: false,
        mouseMove: false,
        dragStart: false,
        dragEnd: false,
        data: this.tempNlpText,
      };
    },
    dragEnd() {
      this.databaseSelectionState.mouseDown = false;
      this.databaseSelectionState.dragStart = false;
      this.databaseSelectionState.dragEnd = true;
      this.isDataDrag = false;
      this.filteredSuggestions.forEach((x) => {
        x.selected = false;
      });
      this.filteredSuggestions = JSON.parse(
        JSON.stringify(this.filteredSuggestions)
      );
      
      this.$emit("dragEnd");
    },
    dragStart(event) {
      if (this.tempNlpText !== "") {
        this.databaseSelectionState.dragStart = true;
        this.databaseSelectionState.mouseMove = false;
        let data = JSON.stringify({
          text: this.tempNlpText,
          html: this.tempNlpHtml,
        });
        event.dataTransfer.setData("object", data);
        this.isDataDrag = true;
        this.$emit("dragStart", event);
      }
    },
    dragStartSuggestion(item, event) {
      let items = this.filteredSuggestions
        .filter((x) => x.selected)
        .map((x) => {
          return { varVal: x.varVal };
        });

      if (items.length) {
        let text = "";
        items.forEach((x) => {
          text += "<p>" + x.varVal + "</p>";
        });

        let data = JSON.stringify({
          text: item.varVal,
          html: text,
        });
        event.dataTransfer.setData("object", data);
        this.isDataDrag = true;
      } else {
        this.filteredSuggestions;
        let data = JSON.stringify({
          text: item.varVal,
          html: "",
        });
        event.dataTransfer.setData("object", data);
        this.isDataDrag = true;
      }
      //event.dataTransfer.dropEffect = "move";
    },  
    toggleDatabaseDisplay() {
      if (this.databaseTabDoc && this.databaseTabDoc.loaded) {
        this.databaseTabDoc.loaded = false;
      }
      this.databaseUploadDisabled = true;
      this.databaseUploadMulti = "false";
      this.$nextTick(() => {
        this.databaseUploadDisabled = false;
        this.databaseUploadMulti = "true";
      });
    },
    show(e) {
      if (nlpUtils.showContextMenu(this.$loginState.user.settings)) {
        if (this.tempNlpText !== "") {
          e.preventDefault();
          this.showMenu = false;
          this.x = e.clientX;
          this.y = e.clientY;
          this.$nextTick(() => {
            this.showMenu = true;
          });
        }
      }
    },    
    mouseUpEvent(event, isSourceSection) {
      switch (event.which) {
        case 1:
          this.databaseSelectionState.mouseUp = true;
          this.databaseSelectionState.mouseDown = false;
          this.databaseSelectionState.mouseMove = false;
          this.databaseSelectionState.dragStart = false;
          this.databaseSelectionState.dragEnd = false;
          this.getSelection(isSourceSection);
          
          this.$emit("selectionUpdated", this.tempNlpHtml);
          break;
      }
    },
    showSource() {},
    getSelectionHtml() {
      var html = "";
      if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
          var container = document.createElement("div");
          for (var i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          html = container.innerHTML;
        }
      } else if (typeof document.selection != "undefined") {
        if (document.selection.type == "Text") {
          html = document.selection.createRange().htmlText;
        }
      }
      return html;
    },
    getSelection(isSourceSection) {
      let selection = window.getSelection();
      let selectionText = selection.toString();
      if (selectionText !== "") {
        //const selectionStart = selection.anchorOffset;
        //const selectionEnd = selection.focusOffset;
        selection = window.getSelection();
        this.originalDocText = this.unmodifiedDocText;
        selectionText = selection.toString();
        this.showNlpOptions = isSourceSection;
        var range = document.createRange();
        range.setStart(selection.anchorNode, selection.anchorOffset);
        range.setEnd(selection.focusNode, selection.focusOffset);
        var backwards = range.collapsed;
        range.detach();

        var endNode = selection.focusNode,
          endOffset = selection.focusOffset;
        selection.collapse(selection.anchorNode, selection.anchorOffset);
        if (backwards) {
          selection.modify("move", "backward", "character");
          selection.modify("move", "forward", "word");
          selection.extend(endNode, endOffset);
          selection.modify("extend", "forward", "character");
          selection.modify("extend", "backward", "word");
        } else {
          selection.modify("move", "forward", "character");
          selection.modify("move", "backward", "word");
          selection.extend(endNode, endOffset);
          selection.modify("extend", "backward", "character");
          selection.modify("extend", "forward", "word");
        }
        selectionText = selection.toString();

        this.tempTextNode = selection.anchorNode;
        this.tempNlpText = selectionText;
        this.tempNlpHtml = this.getSelectionHtml();
        this.registerNewSelection();
      } else {
        this.databaseSelectionState.data = "";
        this.tempNlpText = "";
      }
    },   
    generateContent(contentType) {
      if (this.databaseLoading)
        return;

      const isContentOperation = contentType === "optimise" || contentType === 'skills_extract';
      if (isContentOperation && this.databaseTabDoc.optimiseText &&  this.databaseTabDoc.optimiseContentType === contentType && !this.databaseTabDoc.showOptimise){
        this.databaseTabDoc.showOptimise = true;
        return;
      }

      const data = {
        ...this.generateRequest,
        contentType: contentType,
        language: this.documentLanguage?.text,
        spelling: this.document?.spell_check_language_code,
        text: isContentOperation ? this.databaseTabDoc.originalDocText : undefined
      }
      this.databaseLoading = true;
      this.generatedData[contentType] = null;
      axios
        .post("externalContent/generate", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            if (isContentOperation) {
              this.databaseTabDoc.optimiseText = resp.data.Data;
              this.databaseTabDoc.optimiseContentType = contentType;
              this.databaseTabDoc.showOptimise = true;
            } else {
              this.generatedData[contentType] = resp.data.Data;
            }
          } else {
            this.$emit("triggerNotification", resp.data.Message, "error");
          }
          this.databaseLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.$emit("triggerNotification", "Unexpected Error", "error");
          }
          this.isLoading = false;
          this.databaseLoading = false;
        });
    },       
    },
};
</script>
<style scoped lang="scss">
.draggableText ::selection {
  background: #ffeb0073;
}
.db-file-name {
  display: flex;
  margin: 0 10px;
  overflow: hidden;
  max-width: calc(100% - 180px);
  h3 {
    overflow: hidden;
  }
}
.docView {
  overflow-y: auto;
  max-height: calc(100vh - 240px);
  margin: 0;
}
</style>