<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="500px"
    @keydown.enter="handleEnter"
    @keydown.esc="cancel()"
  >
    <v-card>
      <v-card-title>{{ resetPassword ? 'Forgotten Password' : title}}</v-card-title>
      <v-card-text>
        <v-alert v-if="errMessage" type="error"><span v-html="errMessage"></span></v-alert>
        <v-alert v-if="infoMessage" type="info">{{infoMessage}}</v-alert>
        <v-label v-if="resetPassword">Please enter your login id and press 'Request Password Reset'</v-label>
        <v-select
          v-if="!setPassword && allowTenantSelect"
          v-model="tenant"
          :items="tenants"
          item-text="description"
          item-value="name"
          :menu-props="{
            maxHeight: '100%',
            offsetY: true
          }"
          label="Instance"
          name="instance"
          prepend-icon="mdi-domain"
          type="text"
          ref="instance"
        />
        <v-text-field
          v-if="!setPassword"
          v-model="username"
          label="Login"
          name="login"
          prepend-icon="mdi-account"
          type="text"
          ref="username"
        />
        <v-text-field
          v-if="!resetPassword"
          v-model="password"
          id="password"
          label="Password"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
          ref="password"
        />
        <v-text-field
          v-if="setPassword"
          v-model="passwordconfirm"
          label="Re-enter Password"
          prepend-icon="mdi-lock"
          type="password"
        />
        <div class="d-flex justify-end">
          <v-checkbox
            v-if="!resetPassword"
            v-model="stayLoggedIn"
            label="Keep me logged in"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="resetPassword || setPassword"
          text
          color="primary"
          @click="cancelResetPassword()"
        >Cancel</v-btn>
        <v-btn
          v-if="!resetPassword && !setPassword"
          text
          color="primary"
          @click="resetPassword = true"
        >Forgotten Password</v-btn>
        <v-spacer />
        <v-btn v-if="setPassword" color="primary" @click="setPasswordForToken()">Save Password</v-btn>
        <v-btn v-if="!setPassword && !resetPassword" color="primary" @click="login()">Login</v-btn>
        <v-btn
          v-if="resetPassword"
          color="primary"
          @click="requestResetPassword()"
        >Request Password Reset</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
 
<script>
import axios from "axios";
//import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src

export default {
  props: {
    reEnterPassword: Boolean,
    doLogin: Boolean,
    setPasswordToken: String,
    setPasswordType: String
  },
  data: function() {
    return {
      title: "",
      username: "",
      password: "",
      passwordconfirm: "",
      show: false,
      resetPassword: false,
      setPassword: false,
      errMessage: "",
      infoMessage: "",
      allowTenantSelect: false,
      tenants: [],
      tenant: null,
      stayLoggedIn: false,
      ssoLogins: ['zendesk'],
      ssoRedirect: null,
      ssoReturnTo: null
    };
  },
  watch: {
    doLogin() {
      this.init();
    },
    tenant(val) {
      localStorage.setItem("rmtenant", val);
    }
  },
  created() {
    this.checkSSORedirect();
    this.init();
  },
  methods: {
    init() {
      this.show = !!this.doLogin;
      if (this.show) {
        this.setPassword = !!this.setPasswordToken;
        this.stayLoggedIn = false;
        this.title = this.setPassword
          ? (this.setPasswordType === "set" ? "Password Setup: Enter new password" : "Password Reset Request: Enter new password")
          : this.reEnterPassword
          ? "Your session has timed out. Please login."
          : "Login Details";
        this.$nextTick(() => {
          this.password = "";
          if (!this.$refs.username) return;
          if (!this.username) {
            this.$refs.username.focus;
          } else {
            this.$refs.password.focus;
          }
        });
      }
    },
    handleEnter() {
      if (this.setPassword)
        this.setPasswordForToken();
      else if (this.resetPassword)
        this.requestResetPassword();
      else
        this.login();
    },
    checkSSORedirect() {            
      // on login we need to sniff for redirect from zendesk support in event they hit an article or ticket with no log in
      const params = new URLSearchParams(window.location.search);
      const returnTo = params.get('return_to');

      // have we come in on a zendesk redirect for login? if so we need to log and clear for UX or we get bad history
      if (returnTo && /^https?:\/\/(?:support.role-mapper.com|.*.supp.zendesk.com)/.test(returnTo)) {
        this.ssoReturnTo = returnTo;
        this.ssoRedirect = 'zendesk';
        window.history.replaceState({}, '', `/`);
      }
    },
    login() {
      if (this.allowTenantSelect) {
        if (!this.tenant) {
          this.errMessage = "Please select the Instance";
          return;
        }
      }

      const un = this.username.trim(),
        pw = this.password.trim();
      if (un.length === 0 || pw.length === 0) {
        this.errMessage = "User Name and Password must be entered";
        return;
      }
      
      this.errMessage = "";
      this.infoMessage = "";

      axios
        .post("auth/login/", {
          username: un,
          password: pw,
          stayLoggedIn: this.stayLoggedIn
        })
        .then(resp => {
          if (resp.data.Status === "OK") {
            // this.loginState.isAuthenticated = true;
            // this.loginState.user = resp.data.user;
            // this.loginState.doLogin = false;
            this.show = false;
            this.errMessage = "";
            this.password = "";
            this.$emit("LoggedIn", resp.data);

            // is this a zendesk redirect return url? did it come from zendesk?
            if (this.ssoLogins.includes(this.ssoRedirect)) {
              // ask for sso link from url
              axios
                .post('auth/ssolink', { link: { url: this.ssoReturnTo, sso: this.ssoRedirect}})
                .then((resp) => {
                  if (!resp.data || !resp.data.url) return  alert('Error Creating SSO Link, cannot log you in to alternative resource');
                  // clear redirects and redirect using sso link
                  this.ssoReturnTo = null;
                  this.ssoRedirect = null;
                  window.open(resp.data.url, '_self');
                })
                .catch(() => {
                    alert('Error Creating SSO Link, cannot log you in to alternative resource');
                });
            }
          } else {
            this.errMessage = "Username or Password are incorrect";
            console.log(resp.data.Message);
          }
          //console.log(resp);
        })
        .catch(err => {
          //this.loginState.isAuthenticated = false;
          this.errMessage = "Username or Password are incorrect";
          console.log(err);
        });
    },
    cancel() {
      this.password = "";
      this.$emit("Cancelled");
    },
    cancelResetPassword() {
      this.resetPassword = false;
      this.setPassword = false;
      this.errMessage = "";
    },
    requestResetPassword() {
      const un = this.username.trim();
      if (un.length === 0) {
        this.errMessage = "User Login must be entered";
        return;
      }
      this.errMessage = "";
      this.infoMessage = "";

      axios
        .post("auth/resetPasswordRequest/", { username: un })
        .then(resp => {
          if (resp.data.Status === "OK") {
        //    this.show = false;
            this.password = "";
			this.infoMessage = resp.data.Message;
			this.resetPassword = false;
			this.setPassword = false;
          } else {
            this.errMessage = resp.data.Message;
          }
          //console.log(resp);
        })
        .catch(err => {
          //this.loginState.isAuthenticated = false;
          this.errMessage = err;
          console.log(err);
        });
    },
    setPasswordForToken() {
      const pw = this.password.trim(),
        pwc = this.passwordconfirm,
        hasUpperCase = /[A-Z]/.test(pw),
        hasLowerCase = /[a-z]/.test(pw),
        hasNumbers = /\d/.test(pw),
        hasNonalphas = /[^a-zA-Z\d\s:]/.test(pw);
      if (pw.length < 8 || !hasUpperCase || !hasLowerCase || !hasNumbers || !hasNonalphas) {
        this.errMessage = `Invalid Password
          <ul>
            <li>Must be at least 8 characters long</li>
            <li>Must have at least one uppercase character</li>
            <li>Must have at least one lowercase character</li>
            <li>Must have at least one numeric value</li>
            <li>Must have at least one special character</li>
          </ul>`;
        return;
      }
      if (pw !== pwc) {
        this.errMessage = "Passwords do not match";
        return;
      }
      this.errMessage = "";
      this.infoMessage = "";

      axios
        .post("auth/setPasswordForToken/", {
          password_reset_token: this.setPasswordToken,
          password: pw,
          stayLoggedIn: this.stayLoggedIn
        })
        .then(resp => {
          if (resp.data.Status === "OK") {
            this.show = false;
            this.resetPassword = false;
            this.setPassword = false;
            this.password = "";
            location.hash = "#/home";
            this.$emit("LoggedIn", resp.data);
          } else {
            this.errMessage = resp.data.Message;
          }
          //console.log(resp);
        })
        .catch(err => {
          //this.loginState.isAuthenticated = false;
          this.errMessage = err;
          console.log(err);
        });
    }
  }
};
</script>