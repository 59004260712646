<template>
    <div>
        <div v-if="editingIdx === null">
            <v-expansion-panels v-model="activeConfig" class="px-6 py-2">
                <v-expansion-panel v-for="(config, ci) in exportConfigs" :key="config.tmpl_export_config_id" class="px-4 py-2">
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col class="text-subtitle-1" v-if="config.rules.length === 0">
                                Default
                            </v-col>
                            <v-col class="text-subtitle-1" v-else>
                                {{ config.rules.map(r => `${hierarchies.find(h => h.ht_id === r.ht_id)?.label || ''}: ${[1, 2, 3, 4, 5, 6].filter(l => r[`h_level${l}`]).map(l => r[`h_level${l}`]).join(', ')}`).join() }}
                            </v-col>
                            <v-col class="d-flex justify-end pr-4">
                                <v-icon color="success" v-if="config.active">check_circle_outline</v-icon>
                                <v-icon v-else>not_interested</v-icon>
                                <v-btn icon color="primary" @click="setExportData(config, ci)" title="Configure"><v-icon>settings</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pb-2">
                        <div class="mb-4">
                            <h5 class="mb-2">Rules <v-btn color="primary" icon @click="addRule(config)" class="mr-2"><v-icon>mdi-plus-circle</v-icon></v-btn></h5>
                            <v-row v-for="(rule, ri) in config.rules" :key="ri">
                                <v-col class="py-0">
                                    <v-select
                                        label="Hierarchy Type"
                                        :items="hierarchies"
                                        item-text="label"
                                        item-value="ht_id"
                                        v-model="rule.ht_id">
                                    </v-select>
                                </v-col>
                                <v-col class="py-0"><v-text-field label="Level 1" v-model="rule.h_level1"></v-text-field></v-col>
                                <v-col class="py-0"><v-text-field label="Level 2" v-model="rule.h_level2"></v-text-field></v-col>
                                <v-col class="py-0"><v-text-field label="Level 3" v-model="rule.h_level3"></v-text-field></v-col>
                                <v-col class="py-0"><v-text-field label="Level 4" v-model="rule.h_level4"></v-text-field></v-col>
                                <v-col class="py-0"><v-text-field label="Level 5" v-model="rule.h_level5"></v-text-field></v-col>
                                <v-col class="py-0"><v-text-field label="Level 6" v-model="rule.h_level6"></v-text-field></v-col>
                                <v-col class="text-right">
                                    <v-btn icon @click="deleteRule(config, rule)"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row>
                            <v-col>
                                <v-btn color="red" outlined @click="deleteConfig(ci)" class="mr-2">Delete</v-btn>
                                <v-btn outlined color="primary" @click="cloneConfig(ci)">Copy Configuration</v-btn>
                            </v-col>
                            <v-col class="d-flex align-center justify-end">
                                <v-switch label="Active" v-model="config.active"></v-switch>
                                <v-btn outlined color="primary" @click="setExportData(config, ci)" class="ml-2">Configure Export</v-btn>
                                <v-btn color="primary" @click="saveConfig(ci)" class="ml-2">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="px-6">
                <v-col class="d-flex align-center justify-end">
                    <v-btn color="primary" @click="addConfiguration">Add Configuration</v-btn>
                </v-col>
            </v-row>
        </div>
        <div class="docViewHolder" :style="cssVars" v-else>
            <div class="docSectionHolderLeft">
                <v-btn outlined color="primary" class="ml-4" @click="unsetExportData"><v-icon left>mdi-arrow-left</v-icon>Back to list</v-btn>
                <div style="height:calc(60% - 50px); overflow:scroll;">
                    <draggable :group="{ name: 'tmplpart', pull: 'clone', put: false }" :list="templateData.parts">
                        <div v-for="(item, key) in templateData.parts.filter(x => x.tp_active === 1)" :key="key" class="docSectionHolder" :style="cssVars">
                            <div class="label" :data-id="item.tp_id">
                                {{ item.pt_name }}
                            </div>
                        </div>
                    </draggable>
                </div>
                <hr/>
                <div style="height:14%; overflow:scroll;">
                    <draggable :group="{ name: 'tmplpart', pull: 'clone', put: false }" :list="doc_data_attr">
                        <div v-for="(item, key) in doc_data_attr" :key="key" class="docSectionHolder" :style="cssVars">
                            <div class="label" :data-attr-id="item.value">
                                {{ item.text }}
                            </div>
                        </div>
                    </draggable>
                </div>            
                <hr/>
                <div style="height:25%; overflow:scroll;">
                    <draggable :group="{ name: 'tmplpart', pull: 'clone', put: false }" :list="templateData.dataCaptureTypes">
                        <div v-for="(item, key) in templateData.dataCaptureTypes" :key="key" class="docSectionHolder" :style="cssVars">
                            <div class="label" :data-dc-id="item.id">
                                {{ item.name }}
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
            <div class="docHolder">
                <div class="docHolderActionsHeader">
                    <div class="doc-view-font small" style="position:absolute; left:20px; top:5px" justify-center
                        align-content-center>
                        <v-btn small color="warning" @click="overwriteDialog.show = true;">
                            <v-icon left>mdi-plus-circle</v-icon>
                            Copy Template Config
                        </v-btn>
                        <v-btn small color="primary" @click="addElement" style="margin-left:10px">
                            <v-icon left>mdi-plus-circle</v-icon>
                            Add Text Element
                        </v-btn>
                        <v-btn small color="primary" @click="addTable" style="margin-left:10px">
                            <v-icon left>mdi-plus-circle</v-icon>
                            Add Table
                        </v-btn>
                        <v-btn small color="success" @click="previewHtml" style="margin-left:10px">
                            Preview Sample HTML
                        </v-btn>                 
                    </div>
                    <div class="doc-view-font small" style="position:absolute; right:20px; top:0px" justify-center align-content-center>
                        <div v-if="exportConfigId === 0" style="display:inline; color:red; font-size:11px; font-weight:bold">No Export Config Currently Saved!</div>
                        <v-btn small color="success" @click="saveConfig()" style="margin-left:10px;margin-right:10px">
                            Save Config
                        </v-btn>
                        <v-icon v-if="configSaving" small color="#377DFF">mdi-autorenew mdi-spin</v-icon>
                        <span v-if="configSaving"
                            style="color:#377DFF; padding-left: 5px; vertical-align: text-bottom;">Saving...</span>
                    </div>
                </div>
                <div class="docHolderCenter" ref="docContent">

                    <v-expansion-panels accordion multiple v-model="panelSections">
                        <v-expansion-panel>
                            <v-expansion-panel-header color="#f3f3f3">Header Section</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="exportItemHolder">
                                    <div :class="['exportItemRow']">
                                        <div class="exportItem" v-html="exportHeaderItem.text"></div> 
                                        <div class="exportActions">
                                            <v-icon color="#a1a4a9" small @click="setEdit(exportHeaderItem)">mdi-cog</v-icon>
                                        </div>
                                    </div>                                    
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header color="#f3f3f3">Body Section</v-expansion-panel-header>
                            <v-expansion-panel-content style="padding-top:5px">
                                <draggable :handle="'.dragHandle'" :group="{ name: 'tmplpart' }" @start="draggedParent = null"
                        @add="mapToData" v-model="exportData" :component-data="getComponentData()" @end="dragging = false">
                        <div class="exportItemHolder" v-for="(item, iIdx) in exportData" :key="iIdx">
                            <div :class="['exportItemRow', item.display]">
                                <v-icon class="dragHandle">mdi-drag-variant</v-icon>
                                <div class="exportItem" :align="item.align">
                                    <div v-if="item.display === 'LOGO'" v-html="item.text"></div>
                                    <div v-else-if="item.display === 'TABLE'">

                                        <table width="100%" class="groupTable" cellspacing="0" cellpadding="0"
                                            :id="`groupTable_${iIdx}`" :style="getTableCss(item.css_styles)"
                                            style="empty-cells: hide">
                                            <thead>
                                                <tr v-for="(row, rIdx) in item.header_rows" :key="rIdx"
                                                    :style="getTableCss(row.css_styles)" @mouseover="row.hover = true;"
                                                    @mouseout="row.hover = false;">
                                                    <th width="30px" class="rowActions">
                                                        <v-icon v-if="item.header_rows.length > 1"
                                                            :style="`${row.hover ? '' : 'visibility: hidden', 'cursor:move'}`"
                                                            x-small>mdi-drag-variant</v-icon>
                                                    </th>
                                                    <th v-for="(cell, cIdx) in row.cells" :key="cIdx" :colspan="cell.col_span"
                                                        :class="cell.selected ? 'selected' : ''" :rowspan="cell.row_span">
                                                        <span v-if="cell.content_type === 'static'">
                                                            <v-text-field style="width: 350px; max-width:100%; min-width: 350px"
                                                                class="slimText shrink" flat dense
                                                                v-model="cell.value"></v-text-field>
                                                        </span>
                                                        <div v-else>{{ cell.label }}</div>
                                                    </th>
                                                    <th width="30px" class="rowEndActions">
                                                        <v-icon @click="setEdit(item, row)"
                                                            :style="`${row.hover ? '' : 'visibility: hidden'}`"
                                                            x-small>mdi-cog</v-icon>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <draggable tag="tbody" :list="item.rows" group="tmplpart" handle=".rowActions"
                                                :emptyInsertThreshold='20' @add="(e) => { return addNewRow(item, e); }">
                                                <tr v-for="(row, rIdx) in item.rows" :key="rIdx" @mouseover="row.hover = true;"
                                                    @mouseout="row.hover = false;" :style="getTableCss(row.css_styles)"
                                                    :class="[row.ignore_alternating_style ? 'ignoreAlt' : '']"
                                                    :align="row.align">
                                                    <td width="30px" class="rowActions">
                                                        <v-icon v-if="item.rows.length > 1" style="cursor:move"
                                                            :style="`${row.hover ? '' : 'visibility: hidden'}`"
                                                            x-small>mdi-drag-variant</v-icon>
                                                    </td>
                                                    <td scope="row" v-for="(cell, cIdx) in row.cells" :key="cIdx"
                                                        :colspan="cell.col_span" :class="cell.selected ? 'selected' : ''"
                                                        :rowspan="cell.row_span" :style="cell.style">
                                                        <div>{{ cell.label }}</div>
                                                    </td>
                                                    <td width="30px" class="rowEndActions">
                                                        <v-icon @click="setEdit(item, row)"
                                                            :style="`${row.hover ? '' : 'visibility: hidden'}`"
                                                            x-small>mdi-cog</v-icon>
                                                    </td>
                                                </tr>
                                            </draggable>

                                        </table>
                                    </div>
                                    <div v-else>
                                        {{ item.text }}
                                    </div>
                                </div>
                                <div class="exportActions">
                                    <v-icon color="#a1a4a9" small @click="setEdit(item)">mdi-cog</v-icon>
                                </div>
                            </div>
                        </div>

                    </draggable>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header color="#f3f3f3">Footer Section</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="exportItemHolder">
                                    <div :class="['exportItemRow']">
                                        <div class="exportItem" v-html="exportFooterItem.text"></div> 
                                        <div class="exportActions">
                                            <v-icon color="#a1a4a9" small @click="setEdit(exportFooterItem)">mdi-cog</v-icon>
                                        </div>
                                    </div>                                    
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
            <div class="docSectionHolderRight">
                <div class="rightHolderNav">
                    menu
                </div>
                <div class="rightHolderContent">
                    <div justify-center class="doc-view-font small rightHolderContentLabel"></div>
                    <div class="doc-view-font small rightHolderContentMain">
                        <v-switch v-if="exportConfigId > 0" v-model="exportActive" label="Format Active?" style="padding-left:15px; padding-bottom:20px"></v-switch>
                        <v-select label="Font Selection" style="padding-left:15px" dense  v-model="exportFont" :items="available_fonts">
                            <template slot="label" slot-scope="data">
                                <span :style="{fontFamily : data.item}">{{data.item}}</span>
                            </template>
                            <template slot="selection" slot-scope="data">
                                <span :style="{fontFamily : data.item}" v-html="data.item"></span>
                            </template>  
                            <template slot="item" slot-scope="data">
                                <span :style="{fontFamily : data.item}">{{ data.item  }}</span>
                            </template>                             
                        </v-select>   
                        <v-expansion-panels v-model="panel" multiple>
                            <v-expansion-panel v-if="editItem !== null">
                                <v-expansion-panel-header>
                                    Item Configuration:
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div style="display:inline-flex; width:100%">
                                        <div style="width:25%; display: flex; align-items: center;">
                                            Alignment:
                                        </div>
                                        <div style="width:75%">
                                            <v-btn class="ma-2" outlined fab x-small
                                                :color="editItem.align === 'left' ? 'blue' : '#a1a4a9'"
                                                @click="editItem.align = 'left';">
                                                <v-icon>mdi-format-align-left</v-icon>
                                            </v-btn>
                                            <v-btn class="ma-2" outlined fab x-small
                                                :color="editItem.align === 'center' ? 'blue' : '#a1a4a9'"
                                                @click="editItem.align = 'center';">
                                                <v-icon>mdi-format-align-center</v-icon>
                                            </v-btn>
                                            <v-btn class="ma-2" outlined fab x-small
                                                :color="editItem.align === 'right' ? 'blue' : '#a1a4a9'"
                                                @click="editItem.align = 'right';">
                                                <v-icon>mdi-format-align-right</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div style="display:inline-flex; width:100%; padding-bottom:15px" v-if="editItem.section && (editItem.section === 'HEADER' || editItem.section === 'FOOTER')">
                                        <div style="width:25%; display: flex; align-items: flex-start;">
                                            Active
                                        </div>
                                        <div style="width:75%">
                                            <v-switch v-model="editItem.active"></v-switch>
                                        </div>
                                    </div>
                                    <div style="display:inline-flex; width:100%" v-if="editItem.display === 'TEXT'">
                                        <div style="width:25%; display: flex; align-items: flex-start;">
                                            Text:
                                        </div>
                                        <div style="width:75%">
                                            <v-textarea style="width:95%" outlined hide-details dense v-model="editItem.text"
                                                rows="1" auto-grow></v-textarea>
                                        </div>
                                    </div>
                                    <div style="display:inline-flex; width:100%">
                                        <div style="width:25%; display: flex; align-items: flex-start;">
                                            Format Exclusion:
                                        </div>
                                        <div style="width:75%">
                                            <v-select v-model="editItem.format_exclusion" multiple :items="exportFormats"></v-select>
                                        </div>
                                    </div>                                
                                    <div style="width:100%" v-if="editItem.display === 'TABLE'">
                                        <div style="display:inline-flex; width:100%">
                                            <div style="width:50%; display: flex; align-items: center;">
                                                Ignore Header?
                                            </div>
                                            <div style="width:50%">
                                                <v-switch v-model="editItem.ignore_header"></v-switch>
                                            </div>
                                        </div>                                
                                        Styles: <v-icon @click="openCssMenu(editItem)" x-small>mdi-plus-circle</v-icon>
                                        <table>
                                            <tr v-for="(item, idx) in editItem.css_styles" :key="idx">
                                                <td style="width:80%">{{ item.text }}</td>
                                                <td :style="{ 'width': '10%', 'background-color': item.value }">
                                                    <v-menu v-if="item.type === 'COLOUR'" offset-y
                                                        :close-on-content-click="false">
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon color="#a1a4a9" v-on="on">
                                                                mdi-palette
                                                            </v-icon>
                                                        </template>
                                                        <v-color-picker v-model="item.value"></v-color-picker>
                                                    </v-menu>
                                                    <v-text-field v-else-if="item.type === 'NUMBER'"  v-model="item.value"></v-text-field>
                                                </td>
                                                <td style="width:10%">
                                                    <v-icon color="#a1a4a9" @click="removeCss(editItem, item)">mdi-delete</v-icon>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="editItem !== null && editItem.display === 'TABLE' && editRow !== null">
                                <v-expansion-panel-header>
                                    Row Configuration:
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div style="display:inline-flex; width:100%">
                                        <div style="width:25%; display: flex; align-items: center;">
                                            Row Alignment:
                                        </div>
                                        <div style="width:75%">
                                            <v-btn class="ma-2" outlined fab x-small
                                                :color="editRow.align === 'left' ? 'blue' : '#a1a4a9'"
                                                @click="editRow.align = 'left';">
                                                <v-icon>mdi-format-align-left</v-icon>
                                            </v-btn>
                                            <v-btn class="ma-2" outlined fab x-small
                                                :color="editRow.align === 'center' ? 'blue' : '#a1a4a9'"
                                                @click="editRow.align = 'center';">
                                                <v-icon>mdi-format-align-center</v-icon>
                                            </v-btn>
                                            <v-btn class="ma-2" outlined fab x-small
                                                :color="editRow.align === 'right' ? 'blue' : '#a1a4a9'"
                                                @click="editRow.align = 'right';">
                                                <v-icon>mdi-format-align-right</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div v-if="editRow.tmpl_part_id !== null" style="width:100%">
                                        <div style="width:100%; display: inline-flex;">
                                            <div style="width:50%; display: flex; align-items: flex-start;">
                                                Display Part Label?
                                            </div>
                                            <div style="width:50%">
                                                <v-select dense v-model="editRow.opts.label_display_type" :items="labelDisplay"
                                                    @change="toggleDisplayLabel"></v-select>
                                            </div>
                                        </div>
                                        <div style="width:100%; display: inline-flex;">
                                            <div style="width:50%; display: flex; align-items: flex-start;">
                                                Multiple Row Display?
                                            </div>
                                            <div style="width:50%">
                                                <v-select dense v-model="editRow.opts.display_multi_row"
                                                    :items="rowFormat"></v-select>
                                            </div>
                                        </div>
                                        <div style="width:100%; display: inline-flex;" v-if="usesEssential">
                                            <div style="width:50%; display: flex; align-items: flex-start;">
                                                Display Essential?
                                            </div>
                                            <div style="width:50%">
                                                <v-select dense v-model="editRow.opts.display_essential"
                                                    :items="essentialDisplay"></v-select>
                                            </div>
                                        </div>
                                        <div style="width:100%; display: inline-flex;" v-if="usesEssential">
                                            <div style="width:50%; display: flex; align-items: flex-start;">
                                                Display Essential Flag Value?
                                            </div>
                                            <div style="width:50%">
                                                <v-select dense v-model="editRow.opts.display_essential_flag"
                                                    :items="essentialFlagDisplay" @change="toggleFlagDisplayLabel"></v-select>
                                            </div>
                                        </div>
                                        <div style="width:100%; display: inline-flex;" v-if="availableAttrs.length > 0">
                                            <div style="width:50%; display: flex; align-items: flex-start;">
                                                Group By Attribute Value?
                                            </div>
                                            <div style="width:50%">
                                                <v-select dense v-model="editRow.opts.group_attr" :items="availableAttrs"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display:inline-flex; width:100%"></div>
                                    <div style="display:inline-flex; width:100%">
                                        <div style="width:75%; display: flex; align-items: flex-start;">
                                            Ignore Alternating Row Style:
                                        </div>
                                        <div style="width:25%">
                                            <v-switch v-model="editRow.ignore_alternating_style"></v-switch>
                                        </div>
                                    </div>
                                    <br />
                                    <div style="width:100%">
                                        Styles: <v-icon @click="openCssMenu(editRow)" x-small>mdi-plus-circle</v-icon>
                                        <table style="width:100%">
                                            <tr v-for="(item, idx) in editRow.css_styles" :key="idx">
                                                <td style="width:80%">{{ item.text }}</td>
                                                <td :style="{ 'width': '10%', 'background-color': item.value }">
                                                    <v-menu v-if="item.type === 'COLOUR'" offset-y
                                                        :close-on-content-click="false">
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon color="#a1a4a9" v-on="on">
                                                                mdi-palette
                                                            </v-icon>
                                                        </template>
                                                        <v-color-picker v-model="item.value"></v-color-picker>
                                                    </v-menu>
                                                    <v-text-field v-else-if="item.type === 'NUMBER'" v-model="item.value"></v-text-field>
                                                </td>
                                                <td style="width:10%">
                                                    <v-icon color="#a1a4a9" @click="removeCss(editRow, item)">mdi-delete</v-icon>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <div v-if="editItem !== null">

                            <br />
                            <v-btn small class="error" @click="removeItem">
                                Delete
                            </v-btn>
                        </div>
                    </div>

                </div>
                <v-dialog v-model="cssDialog.show" persistent max-width="600" style="height:800px">
                    <v-card>
                        <v-card-title class="text-h5">
                            Add Css Style
                        </v-card-title>
                        <div class="px-5">
                            <v-select multiple v-model="cssDialog.data" return-object dense :items="cssOpts" item-text="text"
                                item-value="key"></v-select>
                        </div>
                        <v-row dense class="px-4">
                            <v-col dense cols="12">
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="cssDialog.show = false">
                                Cancel
                            </v-btn>
                            <v-btn color="success" text @click="addCssStyles">
                                Add
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="previewDialog.show" persistent scrollable fullscreen hide-overlay>
                    <v-card>
                        <v-card-title>
                            <v-select dense style="width:200px" clearable solo class="ml-2 slimSelect" bg-color="transparent"
                                item-text="system_number" item-value="system_number" :items="docItemsByType"
                                v-model="previewDoc" @change="generatePreviewHtml">
                                <template slot="label">
                                    <span style="font-size:11px; background-color:transparent">Select Preview Doc:</span>
                                </template>
                            </v-select>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <div style="width: 100%; height: 100%; text-align: center" justify-center align-center
                                v-if="previewLoading">
                                Please Wait... Generating...
                                <br />
                                <v-progress-circular :width="3" color="green" indeterminate></v-progress-circular>
                            </div>
                            <div v-else v-html="previewHtmlContent"></div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn color="blue darken-1" text @click="closePreview">
                                Close
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="closePreview">
                                Save
                            </v-btn>
                            <v-btn color="blue" @click="downloadTest">
                                Download
                            </v-btn>
                            <v-btn color="success" @click="downloadTest2">
                                Download New
                            </v-btn>
                            <v-select width="200px" style="padding-left:15px" dense :items="exportFormats" v-model="exportSampleFormat"></v-select>
                            <v-select label="Font Selection" style="padding-left:15px" dense  v-model="exportFont" :items="available_fonts" @change="generatePreviewHtml">
                                <template slot="label" slot-scope="data">
                                    <span :style="{fontFamily : data.item}">{{data.item}}</span>
                                </template>
                                <template slot="selection" slot-scope="data">
                                    <span :style="{fontFamily : data.item}" v-html="data.item"></span>
                                </template>  
                                <template slot="item" slot-scope="data">
                                    <span :style="{fontFamily : data.item}">{{ data.item  }}</span>
                                </template>                             
                            </v-select>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="overwriteDialog.show" persistent width="800px">
                    <v-card>
                        <v-card-title>
                            Inherit Config from Template?
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <b style="color:red">WARNING!</b> - Are you sure you want to replace the existing export config with a new one derived from the format of the Template?
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="overwriteDialog.show = false">
                                Close
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="cloneTemplate">
                                Confirm
                            </v-btn>                        
                        </v-card-actions>
                    </v-card>
                </v-dialog>            
            </div>    
        </div>
        <Loading :isVisible="isLoading" />
        <Notification :notification="notification" />
    </div>
</template>
    
    
<script>
import axios from "axios";
import { mapState } from "vuex";
import Notification from "@/components/common/SnackBar.vue";
import draggable from "vuedraggable";
import { asBlob as html2docx } from 'html-docx-js-typescript';
//import documentExport from "@/common/documentExport.js";

import utils from "@/common/utils.js";
import documentExport from "../../common/documentExport";

export default {
    name: "templateExportFormat",
    components: {
        Notification, draggable
    },
    props: {
        value: { type: Number, required: false },
    },
    data: function () {
        return {
            isLoading: false,
            response: null,
            templateData: null,
            exportConfigs: [],
            activeConfig: null,
            notification: {
                text: "",
                type: "success"
            },
            panel: [0, 1],
            cssDialog: {
                message: "",
                show: false,
                data: [],
                target: null,
            },
            previewDialog: {
                show: false,
            },
            overwriteDialog: {
                show: false,
            },
            editItem: null,
            editRow: null,
            displayOpts: ["TEXT", "TABLE"],
            alignOpts: ["left", "center", "right"],
            labelDisplay: [
                {
                    "text": "Hide",
                    "value": "hide"
                },
                {
                    "text": "Per Row",
                    "value": "perRow"
                },
                {
                    "text": "Merge Rows",
                    "value": "mergeRow"
                }
            ],
            essentialDisplay: [
                {
                    "text": "Essential Only",
                    "value": "essentialOnly"
                },
                {
                    "text": "All",
                    "value": "all"
                }
            ],
            rowFormat: [
                {
                    "text": "Row",
                    "value": "Row"
                },
                {
                    "text": "List",
                    "value": "List"
                },
                {
                    "text": "Nested List",
                    "value": "NestedList"
                }
            ],
            essentialFlagDisplay: [
                {
                    "text": "True",
                    "value": true
                },
                {
                    "text": "False",
                    "value": false
                }
            ],
            cssOpts: [
                {
                    "key": "--headrow-background-color",
                    "text": "Header Row Background Colour",
                    "type": "COLOUR",
                    "value": "#FFFFFF",
                    "tag": "background-color"
                },
                {
                    "key": "--headrow-text-color",
                    "text": "Header Row Text Colour",
                    "type": "COLOUR",
                    "value": "#000000",
                    "tag": "color"
                },
                {
                    "key": "--table-border-color",
                    "text": "Table Border Colour",
                    "type": "COLOUR",
                    "value": "#000000",
                    "tag": "border-color"
                },
                {
                    "key": "--row-background-color",
                    "text": "Row Background Colour (None Alternating)",
                    "type": "COLOUR",
                    "value": "#FFFFFF",
                    "tag": "background-color"
                },
                {
                    "key": "--row-even-colour",
                    "text": "Row Background Colour (Even)",
                    "type": "COLOUR",
                    "value": "#FFFFFF",
                    "tag": "background-color"
                },
                {
                    "key": "--row-odd-colour",
                    "text": "Row Background Colour (Odd)",
                    "type": "COLOUR",
                    "value": "#FFFFFF",
                    "tag": "background-color"
                },
                {
                    "key": "--row-text-even-colour",
                    "text": "Row Text Colour (Even)",
                    "type": "COLOUR",
                    "value": "#000000",
                    "tag": "color"
                },
                {
                    "key": "--row-text-odd-colour",
                    "text": "Row Text Colour (Odd)",
                    "type": "COLOUR",
                    "value": "#000000",
                    "tag": "color"
                },
                {
                    "key": "--row-text-colour",
                    "text": "Row Text Colour (None Alternating)",
                    "type": "COLOUR",
                    "value": "#000000",
                    "tag": "color"
                },
                {
                    "key": "--tbl-border-top",
                    "text": "Border Top",
                    "type": "COLOUR",
                    "value": "#000",
                    "tag": "border-top-color"
                },
                {
                    "key": "--tbl-border-left",
                    "text": "Border Left",
                    "type": "COLOUR",
                    "value": "#000",
                    "tag": "border-left-color"
                },
                {
                    "key": "--tbl-border-right",
                    "text": "Border Right",
                    "type": "COLOUR",
                    "value": "#000",
                    "tag": "border-right-color"
                },
                {
                    "key": "--tbl-border-bottom",
                    "text": "Border Bottom",
                    "type": "COLOUR",
                    "value": "#000",
                    "tag": "border-bottom-color"
                },
                {
                    "key": "--text-size",
                    "text": "Text Size",
                    "type": "NUMBER",
                    "value": "11pt",
                    "tag": "text-size"
                }
            ],
            editingIdx: null,
            exportData: [],
            exportRules: [],
            previewDoc: null,
            previewLoading: false,
            previewHtmlContent: "",
            configSaving: false,
            exportConfigId: 0,
            exportFormats: ["DOCX","PDF","RTF"],
            panelSections: [0,1,2],
            exportHeaderItem: {
                display: "TEXT",
                text: "",
                section: "HEADER",
                active: 1,
                align: "left"
            },
            exportFooterItem: {
                display: "TEXT",
                text: "",
                section: "FOOTER",
                active: 1,
                align: "left"
            },
            exportSampleFormat: "docx",
            available_fonts: ["Calibri","Times New Roman"],
            exportFont: "Calibri",
            exportActive: false,
            availableAttrs: [],
            doc_data_attr: [
                {
                    text: "Document Status",
                    value: "lifecycle_status_name"
                },
                {
                    text: "System Number",
                    value: "system_number"
                }
                //
            ]
        };
    },
    mounted() { },
    created() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
    },
    computed: {
        ...mapState({
            docsLoading: (state) => state.docs.loading,
            docStatusSummary: (state) => state.docs.docStatusSummary,
            docTypeSummary: (state) => state.docs.docTypeSummary,
            hierarchiesLoading: (state) => state.hierarchies.loading,
            docTypes: (state) => state.hierarchies.docTypes,
            hierarchies: (state) => state.hierarchies.hierarchies,
            docClassifiers: (state) => state.hierarchies.docClassifiers,
            docItems: (state) => state.docs.docsList,
        }),
        cssVars() {
            return {
                '--left-panel-width': "250px",
                '--right-panel-width': "350px",
            }
        },
        docItemsByType() {
            return this.docItems.filter(x => x.tmpl_id === this.value);
        },
        usesEssential() {
            let source = this.templateData.parts.find(x => x.tp_id === this.editRow.tmpl_part_id);
            return source?.uses_essential_flag > 0;
        },
    },
    methods: {
        cloneTemplate(){
            let newParts = [];
            let activeParts = this.templateData.parts.filter(x => x.tp_active);
            const markedArray = activeParts.map((item, index) => ({
                ...item,
                split: item.tp_name === "Horizontal Bar" ? index : -1
            }));

            let newItems = markedArray.reduce((acc, item) => {
                if (item.split !== -1) {
                    acc.push([item]);
                } else {
                    acc[acc.length - 1].push(item);
                }
                return acc;
            }, [[]]);

            newItems.forEach((g,gIdx) => {
                
                const splitArray = (arr) => arr.reduce(([match, rest], item) => item.attributes.length < 2 ? [[...match, item], rest] : [match, [...rest, item]], [[], []]);
                let items = splitArray(g);

                items[0].forEach(p => {
                    let newItem = this.baseElement(newParts.length + 1, "PART");
                    let formatOpts = this.resolveFormatOpts(p.html_export_tag);
                    newItem = {
                        ...newItem,
                        tmpl_part_id: p.tp_id,
                        text: p.tp_name,
                        ...formatOpts
                    }
                    newParts.push(newItem);
                })

                if(items[1].length > 0){
                    let newItem = this.baseElement(newParts.length + 1, "TABLE");
                    newItem = {
                        ...newItem,
                        id: gIdx
                    }
                    items[1].forEach((p,pIdx) => {
                        //let source = this.templateData.parts.find(x => x.tp_id === p.tp_id);
                        let child = this.templateData.parts.find(x => x.parent_tp_id === p.tp_id);
                        let parentRow = this.addTableRow(newItem, p, pIdx, false, false);
                        if (child) {
                            let childRow = this.addTableRow(newItem, child, pIdx + 1, true, false);
                            parentRow.childRows = [childRow];
                            newItem.rows.splice(pIdx, 0, parentRow);
                            newItem.rows.splice(pIdx + 1, 0, childRow);
                        } else {
                            newItem.rows.splice(pIdx, 0, parentRow);
                        }
                    })      
                    newParts.push(newItem);              
                }

            })

            this.exportData = newParts;
            this.overwriteDialog.show = false;
        },
        removeCss(target, item){
            let idx = target.css_styles.indexOf(item);
            target.css_styles.splice(idx, 1);
        },
        removeItem(){
            let row = this.editRow !== null;
            if(!row){
                let idx = this.exportData.indexOf(this.editItem);
                if(idx > -1){
                    this.exportData.splice(idx, 1);
                }
            } else {
                let rowIdx = this.editItem.rows.indexOf(this.editRow);
                if(rowIdx > -1){
                    this.editItem.rows.splice(rowIdx, 1);
                }                
            }

            this.editItem = null;
            this.editRow = null;
        },
        downloadTest2(){

            let data = {
                configuration: this.exportData,
                doc_id: this.previewDoc,
                format: this.exportSampleFormat,
                header: this.exportHeaderItem,
                footer: this.exportFooterItem,
                font: this.exportFont
            }

            let options = {
                responseType: "blob",
                headers: { "Content-Type": "application/json" },
            };

            let mime = documentExport.getMimeType(this.exportSampleFormat);
            let docType = this.docTypes.find(x => x.tmpl_id === this.value).tmpl_name.replace(" ","");
            let fileName = `${this.$loginState.user.client}_${docType}_${this.exportSampleFormat.toLowerCase()}_${this.previewDoc}_Sample.${this.exportSampleFormat.toLowerCase()}`;

            axios
            .post("file/downloadTest", data, options)
            .then((resp) => {
                utils.downloadFile(
                    resp.data,
                    fileName,
                    mime
                );
            })
            .catch((err) => {
                alert(err);
            });
        },
        downloadTest(){
            html2docx(this.previewHtmlContent, null, {
              orientation: "portrait",
              margins: { top: 820, bottom: 820 }
            }).then(data => {
              utils.downloadFile(
                data,
                this.previewDoc + ".docx",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              );
            });
        },
        toggleFlagDisplayLabel() {
            if (this.editRow.opts.display_essential_flag) {
                //add new label cell
                let partLabelCell = this.baseCellObj(0, 1, 1, null, null, 'essential_label', this.editRow.text);
                partLabelCell = {
                    ...partLabelCell,
                    label: "Essential?",
                    style: "width: 30px"
                }
                this.editRow.cells.push(partLabelCell);
                //add cell count to all table header/rows
                this.updateTableCells(this.editItem);
            } else {
                let exists = this.editRow.cells.find(x => x.content_type === 'essential_label');
                this.editRow.cells.splice(this.editRow.cells.indexOf(exists), 1);
                //downgrade cell count to all table header/rows
                this.updateTableCells(this.editItem);
            }
        },
        toggleDisplayLabel() {
            let exists = this.editRow.cells.find(x => x.content_type === 'part_label');
            if (!exists && this.editRow.opts.label_display_type !== "hide") {
                //add new label cell
                let partLabelCell = this.baseCellObj(0, 1, 1, null, null, 'part_label', this.editRow.text);
                partLabelCell = {
                    ...partLabelCell,
                    label: this.editRow.text,
                    style: "width: 30px"
                }
                this.editRow.cells.unshift(partLabelCell);
                //add cell count to all table header/rows
                this.updateTableCells(this.editItem);
            } else if (exists && this.editRow.opts.label_display_type === "hide") {
                //existing label cell to be remove
                this.editRow.cells.splice(this.editRow.cells.indexOf(exists), 1);
                //downgrade cell count to all table header/rows
                this.updateTableCells(this.editItem);
            }

            if(this.editRow.child_part){
                let parent = this.editItem.rows.find(x => x.childRows && x.childRows.some(c => c.tmpl_part_id === this.editRow.tmpl_part_id));
                let childRow = parent.childRows.find(c => c.tmpl_part_id === this.editRow.tmpl_part_id);
                let idx = parent.childRows.indexOf(childRow); 
                parent.childRows.splice(idx, 1, {
                    ...this.editRow
                })
            }
        },
        updateTableCells(item) {
            if (item.display === "TABLE") {
                let colCount = Math.max(...item.rows.map(x => {
                    return x.cells ? x.cells.reduce((sum, a) => sum + a.col_span, 0) : 1;
                }));

                item.header_rows.forEach(r => {
                    if (r.cells.length === 1) {
                        r.cells[0].col_span = colCount;
                    } else {
                        let prevColTotal = r.cells.slice(0, -1).reduce((sum, a) => sum + a.col_span, 0);
                        r.cells[r.cells.length - 1].col_span = colCount - prevColTotal;
                    }
                })
                item.rows.forEach(r => {
                    if (r.cells.length === 1) {
                        r.cells[0].col_span = colCount;
                    } else {
                        let prevColTotal = r.cells.slice(0, -1).reduce((sum, a) => sum + a.col_span, 0);
                        r.cells[r.cells.length - 1].col_span = colCount - prevColTotal;
                    }
                })

            }

        },
        closePreview() {
            this.previewDoc = null;
            this.previewDialog.show = false;
            this.previewHtmlContent = "";
        },
        generatePreviewHtml() {
            this.previewLoading = true;
            let data = {
                configuration: this.exportData,
                doc_id: this.previewDoc,
                format: "HTML",
                header: this.exportHeaderItem,
                footer: this.exportFooterItem,
                font: this.exportFont
            }

            axios
                .post("document/generateExportHtml", data)
                .then((resp) => {
                    this.previewHtmlContent = resp.data.body;
                    this.previewLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.previewLoading = false;
                });
        },
        previewHtml() {
            this.previewDialog.show = true;
        },
        log() {

            this.list1.forEach((x, idx) => {
                x.position = idx
            });
            this.list2.forEach((x, idx) => {
                x.position = idx
            });
            this.refreshData();

        },
        refreshData() {
            this.list1 = JSON.parse(JSON.stringify(this.list1));
            this.list2 = JSON.parse(JSON.stringify(this.list2));
        },
        getComponentData() {
            return {
                on: {
                    add: this.addNewItem
                }
            };
        },
        addCssStyles() {
            this.cssDialog.data.forEach(cs => {
                if (!this.cssDialog.target.css_styles.some(x => x.key === cs.key)) {
                    this.cssDialog.target.css_styles.push({
                        ...cs
                    });
                }
            })
            this.cssDialog.show = false;
        },
        openCssMenu(target) {
            this.cssDialog.data = [];
            this.cssDialog.target = target;
            this.cssDialog.show = true;
        },
        mapToData(item) {
            this.exportData.splice(item.newIndex, 1);
            return false;
        },
        baseCellObj(pos, rows, cols, tp_id, tpa_id, type, overrideVal = "") {
            return {
                cell_position: pos,
                row_span: rows,
                col_span: cols,
                selected: false,
                content_type: type ? type : "tmplPart",
                tmpl_part_id: tp_id,
                tpa_id: tpa_id,
                tmpl_view_section_content_id: null,
                layout: [],
                display: "text",
                value: overrideVal
            }
        },
        baseDCCellObj(pos, rows, cols, overrideVal = "", dc_id) {
            return {
                cell_position: pos,
                row_span: rows,
                col_span: cols,
                selected: false,
                content_type: "data_capture",
                dc_id: dc_id,
                tmpl_part_id: null,
                tpa_id: null,
                tmpl_view_section_content_id: null,
                layout: [],
                display: "text",
                value: overrideVal,
                label: overrideVal
            }
        },
        baseAttrCellObj(pos, rows, cols, overrideVal = "", dc_id = null) {
            return {
                cell_position: pos,
                row_span: rows,
                col_span: cols,
                selected: false,
                content_type: "doc_attr",
                doc_attr_val: dc_id,
                tmpl_part_id: null,
                tpa_id: null,
                tmpl_view_section_content_id: null,
                layout: [],
                display: "text",
                value: overrideVal,
                label: overrideVal
            }
        },
        baseDCRowObj(pos, dc) {
            return {
                row_id: 0,
                row_position: pos ?? 0,
                css_styles: [],
                cells: [],
                child_part: false,
                content_type: "data_capture",
                text: dc.name,
                dc_id: dc.id,
                placeholder: true,
                hover: false,
                label: "",
                tmpl_part_id:null,
                align: null,
                opts: {
                    display_label: false,
                    label_display_type: null,
                    display_essential: false,
                    display_essential_flag: false,
                    display_multi_row: null,
                    group_attr: null
                }
            }
        },
        baseDocAttrRowObj(pos, ds) {
            return {
                row_id: 0,
                row_position: pos ?? 0,
                css_styles: [],
                cells: [],
                child_part: false,
                content_type: "doc_attr",
                text: ds.text,
                doc_attr_val: ds.value,
                placeholder: true,
                hover: false,
                label: "",
                tmpl_part_id:null,
                align: null,
                opts: {
                    display_label: false,
                    label_display_type: null,
                    display_essential: false,
                    display_essential_flag: false,
                    display_multi_row: null,
                    group_attr: null
                }
            }
        },
        baseRowObj(pos, tp_id, child = false) {
            return {
                row_id: 0,
                tmpl_part_id: null ?? tp_id,
                row_position: pos ?? 0,
                css_styles: [],
                cells: [],
                child_part: child,
                content_type: "static",
                text: "new row...",
                placeholder: true,
                hover: false,
                label: "",
                align: null,
                opts: {
                    display_label: false,
                    label_display_type: null,
                    display_essential: false,
                    display_essential_flag: false,
                    display_multi_row: null,
                    group_attr: null
                }
            }
        },
        addNewItem(ev) {
            //let type = ev.item.innerText;
            if (ev.to.localName === "tbody") {
                return false;
            }
            //this.exportData.splice(ev.newIndex, 1);
            if(ev.item.childNodes[0].attributes["data-dc-id"]?.value){
                let dc_id = Number(ev.item.childNodes[0].attributes["data-dc-id"].value);
                let pos = this.exportData.length + 1;
                let source = this.templateData.dataCaptureTypes.find(x => x.id === dc_id);
                let newItem = this.baseElement(pos, "DC");
                let align = "left";
                newItem = {
                    ...newItem,
                    tmpl_part_id: null,
                    content_type: "data_capture",
                    text: source.name,
                    dc_id: source.id,
                    align: align
                }
                this.exportData.splice(ev.newIndex, 0, newItem);
            } else if (ev.item.childNodes[0].attributes["data-attr-id"]?.value) {
                let doc_attr = ev.item.childNodes[0].attributes["data-attr-id"].value;
                let pos = this.exportData.length + 1;
                let source = this.doc_data_attr.find(x => x.value === doc_attr);
                let align = "left";
                let newItem = this.baseElement(pos, "ATTR");
                newItem = {
                    ...newItem,
                    tmpl_part_id: null,
                    content_type: "doc_attr",
                    attr_source: source.value,
                    text: source.text,
                    align: align
                }
                this.exportData.splice(ev.newIndex, 0, newItem);
            } else if (ev.item.childNodes[0].attributes["data-id"]?.value) {
                let tp_id = Number(ev.item.childNodes[0].attributes["data-id"].value);
                let pos = this.exportData.length + 1;
                let source = this.templateData.parts.find(x => x.tp_id === tp_id);
                let isLogo = source.tp_name.toLowerCase().indexOf("logo") > -1;
                let align = "left";
                if(source.html_export_tag?.toLowerCase().indexOf("centre") > -1){
                    align = "center";
                }
                let newItem = this.baseElement(pos, isLogo ? "LOGO" : "PART");
                newItem = {
                    ...newItem,
                    tmpl_part_id: tp_id,
                    text: isLogo ? source.default_text : source.tp_name,
                    align: align
                }
                this.exportData.splice(ev.newIndex, 0, newItem);
            }

            this.refreshExportData();
            return false;
        },
        generateNewRow(item, sourcePart, pos) {
            let source = this.templateData.parts.find(x => x.tp_id === sourcePart);
            let child = this.templateData.parts.find(x => x.parent_tp_id === sourcePart);
            let parentRow = this.addTableRow(item, source, pos, false, false);
            if (child) {
                let childRow = this.addTableRow(item, child, pos + 1, true, false);
                parentRow.childRows = [childRow];
                item.rows.splice(pos, 0, parentRow);
                item.rows.splice(pos + 1, 0, childRow);
            } else {
                item.rows.splice(pos, 0, parentRow);
            }
            this.updateTableCells(item);
        },
        addNewRow(item, ev) {
            item.rows.splice(ev.newIndex, 1);
            if(ev.item.childNodes[0].attributes["data-dc-id"]?.value){
                let dc_id = Number(ev.item.childNodes[0].attributes["data-dc-id"].value);
                let source = this.templateData.dataCaptureTypes.find(x => x.id === dc_id);
                let parentRow = this.addDataCaptureRow(item, source);
                item.rows.splice(ev.newIndex, 0, parentRow);
            } else if (ev.item.childNodes[0].attributes["data-attr-id"]?.value){
                let attr_value = ev.item.childNodes[0].attributes["data-attr-id"].value;
                let parentRow = this.addDataAttrRow(item, attr_value);
                item.rows.splice(ev.newIndex, 0, parentRow);
            } else {
                let tp_id = Number(ev.item.childNodes[0].attributes["data-id"].value);
                let source = this.templateData.parts.find(x => x.tp_id === tp_id);
                let child = this.templateData.parts.find(x => x.parent_tp_id === tp_id);
                let parentRow = this.addTableRow(item, source, ev.newIndex, false, false);
                if (child) {
                    let childRow = this.addTableRow(item, child, ev.newIndex + 1, true, false);
                    parentRow.childRows = [childRow];
                    item.rows.splice(ev.newIndex, 0, parentRow);
                    item.rows.splice(ev.newIndex + 1, 0, childRow);
                } else {
                    item.rows.splice(ev.newIndex, 0, parentRow);
                }
            }

            this.updateTableCells(item);
        },
        addDataCaptureRow(item, source){
            let itempos = item.rows.length + 1;
            let newRow = this.baseDCRowObj(itempos,source);
            let colCount = item.rows.length > 0 ? Math.max(...item.rows.map(x => {
                return x.cells ? x.cells.reduce((sum, a) => sum + a.col_span, 0) : 1;
            })) : 1;

            let colAdjust = colCount > 2 ? colCount - 1 : 1;

            newRow.cells.push(this.baseDCCellObj(0,1,1,source.name));
            newRow.cells.push(this.baseDCCellObj(1,1,colAdjust,source.name, source.id));                

            this.refreshExportData();

            return newRow;           
        },
        addDataAttrRow(item, type){
            let source = this.doc_data_attr.find(x => x.value === type);
            let itempos = item.rows.length + 1;
            let newRow = this.baseDocAttrRowObj(itempos,source);
            let colCount = item.rows.length > 0 ? Math.max(...item.rows.map(x => {
                return x.cells ? x.cells.reduce((sum, a) => sum + a.col_span, 0) : 1;
            })) : 1;


            let colAdjust = colCount > 2 ? colCount - 1 : 1;

            newRow.cells.push(this.baseAttrCellObj(0,1,1,source.text));
            newRow.cells.push(this.baseAttrCellObj(1,1,colAdjust,source.text, source.value));                

            this.refreshExportData();

            return newRow;           
        },        
        addTableRow(item, source, pos, child = false, add = true, rowText = "") {
            let itempos = item.rows.length + 1;
            let newRow = this.baseRowObj(itempos, source ? source.tp_id : null, child);
            let colCount = item.rows.length > 0 ? Math.max(...item.rows.map(x => {
                return x.cells ? x.cells.reduce((sum, a) => sum + a.col_span, 0) : 1;
            })) : 1;
            let spanModifier = 0;
            if (source) {
                let activeAttrs = source.attributes.filter(x => x.active);
                newRow.text = source.tp_name;
                if (activeAttrs.length > 0) {
                    if (activeAttrs.length > colCount) {
                        item.header_rows.forEach(r => {
                            if (r.cells.length === 1) {
                                r.cells[0].col_span = activeAttrs.length;
                            } else {
                                let prevColTotal = r.cells.slice(0, -1).reduce((sum, a) => sum + a.col_span, 0);
                                r.cells[r.cells.length - 1].col_span = activeAttrs.length - prevColTotal;
                            }
                        })
                        item.rows.forEach(r => {
                            if (r.cells.length === 1) {
                                r.cells[0].col_span = activeAttrs.length;
                            } else {
                                let prevColTotal = r.cells.slice(0, -1).reduce((sum, a) => sum + a.col_span, 0);
                                r.cells[r.cells.length - 1].col_span = activeAttrs.length - prevColTotal;
                            }
                        })
                    } else {
                        spanModifier = colCount - 1;
                    }

                    activeAttrs.forEach((a, aIdx) => {
                        let applyModifier = aIdx === activeAttrs.length - 1;
                        if (!applyModifier) {
                            spanModifier--;
                        }
                        let newCell = this.baseCellObj(aIdx, 1, (applyModifier && spanModifier !== -1) ? 1 + spanModifier : 1, source.tp_id, a.tpa_id, "tmplPart");
                        newCell.label = `${source.tp_name} (${a.title})`;
                        newRow.cells.push(newCell);
                    })
                } else {
                    let newCell = this.baseCellObj(1, 1, colCount, source.tp_id, null, "tmplPart");
                    newCell.label = `${source.tp_name}`;
                    newRow.cells.push(newCell);
                }
            } else {
                newRow.text = rowText;
                let newCell = this.baseCellObj(1, 1, colCount, null, null, "static");
                newCell.label = `${rowText}`;
                newCell.value = `${rowText}`;
                newRow.cells.push(newCell);
            }


            if (add) {
                item.rows.splice(pos, 0, newRow);
            }

            this.refreshExportData();

            return newRow;

        },
        refreshExportData() {
            this.exportData = this.exportData.map(object => ({ ...object }));
        },
        generateHtml() {

            let data = {
                exportConfig: this.exportData,
                doc_id: 82068,
                format: "HTML",
                header: this.exportHeaderItem,
                footer: this.exportFooterItem
            }

            axios
                .post("document/generateExportHtml", data)
                .then((resp) => {
                    console.log(resp)
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        getTableCss(css_styles) {
            if (!css_styles) return;
            let returnObj = {};
            returnObj["--table-id"] = 0;
            css_styles.forEach(c => {
                returnObj[c.key] = c.value;
            })
            return returnObj;
        },
        setEdit(item, row) {
            this.editItem = item;
            this.editRow = row ? row : null;
            this.availableAttrs = [];
            if (row) {
                let target = this.templateData.parts.find(x => x.tp_id === row.tmpl_part_id);
                if(target && target.attributes.length > 0){
                    this.availableAttrs = [{text: "Do Not Group", value: null}, ...target.attributes.map(x => {
                        return {
                            text: x.title,
                            value: x.tpa_id
                        }
                    })];
                }
            }
        },
        baseElement(order, type) {
            let returnObj = {
                id: 0,
                position: order,
                align: "left",
                text: "",
                display: type,
                format_exclusion: []
            };

            if (type === "TABLE") {
                let newRow = this.baseRowObj(0, null);
                let newCell = this.baseCellObj(1, 1, 1, null, null, "static");
                newRow.cells.push(newCell);
                returnObj = {
                    ...returnObj,
                    css_styles: [],
                    header_rows: [newRow],
                    rows: []
                }
            }

            return returnObj;
        },
        addTable() {
            let nxtPos = this.exportData.length === 0 ? 1 : this.exportData[this.exportData.length - 1].position;
            let newItem = this.baseElement(nxtPos + 1, "TABLE");
            this.exportData.push(newItem);
        },
        addElement() {
            let nxtPos = this.exportData.length === 0 ? 1 : this.exportData[this.exportData.length - 1].position;
            let newItem = this.baseElement(nxtPos + 1, "TEXT");
            this.exportData.push(newItem);
        },
        reorderParts() {

            this.exportData.forEach((x, idx) => {
                x.position = idx;
            })

            /*console.log(ev);
            this.exportData = this.exportData.map((x, idx) => {
                return {
                    ...x,
                    position: idx
                }
            })*/

            //this.exportData = this.exportData.sort((a, b) => (a.position > b.position && 1) || -1);
        },
        setExportData(data, index){
            if (index !== undefined)
                this.editingIdx = index;
            this.editItem = null;
            this.exportData = data.configuration.map(c => {
                return {
                    ...c,
                    format_exclusion: c.format_exclusion ?? [],
                    ignore_header: c.ignore_header ?? false,
                    rows: c.rows?.map(r => {
                        return {
                            ...r,
                            opts: {
                                ...r.opts,
                                group_attr: r.opts?.group_attr ?? null
                            }
                        }
                    })
                }
            });
            this.exportRules = data.rules;
            this.exportHeaderItem = data.header ?? this.exportHeaderItem;
            this.exportFooterItem = data.footer ?? this.exportFooterItem;
            this.exportConfigId = data.tmpl_export_config_id;
            this.exportActive = data.active;

            this.exportHeaderItem.format_exclusion = this.exportHeaderItem.format_exclusion ?? [];
            this.exportFooterItem.format_exclusion = this.exportFooterItem.format_exclusion ?? [];
                      
        },
        unsetExportData() {
            this.editingIdx = null;
            this.exportData = [];
            this.exportHeaderItem = {
                display: "TEXT",
                text: "",
                section: "HEADER",
                active: 1,
                align: "left"
            };
            this.exportFooterItem = {
                display: "TEXT",
                text: "",
                section: "FOOTER",
                active: 1,
                align: "left"
            };
            this.exportConfigId = null;
            this.exportActive = false;

            this.exportHeaderItem.format_exclusion = [];
            this.exportFooterItem.format_exclusion = [];
        },
        getDefaultFormat() {
            
            let groupByCategory = this.templateData.parts.filter(x => x.html_export_tag !== null && x.parent_tp_id === null).reduce((group, item) => {
                const tag = item.html_export_tag;
                var isTableElement = tag.startsWith("table");
                let length = Object.keys(group).length;
                if (isTableElement) {
                    var tableData = tag.split("|");
                    var tableIdx = tableData[2];

                    if (!group[tableIdx]) {
                        group[tableIdx] = { tag: tableIdx, order: length + 1, items: [] };
                    }

                    group[tableIdx].items.push({
                        tag: item.html_export_tag,
                        id: item.tp_id
                    });

                } else {
                    if (!group[tag]) {
                        group[tag] = { tag: tag, order: length + 1, items: [] };
                        group[tag].items.push({
                            tag: item.html_export_tag,
                            id: item.tp_id
                        });
                    } else {
                        group[tag].items.push({
                            tag: item.html_export_tag,
                            id: item.tp_id
                        });
                    }
                }
                return group;
            }, []);


            let newList = [];

            Object.entries(groupByCategory).sort((a, b) => (a[1].order > b[1].order && 1) || -1).forEach(i => {
                i[1].items.forEach(x => {

                    var isTableElement = x.tag.startsWith("table");
                    let source = this.templateData.parts.find(p => p.tp_id === x.id);
                    if (isTableElement) {
                        var tableData = x.tag.split("|");
                        if (tableData[0] == "tableLogo") {
                            let logoElement = this.baseElement(newList.length + 1, "LOGO");
                            if (source) {
                                logoElement = {
                                    ...logoElement,
                                    tmpl_part_id: x.id,
                                    text: source.default_text
                                }
                                newList.push(logoElement);
                            }
                        } else {
                            let tblId = Number(tableData[2]);
                            let sourceTable = newList.find(x => x.id === tblId);
                            if (!sourceTable) {
                                let newTable = this.baseElement(newList.length + 1, "TABLE");
                                newTable.id = tblId;
                                newTable.header_rows[0].cells[0].value = tableData[1];
                                let headerRowCss = this.cssOpts.find(c => c.text === "Header Row Background Colour");
                                newTable.css_styles.push({
                                    ...headerRowCss,
                                    value: tableData[3]
                                });
                                newList.push(newTable);
                                sourceTable = newTable;
                            }

                            if (tableData[1] !== sourceTable.header_rows[0].cells[0].value) {
                                let newStaticRow = this.addTableRow(sourceTable, null, sourceTable.rows.length + 1, false, false, tableData[1]);
                                let rowCss = this.cssOpts.find(c => c.text === "Row Background Colour (None Alternating)");
                                newStaticRow.css_styles.push({
                                    ...rowCss,
                                    value: tableData[3]
                                });
                                newStaticRow.ignore_alternating_style = true;
                                const lightHeader = tableData.includes('light');
                                if (lightHeader) {
                                    let rowLightCss = this.cssOpts.find(c => c.text === "Row Text Colour (None Alternating)");
                                    newStaticRow.css_styles.push({
                                        ...rowLightCss,
                                        value: "#FFF"
                                    });
                                }


                                sourceTable.rows.push(newStaticRow);
                            }

                            this.generateNewRow(sourceTable, source.tp_id, sourceTable.rows.length + 1);
                        }



                    } else {
                        let newItem = this.baseElement(newList.length + 1, "PART");
                        let formatOpts = this.resolveFormatOpts(x.tag);
                        newItem = {
                            ...newItem,
                            tmpl_part_id: x.id,
                            text: source.tp_name,
                            ...formatOpts
                        }
                        newList.push(newItem);
                    }


                })



            })

            return newList;

        },
        resolveFormatOpts(tag) {

            let returnObj = {
                align: "left",
                layout: []
            }

            switch (tag) {
                case "bold_centre_large":
                    returnObj.align = "center";
                    break;
                case "bold_centre":
                    returnObj.align = "center";
                    break;
                case "bold_left_large":
                    returnObj.align = "left";
                    break;
                case "bold_left":
                    returnObj.align = "left";
                    break;
                case "list":
                case "bold_list":
                    break;
            }

            return returnObj;

        },
        triggerNotification(text, type) {
            this.notification = {
                text: text,
                type: type
            }
        },
        saveConfig(index) {
            this.configSaving = true;
            let request;

            if (index !== undefined) {
                request = this.exportConfigs[index];
            } else {
                request = {
                    tmpl_export_config_id: this.exportConfigId,
                    tmpl_id: this.value,
                    configuration: this.exportData,
                    header: this.exportHeaderItem,
                    footer: this.exportFooterItem,
                    font: this.exportFont,
                    active: this.exportActive,
                    rules: this.exportRules
                }
            }

            axios
                .post("templateBuilder/saveTemplateExportConfig", request)
                .then((data) => {
                    this.configSaving = false;
                    this.triggerNotification(`Config Saved`, "success");
                    
                    if (index !== undefined)
                        this.exportConfigs.splice(index, 1, data.data);
                    else if (this.editingIdx >= 0) {
                        this.exportConfigs.splice(this.editingIdx, 1, data.data);
                        this.setExportData(data.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.configSaving = false;
                });
        },
        deleteConfig(index) {
            if (confirm('Permanently delete this export configuration?')) {
                this.configSaving = true;
                let request;

                if (index !== undefined) {
                    request = this.exportConfigs[index];
                }

                axios
                    .post("templateBuilder/deleteTemplateExportConfig", request)
                    .then(() => {
                        this.configSaving = false;
                        this.triggerNotification(`Config Deleted`, "success");
                        
                        if (index !== undefined)
                            this.exportConfigs.splice(index, 1);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.configSaving = false;
                    });
            }
        },
        init() {
            let possibleError = false;
            this.isLoading = true;
            axios
                .get("templateBuilder/templateDetails/" + this.value)
                .then((resp) => {
                    possibleError = true;
                    if (resp.data.Status === "OK") {
                        this.templateData = resp.data.Data;
                    }
                    this.isLoading = false;
                    this.getTemplateExportConfigs();
                })
                .catch((err) => {
                    this.isLoading = false;
                    if (possibleError) {
                        alert("Code Error");
                    } else if (err.response && err.response.status === 401) {
                        this.$emit("sessionExpired", err);
                    } else {
                        alert(err.response ? err.response.data.message : err);
                    }
                    console.log(err);
                });
        },
        getTemplateExportConfigs() {
            let possibleError = false;
            this.isLoading = true;
            axios
                .get("templateBuilder/listExportConfigs/" + this.value)
                .then((resp) => {
                    possibleError = true;
                    if (resp.data.Status === "OK") {
                        this.exportConfigs = resp.data.Data;
                    }
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    if (possibleError) {
                        alert("Code Error");
                    } else if (err.response && err.response.status === 401) {
                        this.$emit("sessionExpired", err);
                    } else {
                        alert(err.response ? err.response.data.message : err);
                    }
                    console.log(err);
                });
        },
        addConfiguration() {
            this.exportConfigs.push({
                tmpl_export_config_id: 0,
				tmpl_id: this.value,
				configuration: this.getDefaultFormat(),
				header:  {
                    display: "TEXT",
                    text: "",
                    section: "HEADER",
                    active: 1,
                    align: "left"
                },
                footer: {
                    display: "TEXT",
                    text: "",
                    section: "FOOTER",
                    active: 1,
                    align: "left"
                },
				font: "Calibri",
				active: true,
                rules: []
            });
            this.activeConfig = this.exportConfigs.length - 1;
        },
        cloneConfig(index) {
            if (confirm('Create a copy of this configuration?')) {
                const currentConfig = this.exportConfigs[index];
                this.exportConfigs.push({
                    tmpl_export_config_id: 0,
                    tmpl_id: this.value,
                    configuration: JSON.parse(JSON.stringify(currentConfig.configuration)),
                    header: JSON.parse(JSON.stringify(currentConfig.header)),
                    footer: JSON.parse(JSON.stringify(currentConfig.footer)),
                    font: currentConfig.font,
                    active: true,
                    rules: []
                });
                this.activeConfig = this.exportConfigs.length - 1;
            }
        },
        addRule(config) {
            config.rules.push({
                ht_id: null,
                h_level1: '',
                h_level2: '',
                h_level3: '',
                h_level4: '',
                h_level5: '',
                h_level6: '',
            });
        },
        deleteRule(config, rule) {
            const idx = config.rules.findIndex(r => r === rule);
            if (idx >= 0)
                config.rules.splice(idx, 1);
        }
    },
};
</script>
<style scoped lang="scss">
.docSectionHolder {
    width: 200px;
    font-size: 16px !important;
    padding-top: 6px;
    margin: auto;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
    position: relative;

    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 3px 16px -4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #54616C !important;
    margin-top: 10px;
    margin-bottom: 10px;


    .label {
        height: 40px;
        padding-top: 5px;
        padding-left: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #9499A0;
        word-wrap: break-word;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
            padding-right: var(--section-hover-pad);
        }

        &.sectionComplete {
            padding-left: 32px;
            color: #00B515 !important;
        }

        .headerActions {
            float: right;
        }
    }

    .docSectionHeaderLabel {
        height: 40px;
        padding-bottom: 11px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        width: 100%;
        color: #9499A0;


        .subLabel {
            height: 40px;
            padding-top: 9px;
            padding-left: 32px;
            font-size: 14px;
            line-height: 22px;
            width: 100%;
            position: relative;
            word-wrap: break-word;
            word-break: break-word;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:hover {
                padding-right: var(--subsection-hover-pad);
            }
        }

        .sectionComplete {
            color: #00B515 !important;
        }
    }
}

.docViewHolder {

    $left-panel-width: 250px;
    $right-panel-width: 350px;
    $actionHeaderHeight: 35px;
    $bannerTopOffset: 0px;
    $headerHeight: 60px;
    $mainPanelHeight: calc(100vh - 120px - 0px);
    $childPanelHeight: calc(100vh - 120px - 0px);
    $docContentHeight: calc(100vh - 160px - 0px);

    width: 100vw;
    height: $mainPanelHeight;
    max-height: $mainPanelHeight;
    overflow: hidden scroll;
    /* padding-top: var(--display-mode-topPadding); */
    position: relative;
    top: 0px;

    $scrollbarOffset: 10px;

    .docSectionHolderLeft {
        width: $left-panel-width;
        height: $childPanelHeight;
        max-height: $childPanelHeight;
        overflow: hidden scroll;
        background: #FAFBFC;
        position: absolute;

        .docSectionHolderLeftActions {
            width: 30px;
            display: inline;
            float: right;
            top: 10px;
            position: relative;
        }
    }

    .docHolder {
        height: $childPanelHeight;
        max-height: $childPanelHeight;
        width: calc(100vw - #{$left-panel-width} - #{$right-panel-width} - #{$scrollbarOffset});

        @media only screen and (max-width: 1264px) {
            width: calc(100vw - #{$left-panel-width} - #{$scrollbarOffset});
        }

        background: #FFFFFF;
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
        padding: 0px 0px 0px 5px;
        left: $left-panel-width;
        right: $right-panel-width;
        position: absolute;

        $contentWidth: calc(100vw - #{$left-panel-width} - #{$right-panel-width} - #{$scrollbarOffset} - 5px);


        .docHolderActionsHeader {
            background-color: rgb(255, 255, 255);
            position: fixed;
            float: right;
            height: $actionHeaderHeight;
            width: $contentWidth;
            z-index: 1;
            overflow: hidden;
            padding-right: 15px;
        }

        .docHolderTop {
            background-color: rgb(255, 255, 255);
            position: fixed;
            height: var(--doc-banner-height);
            width: $contentWidth;
            z-index: 1;
            overflow: hidden;
            padding-right: 15px;
            top: $bannerTopOffset;
        }

        .docHolderCenter {
            width: $contentWidth;

            @media only screen and (max-width: 1264px) {
                width: calc(100vw - #{$left-panel-width} - #{$scrollbarOffset} - 25px);
            }

            overflow-y: scroll;
            overflow-x: hidden;
            height: $docContentHeight;
            max-height: $docContentHeight;
            position: relative;
            top: 40px;
        }

        .docHolderFooter {
            width: $contentWidth;
            overflow-y: scroll;
            height: var(--doc-footer-height);
            max-height: var(--doc-footer-height);
            position: fixed;
            bottom: 0px;
            overflow: hidden;
            padding-right: 15px;
            padding-top: 5px;
            display: inline-flex;
        }

    }

    .docSectionHolderRight {
        width: $right-panel-width;
        height: $childPanelHeight;
        max-height: $childPanelHeight;
        right: $right-panel-width;
        float: right;
        padding: 5px 0px 15px 0px;

        @media only screen and (max-width: 1264px) {
            display: none;
        }

        .rightHolderNavActions {
            width: 30px;
            display: inline-flex;
            float: left;
            top: 18px;
            position: relative;
            height: 0px;
            left: 5px;
        }

        .rightHolderNav {
            width: 100%;
            max-width: 100%;
            display: flex;
            justify-content: center;
            padding-bottom: 5px;
            border-bottom: 1px solid #e3e3e7;
        }

        .rightHolderContent {
            height: calc(100vh - 158px);
            width: $right-panel-width;
            padding: 5px 2px 3px 3px;
            top: 0px;
            position: relative;

            .rightHolderContentLabel {
                position: fixed;
                height: 30px;
                width: $right-panel-width;
                text-align: center;
                padding-top: 5px;
            }

            .rightHolderContentMain {
                width: $right-panel-width;
                height: calc(100vh - 150px - 46px);
                top: 32px;
                position: relative;
                padding-right: 15px;
                padding-left: 5px;
            }
        }


    }
}

.exportItemHolder {
    * {
        font-family: "Manrope", sans-serif !important;
    }

    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color:#75838F;
    width:100%;

    .exportItemRow {
        height: auto;
        min-height: 35px;
        margin-bottom: 5px;
        display: flex;
        border-radius: 5px;
        border: 1px solid transparent;

        &:not(.TABLE):hover {
            border: 1px solid #6cd6ff;
        }

        &.TABLE {
            min-height: 80px;
        }


        .exportItem {
            height: auto;
            padding-top: 2px;
            padding-bottom: 2px;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 5px;
            position: relative;
            width: 100%;
            min-height: 35px;

            .exportContent {
                display: inline-flex;
                height: auto;
                min-height: 25px;
                padding-top: 5px;
                padding-left: 10px;
                padding-right: 10px;
                width: calc(100% - 70px);
                max-width: calc(100% - 70px);
                position: relative;
            }
        }

        .exportActions {
            border-left: 1px dotted #387fe9;
            width: 35px;
            float: right;
            height: auto;
            padding-left: 7px;
            padding-top: 3px;
        }



    }

}

/*
.exportItem {
    height: 35px;
    border: 1px solid transparent;
    margin-top: 10px;
    border-radius: 5px;
    position: relative;

    &.TABLE {
        height: 80px;
        min-height: 80px;
    }

    &:not(.TABLE):hover {
        border: 1px solid #6cd6ff;
    }

    .exportContent {
        display: inline;
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        width: calc(100% - 70px);
        max-width: calc(100% - 70px);
        position: absolute;
        align: center;

        .TABLE {}
    }

    .exportActions {

        border-left: 1px dotted #387fe9;
        width: 35px;
        float: right;
        height: 100%;
        padding-left: 10px;
        padding-top: 5px;
        cursor: pointer;
    }
}
*/
.dragHandle {
    font-size: 12px !important;
    display: inline-flex;
    padding-right: 15px;
    cursor: move;
    align-items: flex-start !important;
    border-right: 1px dotted #387fe9;
    height: auto;
    padding-top: 10px;
    padding-left: 15px;
    width: 35px;
}

.groupTable {
    border: 1px solid;
    border-color: var(--table-border-color);
    border-collapse: collapse;
    border-left: 0;
    border-radius: 4px;
    border-spacing: 0px;




    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
        border-collapse: collapse;
        background-color: var(--headrow-background-color);
        color: var(--headrow-text-color);
    }

    tbody tr:nth-child(even):not(.ignoreAlt) {
        background-color: var(--row-even-colour);
        color: var(--row-text-even-colour);
    }

    tbody tr:nth-child(odd):not(.ignoreAlt) {
        background-color: var(--row-odd-colour);
        color: var(--row-text-odd-colour);
    }

    tbody tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        background-color: var(--row-background-color);
        color: var(--row-text-colour);
    }

    th,
    td {
        padding: 5px 4px 6px 4px;
        text-align: left;
        vertical-align: top;
        border-left: 1px solid;
        border-color: var(--table-border-color);
    }

    td {
        border-top: 1px solid;
        border-color: var(--table-border-color);
    }

    .rowActions {
        background-color: white !important;
        border: 1px solid transparent;
        border-right: 0px solid;
        border-right-color: transparent;
    }

    .rowEndActions {
        background-color: white !important;
        border: 1px solid transparent;
        border-left-color: inherit;
    }

    /*thead:first-child tr:first-child th:first-child,
    tbody:first-child tr:first-child td:first-child {
        border-radius: 4px 0 0 0;
    }

    thead:last-child tr:last-child th:first-child,
    tbody:last-child tr:last-child td:first-child {
        border-radius: 0 0 0 4px;
    }*/

}

.slimText {
    font-family: "Manrope" !important;
    padding-left: 5px;
    font-size: 14px;
    min-height: 35px !important;
    max-height: 35px !important;

    .v-input {
        color: var(--row-text-odd-colour);
    }

    &.v-input__control &.v-input__slot {
        min-height: 35px !important;
        color: var(--row-text-odd-colour);
    }

    &.--is-focused .v-input__slot {
        border: 2px solid #ff0000 !important;
        border-bottom-color: rgba(0, 0, 0, 0.38) !important;
    }



}

.text-field-transparent .v-input__slot {
    background: transparent !important;
}
</style>
    