<template>
  <div>
    <v-container fluid class="no-print">
      <div v-if="cleanItems.length" class="no-print mt-6">
        <v-card :flat="flat">
          <v-row class="px-4">
            <v-col cols="3">
              <v-text-field
                v-model="filterText"
                dense
                outlined
                flat
                clearable
                prepend-inner-icon="mdi-magnify"
                label="type text and enter to filter users..."
                class="hidden-sm-and-down"
                @input="doFilter"
                @keypress.enter="doFilter"
                @click:clear="clearFilter"
                hide-details
              />
            </v-col>
            <v-col class="text-right">
              <v-btn
                large
                icon
                :class="[ 'btn-background ml-3', view === 'details' ? 'depressed' : '']"
                title="Details View"
                @click="view = 'details'"
              >
                <v-icon>view_list</v-icon>
              </v-btn>
              <v-btn
                large
                icon
                :class="[ 'btn-background ml-3', view === 'permissions' ? 'depressed' : '']"
                title="Permission View"
                @click="view = 'permissions'"
              >
                <v-icon>checklist</v-icon>
              </v-btn>
              <v-menu offset-y v-if="canViewReports">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    large
                    icon
                    title="View Reports"
                    class="btn-background ml-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>ssid_chart</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="item in linkedReports"
                    :key="item.value"
                    :to="{ name: 'usageReports', params: { reportName: item.value } }"
                  >
                    <v-list-item-title>
                      {{ item.value }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-divider class="my-3">
          </v-divider>
          <v-data-table
            :headers="headers"
            dense
            :items="items"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :items-per-page="dtItemsPerPage"
            :page.sync="currentPage"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:header="{ props }">
              <thead class="v-data-table-header">
                <tr>
                  <th
                    v-for="head in props.headers"
                    :key="head.value"
                    @click="setSort(head)"
                    :class="head.sortable === false ? '' : 'sortable'"
                  >
                    <template v-if="head.isPG">
                      <v-chip v-if="head.isPG" small>
                        {{ head.text }}
                      </v-chip>
                      <v-icon v-if="!filterPGs.includes(head.value)" class="outlined" @click="togglePGFilter(head.value)">filter_alt</v-icon>
                      <v-icon v-else @click="togglePGFilter(head.value)">filter_alt_off</v-icon>
                    </template>
                    <template v-else>
                      <span>
                        {{ head.text }}
                      </span>
                      <v-icon small v-if="head.sortable !== false && head.value === sortBy">{{
                        sortDesc ? "arrow_downward" : "arrow_upward"
                      }}</v-icon>
                    </template>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td v-for="col in headers" :key="col.value">
                  <v-chip v-if="col.value === 'name'" small outlined label color="teal" @click="editUser(item)">
                    <span v-html="item.name"></span>
                  </v-chip>
                  <v-btn v-else-if="col.isPG" icon>
                    <v-icon v-if="item.permissionGroups.some(pg => pg.pgId === col.value)" @click="toggleUserPG(item, col.value)">check_circle_outline</v-icon>
                    <v-icon v-else style="opacity: 0.3" @click="toggleUserPG(item, col.value)">mdi-plus-outline</v-icon>
                  </v-btn>
                  <span v-else @click="editUser(item)" v-html="item[col.value]" style="font-size: 0.8rem; cursor: pointer"></span>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" class="my-6" @click="resetFilters">Clear Filters</v-btn>
            </template>
          </v-data-table>
          <v-row class="mt-2 mx-1">
            <v-col cols="3">
              <v-btn
                  color="primary"
                  outlined
                  small
                  @click="editUser()"
                  >Add User</v-btn
                >
              <v-btn
                  v-if="$loginState.isInternal"
                  color="primary"
                  class="ml-2"
                  outlined
                  small
                  @click="addFile = true"
                  >Upload Users</v-btn
                >
            </v-col>
            <v-col class="d-flex justify-center">
              <Pagination
                :totalItems="items.length"
                :pageSize="dtItemsPerPage"
                :currentPage="currentPage"
                @pageNavigation="currentPage = $event"
              />
            </v-col>
            <v-col cols="3" class="text-right">
              <v-menu offset-y z-index="301">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="!$loginState.readOnly"
                    outlined
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ itemsPerPageText }}
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="item in itemsPerPageOptions"
                    :key="item.value"
                    @click="setPageSize(item.value)"
                  >
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                color="primary"
                class="ml-2"
                outlined
                small
                @click="exportToCSV()"
                >Export to CSV</v-btn
              >
            </v-col>
          </v-row>
          
        </v-card>
      </div>

      <ResponseHandler :serviceResponse="response"></ResponseHandler>

      <Loading :isVisible="isLoading" />

      <v-dialog v-model="pwdDialogue" persistent width="400">
        <v-card class="pt-5">
          <v-card-text>
            <v-row dense>
              <v-col>{{
                "Enter New Password for " + currentUName + ":"
              }}</v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="newPassword"
                  label="Password*"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelPassword"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="savePassword">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <UserDetail
        :user_id="currentUser.user_id"
        :showDialogue="currentUser.show"
        :userList="items"
        @saved="userSaved"
        @closed="closeUserDialogue"
        @deactivated="userDeactivated"
      ></UserDetail>

      <UploadUsers v-if="addFile"
        :permissionGroups="permissionGroups"
        :ssoEnabled="ssoEnabled"
        @closed="addFile = false"
        @finished="uploadCompleted">
      </UploadUsers>

      <v-snackbar
        v-model="showErrorSnack"
        :timeout="snackTimeout"
        :color="snackColor"
        :multi-line="errorText.length > 50"
        top
      >
        {{ errorText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
//import draggable from "vuedraggable";
import Pagination from "@/components/cPagination";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import UserDetail from "@/components/cUserDetail";
import UploadUsers from "@/components/admin/cUploadUsers";
import utils from "@/common/utils.js";
import moment from "moment";

export default {
  name: "checkJP",
  components: {
    ResponseHandler,
    UserDetail,
    Pagination,
    UploadUsers,
    // draggable
  },
  props: {
    qaActions: { type: Array },
    flat: { type: Boolean, default: false },
  },
  data: function () {
    return {
      response: null,
      items: [],
      cleanItems: [],
      permissionGroups: [],
      utils: utils,
      isDirty: false,
      pwdDialogue: false,
      permissionDialogue: false,
      newPermission: null,
      newPassword: "",
      currentUID: "",
      currentUName: "",
      isLoading: false,
      tempFilterText: "",
      filterText: "",
      filterPGs: [],
      selectedReport: null,
      linkedReports: [
        { key: "User Logins", value: "User Logins Report" },
        //{ key: "Document Report", value: "User Document Report" },
      ],
      dtItemsPerPage: 20,
      itemsPerPageOptions: [
        { text: "10 Rows", value: 10 },
        { text: "20 Rows", value: 20 },
        { text: "30 Rows", value: 30 },
        { text: "50 Rows", value: 50 },
      ],
      currentPage: 1,
      view: "details",
      detailHeaders: [
        { text: "Name", align: "start", value: "name" },
        { text: "email", value: "email" },
        { text: "username", value: "username" },
        {
          text: "First Login",
          value: "first_login",
          sort: (a, b) => {
            if ((!a && !b) || a === b) return 0;
            if (!a && b) return -1;
            if (!b && a) return 1;
            return moment(a, "D MMM YYYY").isAfter(moment(b, "D MMM YYYY"))
              ? 1
              : moment(a, "D MMM YYYY").isBefore(moment(b, "D MMM YYYY"))
              ? -1
              : 0; 
          }
        },
        {
          text: "Last Login",
          value: "last_login",
          sort: (a, b) => {
            if ((!a && !b) || a === b) return 0;
            if (!a && b) return -1;
            if (!b && a) return 1;
            return moment(a, "D MMM YYYY").isAfter(moment(b, "D MMM YYYY"))
              ? 1
              : moment(a, "D MMM YYYY").isBefore(moment(b, "D MMM YYYY"))
              ? -1
              : 0; 
          }
        },
        { text: "Total Logins", value: "logins" },
        //{ text: "Actions", value: "actions", sortable: false },
      ],
      permissionHeaders: [],
      sortBy: "name",
      sortDesc: false,
      dialog: false,
      newUserDialog: false,
      deleteUserDialog: false,
      editUserDialog: false,
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      impersonation_rights: [
        { value: "NONE", label: "None" },
        { value: "ANY_USER", label: "Any User" },
        { value: "SPECIFIC_USERS", label: "Specific Users" },
      ],
      currentUser: { user_id: null, show: false },
      addFile: false,
      ssoEnabled: false,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    headers() {
      if (this.view === "details")
        return this.detailHeaders;
      else
        return [{ text: "Name", align: "start", value: "name" }, ...this.permissionHeaders ];
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.dtItemsPerPage
      );
      return option ? option.text : `${this.dtItemsPerPage} Items`;
    },
    canViewReports() {
      return this.qaActions && this.qaActions.some(a => a.name === 'usageReports');
    },
  },
  created() {
    //this.getHierarchyData();
    this.fetchData();
    this.dtItemsPerPage = Number(localStorage.getItem("dtUL")) || 20;
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      let possibleError = false;

      this.cleanItems = [];

      axios
        .get("user/list/")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.cleanItems = resp.data.Data.users.map((n) => {
              n.match = this.userMatchCriteia(n);
              return n;
            });
            this.ssoEnabled = resp.data.Data.ssoEnabled;
            this.permissionGroups = resp.data.Data.permissionGroups;
            this.permissionHeaders = resp.data.Data.permissionGroups.map(pg => {
              return { text: pg.name, isPG: true, sortable: false, value: pg.permission_group_id }
            });
            this.doFilter();
            this.isLoading = false;
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    userMatchCriteia(user) {
      return `${user.username} ${user.name} ${user.email}`
          .toLowerCase()
          .split(" ")
          .filter((x) => x.length)
          .reduce((p, c) => p + c + "|", "|")
    },
    setPageSize(value) {
      this.dtItemsPerPage = value;
    },
    setSort(head) {
      if (head.sortable === false) return;

      if (this.sortBy === head.value) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = head.value;
      }
    },
    togglePGFilter(pgId) {
      const index = this.filterPGs.indexOf(pgId);
      if (index < 0)
        this.filterPGs.push(pgId);
      else
        this.filterPGs.splice(index, 1);

      this.doFilter();
    },
    resetFilters() {
      this.filterText = "";
      this.filterPGs.splice(0);
      this.doFilter();
    },
    doFilter() {
      const filterText = (this.filterText || "").trim().toLowerCase();

      let users = this.cleanItems;

      this.filterPGs.forEach(pgId => {
        users = users.filter(u => u.permissionGroups.some(pg => pg.pgId === pgId));
      });

      if (filterText) {
        let search = filterText
          .split(" ")
          .filter((x) => x.length)
          .map((x) => x);

        users = users.filter((d) =>
          search.every((s) => d.match.indexOf(s, 0) >= 0)
        );
        users.forEach((d, di) => {
          const marked = { ...d };
          search.forEach((s) => {
            let searchArray = ["name", "email", "username"];
            searchArray.forEach((col) => {
              let val = marked[col] || "",
                posMs = val.indexOf("<mark>"),
                posMe = val.indexOf("</mark>"),
                posPrev = 0,
                output = "";
              while (posMs >= 0) {
                if (posMs - posPrev > 0) {
                  output += val
                    .substring(posPrev, posMs)
                    .replace(
                      new RegExp(s, "gi"),
                      (match) => `<mark>${match}</mark>`
                    );
                }
                output += val.substring(posMs, posMe + 7);
                posPrev = posMe + 7;
                posMs = val.indexOf("<mark>", posMe);
                posMe = val.indexOf("</mark>", posMs);
              }
              if (posPrev < val.length) {
                output += val
                  .substring(posPrev, val.length)
                  .replace(
                    new RegExp(s, "gi"),
                    (match) => `<mark>${match}</mark>`
                  );
              }
              marked[col] = output;
            });
          });
          users.splice(di, 1, marked);
        });
      }
      this.items = users;
    },
    setPassword(item) {
      this.currentUID = item.user_id;
      this.currentUName = item.name
        .replaceAll("<mark>", "")
        .replaceAll("</mark>", "");
      this.newPassword = "";
      this.pwdDialogue = true;
    },
    cancelPassword() {
      this.currentUID = "";
      this.newPassword = "";
      this.pwdDialogue = false;
    },
    savePassword() {
      if (!this.newPassword || !this.currentUID) return;
      let data = { user_id: this.currentUID, password: this.newPassword };
      axios
        .post("user/updatePassword/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.currentUID = "";
            this.newPassword = "";
            this.pwdDialogue = false;
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    exportToCSV() {
      let data = this.detailHeaders.map((h) => '"' + h.text + '"').join(",") + ",Permission Groups\n";
      this.items.forEach((d) => {
        data +=
          this.detailHeaders
            .map((h) => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",")
            + ","
            + d.permissionGroups.map(upg => this.permissionGroups.find(pg => pg.permission_group_id === upg.pgId)?.name).join(";")
            + "\n";
      });
      utils.downloadFile(data, "Users.csv", "text/csv;encoding:utf-8");
    },
    clearFilter() {
      this.filterText = "";
      this.doFilter();
    },
    sortByProperty(property) {
      return function (a, b) {
        if (a[property] > b[property]) return 1;
        else if (a[property] < b[property]) return -1;

        return 0;
      };
    },
    goToReport() {
      if (!this.selectedReport) {
        this.triggerNotification("Please select a report", "error");
      } else {
        this.$router.replace({
          name: "usageReports",
          params: { reportName: this.selectedReport },
        });
      }
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    toggleUserPG(user, pgId) {
      const existing = user.permissionGroups.find(pg => pg.pgId === pgId);
      const data = {
        user_id: user.user_id,
        permission_group_id: pgId,
        user_permission_group_id: existing?.upgId
      }

      axios
        .post("workflow/toggleUserPermissionGroup/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const index = user.permissionGroups.indexOf(existing)
            if (index < 0)
              user.permissionGroups.push({
                pgId: pgId,
                upgId: resp.data.Data.user_permission_group_id
              });
            else
              user.permissionGroups.splice(index, 1);
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    editUser(user) {
      this.currentUser.user_id = user ? user.user_id : null;
      this.currentUser.show = true;
    },
    userSaved(newUser) {
      newUser.match = this.userMatchCriteia(newUser);

      let cachedUser = this.cleanItems.find(
        (x) => x.user_id === newUser.user_id
      );
      
      if (cachedUser) {
        cachedUser.name = newUser.name;
        cachedUser.email = newUser.email;
        cachedUser.username = newUser.username;
        cachedUser.permissionGroups = newUser.permissionGroups;
        cachedUser.match = newUser.match;
      } else {
        this.cleanItems.push(newUser);
      }
      this.closeUserDialogue();
      this.doFilter();
    },
    userDeactivated(user) {
      let cachedUser = this.cleanItems.findIndex(
        (x) => x.user_id === user.user_id
      );
      if (cachedUser >= 0) {
        this.cleanItems.splice(cachedUser, 1);
      }
      this.closeUserDialogue();
      this.doFilter();
    },
    closeUserDialogue() {
      this.currentUser.user_id = null;
      this.currentUser.show = false;
    },
    uploadCompleted() {
      this.triggerNotification("Import complete", "success");
      this.fetchData();
    }
  },
};
</script>
<style scoped lang="scss">
.v-application.theme--light .btn-background.depressed {
  background-color: #dce1e4 !important;
}
.v-application.theme--dark .btn-background.depressed {
  background-color: black !important;
}

.v-data-table-header {
  th {
    padding-bottom: 10px !important;
  }
  .v-chip {
    text-transform: none;
  }
}

::v-deep .v-data-table tbody td {
  height: 37px !important;
}
</style>
