import axios from "axios";
import moment from "moment";
//import utils from "@/common/utils.js";

export const state = () => ({
	initialised: false,
	loading: false,
	docsList: [],
	userName: "",
	hierarchyTypes: [],
	classifierTypes: [],
	docStatusSummary: [],
	docStatusSummaryFiltered: [],
	docTypeSummary: [],
	docStatusIncluded: [],
	reviewGroups: [],
	reviewGroupsSummary: [],
	peopleSummary: [],
	createExtraFields: [],
	templateLinks: [],
	copyFromActions: [],
	docScoreCategories: [
		{ text: "Low", min: 0, max: 40, color: "red" },
		{ text: "Medium", min: 40, max: 75, color: "amber" },
		{ text: "High", min: 75, max: 100, color: "green" }
	],
	useTranslation: false,
	listUpdated: null,
	fromTable: false,
	docListColumns: null
});

export const mutations = {
	initialised(state) {
		state.initialised = true;
		state.loading = true;
	},
	clear(state) {
		state.docsList.splice(0);
		state.hierarchyTypes.splice(0);
		state.classifierTypes.splice(0);
		state.docStatusSummary.splice(0);
		state.docStatusSummaryFiltered.splice(0);
		state.docTypeSummary.splice(0);
		state.reviewGroups.splice(0);
		state.reviewGroupsSummary.splice(0);
		state.peopleSummary.splice(0);
		state.createExtraFields.splice(0);
		state.templateLinks.splice(0);
		state.copyFromActions.splice(0);
		state.listUpdated = null;
		state.fromTable = false;
	},
	unload(state) {
		state.initialised = false;
		state.loading = false;
		mutations.clear(state);
	},
	replace(state, data) {
		mutations.clear(state);

		state.userName = data.userName;
		state.useTranslation = data.useTranslation;
		state.docsList.push(...data.docsList);
		state.hierarchyTypes.push(...data.hierarchyTypes);
		state.classifierTypes.push(...data.classifierTypes);
		state.docStatusSummary.push(...data.docStatusSummary);
		state.docStatusSummaryFiltered.push(...data.docStatusSummaryFiltered);
		state.docTypeSummary.push(...data.docTypeSummary);
		state.reviewGroups.push(...data.reviewGroups);
		state.reviewGroupsSummary.push(...data.reviewGroupsSummary);
		state.peopleSummary.push(...data.peopleSummary);
		state.createExtraFields.push(...data.createExtraFields);
		state.templateLinks.push(...data.templateLinks);
		state.copyFromActions.push(...data.copyFromActions);
		state.listUpdated = new Date();
		state.fromTable = data.fromTable;
		state.docListColumns = data.docListColumns;

		state.loading = false;
	},
	addCopy(state, item) {
		state.docsList.push(item);
		state.listUpdated = new Date();
	},
	update(state, item) {
		let index = state.docsList.findIndex((d) => d.doc_id === item.doc_id);
		if (index >= 0) {
			if (!item.overall_score || !item.overall_score_color) {
				item.overall_score = state.docsList[index].overall_score;
				item.overall_score_color = state.docsList[index].overall_score_color;
			}
			state.docsList.splice(index, 1, item);
		} else {
			state.docsList.push(item);
		}
		state.listUpdated = new Date();
	},
	remove(state, docId) {
		let index = state.docsList.findIndex((d) => d.doc_id === docId);
		if (index >= 0) {
			state.docsList.splice(index, 1);
		}
		state.listUpdated = new Date();
	},
	updateScore(state, data) {
		if (!state.fromTable)
			return;

		let doc = state.docsList.find((d) => d.doc_id === data.doc_id);
		if (doc && doc.overall_score !== data.overall_score && (doc.language_code === "" || doc.language_code === "en")) {
			doc.overall_score = data.overall_score;
			doc.overall_score_color = data.overall_score_color;
			state.listUpdated = new Date();
		}
	},
	updateCommentCount(state, data) {
		let doc = state.docsList.find((d) => d.doc_id === data.doc_id);
		if (doc) {
			if (data.action === 'remove')
				doc.comment_count--;
			else
				doc.comment_count++;
			state.listUpdated = new Date();
		}
	},
	updateDocClassifiers(state, data) {
		if (data && data.docIds && data.docIds.length && data.classifier) {
			data.docIds.forEach(doc_id => {
				const doc = state.docsList.find(d => d.doc_id === doc_id);
				if (doc) {
					const currentClassifier = doc.classifiers.find(c => c.ct_id === data.classifier.ct_id);
					doc[`classifier_${data.classifier.ct_id}`] = data.classifier.value;
					if (currentClassifier)
						currentClassifier.cv_id = data.classifier.cv_id;
					else
						doc.classifiers.push(data.classifier);
				}
			})
		}
	}
};

function prepareRow(row, lvCols, userName, hierarchyLookup, classifierLookup, templateLinks, copyFromActions, docListColumns, reviewGroups) {
	let item = JSON.parse(JSON.stringify(row)),
		match = "";
	lvCols.forEach((c) => {
		item[c] = "";
	});

	item.hierarchies = item.hierarchies || [];
	if (hierarchyLookup && item.hierarchyIds && item.hierarchyIds.length) {
		item.hierarchies = item.hierarchyIds.map(id => {
			return hierarchyLookup['h' + id] || { hr_id: id };
		})
	}
	item.classifiers = item.classifiers || [];
	if (classifierLookup && item.classifierIds && item.classifierIds.length) {
		item.classifiers = item.classifierIds.map(id => {
			return classifierLookup['c' + id] || { cv_id: id };
		})
	}

	item.hierarchies.forEach((h) => {
		item[`listview${h.position}`] = h.hierarchy_text;
		[1, 2, 3, 4, 5, 6]
			.filter(l => h[`hierarchy${l}`])
			.forEach(l => item[`hry${h.position}_${l}`] = h[`hierarchy${l}`])
	});
	if (item.classifiers)
		item.classifiers.forEach(c => {
			item[`classifier_${c.ct_id}`] = c.value;
		});

	if (item.language_code !== "" && item.language_code !== "en")
		item.overall_score = null;

	if (item.lifecycle_id) {
		item.canCopyTo = copyFromActions
			.filter(a => a.lifecycle_id === item.lifecycle_id)
			.map(a => {
				return {
					tmpl_id: a.tmpl_id_copy_destination,
					tmpl_name: a.tmpl_copy_destination
				}
			});
	} else {
		item.canCopyTo = [
			{ tmpl_id: item.tmpl_id, tmpl_name: item.doc_type },
			...templateLinks
				.filter(l => l.tmpl_id_master === item.tmpl_id)
				.map(l => {
					return {
						tmpl_id: l.tmpl_id_linked,
						tmpl_name: l.tmpl_linked
					}
				})
		];
	}

	const doc_review_groups = [];
	if (item.review_group_ids) {
		item.review_group_ids.split(',').forEach(id => {
			const rg = reviewGroups.find(rg => rg.review_group_id == id);
			if (rg) {
				doc_review_groups.push(rg.name);
			}
		});
	} else {
		item.review_groups?.split(',').forEach(v => {
			if (v) {
				doc_review_groups.push(v);
			}
		});
	}
	item.review_groups_list = doc_review_groups;
	item.review_groups = doc_review_groups.join('; ');

	docListColumns.filter(c => c.searchWeighting).forEach(f => {
		match += item[f.value] ? ` ${item[f.value]}` : "";
	})

	item.match = match
		.toLowerCase()
		.split(" ")
		.filter((x) => x.length)
		.reduce((p, c) => p + c + "|", "|");
	const owners = [item.recruiter?.toLowerCase(), item.created_by?.toLowerCase(), ...(item.lifecyle_role_creator || "").toLowerCase().split(',')].filter(u => u);
	item.myJob = owners.includes(userName.toLowerCase());
	return item;
}

let docsRefreshTimeout;

export const actions = {
	init({ commit, getters, rootState }, loginState) {
		if (!getters.isInitialised) {
			commit("initialised");
			actions.getAllDocs({ commit, getters, rootState }, loginState?.user?.name);
		}
	},
	unload({ commit, getters }) {
		if (getters.isInitialised) {
			if (docsRefreshTimeout)
				clearTimeout(docsRefreshTimeout);

			commit("unload");
		}
	},
	refresh({ commit, getters, rootState }) {
		if (!getters.isInitialised || getters.loading) {
			return;
		}

		if (docsRefreshTimeout)
			clearTimeout(docsRefreshTimeout);

		actions.getAllDocs({ commit, getters, rootState }, getters.userName);
	},
	getAllDocs({ commit, getters, rootState }, userName) {
		if (!getters.isInitialised) return;

		axios
			.get("document/docswithparts/")
			.then(async (resp) => {
				if (resp.data.Status === "OK") {
					const data = {};
					data.userName = userName;
					data.hierarchyTypes = resp.data.Data.hierarchyTypes;
					data.classifierTypes = resp.data.Data.classifierTypes || [];
					data.createExtraFields = resp.data.Data.createExtraFields || [];
					data.templateLinks = resp.data.Data.templateLinks || [];
					data.copyFromActions = resp.data.Data.copyFromActions || [];
					data.reviewGroups = resp.data.Data.reviewGroups || [];
					data.docStatusSummary = resp.data.Data.statuses.map(s => {
						s.count = 0;
						s.position = Number(s.position) || 99;
						return s;
					}) || [];
					data.docTypeSummary = [];
					data.fromTable = resp.data.Data.fromTable || false;

					//wait until hierarchies are loaded.
					while (rootState.hierarchies.loading || rootState.settings.loading)
						await new Promise(resolve => setTimeout(resolve, 50));

					let translateSetting = resp.data.Data.settings.find(
						(x) => x.setting === "UseTranslation"
					);
					if (translateSetting) {
						data.useTranslation =
							translateSetting.value == "true" ? true : false;
					} else {
						data.useTranslation = false;
					}

					data.docListColumns = rootState.settings.config.docListColumns;
					data.reviewGroupsSummary = [];
					const peopleSummary = data.docListColumns
						.filter(c => c.isPersonColumn)
						.map(c => {
							return {
								label: c.text,
								column: c.value,
								values: []
							}
						});

					let lvCols = resp.data.Data.hierarchyTypes.map(
						(ht) => "listview" + ht.position
					);

					data.docsList = resp.data.Data.documents.map((x) => {
						let n = prepareRow(x, lvCols, userName, rootState.hierarchies.hierarchyLookup, rootState.hierarchies.docClassifierLookup, data.templateLinks, data.copyFromActions, data.docListColumns, data.reviewGroups);

						let ss = data.docStatusSummary.find(
							(s) => s.status === n.doc_status_text
						);
						if (!ss) {
							let position = 99;
							let show = true;
							if (n.doc_status_text.startsWith('Part ')) {
								const sourceStatus = data.docStatusSummary.find(
									(s) => s.status === n.doc_status_text.replace('Part ', '')
								);
								if (sourceStatus && sourceStatus.position) position = sourceStatus.position - 0.5;
								if (sourceStatus) show = sourceStatus.show;
							}
							ss = { status: n.doc_status_text, count: 0, show: show, position: position };
							data.docStatusSummary.push(ss);
						}
						ss.count++;

						let st = data.docTypeSummary.find((s) => s.docType === n.doc_type);
						if (!st) {
							const template = rootState.hierarchies.docTypes.find(t => t.tmpl_id === n.tmpl_id);
							st = {
								docType: template?.tt_name,
								tt_id: template?.tt_id,
								tmpl_id: template?.tmpl_id,
								count: 0,
								docAbbrev: template?.abbreviation,
								showInAllJobs: template?.showInAllJobs
							};
							data.docTypeSummary.push(st);
						}
						st.count++;

						n.review_groups_list.forEach(v => {
							if (v) {
								if (!data.reviewGroupsSummary.includes(v))
									data.reviewGroupsSummary.push(v);
							}
						});

						peopleSummary.forEach(r => {
							x[r.column]?.split(',').forEach(v => {
								const vlower = v.toLowerCase();
								if (v && !r.values.some(p => p.toLowerCase() === vlower))
									r.values.push(v);
							});
						});

						return n;
					});

					data.reviewGroupsSummary.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}));
					data.docTypeSummary.sort((a, b) => a.tt_id - b.tt_id);
					data.peopleSummary = peopleSummary.filter(s => s.values.length)
						.map(s => {
							return { column: s.column, label: s.label, values: s.values.sort() };
						});
					data.docStatusSummary = data.docStatusSummary.filter(s => s.count);
					data.docStatusSummaryFiltered = [...data.docStatusSummary];

					commit("replace", data);

					//refresh every 15 mins
					docsRefreshTimeout = setTimeout(() => actions.getAllDocs({ commit, getters, rootState }, userName), 15 * 60 * 1000);
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					commit("unload");
				} else {
					//try again in 30 secs
					docsRefreshTimeout = setTimeout(() => actions.getAllDocs({ commit, getters, rootState }, userName), 30 * 1000);
				}
				console.log(err);
			});
	},
	addCopy({ commit, state, rootState }, data) {
		let lvCols = state.hierarchyTypes.map(
			(ht) => "listview" + ht.position
		);
		let item = prepareRow(data, lvCols, state.userName, rootState.hierarchies.hierarchyLookup, rootState.hierarchies.docClassifierLookup, state.templateLinks, state.copyFromActions, state.docListColumns, state.reviewGroups);
		commit("addCopy", item);
	},
	updateDoc({ commit, state, rootState }, doc) {
		let lvCols = state.hierarchyTypes.map(
			(ht) => "listview" + ht.position
		);
		let item = prepareRow(doc, lvCols, state.userName, rootState.hierarchies.hierarchyLookup, rootState.hierarchies.docClassifierLookup, state.templateLinks, state.copyFromActions, state.docListColumns, state.reviewGroups);
		commit("update", item);
	},
	removeDoc({ commit }, docId) {
		commit("remove", docId);
	},
	updateScore({ commit }, data) {
		commit("updateScore", data);
	},
	updateCommentCount({ commit }, data) {
		commit("updateCommentCount", data);
	},
	updateDocClassifiers({ commit }, data) {
		commit("updateDocClassifiers", data);
	},
	getDocumentSummary({ commit, getters }, doc_id) {
		if (commit && getters)
			return new Promise((resolve, reject) => {
				axios
					.post("document/getDocumentSummary/", { doc_id: doc_id })
					.then((resp) => { 
						resolve(resp);
					})
					.catch((err) => {
						reject(err);
					});
			});
	},
};

export const getters = {
	isInitialised: (state) => {
		return state.initialised;
	},
	docsList: (state) => {
		return state.docsList;
	},
	userName: (state) => {
		return state.userName;
	},
	filter: (state) => (params) => {
		let docs = [...state.docsList];

		if (params.docIdsIncluded && params.docIdsIncluded.length)
			docs = docs.filter((doc) => params.docIdsIncluded.includes(doc.doc_id));

		if (params.docIdsExcluded && params.docIdsExcluded.length)
			docs = docs.filter((doc) => !params.docIdsExcluded.includes(doc.doc_id));

		if (params.lifecycleIdsIncluded && params.lifecycleIdsIncluded.length)
			docs = docs.filter((doc) => params.lifecycleIdsIncluded.includes(doc.lifecycle_id));

		if (params.myJobs)
			docs = docs.filter((doc) => doc.myJob);

		if (params.docTypeIncluded && state.docTypeSummary.length > params.docTypeIncluded.length) {
			docs = docs.filter((d) =>
				params.docTypeIncluded.find((s) => s.docType === d.doc_type)
			);
		}
		state.docStatusSummaryFiltered = state.docStatusSummary.filter(x => docs.some(d => d.doc_status_text === x.status));

		if (params.canCopyTo) {
			docs = docs.filter((d) => d.doc_type === params.canCopyTo || d.canCopyTo.some(c => c.tmpl_name === params.canCopyTo));
		}

		if (params.docCreatedDateRange && params.docCreatedDateRange.length) {
			const fromDate = moment(params.docCreatedDateRange[0]);
			const toDate = params.docCreatedDateRange.length > 1 ? moment(params.docCreatedDateRange[1]) : undefined;

			docs = docs.filter((d) => {
				const created = moment(d.created_date);
				return created.isAfter(fromDate) && (!toDate || created.isBefore(toDate));
			});
		}

		if (params.docStatusIncluded) { // && state.docStatusSummary.length > params.docStatusIncluded.length) { - fix weird bug where hidden statuses passed through
			docs = docs.filter((d) =>
				params.docStatusIncluded.find((s) => s.status === d.doc_status_text)
			);
		}

		if (params.docScoresIncluded && state.docScoreCategories.length > params.docScoresIncluded.length) {
			docs = docs.filter((d) =>
				d.overall_score && params.docScoresIncluded.find((s) => d.overall_score >= s.min && d.overall_score <= s.max)
			);
		}

		if (params.hierarchyFilters) {
			params.hierarchyFilters.forEach((hf) => {
				if (hf.selected.length) {
					docs = docs.filter((d) =>
						hf.selected.find((s) => {
							let ht = d.hierarchies.find((ht) => ht.ht_id === hf.ht_id);
							return ht && s.id.split("_")[0] == ht.hr_id; // && !s.children
						})
					);
				}
			});
		}

		if (params.classifierFilters) {
			params.classifierFilters.forEach((cf) => {
				const cvs = cf.selected.map(c => c.cv_id);
				if (cf.selected.length) {
					docs = docs.filter((d) =>
						d.classifiers.some(c => cvs.includes(c.cv_id)) || (cvs.includes(0) && !d.classifiers.some(c => c.ct_id === cf.ct_id))
					);
				}
			});
		}

		if (params.reviewGroupsIncluded && params.reviewGroupsIncluded.length) {
			docs = docs.filter(d => {
				if (!d.review_groups_list)
					return false;

				const docGroups = d.review_groups_list;
				return params.reviewGroupsIncluded.some(i => docGroups.includes(i));
			})
		}

		if (params.peopleIncluded && params.peopleIncluded.length) {
			params.peopleIncluded
				.filter(f => f.values && f.values.length)
				.forEach(f => {
					docs = docs.filter(d => {
						if (!d[f.column])
							return false;

						const docUsers = d[f.column].toLowerCase().split(',');
						return f.values.some(v => docUsers.includes(v.toLowerCase()));
					});
				});
		}

		if (params.languageFilter) {
			docs = docs.filter(d => d.language_code == params.languageFilter);
		}

		if (params.emailable) {
			docs = docs.filter(d => !d.lifecycle_id || d.email_action_id);
		}

		if (params.filterText) {
			let search = params.filterText
				.split(" ")
				.filter((x) => x.length)
				.map((x) => x);

			docs = docs.filter((d) =>
				search.every((s) => d.match.indexOf(s, 0) >= 0)
			);
			const exactMatchRegexp = new RegExp(params.filterText, "gi");
			const searchCols = state.docListColumns.filter(c => c.searchWeighting);
			docs.forEach((d, di) => {
				const marked = { ...d, matchRating: 0 };
				search.forEach((s, si) => {
					const matchRegexp = new RegExp(s, "gi");
					searchCols.forEach(col => {
						//prioritise exact matches when searching multiple words
						if (si === 0 && search.length > 1 && exactMatchRegexp.test(d[col.value]))
							marked.matchRating += col.searchWeighting * search.length;

						if (matchRegexp.test(d[col.value]))
							marked.matchRating += col.searchWeighting;

						let val = marked[col.value] || "",
							posMs = val.indexOf("<mark>"),
							posMe = val.indexOf("</mark>"),
							posPrev = 0,
							output = "";
						if (!(typeof val === 'string' || val instanceof String)) val = "";
						while (posMs >= 0) {
							if (posMs - posPrev > 0) {
								output += val
									.substring(posPrev, posMs)
									.replace(
										new RegExp(s, "gi"),
										(match) => `<mark>${match}</mark>`
									);
							}
							output += val.substring(posMs, posMe + 7);
							posPrev = posMe + 7;
							posMs = val.indexOf("<mark>", posMe);
							posMe = val.indexOf("</mark>", posMs);
						}
						if (posPrev < val.length) {
							output += val
								.substring(posPrev, val.length)
								.replace(
									matchRegexp,
									(match) => `<mark>${match}</mark>`
								);
						}
						marked[col.value] = output;
					})
				});
				//marked.system_number += ` (${marked.matchRating})`;
				docs.splice(di, 1, marked);
			});
			docs.sort((a, b) => a.matchRating < b.matchRating ? 1 : -1);
		}
		docs.forEach((doc, index) => (doc.index = index + 1));
		return docs;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
