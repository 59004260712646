<template>
  <v-container class="homeContainer">
    <v-card>
      <v-card-text>
        <!-- <h1>Welcome {{ $loginState.user.name }}!</h1>
        <h2>Where would you like to start?</h2> -->
        <v-row class="mt-12 justify-center">
          <v-col
            v-if="$loginState.user.createDocumentDialogOptions.length"
            cols="12"
            md="6"
            lg="3"
          >
            <v-card
              flat
              :class="{ primaryLink: true, focused: focusedLink === 'create' }"
              link
              @click="$emit('addDocument')"
              @keypress.enter="$emit('addDocument')"
              @mouseenter="focusedLink = 'create'"
              @mouseleave="focusedLink = null"
            >
              <div class="icon">
                <v-icon large>add_circle</v-icon>
              </div>
              <v-subheader class="subtitle-1">Create</v-subheader>
            </v-card>
          </v-col>
          <v-col
            v-for="l in primaryLinks"
            :key="l.name"
            cols="12"
            md="6"
            lg="3"
          >
            <v-card
              flat
              :class="{ primaryLink: true, focused: l.name === focusedLink }"
              link
              :to="l.name"
              @mouseenter="focusedLink = l.name"
              @mouseleave="focusedLink = null"
            >
              <div class="icon">
                <v-icon large :class="l.iconClass">{{ l.meta.icon }}</v-icon>
              </div>
              <v-subheader class="subtitle-1">{{ l.meta.text }}</v-subheader>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-6 justify-center" v-if="primaryRow2.length !== 0">
          <v-col
            v-for="l in primaryRow2"
            :key="l.name"
            cols="12"
            md="6"
            lg="3"
          >
            <v-card
              flat
              :class="{ primaryLink: true, focused: l.name === focusedLink }"
              link
              :to="l.name"
              @mouseenter="focusedLink = l.name"
              @mouseleave="focusedLink = null"
            >
              <div class="icon">
                <v-icon large :class="l.iconClass">{{ l.meta.icon }}</v-icon>
              </div>
              <v-subheader class="subtitle-1">{{ l.meta.text }}</v-subheader>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-12">
          <div v-for="(l, li) in secondaryLinks" :key="l.name">
            <v-btn class="secondaryLink mx-2" link text :to="l.name">
              <v-list-item-title>{{ l.meta.text }}</v-list-item-title>
            </v-btn>
            <v-divider
              vertical
              v-if="li < secondaryLinks.length - 1"
              style="min-height: 30px"
            />
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "HomePage",
  props: {
    qaActions: { type: Array, required: true },
  },
  data: () => ({
    primaryLinks: [],
    primaryRow2: [],
    secondaryLinks: [],
    focusedLink: null,
  }),
  methods: {
    addPrimaryLink(name) {
      const action = this.qaActions.find((a) => a.name == name);
      if (action && !this.primaryLinks.includes(action))
        this.primaryLinks.push(action);
    },
    addPrimaryRow2Link(name) {
      const action = this.qaActions.find((a) => a.name == name);
      if (action && !this.primaryRow2.includes(action))
        this.primaryRow2.push(action);
    },
    addSecondaryLink(name) {
      const action = this.qaActions.find((a) => a.name == name);
      if (action && !this.secondaryLinks.includes(action))
        this.secondaryLinks.push(action);
    },
  },
  mounted() {
    this.primaryLinks.splice(0);
    this.addPrimaryLink("myjobs");
    this.addPrimaryLink("alljobs");

    this.primaryRow2.splice(0);
    const dashboards = this.qaActions.filter((a) => a.meta.dashboardId);
    this.primaryRow2.push(...dashboards);
    this.addPrimaryRow2Link("insights");

    this.secondaryLinks.splice(0);
    this.addSecondaryLink("contentmanager");
    this.addSecondaryLink("usageReports");
    this.addSecondaryLink("admin");

    this.$support.show();
  },
  beforeDestroy() {
    this.$support.hide();
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/vars";

.homeContainer {
  > .v-card {
    min-height: calc(100vh - 80px);

    .v-card__text {
      padding: 16px 48px;
    }
    @media (min-width: 960px) {
      .v-card__text {
        padding: 16px 180px;
      }
    }

    h1 {
      text-align: center;
      font-weight: normal;
      font-size: 32px;
      margin-top: 50px;
    }

    h2 {
      text-align: center;
      font-size: 20px;
      margin-top: 15px;
      font-weight: normal;
    }
    &.theme--light {
      h1 {
        color: $primary-color-light;
      }
    }
    &.theme--dark {
      h1 {
        color: $primary-color-dark;
      }
    }

    .primaryLink {
      margin: 0;
      padding: 2em 0;
      border-radius: 32px !important;
      border: solid 2px $input-border-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        display: flex;
        justify-content: center;
        width: 64px;
        height: 64px;
        border-radius: 20px !important;
        background-color: $input-border-color;
        .v-icon {
          color: white;
        }
      }
      .v-subheader {
        margin-top: 1em;
      }

      &.theme--dark {
        .icon {
          background-color: $input-border-color-dark;
        }
      }

      &.focused,
      &:focus {
        border-color: $primary-color-light !important;
        .icon {
          background-color: $primary-color-light;
        }
        &.theme--dark {
          border-color: $primary-color-dark !important;
          .icon {
            background-color: $primary-color-dark;
          }
        }
      }
    }
  }
}
</style>
