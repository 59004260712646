<template>
  <v-container v-if="document" style="max-width: 100% !important">
    <v-card>
      <v-app-bar fixed dense color="grey lighten-5">
        <v-btn small tile @click="closeDocument" style="margin-right: 15px">
          <v-icon>mdi-close</v-icon>Close
        </v-btn>
        <v-btn
          v-if="
            !$loginState.readOnly &&
            (docTypes.length === 1 || document.doc_status === 'DELETED')
          "
          small
          tile
          @click="copyDocument"
          style="margin-right: 15px"
        >
          <v-icon>mdi-content-copy</v-icon>Copy
        </v-btn>
        <v-menu
          v-if="
            !$loginState.readOnly &&
            docTypes.length > 1 &&
            document.doc_status !== 'DELETED'
          "
          v-model="copymenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!$loginState.readOnly"
              small
              tile
              style="margin-right: 15px"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-content-copy</v-icon>Copy/Link
            </v-btn>
          </template>

          <v-card>
            <v-list dense>
              <v-list-item dense>
                <v-list-item-action>
                  <v-btn dense text @click="linkDocumentAction(null, 'copy')">
                    <span class="ml-1">Copy {{ linkDef.tmpl_name }}</span>
                  </v-btn>
                </v-list-item-action>
                <v-spacer></v-spacer>
                <v-list-item-action>
                  <v-btn dense text @click="copymenu = false">Cancel</v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
              <v-subheader
                >{{
                  docTypes.filter((x) => x.canCreate || x.docs.length > 0)
                    .length === 0
                    ? "No "
                    : ""
                }}
                Linked Documents</v-subheader
              >
              <v-list-item
                v-for="(dt, dti) in docTypes.filter(
                  (x) => x.canCreate || x.docs.length > 0
                )"
                :key="'dt_' + dti"
                dense
              >
                <v-list-item-avatar>
                  <PartTypeIcon
                    :partType="dt.docAbbrev"
                    :file_info="null"
                  ></PartTypeIcon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div>
                    <v-btn
                      v-if="dt.docs.length === 1"
                      dense
                      text
                      @click="linkDocumentAction(dt.docs[0], 'view')"
                      >{{ dt.tmpl_name }} {{ dt.docs[0].reference }}</v-btn
                    >
                    <v-menu offset-y v-if="dt.docs.length > 1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dense text v-bind="attrs" v-on="on">
                          {{ dt.tmpl_name }}s
                          <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          dense
                          v-for="(d, di) in dt.docs"
                          :key="'tv' + dti + '_' + di"
                          @click="linkDocumentAction(d, 'view')"
                        >
                          <v-list-item-title>{{
                            d.reference
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn
                      v-if="dt.canCreate"
                      dense
                      text
                      @click="linkDocumentAction(dt, 'link')"
                      >Create
                      {{
                        !dt.docs.length ? "Linked " + dt.tmpl_name : ""
                      }}</v-btn
                    >
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-menu
          v-model="exportMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              tile
              style="margin-right: 15px; min-width: 100px"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                >{{
                  $loginState.user.client === "Diageo" ||
                  $loginState.user.client === "Chevron"
                    ? "mdi-share-all-outline  "
                    : "mdi-file-download-outline"
                }}
                mdi-share-all-outline</v-icon
              >
              <span style="margin-left: 5px">{{
                $loginState.user.client === "Diageo" ||
                $loginState.user.client === "Chevron"
                  ? "   Share"
                  : "Export"
              }}</span>
            </v-btn>
          </template>

          <v-card>
            <v-list dense>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Export Options</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn dense text @click="exportMenu = false">Cancel</v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list dense>
              <div v-if="!this.$loginState.readOnly">
                <v-list-item
                  v-for="(sec, si) in html_export_sections"
                  :key="'dt' + si"
                  dense
                >
                  <v-row>
                    <v-col cols="1">
                      <v-icon>{{
                        "mdi-numeric-" + (si + 1) + "-box-multiple-outline"
                      }}</v-icon>
                    </v-col>
                    <v-col cols="11">
                      <v-btn dense text @click="docToClipboard(sec)">{{
                        sec.replace(/ .*/, "") +
                        (sec.replace(/ .*/, "") === "Section"
                          ? " " + (si + 1)
                          : "") +
                        " to Clipboard"
                      }}</v-btn>
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item
                  key="dt09"
                  dense
                  v-if="usesTaleoFeed || this.$loginState.canDemo"
                >
                  <v-row>
                    <v-col cols="1">
                      <v-icon>mdi-transfer-right</v-icon>
                    </v-col>
                    <v-col cols="11">
                      <v-btn
                        v-if="this.$loginState.user.client === 'Akamai'"
                        dense
                        text
                        @click="sendToTaleo()"
                        >Export to ACE</v-btn
                      >
                      <v-btn
                        v-else-if="
                          this.$loginState.user.client === 'Diageo' ||
                          this.$loginState.user.client === 'Chevron'
                        "
                        dense
                        text
                        @click="sendToTaleo()"
                        >Export to Workday</v-btn
                      >
                      <v-btn v-else dense text @click="sendToTaleo()"
                        >Export to ATS</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item
                  key="dt19"
                  dense
                  v-if="
                    this.$loginState.canDemo ||
                    $loginState.user.client === 'Chevron'
                  "
                >
                  <v-row>
                    <v-col cols="1">
                      <v-icon>mdi-email-send-outline</v-icon>
                    </v-col>
                    <v-col cols="11">
                      <v-btn
                        dense
                        text
                        @click="
                          emailDialogue.show = true;
                          exportMenu = false;
                        "
                        >Email to...</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-list-item>
              </div>
              <v-list-item key="dt10" dense>
                <v-row>
                  <v-col cols="1">
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-col>
                  <v-col cols="11">
                    <v-btn dense text @click="generatePDF()"
                      >Download PDF</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item key="dt11" dense v-if="!this.$loginState.canDemo">
                <v-row>
                  <v-col cols="1">
                    <v-icon>mdi-file-word</v-icon>
                  </v-col>
                  <v-col cols="11">
                    <v-btn dense text @click="generateRTF()"
                      >Download RTF</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item key="dt14" dense v-if="!this.$loginState.canDemo">
                <v-row>
                  <v-col cols="1">
                    <v-icon>mdi-file-word-box-outline</v-icon>
                  </v-col>
                  <v-col cols="11">
                    <v-btn dense text @click="generateDOCX()"
                      >Download Word</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item key="dt12" dense>
                <v-row>
                  <v-col cols="1">
                    <v-icon>mdi-file-word-box</v-icon>
                  </v-col>
                  <v-col cols="11">
                    <v-btn dense text @click="generateDOCX(true)"
                      >Download Word (+Notes)</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item
                key="dt13"
                dense
                v-if="
                  this.usesInterviewTemplate ||
                  this.$loginState.user.client === 'Scottish Parliament' ||
                  this.$loginState.user.client === 'Baker Hughes'
                "
              >
                <v-row>
                  <v-col cols="1">
                    <v-icon>mdi-view-list-outline</v-icon>
                  </v-col>
                  <v-col cols="11">
                    <v-btn dense text @click="generateInterviewTemplate()"
                      >Export Interview Template</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item
                key="dt15"
                dense
                v-if="
                  (this.$loginState.user.client === 'Akamai' ||
                    this.$loginState.user.client === 'Elsevier' ||
                    this.$loginState.user.client === 'Demo') &&
                  this.document.state.canEditDocParts
                "
              >
                <v-row>
                  <v-col cols="1">
                    <v-icon>mdi-account-reactivate</v-icon>
                  </v-col>
                  <v-col cols="11">
                    <v-btn dense text @click="generateIntakeFlow()">
                      <span v-if="this.$loginState.user.client === 'Akamai'"
                        >Export Launch Template</span
                      >
                      <span v-else>Export Intake Flow</span></v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-menu
          offset-y
          v-if="
            document &&
            document.state.uses_workflow &&
            document.state.actionsAvailable.length
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="primary" v-bind="attrs" v-on="on">
              <span>Workflow</span>
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item>
              <v-list-item-title class="subtitle-1">
                Document Actions
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(aa, ai) in document.state.actionsAvailable"
              :key="'aa' + ai"
              @click="doStateAction(aa)"
            >
              <v-list-item-icon>
                <v-icon>{{
                  "mdi-alpha-" +
                  aa.name.substr(0, 1).toLowerCase() +
                  "-box-outline"
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ aa.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div
          v-if="docTypes.length > 1"
          class="headerCell"
          style="padding-left: 60px; padding-top: 13px !important"
        >
          <PartTypeIcon
            :partType="document.doc_type_abbrev"
            :file_info="null"
            size="medium"
          ></PartTypeIcon>
          <span style="top: -8px; left: 4px; position: relative"
            >ID: {{ documentId }}</span
          >
        </div>
        <div
          v-else
          class="headerCell"
          style="padding-left: 60px; padding-top: 13px !important"
        >
          <span>ID: {{ documentId }}</span>
        </div>
        <div
          class="headerCell"
          style="
            padding-left: 60px;
            padding-top: 13px !important;
            cursor: pointer;
          "
          @click="openHeader"
        >
          <span style="font-size: 18px; padding-top: 6px">{{
            document.doc_name
          }}</span>
        </div>

        <div
          v-if="useTranslation"
          class="headerCell"
          style="
            padding-left: 81px;
            padding-top: 9px !important;
            cursor: pointer;
          "
          @click="translateTextDialog"
        >
          <div class="d-flex" v-if="documentLanguage.flagCode == 'gb'">
            <img
              style="margin-right: 15px"
              src="../assets/Anglosphere.png"
              width="30px"
            />
            &nbsp;{{ documentLanguage.value.toUpperCase() }}
          </div>
          <div class="d-flex" v-if="documentLanguage.flagCode !== 'gb'">
            <country-flag
              :country="documentLanguage.flagCode"
              size="normal"
              style="margin-right: 5px"
            />
            &nbsp;{{ documentLanguage.value.toUpperCase() }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="headerCellSmall" @click="openHeader">
          <div
            style="margin-top: 7px; cursor: pointer"
            v-if="hierarchyTypes.find((h) => h.ht_id === 3)"
          >
            <span>{{ hierarchyTypes.find((h) => h.ht_id === 3).label }}:</span>
            <span
              v-if="document.hierarchies.find((h) => h.ht_id === 3)"
              style="padding-left: 5px"
              >{{
                document.hierarchies.find((h) => h.ht_id === 3).hierarchy1
              }}</span
            >
          </div>
          <div v-if="hierarchyTypes.find((h) => h.ht_id === 1)">
            <span>{{ hierarchyTypes.find((h) => h.ht_id === 1).label }}:</span>
            <span
              v-if="document.hierarchies.find((h) => h.ht_id === 1)"
              style="padding-left: 5px"
            >
              {{
                this.$loginState.user.client !== "Baker Hughes"
                  ? ""
                  : document.hierarchies.find((h) => h.ht_id === 1).hierarchy2 +
                    " / "
              }}
              {{ document.hierarchies.find((h) => h.ht_id === 1).hierarchy1 }}
            </span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="headerCell" style="padding-left: 60px; cursor: pointer">
          <v-chip
            dense
            class="mx-2 status-chip"
            :color="docStatus.colour"
            label
            outlined
            @click="openHeader"
            >{{ document.doc_status_text }}</v-chip
          >
        </div>
        <div
          class="headerCell"
          style="
            padding-left: 60px;
            cursor: pointer;
            title: 'Show Header changes';
          "
        >
          <v-icon small @click="showheaderHistory">mdi-history</v-icon>
        </div>
        <!-- <div class="headerCell" style="padding-left: 60px; cursor: pointer">
          <v-chip
            dense
            class="mx-2 status-chip"
            label
            outlined
            @click="openCheckStatus"
            >Checks</v-chip
          >
        </div> -->
      </v-app-bar>
    </v-card>
    <input type="text" id="htmlexport" style="height: 5px; width: 5px" />
    <v-row
      style="max-height: 60px; margin-top: -18px; color: rgba(0, 0, 0, 0.6)"
    >
      <v-col cols="2"></v-col>
      <v-col cols="9">
        <v-row></v-row>
      </v-col>
    </v-row>
    <v-row
      style="max-height: 60px; margin-top: -18px; color: rgba(0, 0, 0, 0.6)"
    >
      <v-col cols="2"></v-col>
      <v-col cols="9">
        <v-row></v-row>
      </v-col>
    </v-row>
    <v-row dense id="doccontainer">
      <v-col cols="9">
        <v-card
          class="no-print-border printFullWidth"
          style="
            max-width: 70%;
            max-width: 90%;
            border-radius: 12px;
            margin-top: 12px;
            margin-left: 15%;
          "
        >
          <v-card-text class="pa-1">
            <v-row
              dense
              v-for="pt in document.parttypes"
              :key="pt.type + pt.tmpl_part_id"
            >
              <v-col>
                <v-row dense class="py-0">
                  <v-col
                    cols="10"
                    :class="[
                      pt.type.split(' ').join('') + 'Gutter',
                      'no-print',
                    ]"
                    style="
                      display: flex;
                      height: 0;
                      padding: 0;
                      left: -120px;
                      position: relative;
                    "
                    ><v-icon
                      style="margin-left: -19px"
                      small
                      :title="'View deleted ' + pt.type + ' rows'"
                      v-if="pt.cardinality.maxError > 1 && pt.hasDeletedParts"
                      @click="showDeletedPartHistory(pt)"
                      >mdi-history</v-icon
                    >
                    <span style=":right ">{{ pt.type }}</span>
                  </v-col>
                </v-row>
                <div v-if="pt.tmpl_part_metadata.moveable && isEditable">
                  <draggable
                    :list="pt.parts"
                    handle=".dragHandle"
                    class="list-group"
                    ghost-class="ghost"
                    @change="(e) => changeOrder(e, pt)"
                    @start="dragging = true"
                    @end="dragging = false"
                  >
                    <v-row
                      :id="'dp' + p.doc_part_id"
                      dense
                      :class="pt.type.split(' ').join('') + 'Row'"
                      v-for="(p, ip) in pt.parts"
                      :key="'dp' + pt.type + ip"
                    >
                      <v-col cols="1" class="text-center drag-handle">
                        <v-icon class="dragHandle">mdi-drag</v-icon>
                      </v-col>
                      <v-col
                        :cols="
                          pt.tmpl_part_metadata.uses_essential_flag ? 9 : 10
                        "
                      >
                        <v-row dense>
                          <v-col cols="1" style="max-width: 90px">
                            <span
                              @click="addPart(pt)"
                              v-if="
                                ip === pt.parts.length - 1 &&
                                (docStatus.allow_errors ||
                                  !pt.cardinality.maxError ||
                                  pt.cardinality.maxError > pt.parts.length)
                              "
                            >
                              <v-icon class="addIcon">mdi-playlist-plus</v-icon>
                            </span>
                          </v-col>
                          <v-col
                            :class="{
                              editAreaListBlank:
                                document.state.canEditDocParts && !p.text,
                              editAreaList:
                                document.state.canEditDocParts && p.text,
                              parentList: pt.childPartTypes.length,
                              editAreaListReadOnly:
                                !document.state.canEditDocParts,
                              desirableText:
                                !p.is_essential &&
                                pt.type === 'Requirement' &&
                                p.text,
                              highlightSection: p.highlightSection,
                            }"
                            :style="{
                              'list-style-image':
                                !p.is_essential &&
                                pt.type === 'Requirement' &&
                                p.text
                                  ? 'url(' +
                                    require('@/assets/static/red-x.png') +
                                    ')'
                                  : '',
                            }"
                          >
                            <div style="width: 100%">
                              <div
                                style="width: 100%"
                                @mouseenter="partHasHover(p)"
                                @mouseleave="partLostHover(p)"
                              >
                                <div style="float: right">
                                  <v-icon
                                    color="grey darken-1"
                                    small
                                    v-show="p.hover && pt.parts.length > 1"
                                    @click="deleteRow(p, pt, null)"
                                    :title="'Delete ' + pt.type"
                                    >mdi-delete</v-icon
                                  >
                                  <v-icon
                                    v-if="partsHistory.length"
                                    color="grey darken-1"
                                    small
                                    v-show="p.hover"
                                    @click="showPartHistory(p, pt)"
                                    :title="'View ' + pt.type + ' History'"
                                    >mdi-history</v-icon
                                  >
                                </div>
                                <div
                                  v-html="
                                    p.text
                                      ? applyReadOnlyHighlights(
                                          p.text,
                                          pt.tmpl_part_metadata
                                        )
                                      : pt.tmpl_part_metadata.placeholder
                                  "
                                  @click="editText(pt, p, true)"
                                ></div>
                              </div>
                              <v-icon
                                style="float: right; margin-right: 15px"
                                v-if="pt.childPartTypes.length && p.expanded"
                                small
                                @click="p.expanded = !p.expanded"
                                >mdi-chevron-triple-up</v-icon
                              >

                              <v-badge
                                style="
                                  float: right;
                                  margin-right: 15px;
                                  font-weight: 500 !important;
                                "
                                v-if="
                                  pt.childPartTypes.length &&
                                  !p.expanded &&
                                  p.childParts.length
                                "
                                color="grey lighten-1"
                                :content="p.childParts.length"
                              >
                                <v-icon
                                  style="float: right; padding: 2px"
                                  title="Show sub-parts"
                                  small
                                  @click="p.expanded = !p.expanded"
                                  >mdi-chevron-triple-down</v-icon
                                >
                              </v-badge>
                              <v-icon
                                v-if="
                                  pt.childPartTypes.length &&
                                  !p.expanded &&
                                  !p.childParts.length
                                "
                                style="
                                  float: right;
                                  margin-right: 15px;
                                  padding: 2px;
                                "
                                title="Show sub-parts"
                                small
                                @click="p.expanded = !p.expanded"
                                >mdi-chevron-triple-down</v-icon
                              >
                              <div dense v-if="p.expanded">
                                <v-row
                                  class="subPartRow"
                                  v-for="(cpt, cpti) in pt.childPartTypes"
                                  :key="'p' + p.doc_part_id + '_' + cpti"
                                >
                                  <!-- <v-col cols="1"></v-col> -->
                                  <v-col cols="1">
                                    <span class="subPartGutter"
                                      >- {{ cpt.type }}</span
                                    >
                                    <span
                                      @click="addPart(cpt, p)"
                                      class="subPartSpan"
                                    >
                                      <v-icon small>mdi-playlist-plus</v-icon>
                                    </span>
                                  </v-col>
                                  <v-col cols="10" class="subPartText">
                                    <ul>
                                      <li
                                        v-for="(cp, cpi) in p.childParts.filter(
                                          (x) =>
                                            x.tmpl_part_id === cpt.tmpl_part_id
                                        )"
                                        :key="
                                          'p' +
                                          p.doc_part_id +
                                          '_tpi' +
                                          cpt.tmpl_part_id +
                                          '_' +
                                          cpi
                                        "
                                        @click="editText(cpt, cp, true, p)"
                                        :class="{
                                          highlightSection: cp.highlightSection,
                                        }"
                                        :id="'dp' + cp.doc_part_id"
                                      >
                                        <span
                                          v-html="
                                            cp.text
                                              ? applyReadOnlyHighlights(
                                                  cp.text,
                                                  cpt.tmpl_part_metadata
                                                )
                                              : cpt.tmpl_part_metadata
                                                  .placeholder
                                          "
                                        ></span>
                                        <!-- <div
                                          style="width: 100%"
                                          @mouseenter="partHasHover(cp)"
                                          @mouseleave="partLostHover(cp)"
                                        >
                                          <div style="float: right">
                                            <v-icon
                                              color="grey darken-1"
                                              small
                                              v-show="cp.hover"
                                              @click="deleteRow(cp, cpt, p)"
                                              :title="'Delete ' + pt.type"
                                              >mdi-delete</v-icon
                                            >
                                          </div>
                                          <span
                                            v-html="
                                              cp.text
                                                ? applyReadOnlyHighlights(
                                                    cp.text,
                                                    cpt.tmpl_part_metadata
                                                  )
                                                : cpt.tmpl_part_metadata
                                                    .placeholder
                                            "
                                          ></span>
                                        </div> -->
                                      </li>
                                    </ul>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                            <div style="height: 0">
                              <span v-if="p.notes" @click="editText(pt, p)">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      :class="
                                        pt.tmpl_part_metadata
                                          .uses_essential_flag
                                          ? 'notesIcon3'
                                          : 'notesIcon2'
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-chat</v-icon
                                    >
                                  </template>
                                  <div
                                    style="max-width: 500px"
                                    v-html="p.notes"
                                  ></div>
                                </v-tooltip>
                              </span>
                            </div>
                            <span color="red">{{ p.rowMessage }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        v-if="pt.tmpl_part_metadata.uses_essential_flag"
                        cols="1"
                        class="essential"
                      >
                        <v-checkbox
                          v-if="isEditable"
                          dense
                          v-model="p.is_essential"
                          label="Essential?"
                          @mouseup="saveEssential(p, pt)"
                          :class="{
                            desirableBox:
                              !p.is_essential &&
                              pt.type === 'Requirement' &&
                              p.text,
                          }"
                          color="rgba(56, 106, 163, 0.5)"
                        ></v-checkbox>
                        <span
                          v-else
                          class="float-right"
                          style="
                            font-style: italic;
                            color: rgba(56, 106, 163, 0.801);
                          "
                          >{{
                            p.is_essential ? "Essential" : "Non Essential"
                          }}</span
                        >
                      </v-col>
                      <!-- <v-col
                        v-if="document.state.canEditDocParts"
                        cols="1"
                        :class="[
                          pt.type.split(' ').join('') + 'Error',
                          'errorComment',
                          'no-print',
                        ]"
                      >
                        <span style="color: #80808094">{{ p.WordCount }}</span>
                        <span
                          :class="p.Status.trim() !== 'OK' ? 'textError' : ''"
                          :style="{
                            color:
                              p.Status.trim() !== 'OK' ? 'red' : '#00800094',
                          }"
                          >{{ p.Status !== "OK" ? " - " + p.Status : "" }}</span
                        > -->
                      <!-- <div
                          v-if="
                            ip === pt.parts.length - 1 &&
                            pt.parts.length >= pt.cardinality.maxWarn
                          "
                        >
                          <span style="color: #bf8207">{{
                            pt.cardinality.maxWarn +
                            " recommended, " +
                            pt.cardinality.maxError +
                            " max"
                          }}</span>
                        </div>
                      </v-col> -->
                      <div style="padding-bottom: 10px"></div>
                    </v-row>
                  </draggable>
                </div>
                <div v-else>
                  <div
                    :id="'dp' + p.doc_part_id"
                    dense
                    v-for="(p, ip) in pt.parts"
                    :key="document.system_number + 'ip' + ip"
                  >
                    <v-row
                      dense
                      v-for="(sp, isp) in p.subParts"
                      :key="document.system_number + 'ip' + ip + '_' + isp"
                      :class="sp.type === 'listitem' ? 'ListItemRow' : ''"
                    >
                      <v-col cols="1" class="text-center number-part">
                        <span>&nbsp;</span>
                        <div
                          style="height: 0"
                          v-if="isp === 0 && document.state.canEditDocParts"
                        >
                          <span
                            @click="rFlex(pt, document.doc_id.toString())"
                            v-if="
                              pt.type === 'Working Pattern' &&
                              isp === 0 &&
                              isEditable
                            "
                          >
                            <v-icon style="margin-left: 10px; cursor: pointer"
                              >mdi-electron-framework</v-icon
                            >
                          </span>
                          <span
                            v-if="pt.tmpl_part_metadata.tp_locked"
                            title="Content Locked"
                          >
                            <v-icon
                              color="grey lighten-2"
                              x-small
                              style="margin-left: 50px"
                              >mdi-lock</v-icon
                            >
                          </span>
                        </div>
                      </v-col>
                      <v-col
                        v-if="sp.type === 'text'"
                        class="printFullWidth"
                        :cols="
                          pt.tmpl_part_metadata.uses_essential_flag ? 9 : 10
                        "
                      >
                        <v-row
                          dense
                          style="margin-right: -75px"
                          :class="pt.type.split(' ').join('') + 'Row'"
                        >
                          <v-col
                            :class="[
                              document.state.canEditDocParts
                                ? sp.text
                                  ? 'editArea'
                                  : 'editAreaBlank'
                                : 'editAreaReadOnly',
                              p.highlightSection ? 'highlightSection' : '',
                            ]"
                          >
                            <div style="width: 100%">
                              <div
                                style="width: 100%"
                                @mouseenter="partHasHover(p)"
                                @mouseleave="partLostHover(p)"
                              >
                                <div
                                  style="float: right"
                                  v-if="partsHistory.length"
                                >
                                  <v-icon
                                    color="grey darken-1"
                                    small
                                    v-show="p.hover"
                                    @click="showPartHistory(p, pt)"
                                    :title="'View ' + pt.type + ' History'"
                                    >mdi-history</v-icon
                                  >
                                </div>
                                <div
                                  @click="editText(pt, p)"
                                  style="min-height: 25px"
                                  :class="pt.type.split(' ').join('')"
                                  v-html="
                                    sp.text
                                      ? applyReadOnlyHighlights(
                                          sp.text,
                                          pt.tmpl_part_metadata
                                        )
                                      : pt.tmpl_part_metadata.placeholder
                                  "
                                ></div>
                              </div>
                              <v-badge
                                style="float: right; margin-right: 15px"
                                v-if="
                                  pt.childPartTypes.length &&
                                  !p.expanded &&
                                  p.childParts.length
                                "
                                color="grey lighten-1"
                                :content="p.childParts.length"
                              >
                                <v-icon
                                  style="float: right"
                                  title="Show sub-parts"
                                  small
                                  @click="p.expanded = !p.expanded"
                                  >mdi-chevron-triple-down</v-icon
                                >
                              </v-badge>
                              <v-icon
                                v-if="
                                  pt.childPartTypes.length &&
                                  !p.expanded &&
                                  !p.childParts.length
                                "
                                style="
                                  float: right;
                                  margin-right: 15px;
                                  padding: 2px;
                                "
                                title="Show sub-parts"
                                small
                                @click="p.expanded = !p.expanded"
                                >mdi-chevron-triple-down</v-icon
                              >
                              <v-icon
                                style="
                                  float: right;
                                  margin-right: 15px;
                                  padding: 2px;
                                "
                                v-if="pt.childPartTypes.length && p.expanded"
                                small
                                @click="p.expanded = !p.expanded"
                                >mdi-chevron-triple-up</v-icon
                              >
                              <div dense v-if="p.expanded" class="allSubParts">
                                <v-row
                                  class="subPartRow"
                                  v-for="(cpt, cpti) in pt.childPartTypes"
                                  :key="'p' + p.doc_part_id + '_' + cpti"
                                >
                                  <!-- <v-col cols="1"></v-col> -->
                                  <v-col cols="1">
                                    <span class="subPartGutter"
                                      >- {{ cpt.type }}</span
                                    >
                                    <span
                                      v-if="isEditable"
                                      @click="addPart(cpt, p)"
                                      class="subPartSpan"
                                    >
                                      <v-icon small>mdi-playlist-plus</v-icon>
                                    </span>
                                  </v-col>
                                  <v-col cols="10" class="subPartText">
                                    <ul>
                                      <li
                                        v-for="(cp, cpi) in p.childParts.filter(
                                          (x) =>
                                            x.tmpl_part_id === cpt.tmpl_part_id
                                        )"
                                        :key="
                                          'p' +
                                          p.doc_part_id +
                                          '_tpi' +
                                          cpt.tmpl_part_id +
                                          '_' +
                                          cpi
                                        "
                                        @click="editText(cpt, cp, true, p)"
                                        :class="{
                                          highlightSection: cp.highlightSection,
                                        }"
                                        :id="'dp' + cp.doc_part_id"
                                      >
                                        <span
                                          v-html="
                                            cp.text
                                              ? applyReadOnlyHighlights(
                                                  cp.text,
                                                  cpt.tmpl_part_metadata
                                                )
                                              : cpt.tmpl_part_metadata
                                                  .placeholder
                                          "
                                        ></span>
                                      </li>
                                    </ul>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                            <div
                              v-if="document.state.canEditDocParts"
                              style="height: 0"
                            >
                              <span
                                v-if="p.notes && isp === 0"
                                @click="editText(pt, p)"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      class="notesIcon"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-chat</v-icon
                                    >
                                  </template>
                                  <div
                                    style="max-width: 500px"
                                    v-html="p.notes"
                                  ></div>
                                </v-tooltip>
                              </span>
                            </div>
                            <span color="red">{{ p.rowMessage }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        v-else
                        style="margin-left: 72px; max-width: 827px"
                        :cols="
                          pt.tmpl_part_metadata.uses_essential_flag ? 9 : 10
                        "
                      >
                        <v-row dense>
                          <v-col
                            :class="
                              document.state.canEditDocParts
                                ? 'editAreaList'
                                : 'editAreaListReadOnly'
                            "
                            @click="editText(pt, p)"
                          >
                            <div style="width: 100%">
                              <span
                                v-html="
                                  applyReadOnlyHighlights(
                                    sp.text,
                                    pt.tmpl_part_metadata
                                  )
                                "
                              ></span>
                              <v-icon
                                style="float: right; margin-right: 15px"
                                v-if="pt.childPartTypes.length && p.expanded"
                                small
                                @click="p.expanded = !p.expanded"
                                >mdi-chevron-triple-up</v-icon
                              >
                              <v-badge
                                style="float: right; margin-right: 15px"
                                v-if="
                                  pt.childPartTypes.length &&
                                  !p.expanded &&
                                  p.childParts.length
                                "
                                color="grey"
                                :content="p.childParts.length"
                              >
                                <v-icon
                                  style="float: right"
                                  title="Show sub-parts"
                                  small
                                  @click="p.expanded = !p.expanded"
                                  >mdi-chevron-triple-down</v-icon
                                >
                              </v-badge>
                              <v-icon
                                v-if="
                                  pt.childPartTypes.length &&
                                  !p.expanded &&
                                  !p.childParts.length
                                "
                                style="float: right; margin-right: 15px"
                                title="Show sub-parts"
                                small
                                @click="p.expanded = !p.expanded"
                                >mdi-chevron-triple-down</v-icon
                              >
                              <div dense v-if="p.expanded">
                                <v-row
                                  v-for="(cpt, cpti) in pt.childPartTypes"
                                  :key="'p' + p.doc_part_id + '_' + cpti"
                                >
                                  <v-col cols="2">
                                    <span class="GutterLabel"
                                      >{{ cpt.type }}s</span
                                    >
                                    <span
                                      v-if="isEditable"
                                      @click="addPart(cpt, p)"
                                    >
                                      <v-icon small>mdi-playlist-plus</v-icon>
                                    </span>
                                  </v-col>
                                  <v-col cols="10">
                                    <ul>
                                      <li
                                        v-for="(cp, cpi) in p.childParts.filter(
                                          (x) =>
                                            x.tmpl_part_id === cpt.tmpl_part_id
                                        )"
                                        :key="
                                          'p' +
                                          p.doc_part_id +
                                          '_tpi' +
                                          cpt.tmpl_part_id +
                                          '_' +
                                          cpi
                                        "
                                        @click="editText(cpt, cp, true, p)"
                                      >
                                        <span
                                          v-html="
                                            cp.text
                                              ? applyReadOnlyHighlights(
                                                  cp.text,
                                                  cpt.tmpl_part_metadata
                                                )
                                              : cpt.tmpl_part_metadata
                                                  .placeholder
                                          "
                                        ></span>
                                      </li>
                                    </ul>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                            <div
                              v-if="document.state.canEditDocParts"
                              style="height: 0"
                            >
                              <span
                                class="no-print notes"
                                v-if="p.notes && isp === 0"
                                @click="editText(pt, p)"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      :class="
                                        pt.tmpl_part_metadata
                                          .uses_essential_flag
                                          ? 'notesIcon3'
                                          : 'notesIcon2'
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-chat</v-icon
                                    >
                                  </template>
                                  <div
                                    style="max-width: 500px"
                                    v-html="p.notes"
                                  ></div>
                                </v-tooltip>
                              </span>
                            </div>
                            <span color="red">{{ p.rowMessage }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="1"
                        v-if="pt.tmpl_part_metadata.uses_essential_flag"
                        class="essential"
                      >
                        <v-checkbox
                          v-if="isEditable"
                          dense
                          v-model="p.is_essential"
                          label="Essential?"
                          @mouseup="saveEssential(p, pt)"
                          color="rgba(56, 106, 163, 0.801)"
                        ></v-checkbox>
                        <span
                          v-else
                          class="float-right"
                          style="
                            font-style: italic;
                            font-size: 12px;
                            color: rgba(56, 106, 163, 0.801);
                          "
                          >{{
                            p.is_essential ? "Essential" : "Non Essential"
                          }}</span
                        >
                      </v-col>
                      <!-- <v-col
                        v-if="document.state.canEditDocParts"
                        cols="1"
                        :class="[
                          pt.type.split(' ').join('') + 'Error',
                          sp.type === 'text' ? 'errorComment' : 'errorComment2',
                          'no-print',
                        ]"
                      >
                        <span v-if="isp === 0" style="color: #80808094">{{
                          p.WordCount
                        }}</span>
                        <span
                          :class="p.Status.trim() !== 'OK' ? 'textError' : ''"
                          :style="{
                            color:
                              p.Status.trim() !== 'OK' ? 'red' : '#00800094',
                          }"
                          v-if="isp === 0"
                          >{{ p.Status !== "OK" ? " - " + p.Status : "" }}</span
                        >
                      </v-col> -->
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="3"
        v-if="docStatus.allow_edit || document.state.canEditDocParts"
      >
        <div style="top: 60px; right: 30px; max-width: 24%; position: fixed">
          <v-card
            class="scoreCard"
            v-if="
              (checkSummary && checkSummary.categories.length) &&
              !history &&
              !ptDeleteHistory &&
              !headerHistoryShow &&
              documentIssueDisplay
            "
          >
            <v-card-title class="scoreTitle">
              <v-progress-circular
                v-if="documentIssueDisplay === 'score'"
                :rotate="90"
                :size="45"
                :width="10"
                :value="checkSummary.score"
                :color="checkSummary.scoreColour"
                class="mr-1 mb-1 mt-1"
                ><span style="font-size: 13px; color: gray">
                  {{ checkSummary.score.toFixed(0) }}</span
                >
              </v-progress-circular>
              <div class="inclusionHeading">Document Inclusion & Quality</div>
              <v-spacer></v-spacer
              ><v-btn
                icon
                dense
                @click="allCheckSummaryPanels"
                v-if="
                  checkSummaryPanels.length < checkSummary.categories.length
                "
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
              <v-btn
                icon
                dense
                @click="closeCheckSummaryPanels"
                v-if="
                  checkSummaryPanels.length === checkSummary.categories.length
                "
              >
                <v-icon>mdi-chevron-double-up</v-icon>
              </v-btn></v-card-title
            >
            <v-card-text dense style="padding: 0">
              <v-expansion-panels
                accordion
                multiple
                v-model="checkSummaryPanels"
              >
                <v-expansion-panel
                  class="sectionScore"
                  v-for="(i, ii) in checkSummary.categories"
                  :key="'i' + ii"
                >
                  <v-expansion-panel-header
                    :disable-icon-rotate="i.score == '100'"
                  >
                    <span class="sectionText"
                      >{{ i.category.trim() + " " }}
                      <v-badge
                        v-if="documentIssueDisplay === 'basic' && i.errorCount"
                        :content="i.errorCount"
                        color="red"
                        inline
                        overlap
                      >
                      </v-badge
                      ><v-badge
                        v-if="
                          documentIssueDisplay === 'basic' && i.warningCount
                        "
                        :content="i.warningCount"
                        color="amber"
                        inline
                        overlap
                      >
                      </v-badge>
                      <v-progress-circular
                        v-if="documentIssueDisplay === 'score'"
                        :rotate="90"
                        :size="30"
                        :width="7"
                        :value="i.score"
                        :color="i.scoreColour"
                        class="float-right"
                        ><span style="font-size: 9px; color: gray">
                          {{ i.score }}</span
                        >
                      </v-progress-circular> </span
                    ><template v-if="i.score == '100'" v-slot:actions>
                      <v-icon> mdi-check </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="checkSummaryView === 1">
                    <v-row
                      dense
                      v-for="(c, ci) in i.titles"
                      :key="'i' + ii + 'ci' + ci"
                    >
                      <v-col>
                        <v-row dense>
                          <v-col cols="1"></v-col>
                          <v-col cols="11"
                            ><span style="font-size: 13px">{{
                              c.title
                            }}</span></v-col
                          >
                          <!-- <v-col cols="9">
						</v-col> -->
                        </v-row>
                        <v-row
                          dense
                          v-for="(e, ei) in checkSummary.errors.filter(
                            (x) =>
                              x.category === i.category && x.title === c.title
                          )"
                          :key="'e_' + ii + '_' + ei"
                        >
                          <v-col cols="1"></v-col>
                          <v-col
                            cols="11"
                            @mouseenter="highlightSection(e)"
                            @mouseleave="unhighlightSection"
                            @click="scrollToSection(e)"
                            class="sectionItem"
                          >
                            <v-badge
                              color="red"
                              :content="e.count"
                              inline
                              overlap
                              style="color: slategray; font-size: 12px"
                            >
                              <div
                                :style="{
                                  'min-width': '22px',
                                  height:
                                    e.effect === 'highlight' ? '22px' : '',
                                  'margin-right': '10px',
                                  'background-color':
                                    e.effect === 'highlight'
                                      ? e.colour
                                      : 'transparent',
                                  'border-bottom':
                                    e.effect.indexOf('dotted') >= 0
                                      ? 'dotted 3px ' + e.colour
                                      : e.effect.indexOf('solid') >= 0
                                      ? 'solid 3px ' + e.colour
                                      : '',
                                }"
                              ></div>
                              <div v-html="e.description"></div>
                            </v-badge>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          v-for="(w, wi) in checkSummary.warnings.filter(
                            (x) =>
                              x.category === i.category && x.title === c.title
                          )"
                          :key="'w_' + ii + '_' + wi"
                        >
                          <v-col cols="1"></v-col>
                          <v-col
                            cols="11"
                            @mouseenter="highlightSection(w)"
                            @mouseleave="unhighlightSection"
                            @click="scrollToSection(w)"
                            class="sectionItem"
                          >
                            <v-badge
                              color="amber"
                              :content="w.count"
                              inline
                              overlap
                              style="color: slategray; font-size: 12px"
                            >
                              <div
                                :style="{
                                  'min-width': '22px',
                                  height:
                                    w.effect === 'highlight' ? '22px' : '',
                                  'margin-right': '10px',
                                  'background-color':
                                    w.effect === 'highlight'
                                      ? w.colour
                                      : 'transparent',
                                  'border-bottom':
                                    w.effect.indexOf('dotted') >= 0
                                      ? 'dotted 3px ' + w.colour
                                      : w.effect.indexOf('solid') >= 0
                                      ? 'solid 3px ' + w.colour
                                      : '',
                                }"
                              ></div>
                              <div v-html="w.description"></div>
                            </v-badge>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                  <v-expansion-panel-content
                    v-if="checkSummaryView === 2"
                    style="margin-top: -12px"
                  >
                    <v-row
                      dense
                      v-for="(t, ti) in i.titles"
                      :key="'i' + ii + 'ti' + ti"
                    >
                      <v-col cols="1">
                        <div
                          :style="{
                            'margin-top': '3px',
                            height:
                              t.effect === 'highlight' || t.effect === 'border'
                                ? '18px'
                                : '12px',
                            'background-color':
                              t.effect === 'highlight'
                                ? t.colour
                                : 'transparent',
                            'border-bottom':
                              t.effect.indexOf('dotted') >= 0
                                ? 'dotted 3px ' + t.colour
                                : t.effect.indexOf('solid') >= 0
                                ? 'solid 3px ' + t.colour
                                : t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-top':
                              t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-left':
                              t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-right':
                              t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-radius':
                              t.effect === 'border' ? '4px ' : '',
                          }"
                        >
                          <v-icon v-if="t.effect.substr(0, 5) === 'icon-'">{{
                            t.effect.substr(5)
                          }}</v-icon>
                          <!-- 'border': t.effect === 'border' ? '1px solid ' + t.colour : 'inherit' -->
                        </div>
                      </v-col>
                      <v-col cols="11">
                        <span style="font-size: 13px; margin-right: 10px">{{
                          t.title
                        }}</span>
                        <span
                          v-for="(d, di) in t.issues"
                          :key="'i' + ii + 'ti' + ti + 'di' + di"
                        >
                          <v-tooltip bottom v-if="d.errorCount > 0">
                            <template v-slot:activator="{ on, attrs }"
                              ><v-btn
                                x-small
                                text
                                v-bind="attrs"
                                v-on="on"
                                @mouseenter="highlightSection(d, true)"
                                @mouseleave="unhighlightSection"
                                @click="scrollToSection(d)"
                                class="sectionItem"
                              >
                                <v-icon color="red" style="font-size: 18px"
                                  >mdi-alert</v-icon
                                >
                                <span
                                  v-if="d.errorCount > 1"
                                  style="
                                    margin-top: -12px;
                                    margin-left: -5px;
                                    font-size: 9px;
                                    text-transform: none;
                                  "
                                  >x{{ d.errorCount }}</span
                                ></v-btn
                              >
                            </template>
                            <div style="max-width: 300px">
                              <span v-html="d.description"></span>
                            </div>
                          </v-tooltip>
                          <v-tooltip bottom v-if="d.warningCount > 0">
                            <template v-slot:activator="{ on, attrs }"
                              ><v-btn
                                x-small
                                text
                                v-bind="attrs"
                                v-on="on"
                                @mouseenter="highlightSection(d)"
                                @mouseleave="unhighlightSection"
                                @click="scrollToSection(d)"
                                class="sectionItem"
                              >
                                <v-icon color="grey" style="font-size: 18px"
                                  >mdi-alert</v-icon
                                >
                                <span
                                  v-if="d.warningCount > 1"
                                  style="
                                    margin-top: -12px;
                                    margin-left: -5px;
                                    font-size: 9px;
                                    text-transform: none;
                                  "
                                  >x{{ d.warningCount }}</span
                                ></v-btn
                              >
                            </template>
                            <div style="max-width: 300px">
                              <span v-html="d.description"></span>
                            </div>
                          </v-tooltip>
                        </span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                  <v-expansion-panel-content
                    v-if="checkSummaryView === 3"
                    style="margin-top: -12px"
                  >
                    <v-row
                      dense
                      v-for="(t, ti) in i.titles"
                      :key="'i' + ii + 'ti' + ti"
                    >
                      <v-col cols="1">
                        <div
                          :style="{
                            'margin-top': '3px',
                            height: t.effect === 'highlight' ? '18px' : '12px',
                            'background-color':
                              t.effect === 'highlight'
                                ? t.colour
                                : 'transparent',
                            'border-bottom':
                              t.effect.indexOf('dotted') >= 0
                                ? 'dotted 3px ' + t.colour
                                : t.effect.indexOf('solid') >= 0
                                ? 'solid 3px ' + t.colour
                                : '',
                          }"
                        ></div>
                      </v-col>
                      <v-col cols="8">
                        <span style="font-size: 13px; margin-right: 10px">{{
                          t.title
                        }}</span>
                      </v-col>
                      <v-col cols="3">
                        <div
                          style="
                            width: 100%;
                            border: solid #f0e8e8 1px;
                            height: 19px;
                          "
                        >
                          <div
                            v-for="n in t.errorCount"
                            :key="'i' + ii + 'ti' + ti + 'n' + n"
                            :style="{
                              width: 100 / checkSummary.maxIssues - 2 + '%',
                              'background-color': 'red',
                              margin: '1%',
                              float: 'left',
                              height: '15px',
                            }"
                          ></div>
                          <div
                            v-for="n in t.warningCount"
                            :key="'i' + ii + 'ti' + ti + 'n' + n"
                            :style="{
                              width: 100 / checkSummary.maxIssues - 2 + '%',
                              'background-color': 'orange',
                              margin: '1%',
                              float: 'left',
                              height: '15px',
                            }"
                          ></div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
          <v-card v-if="history" max-height="800" class="overflow-y-auto">
            <v-card-title
              >{{ history.name }} History <v-spacer></v-spacer>
              <v-btn
                small
                @click="
                  history = null;
                  unhighlightSection();
                "
                ><v-icon>mdi-close-thick</v-icon></v-btn
              ></v-card-title
            >
            <v-card-text>
              <v-timeline dense style="max-height: 100%">
                <v-timeline-item
                  small
                  v-for="(h, hi) in history.items"
                  :key="'h' + hi"
                >
                  <v-row dense>
                    <v-col>
                      <h5>
                        {{ h.version_desc }}
                        <v-btn
                          class="float-right"
                          v-if="h.canRevert && isEditable"
                          x-small
                          @click="revertChange(h)"
                          >Revert</v-btn
                        >
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-for="(c, ci) in h.changes"
                    :key="'h' + hi + 'c' + ci"
                  >
                    <v-col cols="3">
                      <strong class="histText">{{ c.title }}</strong>
                    </v-col>
                    <v-col>
                      <span class="histText" v-html="c.desc"></span>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
          <v-card
            v-if="ptDeleteHistory"
            max-height="800"
            class="overflow-y-auto"
          >
            <v-card-title v-if="ptDeleteHistory.length"
              >{{ ptDeleteHistory[0].name }} Delete History
              <v-spacer></v-spacer>
              <v-btn
                small
                @click="
                  ptDeleteHistory = null;
                  unhighlightSection();
                "
                ><v-icon>mdi-close-thick</v-icon></v-btn
              >
            </v-card-title>
            <v-card-title v-else
              >No Deleted {{ ptDeleteHistoryPT.type }} Rows
              <v-spacer></v-spacer>
              <v-btn
                small
                @click="
                  ptDeleteHistory = null;
                  unhighlightSection();
                "
                ><v-icon>mdi-close-thick</v-icon></v-btn
              >
            </v-card-title>
            <v-card-text v-if="ptDeleteHistory.length">
              <v-timeline dense>
                <v-timeline-item
                  small
                  v-for="(dh, hi) in ptDeleteHistory"
                  :key="'dh' + hi"
                >
                  <v-row dense>
                    <v-col>
                      <h5>
                        {{ dh.items[0].version_desc }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="10">
                      <span class="histText" v-html="dh.items[0].text"></span>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        class="float-right"
                        v-if="
                          dh.items[0].canRevert &&
                          isEditable &&
                          (docStatus.allow_errors ||
                            ptDeleteHistoryPT.parts.length <
                              ptDeleteHistoryPT.cardinality.maxError)
                        "
                        x-small
                        @click="revertDelete(dh)"
                        >Undo</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
          <v-card
            v-if="headerHistoryShow"
            max-height="800"
            class="overflow-y-auto"
          >
            <v-card-title
              >Header History
              <v-spacer></v-spacer>
              <v-btn
                small
                @click="
                  headerHistoryShow = false;
                  unhighlightSection();
                "
                ><v-icon>mdi-close-thick</v-icon></v-btn
              >
            </v-card-title>
            <v-card-text v-if="headerHistoryShow && headerHistory.length">
              <v-timeline dense>
                <v-timeline-item
                  small
                  v-for="(hh, hi) in headerHistory"
                  :key="'hh' + hi"
                >
                  <v-row dense>
                    <v-col>
                      <h5>
                        {{ hh.version_desc }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-for="(hc, hci) in hh.changes"
                    :key="'hh' + hi + 'c' + hci"
                  >
                    <v-col cols="4">
                      <h5 v-html="hc.title"></h5>
                    </v-col>
                    <v-col cols="6">
                      <span class="histText" v-html="hc.desc"></span>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        class="float-right"
                        v-if="hh.canRevert && isEditable"
                        x-small
                        @click="revertHierarchy(hc)"
                        >Revert</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <TextEdit
      :open="textEdit.show"
      @saved="saveTextEdit"
      @closed="textEdit.show = false"
      @deleted="deleteTextEdit"
      :settings="textEdit"
    ></TextEdit>
    <RoleFlex
      :open="roleFlex.show"
      @closed="roleFlex.show = false"
      @workingPattern="newWorkingPattern"
      label="text"
      :documentId="roleFlex.documentId"
      :docPart="roleFlex.docPart"
    ></RoleFlex>
    <DocumentHeader
      :open="showHeaderDetail"
      :source="document"
      :checks="checkSummary"
      @saved="documentHeaderSaved"
      @closed="documentHeaderClosed"
    ></DocumentHeader>
    <v-dialog v-model="reqDialogue.show" max-width="500px">
      <v-card>
        <v-toolbar color="grey lighten-5" dense>
          <v-toolbar-title>Send Content To Taleo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            @click="sendDataToTaleo()"
            :disabled="!reqDialogue.value || awaitingTaleo"
            >Send</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn :disabled="awaitingTaleo" @click="reqDialogue.show = false"
            >Cancel</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="6">
              <v-combobox
                v-model="reqDialogue.value"
                :items="taleoReqData"
                label="Requisition Number"
              ></v-combobox>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="reqDialogue.USA"
                label="US Job?"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="6"
              v-if="awaitingTaleo"
              style="padding-top: 20px; padding-left: 25px"
            >
              <v-progress-circular
                :width="3"
                color="green"
                indeterminate
              ></v-progress-circular>
              Exporting to Taleo....
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="emailDialogue.show" max-width="500px">
      <v-card>
        <v-toolbar color="grey lighten-5" dense>
          <v-toolbar-title>Send Document</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn @click="sendEmail()" :disabled="!emailDialogue.value"
            >Send</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click="emailDialogue.show = false">Cancel</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Email Address"
                required
                :rules="[rules.required, rules.email]"
                v-model="emailDialogue.value"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmCopyShow" max-width="700px">
      <v-card>
        <v-card-actions>
          <div class="pa-2">Copy Confirmation</div>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmCopyShow = false" color="primary"
            >Cancel</v-btn
          >
          <v-btn text @click="doCopy()">Copy Document</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col>{{ confirmCopy }}</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CheckStatus
      :source="checkSummary"
      :open="showCheckStatus"
      @closed="closeCheckStatus"
    />
    <Loading :isVisible="isLoading" />
    <div id="pdfsection" style="max-width: 900px; padding: 50px"></div>
    <v-dialog v-model="dialogLinks" max-width="1500px" min-height="700px">
      <v-card>
        <v-card-title class="justify-center">
          <div class="justify-center">External File Link:</div>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <div align="center" class="justify-center">
                <FileUploader
                  :fileUploadData="fileUploadFormData"
                  multiple="false"
                  :uploadDisabled="fileUploadDisabled"
                  :uploadedCallback="filesUploadedCallback"
                  :useTranslation="useTranslation"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="translateDialog" max-width="1500px" min-height="700px">
      <v-card>
        <v-card-title class="justify-center">
          <div class="justify-center">Translation Options:</div>
        </v-card-title>
        <v-card-text class="justify-center">
          <v-row dense v-if="document.language_code != 'en'">
            <v-col cols="2" md="2" sm="2" dense>
              <h3>Original Document:</h3>
            </v-col>
            <v-col
              cols="7"
              md="7"
              sm="7"
              style="padding-top: 15px"
              class="justify-left"
              dense
            >
              <div
                dense
                v-for="lang in filterLanguageDocs('en')"
                :key="lang.doc_id"
              >
                <a
                  dense
                  @click="openTranslatedDoc(lang)"
                  v-if="lang.language_code !== 'en'"
                >
                  <country-flag
                    :country="getLangCodeFlag(lang.language_code)"
                    :title="getLangCodeName(lang.language_code)"
                    size="normal"
                  />
                  <span dense style="padding-left: 20px; vertical-align: top">{{
                    lang.system_number
                  }}</span>
                </a>
                <a
                  dense
                  @click="openTranslatedDoc(lang)"
                  v-if="lang.language_code == 'en'"
                >
                  <img
                    style="margin-right: 0px"
                    src="../assets/Anglosphere.png"
                    width="28px"
                    height="20px"
                  />
                  <span dense style="padding-left: 20px; vertical-align: top">{{
                    lang.system_number
                  }}</span>
                </a>
              </div>
            </v-col>
          </v-row>
          <v-row class="justify-center" dense>
            <v-col cols="7" md="7" sm="7" dense>
              <h3>
                Existing Translated Documents ({{
                  filterLanguageDocs(null).length
                }})
              </h3>
            </v-col>
            <v-col cols="5" md="5" sm="5" dense>
              <h3>Create New Translation</h3>
            </v-col>
          </v-row>
          <v-row style="padding-top: 5px" class="justify-center" dense>
            <v-col cols="7" md="7" sm="7" dense>
              <div v-for="lang in filterLanguageDocs(null)" :key="lang.doc_id">
                <a @click="openTranslatedDoc(lang)">
                  <country-flag
                    :country="getLangCodeFlag(lang.language_code)"
                    :title="getLangCodeName(lang.language_code)"
                    size="normal"
                  />
                  <span style="padding-left: 20px; vertical-align: top">{{
                    lang.system_number
                  }}</span>
                </a>
              </div>
            </v-col>
            <v-col cols="3" md="3" sm="3" dense>
              <v-select
                dense
                v-model="selectdTranslateLang"
                :items="translateOptions"
                label="Target Language"
              >
                <template slot="item" slot-scope="item">
                  <div v-if="item.item.value !== 'en'">
                    <country-flag :country="getFlagCode(item)" size="normal" />
                    <span style="padding-left: 20px; vertical-align: top">{{
                      item.item.text
                    }}</span>
                  </div>
                  <div v-if="item.item.value == 'en'">
                    <img
                      style="margin-right: 0px"
                      src="../assets/Anglosphere.png"
                      width="28px"
                      height="20px"
                    /><span style="padding-left: 26px; vertical-align: top">{{
                      item.item.text
                    }}</span>
                  </div>
                </template>
                <template v-slot:selection="item">
                  <div v-if="item.item.value !== 'en'">
                    <country-flag
                      :country="getFlagCode(item)"
                      size="normal"
                    /><span style="padding-left: 20px; vertical-align: top">{{
                      item.item.text
                    }}</span>
                  </div>
                  <div v-if="item.item.value == 'en'">
                    <img
                      style="margin-right: 0px"
                      src="../assets/Anglosphere.png"
                      width="28px"
                      height="20px"
                    /><span style="padding-left: 26px; vertical-align: top">{{
                      item.item.text
                    }}</span>
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" md="2" sm="2" dense right>
              <v-btn
                color=""
                :disabled="isTranslating"
                text
                right
                class="success"
                @click="copyAndTranslateDoc(selectdTranslateLang)"
                >Create</v-btn
              ><br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7"></v-col>
            <v-col cols="5" v-if="isTranslating">
              <v-progress-circular
                :width="3"
                color="green"
                indeterminate
              ></v-progress-circular>
              Document Creating...Please Wait
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeTranslateDialog"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import TextEdit from "@/components/cTextEditDialogue"; // @ is an alias to /src
import RoleFlex from "@/components/cRoleFlex"; // @ is an alias to /src
import DocumentHeader from "@/components/cDocumentHeader"; // @ is an alias to /src
import CheckStatus from "@/components/cDocumentCheckStatus";
import PartTypeIcon from "@/components/cPartTypeIcon"; // @ is an alias to /src
import utils from "@/common/utils.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { asBlob as html2docx } from 'html-docx-js-typescript';
import ScrollTo from "vue-scrollto";
import FileUploader from "../components/cFileUpload.vue";
import CountryFlag from "vue-country-flag";

export default {
  name: "DocumentFull",
  components: {
    ResponseHandler,
    TextEdit,
    RoleFlex,
    DocumentHeader,
    draggable,
    PartTypeIcon,
    CheckStatus,
    FileUploader,
    CountryFlag,
  },
  props: {
    item: null,
    documentId: null,
    isCopied: null,
  },
  data: function () {
    return {
      response: null,
      document: null,
      hierarchyTypes: [],
      docHierarchies: [],
      dialogLinks: false,
      isDirty: false,
      isLoading: false,
      isEditable: false,
      translateDialog: false,
      textEdit: {
        text: "",
        notes: "",
        label: "text",
        show: false,
        showWordCount: true,
        showNotes: true,
        title: "",
        rules: null,
        lookup: [],
        wordChecks: [],
        allow_errors: false,
        //closed: false
      },
      wordChecks: [],
      roleFlex: {
        documentId: "",
        show: false,
        docPart: {},
      },
      useTranslation: false,
      dragging: false,
      pdfing: false,
      showHeaderDetail: false,
      showCheckStatus: false,
      checkSummary: null,
      checkSummaryPanels: [],
      checkSummaryView: 2,
      html_export_sections: [],
      exportMenu: false,
      docTypes: [],
      linkDef: null,
      copymenu: false,
      selectdTranslateLang: {},
      reqDialogue: {
        show: false,
        value: null,
      },
      emailDialogue: {
        show: false,
        value: null,
      },
      disableChecks: false,
      usesTaleoFeed: false,
      usesInterviewTemplate: false,
      usesIntakeFlow: false,
      wayBackHidden: true,
      dpScrolled: [],
      confirmCopy: "",
      confirmCopyShow: false,
      fileUploadFormData: {},
      fileUploadDisabled: false,
      history: null,
      ptDeleteHistory: null,
      partsHistory: [],
      headerHistory: [],
      headerHistoryShow: false,
      docStatus: null,
      documentIssueDisplay: "",
      documentLanguage: { text: "English", value: "en", flagCode: "gb" },
      translateOptions: [
        { text: "Afrikaans", value: "af", flagCode: "na" },
        { text: "Albanian", value: "sq", flagCode: "al" },
        { text: "Arabic", value: "ar", flagCode: "eg" },
        { text: "Chinese (Simplified)", value: "zh", flagCode: "cn" },
        { text: "Chinese (Traditional)", value: "zh-TW", flagCode: "cn" },
        { text: "Croatian", value: "hr", flagCode: "hr" },
        { text: "Czech", value: "cs", flagCode: "cz" },
        { text: "Danish", value: "da", flagCode: "dk" },
        { text: "Dutch", value: "nl", flagCode: "nl" },
        { text: "English", value: "en", flagCode: "gb" },
        { text: "French", value: "fr", flagCode: "fr" },
        { text: "German", value: "de", flagCode: "de" },
        { text: "Greek", value: "el", flagCode: "gr" },
        { text: "Italian", value: "it", flagCode: "it" },
        { text: "Japanese", value: "ja", flagCode: "jp" },
        { text: "Korean", value: "ko", flagCode: "kr" },
        { text: "Polish", value: "pl", flagCode: "pl" },
        { text: "Portuguese", value: "pt", flagCode: "pt" },
        { text: "Romanian", value: "ro", flagCode: "ro" },
        { text: "Russian", value: "ru", flagCode: "ru" },
        { text: "Spanish", value: "es", flagCode: "es" },
        { text: "Spanish (Mexico)", value: "es-MX", flagCode: "mx" },
        { text: "Swedish", value: "sv", flagCode: "se" },
        { text: "Thai", value: "th", flagCode: "th" },
        { text: "Vietnamese", value: "vi", flagCode: "vn" },
        { text: "Welsh", value: "cy", flagCode: "gb-wls" },
      ],
      awaitingTaleo: false,
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      connectingToTaleo: false,
      isTranslating: false,
      alternateLanguageDocuments: [],
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      taleoReqData: [],
    };
  },
  created() {
    if (!this.item) {
      this.fetchData();
    } else {
      this.document = JSON.parse(JSON.stringify(this.item));
    }
  },
  updated() {
    if (this.item) {
      this.document = JSON.parse(JSON.stringify(this.item));
    }
  },
  watch: {
    documentId(newval) {
      if (newval) this.fetchData();
    },
    dialogLinks(val) {
      val || this.close();
    },
  },
  methods: {
    filterLanguageDocs(target) {
      if (target) {
        return this.alternateLanguageDocuments.filter(
          (lang) => lang.language_code === target
        );
      } else {
        return this.alternateLanguageDocuments.filter(
          (lang) => lang.language_code !== "en"
        );
      }
    },
    getLangCodeFlag(item) {
      let targetLang = this.translateOptions.find((x) => x.value == item);
      return targetLang.flagCode;
    },
    getLangCodeName(item) {
      let targetLang = this.translateOptions.find((x) => x.value == item);
      return targetLang.text;
    },
    openTranslatedDoc(item) {
      this.closeTranslateDialog();
      this.$emit("openDocument", {
        system_number: utils.removeTags(item.system_number),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
      });
    },
    copyAndTranslateDoc(item) {
      this.isTranslating = true;

      let targetLang = this.translateOptions.find((x) => x.value == item);
      this.documentLanguage = targetLang;
      let queryTargetLang = this.selectdTranslateLang
        ? this.selectdTranslateLang
        : "";

      let data = {
        doc_data: {
          source_doc_id: this.document.doc_id,
          tmpl_id: this.document.tmpl_id,
        },
        targetLang: queryTargetLang,
      };

      axios
        .post("document/copyDocAndTranslate/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.closeTranslateDialog();
            this.isTranslating = false;
            let doc = resp.data.Data.documents[0];
            this.$emit("openDocument", doc);
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.sessionExpired(err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    getFlagCode(item) {
      return item.item.flagCode;
    },

    translateTextDialog() {
      this.translateDialog = true;
    },
    closeTranslateDialog() {
      this.translateDialog = false;
    },
    translateText(item) {
      let targetLang = this.translateOptions.find((x) => x.value == item);
      this.documentLanguage = targetLang;
      this.closeTranslateDialog();
      this.fetchData(this.selectdTranslateLang);
    },
    async downloadFileTest() {
      //
      let html = `<!DOCTYPE  html><html><body>
	  <style>
		p, h2, h3, h4 { font-family: calibri; }
		.lst { font-family: calibri; font-size: 12pt; }
    </style>${this.docToHTML(null, true, true)}</body></html>`;

      let formData = {
        htmlContent: encodeURIComponent(html),
      };

      let options = {
        responseType: "blob",
        headers: { "Content-Type": "application/json" },
      };

      axios
        .post("file/downloadTest", formData, options)
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.docx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          alert(err);
        });
    },
    setupUploadData() {
      this.fileUploadFormData = {
        hr_ids: [],
        doc_id: [],
      };
      this.document.hierarchies.forEach((dh) => {
        this.fileUploadFormData.hr_ids.push(dh.hr_id);
      });
      this.fileUploadDisabled = true;

      this.$nextTick(function () {
        this.fileUploadDisabled = false;
        this.fileUploadFormData = JSON.parse(
          JSON.stringify(this.fileUploadFormData)
        );
      });
    },
    fetchData(targetLang) {
      this.isLoading = true;
      axios.get("document/getHierarchyValues").then((resp) => {
        if (resp.data.Status === "OK") {
          let dts = resp.data.Data.docTypes;
          this.statuses = resp.data.Data.statuses;
          let that = this;

          let queryTargetLang = targetLang ? targetLang : "";

          axios
            .get("document/docswithparts/" + this.documentId, {
              params: { targetLang: queryTargetLang },
            })
            .then((resp) => {
              this.history = null;
              this.ptDeleteHistory = null;
              if (resp.data.Status === "OK") {
                if (targetLang && that.selectdTranslateLang !== "en") {
                  that.documentLanguage = that.translateOptions.find(
                    (x) => x.value == that.selectdTranslateLang
                  );
                } else {
                  that.documentLanguage = that.translateOptions.find(
                    (x) => x.value == resp.data.Data.documents[0].language_code
                  );
                }

                this.hierarchyTypes = resp.data.Data.hierarchyTypes;
                this.partsHistory = resp.data.Data.documents[0].DPHistory || [];
                this.wordChecks = utils.initialisePartChecks(
                  resp.data.Data.documents[0].wordChecks
                );
                this.documentIssueDisplay = resp.data.Data.settings.find(
                  (x) => x.setting === "document_issue_display"
                );

                let translateSetting = resp.data.Data.settings.find(
                  (x) => x.setting === "UseTranslation"
                );
                if (translateSetting) {
                  this.useTranslation =
                    translateSetting.value == "true" ? true : false;
                } else {
                  this.useTranslation = false;
                }

                if (
                  this.useTranslation &&
                  resp.data.Data.documents[0].language_code !== "en"
                ) {
                  this.disableChecks = true;
                }

                if (this.documentIssueDisplay)
                  this.documentIssueDisplay = this.documentIssueDisplay.value;
                this.setupDocument(resp.data.Data.documents);
                this.setDocStatus();

                if (resp.data.Data.documents[0].alternateLanguageDocs) {
                  this.alternateLanguageDocuments = JSON.parse(
                    JSON.stringify(
                      resp.data.Data.documents[0].alternateLanguageDocs
                    )
                  );
                }

                this.linkDef = dts.find(
                  (x) => x.tmpl_id === this.document.tmpl_id
                );

                dts.forEach((dt) => {
                  dt.docs = this.document.linkedDocs.filter(
                    (ld) => ld.tmpl_id === dt.tmpl_id
                  );
                  dt.canCreate =
                    this.linkDef.linked_templates &&
                    this.linkDef.linked_templates.some(
                      (x) =>
                        x.tmpl_id === dt.tmpl_id &&
                        (x.max_links === 0 || dt.docs.length < x.max_links)
                    );
                  if (dt.tmpl_name === "Interview Template")
                    this.usesInterviewTemplate = true;
                });
                this.docTypes = dts;
                this.isLoading = false;
                this.setCheckSummary();
                if (this.isCopied) {
                  this.openHeader();
                }
              }
              this.response = resp.data;
              //console.log(resp);
            })
            .catch((err) => {
              if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                console.log(err);
                this.response = err.response
                  ? err.response.data
                  : { message: "Unexpected Error" };
              }
              this.isLoading = false;
            });
        }
      });
    },
    setDocStatus() {
      this.docStatus = this.statuses.find(
        (x) => x.status === this.document.doc_status
      );
      this.textEdit.allow_errors = this.docStatus.allow_errors;
      this.isEditable =
        this.docStatus.allow_edit && this.document.state.canEditDocParts;
    },
    setupDocument(doc) {
      let usesTaleoFeed = false;
      this.headerHistory = doc[0].DHHistory
        ? doc[0].DHHistory.headerHistory
        : [];
      this.docHierarchies = doc[0].hierarchies;
      this.document = doc.map((x) => {
        let n = JSON.parse(JSON.stringify(x));

        n.parttypes.forEach((pt) => {
          pt.cardinality = utils.getPartTypeMinMax(
            this.getWordChecksForPart(pt.tmpl_part_id),
            null,
            pt
          );
          pt.parts.forEach((p) => {
            p.rowDirty = false;
            p.rowMessage = "";
            p.expanded = false;
            p.hover = false;
            p.highlightSection = false;
            p.childParts.forEach((cp) => (cp.highlightSection = false));
            utils.setDocPartStatus(p, pt.tmpl_part_metadata, this.wordChecks);
          });
          let sections = (pt.tmpl_part_metadata.html_export_section || "")
            .split("|")
            .filter((x) => x);
          if (sections) {
            if (
              sections.some(
                (x) => x.startsWith("internal") || x.startsWith("external")
              )
            )
              usesTaleoFeed = true;
            sections
              .filter(
                (x) => !x.startsWith("internal") && !x.startsWith("external") // just for taleo push
              )
              .forEach((sec) => {
                if (this.html_export_sections.indexOf(sec) < 0) {
                  this.html_export_sections.push(sec);
                }
              });
          }
          pt.hasDeletedParts =
            pt.cardinality.maxError > 1 &&
            n.DPHistory &&
            n.DPHistory.length &&
            n.DPHistory.some((pa) => pa.isDeleted && pa.name === pt.type);
        });
        return n;
      })[0];
      this.usesTaleoFeed = usesTaleoFeed;
    },
    revertChange(item) {
      let docpart = this.history.part;
      let obj = { text: null, is_essential: null, notes: null, quality: null };
      item.changes.forEach((c) => {
        obj[c.column] = c.oldValue;
      });
      this.saveRow(
        docpart,
        obj.text === null ? docpart.text : obj.text,
        obj.notes === null ? docpart.notes : obj.notes,
        null,
        obj.is_essential === null ? docpart.is_essential : obj.is_essential,
        obj.quality === null ? docpart.quality : obj.quality,
        this.history.partType
      );
    },
    saveEssential(row, partType) {
      this.saveRow(
        row,
        row.text,
        row.notes,
        null,
        !row.is_essential,
        row.quality,
        partType
      );
    },
    saveRow(
      row,
      newval,
      newnotes,
      newsubparts,
      is_essential,
      quality,
      partType
    ) {
      this.isLoading = true;
      if (newsubparts && newsubparts.length) {
        newval = utils.subpartsToPart(newsubparts);
      }
      const isNew = !row.doc_part_id;
      let data = {
        text: newval,
        notes: newnotes,
        is_essential: is_essential,
        quality: quality,
        doc_part_id: row.doc_part_id,
        doc_part_type: partType.type,
        tmpl_part_id: row.tmpl_part_id,
      };
      if (isNew) {
        data.sequence = row.sequence;
        data.doc_id = this.document.doc_id;
        data.system_number = this.document.system_number;
        data.parent_dp_id = row.parent_dp_id;
      }

      axios
        .post("document/savedocpart/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            row.rowDirty = false;
            row.hover = false;
            row.text = newval;
            row.notes = newnotes;
            row.is_essential = is_essential;
            row.quality = quality;
            utils.setDocPartStatus(
              row,
              partType.tmpl_part_metadata,
              this.wordChecks
            );
            if (isNew) {
              row.doc_part_id = parseInt(resp.data.Data.newRow.doc_part_id);
              row.dp_name = resp.data.Data.newRow.dp_name;
              if (partType.parts) {
                row.childParts = [];
                row.expanded = false;
                partType.parts.push(row);
              } else {
                this.textEdit.parent.childParts.push(row);
              }
            }
            let setHistory = false;
            let phIndex = this.partsHistory.findIndex(
              (ph) => ph.key === row.doc_part_id
            );
            if (phIndex >= 0) {
              if (this.history && this.history.key === row.doc_part_id) {
                this.history = null;
                setHistory = true;
              }
              this.partsHistory.splice(phIndex, 1);
            }
            this.partsHistory.push(resp.data.Data.audit);
            if (setHistory) {
              this.history = this.partsHistory[this.partsHistory.length - 1];
              this.history.part = row;
              this.history.partType = partType;
            }
            this.setCheckSummary();
          } else {
            row.rowMessage = resp.data.Message;
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            row.rowMessage = err;
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    deleteRow(row, partType, parent) {
      this.isLoading = true;
      let data = {
        id: row.id,
        doc_part_id: row.doc_part_id,
        doc_part_type: partType.type,
        tmpl_part_id: row.tmpl_part_id,
      };

      axios
        .post("document/removedocpart/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            row.rowDirty = false;
            let parts = parent
              ? parent.childParts
              : this.document.parttypes.find((pt) => pt.type === partType.type)
                  .parts;
            let pIndex = parts.findIndex(
              (p) => p.doc_part_id === data.doc_part_id
            );
            parts.splice(pIndex, 1);

            let phIndex = this.partsHistory.findIndex(
              (ph) => ph.key === row.doc_part_id
            );
            if (phIndex >= 0) {
              if (this.history && this.history.key === row.doc_part_id) {
                this.history = null;
              }
              this.partsHistory.splice(phIndex, 1);
            }
            this.partsHistory.push(resp.data.Data.audit);
            if (this.ptDeleteHistory) {
              this.ptDeleteHistory.push(resp.data.Data.audit);
            }
            partType.hasDeletedParts = true;
            this.setCheckSummary();
          } else {
            row.rowMessage = resp.data.Message;
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    revertDelete(item) {
      this.isLoading = true;
      let partType = this.ptDeleteHistoryPT;
      let row = this.instantiateNewRow(partType);
      let data = {
        doc_part_id: item.key,
        sequence: row.sequence,
        doc_part_type: partType.type,
      };

      axios
        .post("document/undoRemoveDocpart/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            row.doc_part_id = parseInt(resp.data.Data.newRow.doc_part_id);
            row.dp_name = resp.data.Data.newRow.dp_name;
            row.text = resp.data.Data.newRow.text;
            row.sequence = resp.data.Data.newRow.sequence;
            row.notes = resp.data.Data.newRow.notes;
            row.tp_id = resp.data.Data.newRow.tp_id;
            row.is_essential = resp.data.Data.newRow.is_essential;
            row.quality = resp.data.Data.newRow.quality;
            row.dp_active = resp.data.Data.newRow.dp_active;
            utils.setDocPartStatus(
              row,
              partType.tmpl_part_metadata,
              this.wordChecks
            );
            if (partType.parts) {
              row.childParts = [];
              row.expanded = false;
              partType.parts.push(row);
            } else {
              this.textEdit.parent.childParts.push(row);
            }

            let phIndex = this.partsHistory.findIndex(
              (ph) => ph.key === row.doc_part_id
            );
            if (phIndex >= 0) {
              this.partsHistory.splice(phIndex, 1);
            }
            this.partsHistory.push(resp.data.Data.audit);

            let dhIndex = this.ptDeleteHistory.findIndex(
              (ph) => ph.key === row.doc_part_id
            );
            if (dhIndex >= 0) {
              this.ptDeleteHistory.splice(dhIndex, 1);
            }
            partType.hasDeletedParts = this.partsHistory.some(
              (pa) => pa.isDeleted && pa.name === partType.type
            );
            this.setCheckSummary();
          } else {
            row.rowMessage = resp.data.Message;
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    revertHierarchy(hc) {
      this.isLoading = true;
      let data = {
        doc_id: this.document.doc_id,
        system_number: this.document.system_number,
        ht_id: hc.ht_id,
        hr_id: hc.oldValue,
      };

      axios
        .post("document/saveDocumentHierarchy/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.documentHeaderSaved(resp.data);
          } else {
            resp.data.Message;
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    revertDocChange(headerVersion) {
      this.isLoading = true;
      let qQuality = headerVersion.changes.find((x) => x.column === "quality");
      let qRecruiter = headerVersion.changes.find(
        (x) => x.column === "owner_name"
      );
      let qStatus = headerVersion.changes.find((x) => x.column === "status");
      let data = {
        doc_id: this.document.doc_id,
        system_number: this.document.system_number,
        quality: qQuality ? qQuality.oldValue : this.document.quality,
        recruiter: qRecruiter ? qRecruiter.oldValue : this.document.recruiter,
        doc_status: qStatus ? qStatus.oldValue : this.document.doc_status,
      };

      axios
        .post("document/revertDocumentHeader/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.documentHeaderSaved(resp.data);
          } else {
            resp.data.Message;
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    makeDirty(row) {
      if (!row.rowDirty) {
        this.dirtyCount++;
        row.rowDirty = true;
        this.isDirty = true;
      }
    },
    rFlex(pt, documentId) {
      this.roleFlex.show = true;
      this.roleFlex.documentId = documentId;
      this.roleFlex.docPart = pt;
    },
    addPart(partType, parent) {
      if (!this.isEditable) return;
      this.textEdit.text = "";
      this.textEdit.notes = "";
      this.textEdit.partType = partType;
      this.textEdit.parent = parent || null;
      this.textEdit.subParts = partType.tmpl_part_metadata.moveable ? null : [];
      this.textEdit.row = this.instantiateNewRow(partType, parent);
      this.textEdit.title = "Add New " + partType.type;
      this.textEdit.lookup = [];
      this.textEdit.rules = partType.tmpl_part_metadata;
      this.textEdit.canDelete = false;
      this.prepEditPartLookup(partType.tmpl_part_id);
    },
    instantiateNewRow(partType, parent) {
      let currentParts = (parent ? parent.childParts : partType.parts).filter(
        (x) => x.tmpl_part_id === partType.tmpl_part_id
      );
      let sequence = currentParts.length
        ? currentParts.reduce(
            (pval, cval) => (pval > cval.sequence ? pval : cval.sequence),
            0
          ) + 1
        : 1;
      return {
        Status: "",
        StatusList: [],
        WordCount: "",
        doc_part_id: 0,
        id: "",
        notes: "",
        rowDirty: false,
        rowMessage: "",
        sequence: sequence,
        status: "",
        text: "",
        is_essential:
          partType.tmpl_part_metadata.uses_essential_flag === 2 ? 1 : 0,
        quality: 0,
        hover: false,
        type: partType.type,
        tmpl_part_id: partType.tmpl_part_id,
        parent_dp_id: parent ? parent.doc_part_id : null,
      };
    },
    editText(partType, row, singleSection, parent) {
      if (!this.document.state.canEditDocParts) return;
      this.textEdit.text = utils.removeTags(row.text).trim();
      this.textEdit.notes = row.notes;
      this.textEdit.is_essential = row.is_essential;
      this.textEdit.quality = row.quality;
      this.textEdit.subParts = singleSection ? null : row.subParts;
      this.textEdit.row = row;
      this.textEdit.title = partType.type;
      this.textEdit.lookup = [];
      this.textEdit.partType = partType;
      this.textEdit.parent = parent;
      this.textEdit.wordChecks = this.getWordChecksForPart(
        partType.tmpl_part_metadata.tmpl_part_id
      );
      this.textEdit.rules = JSON.parse(
        JSON.stringify(partType.tmpl_part_metadata)
      );
      if (!this.isEditable) this.textEdit.rules.tp_locked = 1;
      this.textEdit.canDelete =
        singleSection &&
        (parent || partType.cardinality.minError >= 0) &&
        (!partType.parts ||
          partType.cardinality.minError < partType.parts.length); // not applying rule on childparts yet
      this.prepEditPartLookup(row.tmpl_part_id);
    },
    prepEditPartLookup(tmpl_part_id) {
      this.isLoading = true;
      axios
        .get("document/getTemplatePartValues/" + tmpl_part_id)
        .then((resp) => {
          this.textEdit.lookup = resp.data.Data;
          this.textEdit.show = true;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    saveTextEdit(newval) {
      this.saveRow(
        this.textEdit.row,
        newval.text,
        newval.notes,
        newval.subParts,
        newval.is_essential,
        newval.quality,
        this.textEdit.partType
      );
      this.textEdit.show = false;
    },
    deleteTextEdit() {
      this.deleteRow(
        this.textEdit.row,
        this.textEdit.partType,
        this.textEdit.parent
      );
      this.textEdit.show = false;
    },
    applyReadOnlyHighlights(text, tmpl_part_metadata) {
      if (!this.docStatus.allow_edit) {
        return text;
      } else {
        let status = utils.applyHighlights(
          text,
          this.getWordChecksForPart(tmpl_part_metadata.tmpl_part_id),
          true
        );
        return status.highlightedText;
      }
    },
    getWordChecksForPart(tpid) {
      let hchys = this.docHierarchies;
      return this.wordChecks.filter((w) =>
        w.tmpl_part_types.some(
          (tp) =>
            tp.tp_id === tpid &&
            (!tp.exclusions.length ||
              !hchys.some((h) =>
                tp.exclusions.some((e) => e.hr_id === h.hr_id)
              ))
        )
      );
    },
    openCheckStatus() {
      this.showCheckStatus = true;
    },
    setCheckSummary() {
      this.checkSummary = utils.documentCheckSummary(
        this.document,
        this.wordChecks,
        this.checkSummaryView
      );
    },
    allCheckSummaryPanels() {
      this.checkSummaryPanels = this.checkSummary.categories.map((c, i) => i);
    },
    closeCheckSummaryPanels() {
      this.checkSummaryPanels = [];
    },
    closeCheckStatus() {
      this.showCheckStatus = false;
    },
    openHeader() {
      this.showHeaderDetail = true;
    },
    documentHeaderClosed() {
      this.showHeaderDetail = false;
    },
    documentHeaderSaved(responseData) {
      this.setupDocument(responseData.Data.documents);
      this.setDocStatus();
      this.setCheckSummary();

      this.showHeaderDetail = false;
      this.$emit("documentHeaderSaved", this.document);
      this.response = responseData;
    },
    async closeDocument() {
      this.$emit("closeDocument", this.document);
    },
    close() {
      this.dialogLinks = false;
    },
    copyCheck() {
      let text = "";
      if (this.checkSummary) {
        if (this.checkSummary.errors.length) {
          text = `${this.checkSummary.errors.length} error${
            this.checkSummary.errors.length > 1 ? "s" : ""
          }`;
        }
        if (this.checkSummary.warnings.length) {
          if (text) text += " and ";
          text += `${this.checkSummary.warnings.length} warning${
            this.checkSummary.warnings.length > 1 ? "s" : ""
          }`;
        }
        if (text) {
          this.confirmCopy = `The document has ${text}. Are you sure you want to copy it?`;
          this.confirmCopyShow = true;
          return false;
        }
      }
      return true;
    },
    doCopy() {
      this.confirmCopyShow = false;
      this.$emit("copyDocument", this.document);
    },
    copyDocument() {
      if (this.copyCheck()) {
        this.doCopy();
      }
    },
    filesUploadedCallback(data) {
      this.$emit("copyDocument", this.document, this.tmpTargetDocTmplId, data);
      //this.createExternalDocument(data);
    },
    linkDocumentAction(docType, action) {
      this.copymenu = false;
      if (action) {
        switch (action) {
          case "copy":
          case "link":
            if (docType && docType.tmpl_name == "External File") {
              this.tmpTargetDocTmplId = docType.tmpl_id;
              this.dialogLinks = true;
              this.setupUploadData();
              return;
            } else {
              this.$emit(
                "copyDocument",
                this.document,
                docType ? docType.tmpl_id : null
              );
            }
            break;
          case "view":
            this.$emit("openDocument", docType);
            break;
        }
      } else {
        switch (docType.Action.toLowerCase()) {
          case "copy":
            this.$emit("copyDocument", this.document, docType.tmpl_id);
            break;
          case "view":
            this.$emit("openDocument", docType);
            break;
        }
      }
    },
    validateForCopy: function () {},
    changeOrder: function (e, parttype) {
      // let parttype = e.moved.element.type,
      // this.document.parttypes.find(
      //   ptt => ptt.type ===  pt.type || parttype
      // );
      let data = parttype.parts.map((r, i) => {
        let ret = {};
        ret.sequence = i + 1;
        ret.type = parttype.type;
        ret.doc_part_id = r.doc_part_id;
        return ret;
      });
      let orig = this.item
        ? this.item.parttypes
            .find((pt) => pt.type === parttype)
            .parts.find((p) => p.doc_part_id === this.document.doc_part_id)
        : null;
      this.isLoading = true;
      axios
        .post("document/savedocpartsequence/", data)
        .then((resp) => {
          if (orig) {
            if (resp.data.Status === "OK" && resp.data.RowsUpdated) {
              orig.parts = this.document.parts;
            } else {
              this.document.parts = orig.parts;
            }
          }
          this.response = resp.data;
          //console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          this.document[parttype].parts = orig[parttype].parts;
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    generatePDF() {
      var doc = document.getElementById("pdfsection");
      doc.innerHTML = this.docToHTML("", true, false);
      let id = this.documentId;
      var w = doc.offsetWidth;
      var h = doc.offsetHeight;
      this.isLoading = true;
      html2canvas(doc, {
        dpi: 300, // Set to 300 DPI
        scale: 3, // Adjusts your resolution
      }).then((canvas) => {
        var img = canvas.toDataURL("image/jpeg", 1);
        var pdf = new jsPDF("p", "pt", [w, h]);
        pdf.addImage(img, "JPEG", 0, 0, w, h);
        pdf.save(id + ".pdf");
        this.isLoading = false;
        doc.innerHTML = "";
        doc.h;
        this.exportMenu = false; // close export menu
      });
    },
    newWorkingPattern(html, wordCount) {
      let newVal = utils.partToSubParts(html);
      let wpPartType = this.document.parttypes.find(
        (pt) => pt.type === "Working Pattern"
      );
      wpPartType.parts[0].subParts = newVal;
      wpPartType.parts[0].text = html;
      wpPartType.wordCount = wordCount;
    },
    highlightSection(issue) {
      if (this.disableChecks) {
        return;
      }
      this.unhighlightSection();
      issue.items.forEach((t) => {
        t.instances.forEach((i) => {
          i.partsAffected.forEach((pa) => {
            this.document.parttypes.forEach((pt) => {
              pt.parts.forEach((p) => {
                if (pa === p.doc_part_id) p.highlightSection = true;
                p.childParts.forEach((cp) => {
                  if (pa === cp.doc_part_id) cp.highlightSection = true;
                });
              });
            });
          });
        });
      });
    },
    unhighlightSection() {
      this.document.parttypes.forEach((pt) => {
        pt.parts.forEach((p) => {
          p.highlightSection = false;
          p.childParts.forEach((cp) => (cp.highlightSection = false));
        });
      });
    },
    scrollToSection(issue) {
      let options = {
        container: ".v-dialog--active.v-dialog--fullscreen", //this.$refs.doccontainer, //"#container",
        easing: "ease-in",
        offset: -60,
        force: true,
        cancelable: true,
        // onStart: function (element) {
        //   // scrolling started
        // },
        // onDone: function (element) {
        //   // scrolling is done
        // },
        // onCancel: function () {
        //   // scrolling has been interrupted
        // },
        x: false,
        y: true,
      };
      let next;
      let thisSet = [];
      let processing = true;
      while (processing) {
        issue.items.forEach((t) => {
          t.instances.forEach((i) => {
            i.partsAffected.forEach((pa) => {
              if (this.dpScrolled.indexOf(pa) >= 0) {
                thisSet.push(pa);
              } else if (!next) {
                next = pa;
                thisSet.push(pa);
              }
            });
          });
        });
        if (
          this.dpScrolled.length === thisSet.length &&
          thisSet.length > 0 &&
          this.dpScrolled[thisSet.length - 1] === thisSet[thisSet.length - 1]
        ) {
          this.dpScrolled.splice(0);
          thisSet.splice(0);
        } else {
          processing = false;
        }
      }
      this.dpScrolled = thisSet;
      ScrollTo.scrollTo("#dp" + next, 500, options);
    },
    showPartHistory(p, pt) {
      this.ptDeleteHistory = null;
      this.headerHistoryShow = false;
      this.history = this.partsHistory
        ? this.partsHistory.find((pa) => pa.key === p.doc_part_id)
        : null;
      this.unhighlightSection();
      if (this.history) {
        this.history.part = p;
        this.history.partType = pt;
        p.highlightSection = true;
      }
    },
    showDeletedPartHistory(pt) {
      this.history = null;
      this.headerHistoryShow = false;
      this.ptDeleteHistoryPT = pt;
      this.ptDeleteHistory = this.partsHistory
        ? this.partsHistory.filter((pa) => pa.isDeleted && pa.name === pt.type)
        : null;
      this.unhighlightSection();
    },
    showheaderHistory() {
      this.history = null;
      this.ptDeleteHistoryPT = null;
      this.ptDeleteHistory = null;
      this.headerHistoryShow = true;
      this.unhighlightSection();
    },
    partHasHover(p) {
      p.hover = true;
    },
    partLostHover(p) {
      p.hover = false;
    },
    sendEmail() {
      if (this.$loginState.canDemo) {
        this.response = {
          Message:
            "'Demo' email server is not currently configued. This error has been automatically logged and a support ticket raised.",
          Data: null,
          Status: "Error",
          RowsUpdated: 0,
        };
      }
      this.emailDialogue.show = false;
      this.emailDialogue.value = "";
      this.exportMenu = false;
    },
    sendToTaleo() {
      if (this.$loginState.canDemo) {
        this.response = {
          Message: "Document posted to Workday",
          Data: null,
          Status: "OK",
          RowsUpdated: 0,
        };
        this.exportMenu = false;
      } else {
        this.reqDialogue.show = true;
      }
    },
    sendDataToTaleo() {
      this.awaitingTaleo = true;

      if (!this.reqDialogue.value) {
        alert("Requisition Number must be entered");
        return;
      }

      let mm = `<br><p><b>Build your career at Akamai</b></p><p>Our ability to shape digital life today relies on developing exceptional people like you. The kind that can turn impossible into possible. We’re doing everything we can to make Akamai a great place to work. A place where you can learn, grow and have a meaningful impact. </p>`;
      mm += `<br><p>With our company moving so fast, it’s important that you’re able to build new skills, explore new roles, and try out different opportunities. There are so many different ways to build your career at Akamai, and we want to support you as much as possible. We have all kinds of development opportunities available, from programs such as GROW and Mentoring, to internal events like the APEX Expo and tools such as Linkedin Learning, all to help you expand your knowledge and experience here.</p>`;
      mm += `<br><p><b>Learn more</b></p><p>Not sure if this job is the right match for you or want to learn more about the job before you apply? Schedule a 15-minute exploratory call with the Recruiter and they would be happy to share more details. Here is the contact info for the recruiter assigned to this position:</p><br>`;
      let data = {
        ats_reference: this.reqDialogue.value,
        htmlInt:
          this.docToHTML(
            "internal",
            false,
            false,
            this.reqDialogue.USA
          ).replace(/[^\000-\177]/g, "") + mm,
        htmlExt: this.docToHTML(
          "external",
          false,
          false,
          this.reqDialogue.USA
        ).replace(/[^\000-\177]/g, ""),
        reference: this.document.system_number,
        title: this.document.parttypes.find(
          (t) => t.type === "Advert Job Title"
        ).parts[0].text,
        USA: this.reqDialogue.USA,
      };

      axios
        .post("client/pingtaleo/", data)
        .then((resp) => {
          this.triggerNotification(resp.data.Message, "success");
          this.isLoading = false;
          this.awaitingTaleo = false;
          this.reqDialogue.show = false;
          this.reqDialogue.USA = false;
          this.reqDialogue.value = "";
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.triggerNotification(err, "error");
          }
          this.isLoading = false;
          this.awaitingTaleo = false;
        });
    },

    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    postToTaleo() {
      if (!this.reqDialogue.value) {
        alert("Requisition Number must be entered");
        return;
      }
      let mm = `<br><p><b>Build your career at Akamai</b></p><p>Our ability to shape digital life today relies on developing exceptional people like you. The kind that can turn impossible into possible. We’re doing everything we can to make Akamai a great place to work. A place where you can learn, grow and have a meaningful impact. </p>`;
      mm += `<br><p>With our company moving so fast, it’s important that you’re able to build new skills, explore new roles, and try out different opportunities. There are so many different ways to build your career at Akamai, and we want to support you as much as possible. We have all kinds of development opportunities available, from programs such as GROW and Mentoring, to internal events like the APEX Expo and tools such as Linkedin Learning, all to help you expand your knowledge and experience here.</p>`;
      mm += `<br><p><b>Learn more</b></p><p>Not sure if this job is the right match for you or want to learn more about the job before you apply? Schedule a 15-minute exploratory call with the Recruiter and they would be happy to share more details. Here is the contact info for the recruiter assigned to this position:</p><br>`;
      let data = {
        ats_reference: this.reqDialogue.value,
        htmlInt:
          this.docToHTML(
            "internal",
            false,
            false,
            this.reqDialogue.USA
          ).replace(/[^\000-\177]/g, "") + mm,
        htmlExt: this.docToHTML(
          "external",
          false,
          false,
          this.reqDialogue.USA
        ).replace(/[^\000-\177]/g, ""),
        reference: this.document.system_number,
        title: this.document.parttypes.find(
          (t) => t.type === "Advert Job Title"
        ).parts[0].text,
        USA: this.reqDialogue.USA,
      };

      axios
        .post("client/pingtaleo/", data)
        .then((resp) => {
          this.response = resp.data;
          this.isLoading = false;
          this.reqDialogue.show = false;
          this.reqDialogue.USA = false;
          this.reqDialogue.value = "";
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    docToClipboard(section) {
      let copyText = document.getElementById("htmlexport");
      copyText.value = this.docToHTML(section);
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
    },
    docToHTML(section, forDocx, showNotes, USJob) {
      let html = "";
      let ul = "<ul>",
        ul_e = "</ul>",
        li = '<li class="lst">',
        li_e = "</li>",
        p_s = "<p>",
        p_e = "</p>",
        n_s = '<p style="color: gray;"><i>',
        n_e = "</i></p>";
      let overrides =
        this.document.exportOverrides && this.document.exportOverrides.length
          ? this.document.exportOverrides
          : null;
      this.document.parttypes.forEach((pt) => {
        let includePT = !section,
          useDefaultText = false,
          overrideText = "";
        if (section) {
          // all this just for taleo export
          let applicableSections = (
            pt.tmpl_part_metadata.html_export_section || ""
          ).split("|");
          if (applicableSections.indexOf(section) >= 0) {
            includePT = true;
          } else {
            let special =
              applicableSections.find((x) => x.startsWith(section)) || "";
            useDefaultText = special === section + "_default";
            let possOverride = overrides
              ? overrides.find((x) => x.tp_id === pt.tmpl_part_id)
              : false;
            let override =
              possOverride &&
              possOverride.html_export_section.indexOf(section) >= 0
                ? possOverride
                : false;
            if (override && USJob) {
              overrideText = override.default_text;
              includePT = true;
            } else if (special === section + "_usa") {
              // always export whether location is USA or not
              includePT = true;
            }
          }
        }
        if (includePT) {
          let def_s = p_s,
            def_e = p_e;
          switch (pt.tmpl_part_metadata.html_export_tag) {
            case "bold_centre_large":
              def_s = forDocx
                ? '<h2 style="text-align: center; margin: auto;">'
                : '<h2 align="center">';
              def_e = "</h2>";
              break;
            case "bold_centre":
              def_s = forDocx
                ? '<h3 style="text-align: center; margin: auto;">'
                : '<p align="center"><b>';
              def_e = forDocx ? "</h3>" : "</b></p>";
              break;
            case "bold_left":
              def_s = forDocx ? "<h4>" : "<p><b>";
              def_e = forDocx ? "</h4>" : "</b></p>";
              if (section === "internal") def_s = "<br>" + def_s;
              break;
            case "list":
              def_s = li;
              def_e = li_e;
              break;
          }
          let needsULEnd = false;
          pt.parts.forEach((p, pi) => {
            if (!pt.tmpl_part_metadata.uses_essential_flag || p.is_essential) {
              if (overrideText) {
                html += `${def_s}${overrideText}${def_e}`;
              } else if (useDefaultText) {
                html += `${def_s}${pt.tmpl_part_metadata.default_text}${def_e}`;
              } else if (p.subParts && p.subParts.length) {
                let tag,
                  endTag,
                  ulStarted = false;
                if (pt.tmpl_part_metadata.html_export_tag === "list") {
                  if (def_s === li && pi === 0) {
                    html += ul;
                    needsULEnd = true;
                  }
                  html += `${def_s}${p.subParts[0].text}${def_e}`;
                  if (def_s === li && pi === pt.parts.length - 1) {
                    (html += ul_e), (needsULEnd = false);
                  }
                } else {
                  p.subParts.forEach((sp, spi) => {
                    if (sp.type === "listitem") {
                      tag = li;
                      endTag = li_e;
                      if (!ulStarted) {
                        html += ul;
                        ulStarted = true;
                      }
                    } else {
                      tag = def_s;
                      endTag = def_e;
                      if (ulStarted) {
                        html += ul_e;
                        ulStarted = false;
                      }
                    }

                    html += `${tag}${sp.text}${endTag}`;
                    if (spi === p.subParts.length - 1 && ulStarted) {
                      html += ul_e;
                      ulStarted = false;
                    }
                  });
                }
              } else {
                if (def_s === li && pi === 0) html += ul;
                html += `${def_s}${p.text}${def_e}`;
                if (def_s === li && pi === pt.parts.length - 1) html += ul_e;
              }
              // add the export default text if it is not empty and the field is
              if (
                pt.tmpl_part_metadata.export_default_text &&
                p.subParts[0].text === ""
              ) {
                let def_blue = def_s.replaceAll(">", ' style="color: blue;">');
                html += `${def_blue}${pt.tmpl_part_metadata.export_default_text}${def_e}`;
              }
              if (p.childParts.length) {
                html += ul;
                html += p.childParts.reduce((p, c) => {
                  return p + `<li>${c.text}</li>`;
                }, "");
                html += ul_e;
              }
              if (showNotes && p.notes) {
                html += `${n_s}${p.notes}${n_e}`;
              }
            }
          });
          if (needsULEnd) html += ul_e;
        }
      });
      this.exportMenu = false; // close export menu
      if (forDocx) {
        return html
          .replace(/‘/g, "&lsquo;")
          .replace(/’/g, "&rsquo;")
          .replace(/“/g, "&ldquo;")
          .replace(/”/g, "&rdquo;")
          .replace(/£/g, "&pound;")
          .replace(/€/g, "&euro;")
          .replace(/©/g, "&copy;")
          .replace(/®/g, "&reg;");
      } else {
        return html;
      }
    },
    convertHtmlToRtf(html, fullDoc) {
      if (!(typeof html === "string" && html)) {
        return null;
      }

      var tmpRichText, hasHyperlinks;
      var richText = html;

      if (fullDoc) {
        // Making Job Title larger if full doc selected ...
        richText = richText.replace(
          /^<(?:p align="center"><b>)/gi,
          "\n{\\pard\\qc{\\b\\fs34{}"
        );
      }

      // Singleton tags
      richText = richText.replace(
        /<(?:hr)(?:\s+[^>]*)?\s*[\\/]?>/gi,
        "\n{\\pard \\brdrb \\brdrs \\brdrw10 \\brsp20 \\par}\n\r{\\pard\\par}"
      );
      richText = richText.replace(
        /<(?:br)(?:\s+[^>]*)?\s*[\\/]?>/gi,
        "\n{\\pard\\par\\sect}"
      );

      // Empty tags
      richText = richText.replace(
        /<(?:p|div|section|article)(?:\s+[^>]*)?\s*[\\/]>/gi,
        "{\\pard\\sect}"
      );
      richText = richText.replace(/<(?:[^>]+)\/>/g, "");

      // Hyperlinks
      richText = richText.replace(
        /<a(?:\s+[^>]*)?(?:\s+href=(["'])(?:javascript:void\(0?\);?|#|return false;?|void\(0?\);?|)\1)(?:\s+[^>]*)?>/gi,
        "{{{\n"
      );
      tmpRichText = richText;
      richText = richText.replace(
        /<a(?:\s+[^>]*)?(?:\s+href=(["'])(.+)\1)(?:\s+[^>]*)?>/gi,
        '{\\field{\\*\\fldinst{HYPERLINK\n "$2"\n}}{\\fldrslt{\\ul\\cf1\n'
      );
      hasHyperlinks = richText !== tmpRichText;
      richText = richText.replace(/<a(?:\s+[^>]*)?>/gi, "{{{\n");
      richText = richText.replace(/<\/a(?:\s+[^>]*)?>/gi, "\n}}}");

      // Start tags
      richText = richText.replace(
        /<(?:p align="center"><b>)/gi,
        "\n{\\pard\\qc{\\b\\fs25{}"
      );
      richText = richText.replace(
        /<(?:b|strong)(?:\s+[^>]*)?>/gi,
        "{\\b\\fs27{}"
      );
      richText = richText.replace(/<(?:i|em)(?:\s+[^>]*)?>/gi, "{\\i\n");
      richText = richText.replace(/<(?:u|ins)(?:\s+[^>]*)?>/gi, "{\\ul\n");
      richText = richText.replace(
        /<(?:strike|del)(?:\s+[^>]*)?>/gi,
        "{\\strike\n"
      );
      richText = richText.replace(/<sup(?:\s+[^>]*)?>/gi, "{\\super\n");
      richText = richText.replace(/<sub(?:\s+[^>]*)?>/gi, "{\\sub\n");
      richText = richText.replace(
        /<(?:p|div|section|article)(?:\s+[^>]*)?>/gi,
        "\n{\\pard{}"
      );
      richText = richText.replace(
        /<(?:li)(?:\s+[^>]*)?\s*[\\/]?>/gi,
        "\n{\\f2 {\\pntext \\bullet\\tab}{\\pn\\pnlvlblt\\pnstart1{\\pntxtb\\bullet}}{\\ltrch{}"
      );

      // End tags
      richText = richText.replace(
        /<\/(?:p|div|section|article)(?:\s+[^>]*)?>/gi,
        "\n\\line\\par}"
      );
      richText = richText.replace(/<\/(?:ul)(?:\s+[^>]*)?>/gi, "\\line{}");
      richText = richText.replace(
        /<\/(?:b|strong|i|em|u|ins|strike|del|sup|sub)(?:\s+[^>]*)?>/gi,
        "}"
      );
      richText = richText.replace(
        /<(?:\/li)(?:\s+[^>]*)?\s*[\\/]?>/gi,
        "}\\li720\\ri0\\sa0\\sb0\\jclisttab\\tx720\\fi-360\\ql\\par}"
      );

      // Strip any other remaining HTML tags [but leave their contents]
      richText = richText.replace(/<(?:[^>]+)>/g, "");

      richText = richText
        .replace(/‘/g, "\\'91")
        .replace(/’/g, "\\'92")
        .replace(/“/g, "\\'93")
        .replace(/”/g, "\\'94")
        .replace(/£/g, "\\'a3")
        .replace(/€/g, "\\'80")
        .replace(/©/g, "\\'a9")
        .replace(/®/g, "\\'ae");

      // Prefix and suffix the rich text with the necessary syntax
      richText =
        "{\\rtf1\\ansi\\deff0{\\fonttbl{\\f0 Open-Sans;}}" +
        (hasHyperlinks ? "{\\colortbl\n;\n\\red0\\green0\\blue255;\n}\n" : "") +
        richText +
        "\n}";

      return richText;
    },
    copyStringToClipboard(str) {
      // Create new element
      var el = document.createElement("textarea");
      // Set value (string to be copied)
      el.value = str;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute("readonly", "");
      el.style = { position: "absolute", left: "-9999px" };
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      el.setSelectionRange(0, 99999); /*For mobile devices*/
      // Copy text to clipboard
      document.execCommand("copy");
      // Remove temporary element
      document.body.removeChild(el);
    },
    generateRTF(section) {
      let rtfText = this.convertHtmlToRtf(this.docToHTML(section), true);
      utils.downloadFile(
        rtfText,
        this.document.system_number + " " + this.document.doc_type + ".rtf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
      this.exportMenu = false; // close export menu
    },
    generateDOCX(full) {
      let html = `<!DOCTYPE  html><html><body>
	  <style>
		p, h2, h3, h4 { font-family: calibri; }
		.lst { font-family: calibri; font-size: 12pt; }
	  </style>${this.docToHTML(null, true, full)}</body></html>`;

      html2docx(html, {
        orientation: "portrait",
        margins: { top: 820, bottom: 820 },
      }).then(data => {
        utils.downloadFile(
          data,
          this.document.system_number +
            (full ? " FULL" : " " + this.document.doc_type) +
            ".docx",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      });
      this.exportMenu = false; // close export menu
    },
    generateInterviewTemplate() {
      let n_s = '<p style="color: gray;"><i>',
        n_e = "</i></p>";
      let html = `<!DOCTYPE  html><html><body>
	  <style>
		.pagebreak { page-break-before: always !important; }
		.cell_p1 { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; min-width: 30%; max-width: 30%; width: 30%;}
		.cell_p4 { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; min-width: 70%; max-width: 70%; width: 70%; }
		.page_heading { font-weight: bold; font-size: 20pt; font-family: calibri; }
		.cell_heading { font-weight: bold; font-size: 13pt; }
		.ivSection { font-weight: bold; font-size: 13pt; font-family: calibri; min-height: 500px; }
		.cell_hint { font-style: italic; color: gray; }
		table, th, td { border: 1px solid black; border-collapse: collapse; }
		td { padding: 5px; }
	  </style>
	  <span class="page_heading">Interview Template - ${this.document.doc_name}</span>
	  <div><br/><br/><br/><br/></div>
	  <table style="width: 100%">
	  <tbody>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Name of Candidate:</span></td>
			<td class="cell_p4">&nbsp;</td>
		</tr>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Job Role:</span></td>
			<td class="cell_p4"><span class="cell_heading">${this.document.doc_name}</span></td>
		</tr>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Interviewer(s):</span></td>
			<td class="cell_p4">&nbsp;</td>
		</tr>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Date / Time of Interview:</span></td>
			<td class="cell_p4">&nbsp;</td>
		</tr>
	  </tbody>
	  </table>`;
      let reqSummary = `
	  	<p class="pagebreak"><span class="page_heading">Interview Template - ${this.document.doc_name}</span></p>
	  	<table style="width: 100%"><caption>Overall Summary</caption>
			<tr>
				<td colspan="8">&nbsp;</td>
				<td><span class="cell_heading">Rating</span></td>
			</tr>`;
      this.document.parttypes
        .find((pt) => pt.type === "Requirement")
        .parts.filter((p) => p.is_essential)
        .forEach((p) => {
          reqSummary += `
			<tr>
				<td colspan="8">${p.text}</td>
				<td>&nbsp;</td>
			</tr>`;
          html += `
	  <p class="pagebreak"><span class="page_heading">Interview Template - ${
      this.document.doc_name
    }</span></p>
	  <table>
		<tr>
			<td><span class="cell_heading">Requirement:</span><br/><span class="cell_hint">(Any skill/knowledge/experience that is essential for the role)</span></td>
			<td colspan="3"><span class="cell_heading">${p.text}</span></td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Indicators:</span> <span class="cell_hint">(what does good look like for this requirement)</span>
			<ul class="ivSection">
      ${p.childParts
        .filter((c) => c.dp_name === "Indicator")
        .reduce((p, c) => {
          return p + `<li>${c.text}</li>`;
        }, "")}
			</ul></td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Interview Questions:</span>
			<ul class="ivSection">
      ${p.childParts
        .filter((c) => c.dp_name === "Question")
        .reduce((p, c) => {
          return p + `<li>${c.text}</li>`;
        }, "")}
			</ul></td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Evidence:</span> <span class="cell_hint">(summarise evidence from your notes)</span><br/>&nbsp;<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;</td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Rating</span></td>
		</tr>
		<tr>
			<td style="width: 25%; text-align: center;">1</td>
			<td style="width: 25%; text-align: center;">2</td>
			<td style="width: 25%; text-align: center;">3</td>
			<td style="width: 25%; text-align: center;">4</td>
		</tr>
		<tr>
			<td style="width: 25%; text-align: center;">${n_s}No evidence (not competent or experienced)${n_e}</td>
			<td style="width: 25%; text-align: center;">${n_s}Some evidence (some competence or experience)${n_e}</td>
			<td style="width: 25%; text-align: center;">${n_s}Good evidence (good competence or experience)${n_e}</td>
			<td style="width: 25%; text-align: center;">${n_s}Excellent evidence (excellent competence or experience)${n_e}</td>
		</tr>
	  </table>`;
        });
      reqSummary += `
			<tr>
				<td colspan="8" style="text-align: right;">TOTAL</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td colspan="9" >Additional notes:<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;</td>
			</tr>
			<tr>
				<td colspan="9" >Decision:<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;</td>
			</tr>
			</table>`;
      html +=
        reqSummary +
        `
	  	<p class="pagebreak"><span class="page_heading">How to use this Interview Template</span></p>
		<table style="width: 100%">
	  <tbody>
		<tr>
			<td style="width: 34%;"><span class="cell_heading">Preparing for the Interview</span></td>
			<td style="width: 22%;"><span class="cell_heading">Conducting the Interview</span></td>
			<td style="width: 22%;"><span class="cell_heading">Scoring the Interview</span></td>
			<td style="width: 22%;"><span class="cell_heading">Avoiding Bias</span></td>
		</tr>
		<tr>
			<td style="vertical-align: top; font-size: 10pt; width: 34%;">
				1.	Decide on which of the essential requirements for the role you are going to assess in the interview<br/><br/>
				2.	Write interview questions for each requirement and add these to the interview template.  These should consist of an opening question and probing questions. <br/><br/>
				3.	Think about what good looks like for this requirement and add these to the interview template as indicators<br/>
				<br/>
				For example:<br/>
				<b>Requirement:</b> <br/>
				"Experience managing complex global projects"<br/>
				<br/>
				<b>Indicators:</b><br/>
				-	Has managed multiple, complex projects previously<br/>
				-	Can describe each stage of the project management lifecycle<br/>
				-	Demonstrates various methods to keep stakeholders updated<br/>
				<br/>
				<b>Opening question:</b> <br/>
				"Tell me about a time when you have managed a complex project with multiple stakeholders"<br/>
				<br/>
				<b>Probing questions:</b><br/>
				"What was your specific role on the project?"<br/>
				"How did you manage the complexity?"<br/>
				"How did you communicate with the stakeholders?"<br/>
				"What feedback did you receive?"<br/>
			</td>
			<td style="vertical-align: top; font-size: 10pt; width: 22%;">
				<b>At the start of the interview:</b>
				<ul>
					<li>Meet and greet the candidate and introduce those present</li>
					<li>Explain that the interview is a two -way exchange of information.</li>
					<li>Indicate how long the interview is likely to last</li>
					<li>Explain that the interview is structured around essential requirements for the job and that you will be looking for specific examples of what the candidate has done</li>
					<li>Explain that you will be taking notes</li>
				</ul>
				<b>During the interview:</b>
				<ul><li>Take notes on evidence relating to each requirement and question</li></ul>
				<b>At the end of the interview:</b>
				<ul><li>Give the candidate the opportunity to ask any questions and explain to them the next steps in the process</li></ul>
			</td>
			<td style="vertical-align: top; font-size: 10pt; width: 22%;">
				<ul>
					<li>As soon as possible after the interview, review your notes and confirm any evidence against each requirement</li>
					<li>Assign a rating to each requirement</li>
					<li>If more than one interviewer is present, each person should score the interview separately</li>
					<li>Use the ratings from the interview to decide whether the candidate should go forward to the next stage of the process</li>
					<li>Make sure that your notes explain the reason for your decision</li>
				</ul>
			</td>
			<td style="vertical-align: top; font-size: 10pt; width: 22%;">
				<ul>
					<li>Make sure you have an understanding of the essential requirements for the role and that you are basing your interview around these</li>
					<li>If more than one interviewer is present, each person should score the interview first before discussing to avoid priming / Groupthink</li>
					<li>Be aware of our unconscious tendency to favour people who remind us of ourselves (in-group bias)</li>
					<li>Try not to put too much weight on first impressions, whether these are negative or positive.  Make sure that evidence from the whole interview is taken into account</li>
					<li>Assessors allow the same time and stick to the script to avoid 'intuition' or 'gut' feel</li>
					<li>Don’t compare candidates to each other.  Make sure you are assessing each candidate fairly against the requirements for the job, not against each other</li>
				</ul>
			</td>
		</tr>
	  </tbody>
		</table>
	  </body></html>`;
      html = html.replace(/’/g, "'");
      html = html.replace(/•/g, "");

      html2docx(html, {
        orientation: "landscape",
        margins: { top: 520, bottom: 520, left: 500, right: 500 },
      }).then(data => {
        utils.downloadFile(
          data,
          this.document.system_number + " Interview Template.docx",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      });
      this.exportMenu = false; // close export menu
    },
    recordIntakeFlow() {
      axios
        .post("client/recordTransaction/", {
          transaction_type: "intake_flow",
          attribute: "doc_id",
          attribute_id: this.document.doc_id,
        })
        .then((resp) => {
          if (resp.data.Status !== "OK") {
            resp.data.Message;
            this.response = resp.data;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    generateIntakeFlow() {
      // let n_s = '<p style="color: gray;"><i>',
      //   n_e = "</i></p>";
      this.recordIntakeFlow();
      let html = `
        <!DOCTYPE  html><html><body>
          <style>
            .pagebreak { page-break-before: always !important; }
            .cell_p0 { min-width: 15%; max-width: 15%; width: 15%;}
            .cell_p1 { padding: 5px; pmin-width: 20%; max-width: 20%; width: 20%;}
            .cell_p2 { padding: 5px; min-width: 25%; max-width: 25%; width: 25%;}
            .cell_p3 { padding: 5px; min-width: 30%; max-width: 30%; width: 30%;}
            .cell_p4 { padding: 5px; min-width: 50%; max-width: 50%; width: 50%; }
            .cell_across { padding: 0; min-width: 100%; max-width: 100%; width: 100%; }
            .page_heading { font-weight: bold; font-size: 14pt; text-decoration: underline; text-align: center;}
            .section_heading { padding-top: 8px; padding-bottom: 8px; padding-left: 15px; margin:0; font-weight: bold; text-align: center;}
            .cell_heading { padding:0; font-weight: bold; background-color: #DBE5F1 }
            .ivSection { font-weight: bold; font-family: calibri; min-height: 500px; }
            .cell_hint { font-style: italic; color: gray; }
            table, th, td { border: 1px solid black; border-collapse: collapse; }
            td { padding: 0 5px; }
            * { font-family: calibri; font-size: 9pt; }
          </style>
          <div class="page_heading">Launch Meeting Plan</div>
          <table style="width: 100%">
            <tr style="height: 14px">
              <td class="cell_across" colspan="4" style="background-color: #366091; color: white;">
                <div class="section_heading">POSITION INFORMATION</div>
              </td>
            </tr>
            <tr style="height:20px">
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Date of Call/Meeting:</td>
              <td class="cell_p2">&nbsp;</td>
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Recruiter:</td>
              <td class="cell_p2">${this.$loginState.user.name}</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Position Title:</td>
              <td class="cell_p2">${this.document.doc_name}</td>
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Requisition #:</td>
              <td class="cell_p2">&nbsp;</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Job Posting Title:</td>
              <td class="cell_p2">${
                this.document.parttypes.find(
                  (pt) =>
                    pt.type === "Advert Job Title" ||
                    pt.type === "Título de Trabajo del Anuncio" ||
                    pt.type === "Título de Trabajo"
                ).parts[0].text
              }</td>
              <td class="cell_p4 cell_heading" style="background-color: #DBE5F1" colspan="2">Is the Job title appropriate & relevant for attraction?</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Hiring Manager:</td>
              <td class="cell_p2">&nbsp;</td>
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Business Group/Function:</td>
              <td class="cell_p2">&nbsp;</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">`;
      if (
        this.$loginState.user.client === "Akamai" ||
        this.$loginState.user.client === "staging"
      ) {
        html += `Mid-point budget:</td>
              <td class="cell_p2">&nbsp;<br></td>
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Sales roles: % split, bonus % & type</td>
              <td class="cell_p2">&nbsp;<br></td>`;
      } else {
        html += `Salary:</td>
              <td class="cell_p2" colspan="3">&nbsp;<br></td>`;
      }
      html += `
            </tr>
            <tr style="height:50px">
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Relocations Considered?</td>
              <td class="cell_p2">&nbsp;<br></td>
              <td class="cell_p1 cell_heading" style="background-color: #DBE5F1">Expecting internal candidates?<br>From what teams?</td>
              <td class="cell_p2">&nbsp;<br></td>
            </tr>
            <tr style="height: 10px">
              <td class="cell_across" colspan="4" style="background-color: #366091; color: white;">
                <div class="section_heading">JOB USP</div>
              </td>
            </tr>
            <tr style="height:90px">
              <td colspan="4">${
                this.document.parttypes.find((pt) => pt.type === "Job USP")
                  .parts[0].text
              }</td>
            </tr>
            <tr style="height:60px">
              <td class="cell_p4 cell_heading" style="background-color: #DBE5F1" colspan="2">Additional unique selling points (to build on above to discuss with candidate) - why should anyone apply for the position?</td>
              <td colspan="2">&nbsp;</td>
            </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="6" style="background-color: #366091; color: white;">
            <div class="section_heading">FURTHER NOTES/INFORMATION</div>
          </td>
        </tr>
        <tr style="height:80px">
          <td colspan="6">&nbsp;</td>
        </tr>
          </table>
          <p class="pagebreak">&nbsp;</p>
          <table style="width: 100%">
            <tr style="height: 14px">
              <td class="cell_across" colspan="10" style="background-color: #366091; color: white;">
                <div class="section_heading">RESPONSIBILITIES</div>
              </td>
            </tr>
            <tr>
              <td class="cell_p4 cell_heading" colspan="7" style="background-color: #DBE5F1">Responsibilities</td>
              <td class="cell_p4 cell_heading" colspan="3" style="background-color: #DBE5F1">Any more information to bring the role to life</td>
            </tr>`;
      let respSummary = "";
      let noResps = this.document.parttypes.find(
        (pt) => pt.type === "Responsibility"
      ).parts.length;
      this.document.parttypes
        .find((pt) => pt.type === "Responsibility")
        .parts.forEach((p) => {
          respSummary += `
            <tr>
              <td colspan="7">${p.text}</td>
            </tr>`;
        });
      html +=
        respSummary +
        `
              <tr style="height:60px">
                <td class="cell_p4 cell_heading" style="background-color: #DBE5F1" colspan="7">What are the key challenges someone may face in this role in the first 3-6 months?</td>
                <td colspan="3">&nbsp;</td>
              </tr>`;
      if (noResps < 6) {
        html += `
            <tr style="height: 10px">
              <td class="cell_across" colspan="10" style="background-color: #366091; color: white;">
                <div class="section_heading">FURTHER NOTES/INFORMATION</div>
              </td>
            </tr>
            <tr style="height:100px">
              <td colspan="10">&nbsp;</td>
            </tr>`;
      }
      html += `
          </tbody>
          </table>
          <p class="pagebreak">&nbsp;</p>
          <table style="width: 100%">
            <tr style="height: 14px">
              <td class="cell_across" colspan="6" style="background-color: #366091; color: white;">
                <div class="section_heading">ABOUT THE TEAM</div>
              </td>
            </tr>
            <tr style="height:70px">
              <td colspan="6">${
                this.document.parttypes.find(
                  (pt) => pt.type === "About the Team"
                ).parts[0].text
              }</td>
            </tr>
            <tr style="height:30px">
              <td class="cell_p4 cell_heading" style="background-color: #DBE5F1" colspan="3">Who are the key stakeholders / team / department this person will work with and in what capacity?</td>
              <td colspan="3">&nbsp;</td>
            </tr>
            <tr style="height:30px">
              <td class="cell_p4 cell_heading" style="background-color: #DBE5F1" colspan="3">Department/Team Structure</td>
              <td colspan="3">&nbsp;</td>
            </tr>
            <tr style="height: 10px">
              <td class="cell_across" colspan="6" style="background-color: #366091; color: white;">
                <div class="section_heading">POSITION REQUIREMENTS</div>
              </td>
            </tr>
            <tr>
              <td class="cell_p4 cell_heading" colspan="3" style="background-color: #DBE5F1">Essential Requirements</td>
              <td class="cell_p4 cell_heading" colspan="3" style="background-color: #DBE5F1">Telephone/Pre-Screening Criteria / Questions</td>
            </tr>`;
      let reqSummary = "";
      let noReqs = this.document.parttypes.find(
        (pt) => pt.type === "Requirement"
      ).parts.length;
      this.document.parttypes
        .find((pt) => pt.type === "Requirement")
        .parts.filter((p) => p.is_essential)
        .forEach((p) => {
          reqSummary += `
        <tr>
          <td colspan="3">${p.text}</td>
          <td colspan="3">&nbsp;</td>
        </tr>`;
        });
      html += reqSummary;
      if (noReqs < 6) {
        html += `
            <tr style="height: 10px">
              <td class="cell_across" colspan="10" style="background-color: #366091; color: white;">
                <div class="section_heading">FURTHER NOTES/INFORMATION</div>
              </td>
            </tr>
            <tr style="height:150px">
              <td colspan="10">&nbsp;</td>
            </tr>`;
      }
      html += `
          </table>
          <p class="pagebreak">&nbsp;</p>
          <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: #366091; color: white;">
            <div class="section_heading">INSIGHT AND MARKET INTELLIGENCE</div>
          </td>
        </tr>
        <tr style="height:60px">
          <td class="cell_p4 cell_heading" style="background-color: #DBE5F1">
            Are there alternative job titles / common in competitor market? TA to make suggestions
          </td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height:50px">
          <td class="cell_p4 cell_heading" style="background-color: #DBE5F1">
            Any movements in the market that you are aware of? Any opportunity? 
          </td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: #366091; color: white;">
            <div class="section_heading">SOURCING & ATTRACTION STRATEGY</div>
          </td>
        </tr>
        <tr style="height:70px">
          <td class="cell_p4 cell_heading" style="background-color: #DBE5F1">Please provide any details of target competitors/ local communities/groups</td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height:70px">
          <td class="cell_p4 cell_heading" style="background-color: #DBE5F1">Have you asked for referrals from relevant team/networks? Discuss LinkedIn, sharing posts etc.</td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: #366091; color: white;">
            <div class="section_heading">ANY OTHER COMMENTS/NOTES</div>
          </td>
        </tr>
        <tr style="height:150px">
          <td colspan="2">&nbsp;</td>
        </tr>
          </table>
          <p class="pagebreak">&nbsp;</p>
          <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: #366091; color: white;">
            <div class="section_heading">INTERVIEW & ASSESSMENT</div>
          </td>
        </tr>
        <tr style="height:150px">
          <td class="cell_p4 cell_heading" style="background-color: #DBE5F1">
          How many interviews will there be and what format will these be in (phone, face-to-face, presentation)?<br>
          How long will each interview last?<br>
          Have you already considered who you would like in the interview process and what’s the diversity of the panel?<br>
          Do they know they will be involved?<br>
        `;
      if (
        this.$loginState.user.client === "Akamai" ||
        this.$loginState.user.client === "staging"
      ) {
        html += `<b>If appropriate</b> - please discuss if HackerRank should support this recruitment (this link should be sent as pre work)
                  https://docs.google.com/presentation/d/15LPYM5ZelFaN1DV1JXZziyD62iW6VENdkBXNRIfL6lc/edit?usp=sharing`;
      }
      html += `</td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: #366091; color: white;">
            <div class="section_heading">ANY OTHER COMMENTS/NOTES</div>
          </td>
        </tr>
        <tr style="height:200px">
          <td colspan="2">&nbsp;</td>
        </tr>
      </body></html>`;
      html = html.replace(/’/g, "'");
      html = html.replace(/•/g, "");

      html2docx(html, {
        orientation: "landscape",
        margins: { top: 500, bottom: 500, left: 500, right: 500 },
      }).then(data => {
        utils.downloadFile(
          data,
          this.document.system_number + " Intake Flow.docx",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      });
      this.exportMenu = false; // close export menu
    },
    saveDocHeader(status) {
      this.isLoading = true;
      this.document.doc_status = status;
      this.setDocStatus();
      let data = {
        system_number: this.document.system_number,
        doc_id: this.document.doc_id,
        doc_status: status,
        doc_status_text: this.docStatus.text,
        recruiter: this.document.recruiter,
        contact: this.document.contact,
        approval_requested_date: this.document.approval_requested_date,
        start_date: this.document.start_date,
        quality: this.document.quality,
        hierarchies: this.document.hierarchies,
      };

      axios
        .post("document/saveDocumentHeader/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.docHierarchies = this.document.hierarchies;
            this.$emit("saved", resp.data);
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          this.isLoading = false;
          this.show = false;
          this.document = null;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    doStateAction(action) {
      if (!this.document) return;
      let dc = this.dataCapture;
      let request = {
        doc_id: this.document.doc_id,
        action_available_id: action.action_available_id,
        lifecycle_role_id: action.lifecycle_role_id,
        document_role_id: action.document_role_id,
        preProcessResponse: null,
      };
      axios
        .post("document/processdocumentaction/", request)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            if (resp.data.Data.dataCapture) {
              dc.def = resp.data.Data.dataCapture;
              dc.action = action;
              dc.value = null;
              dc.show = true;
              return;
            } else {
              dc.def = null;
              dc.action = null;
              dc.value = null;
              dc.show = false;
              if (resp.data.Data.documents && resp.data.Data.documents.length) {
                this.$emit("saved", resp.data);
              }
          this.response = resp.data;
            }
          } else {
            alert(resp.data.Message);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.dragHandle {
  color: rgba(56, 106, 163, 0.801) !important;
  cursor: grab;
  margin-left: -40px;
  /* margin-left: 30%; */
}
.status-chip {
  font-size: 0.7rem;
  margin-left: -8px;
  height: 25px;
}
.drag-handle,
.number-part {
  max-width: 20px;
  padding: 6px 0;
}
.data-content {
  margin-top: -5px;
  padding-top: 0;
}
.data-content-2 {
  margin-top: -7px;
  min-height: auto !important;
  max-height: auto;
  padding-bottom: 0;
}
.data-label {
  font-size: 0.6rem;
  padding: 0 5px 0 16px;
  color: darkgray;
}
.data-value {
  font-size: 0.9rem;
}
.data-value-2 {
  font-size: 0.8rem;
  font-style: italic;
  padding-bottom: 10px;
}
.editIcon {
  position: relative;
  left: 870px;
  bottom: 14px;
  font-size: 1.1rem;
  color: lightgrey;
}
.editIcon2 {
  position: relative;
  left: 824px;
  bottom: 18px;
  font-size: 1.1rem;
  color: lightgrey;
}
.addIcon {
  position: relative;
  left: 30px;
  top: 15px;
  font-size: 1.3rem;
  color: lightgray !important;
}
.workflowIcon {
  position: relative;
  left: -68px;
  bottom: 74px;
  font-size: 1.7rem;
  color: lightgrey;
}
.workflowIcon:hover {
  color: grey;
  text-shadow: 3px 3px 2px #9ecaed;
  cursor: pointer;
}
.notesIcon {
  /* position: relative; */
  left: 111.5%;
  z-index: 202;
  bottom: 18px;
  font-size: 1.1rem !important;
  color: rgba(56, 106, 163, 0.801) !important;
}
.notesIcon2 {
  /* position: relative; */
  z-index: 202;
  left: 121%;
  bottom: 18px;
  font-size: 1.1rem !important;
  color: rgba(56, 106, 163, 0.801) !important;
}
.notesIcon3 {
  /* position: relative; */
  z-index: 202;
  left: 136%;
  bottom: 18px;
  font-size: 1.1rem !important;
  color: rgba(56, 106, 163, 0.801) !important;
}
.pdfIcon {
  position: relative;
  left: 55px;
  top: 11px;
  height: 25px;
}
.addIcon:hover {
  color: black !important;
  box-shadow: none !important;
  border: none !important;
}
.notesIcon:hover,
.notesIcon2:hover,
.notesIcon3:hover {
  color: black !important;
}
/* .errorComment {
  font-style: italic;
  font-size: 0.75rem;
  position: relative;
  left: 169px;
  min-width: 150px;
}
.errorComment2 {
  font-style: italic;
  font-size: 0.75rem;
  position: relative;
  left: 122px;
  min-width: 105px;
} */
.CallToAction,
.LlamadaAcción {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  color: rgb(73, 80, 87);
}
.AdvertJobTitle,
.TítulodeTrabajo,
.JobTitle {
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  margin-bottom: 5px;
  color: rgb(73, 80, 87);
}
.DocumentHdr {
  display: flex;
  justify-content: center;
  font-style: italic;
  font-size: 1.5rem;
  color: #9e9e9e;
  max-height: 5px;
}
.ContentTitle {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  margin-bottom: -20px;
  color: rgb(0, 0, 0);
}
.GutterLabel {
  color: #ce8705ba;
  padding-left: 5px;
  padding-right: 5px;
}
.AboutTeamHdr,
.JobUSPHdr,
.JobPurposeHdr,
.ReqHdr,
.RespHdr,
.FlexibleWork,
.WithUsHdr,
.ForYouHdr,
.AboutUsHdr,
.JoinUsHdr,
.ClosingCopyHdr,
.AboutYouHdr,
.PreparingHdr,
.ConductingHdr,
.ScoringHdr,
.AvoidingHdr {
  font-weight: 700;
  font-size: 1.1rem;
  padding-bottom: 0;
  color: rgb(73, 80, 87);
}
.WorkingPattern {
  line-height: 18px;
}
.Responsibility,
.Requirement,
.Activity,
.Indicator {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}
.AdvertJobTitleGutter,
.TítulodeTrabajoGutter,
.JobTitleGutter,
.CallToActionGutter,
.LlamadaAcciónGutter,
.AbouttheTeamGutter,
.AboutTeamGutter,
.JobUSPGutter,
.JobPurposeGutter,
.JobPurposeHdrGutter,
.ResponsibilityGutter,
.RequirementGutter,
.ActivityGutter,
.IndicatorGutter,
.FlexibleWorkGutter,
.WorkingPatternGutter,
.AboutYouGutter {
  color: #ce8705ba;
  width: 0px;
}
.JobUSPHdrRow,
.JobPurposeHdrRow,
.RespIntroRow,
.ReqIntroRow,
.ResponsibilityRow,
.RequirementRow,
.ActivityRow,
.IndicatorRow,
.FlexibleWorkRow,
.FlexIntroRow,
.WorkingPatternRow,
.WithUsRow,
.ForYouRow,
.AboutUsRow,
.JoinUsRow,
.ListItemRow,
.ClosingCopyRow,
.ReqHdrRow,
.RespHdrRow,
.AbouttheTeamRow,
.AboutTeamRow,
.JobUSPRow,
.RequirementIntroRow,
.AboutYouRow {
  margin-top: -10px;
}
.CallToActionRow,
.LlamadaAcciónRow {
  margin-top: -15px;
}
.AdvertJobTitleRow,
.TítulodeTrabajoRow,
.JobTitleRow {
  margin-top: 10px;
}
.JobUSPHdrGutter,
.JobPurposeHdrGutter,
.RespIntroGutter,
.ReqIntroGutter,
.AboutTeamHdrGutter,
.ReqHdrGutter,
.RespHdrGutter,
.FlexIntroGutter,
.WithUsHdrGutter,
.ForYouHdrGutter,
.AboutUsHdrGutter,
.JoinUsHdrGutter,
.WithUsGutter,
.JoinUsGutter,
.AboutUsGutter,
.GradeGutter,
.ForYouGutter,
.ClosingCopyHdrGutter,
.ClosingCopyGutter,
.RequirementIntroGutter,
.ContentTitleGutter,
.AboutYouHdr,
.DocumentHdrGutter,
.InterviewHdrGutter,
.PreparingHdrGutter,
.PreparingTextGutter {
  display: none !important;
}
.JobUSPHdrError,
.JobPurposeHdrError,
.RespIntroError,
.ReqIntroError,
.AboutTeamHdrError,
.ReqHdrError,
.RespHdrError,
.FlexIntroError,
.WorkingPatternError,
.AboutUsError,
.FlexibleWorkError,
.WithUsHdrError,
.WithUsError,
.ForYouHdrError,
.ForYouError,
.AboutUsHdrError,
.JoinUsHdrError,
.ClosingCopyHdrError,
.ClosingCopyError,
.RequirementIntroError,
.JobTitleError,
.ContentTitleError,
.AboutYouHdrError,
.DocumentHdrError,
.PreparingListError,
.PreparingTextError {
  display: none !important;
}
.headerCell {
  padding: 10px 10px 10px 0;
}
.headerCellSmall {
  padding: 0 10px;
  font-size: 0.75rem;
  color: #946206da;
}
@media print {
  .AdvertJobTitle,
  .TítulodeTrabajo {
    font-size: 1.5rem !important;
  }
  .CallToAction,
  .LlamadaAcción {
    margin-top: -25px;
    font-size: 0.8rem !important;
  }
  .JobUSPHdrRow,
  .RespIntroRow,
  .WithUsRow,
  .WithUsHdr,
  .FlexIntro,
  .WorkingPattern,
  .ForYouHdr,
  .ForYouRow,
  .AboutUsHdr,
  .AboutUsRow {
    margin-top: -30px;
  }
  .list-group {
    margin-top: -30px;
  }
}
.editArea,
.editAreaReadOnly {
  margin-left: 50px;
  white-space: pre-wrap;
  border: solid white 1px;
}
.editArea:hover {
  outline: none;
  box-shadow: 0 0 10px #9ecaed;
  border: solid #9ecaed 1px;
  cursor: pointer;
  border-radius: 5px;
}
.editAreaBlank {
  outline: none;
  color: #ed8a8a !important;
  box-shadow: 0 0 5px #ed8a8a30;
  border: solid #ed8a8a30 1px;
  cursor: pointer;
  border-radius: 5px;
  font-style: italic;
  font-weight: 300 !important;
  margin-left: 50px;
}
.editAreaListBlank {
  display: list-item;
  outline: none;
  color: #ed8a8a !important;
  box-shadow: 0 0 5px #ed8a8a30;
  border: solid #ed8a8a30 1px;
  cursor: pointer;
  border-radius: 5px;
  font-style: italic;
  font-weight: 300 !important;
}
.editAreaBlank .CallToAction,
.editAreaBlank .LlamadaAcción,
.editAreaBlank .AboutTeamHdr {
  outline: none;
  color: #ed8a8a !important;
  font-style: italic;
  font-weight: 300 !important;
}
.parentList {
  border: solid 1px lightgrey !important;
  border-radius: 4px !important;
  margin-top: 5px !important;
  margin-right: 3px !important;
}
.editAreaList,
.editAreaListReadOnly {
  display: list-item;
  margin-top: -4px;
  border: solid white 1px;
}
.editAreaList:hover {
  outline: none;
  box-shadow: 0 0 10px #9ecaed;
  border: solid #9ecaed 1px;
  cursor: pointer;
  border-radius: 5px;
}
.editAreaBlank:hover,
.editAreaListBlank:hover {
  outline: none;
  box-shadow: 0 0 10px #9ecaed;
  border: solid #9ecaed 1px;
  cursor: pointer;
  border-radius: 5px;
}
.v-input--selection-controls {
  margin-top: 3px;
}
label,
.v-label,
.v-input--selection-controls .v-input__slot > .v-label {
  font-size: 10px;
}
.v-messages {
  max-height: 1px;
}
.desirableText {
  color: #aaa;
  font-weight: 300;
  font-style: italic;
}
.essential {
  position: relative;
  left: 65px;
  top: -5px;
  max-height: 20px;
  font-style: italic;
}
.wayBack {
  height: 0;
  position: relative !important;
  top: 16px;
  left: 1000px;
  z-index: 200;
  color: lightgray !important;
}
.wayBackHidden {
  display: none;
}
@keyframes hideshow {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.wayBackDisplayed {
  position: relative;
  right: -49px;
  top: -8px;
  animation: hideshow 2s ease;
  margin-bottom: -12px;
}
.v-list-item__action {
  margin: 1px 0;
}
.subPartGutter {
  position: absolute;
  left: -80px;
  color: #ce8705ba;
}
.subPartSpan {
  position: absolute;
  left: 73px;
}
.subPartText {
  margin-left: -70px;
}
div .subPartRow:not(first-child) {
  margin-left: -4px;
}
</style>
<style>
label[for="linkedDoc"] {
  font-size: 15px;
}
.desirableText {
  color: #aaa;
  font-weight: 300;
  font-style: italic;
}
.desirableBox div div div i {
  color: #f55 !important;
}
.essential label {
  color: rgba(128, 128, 128, 0.637) !important;
  font-size: 12px;
  margin-left: -7px;
}
#sd-menu {
  top: 0px !important;
  left: 29px;
}
#sd-menu button {
  top: -1px;
  width: 30px;
  height: 30px;
}
#sd-sub-menu {
  margin-top: -25px;
  width: 24px !important;
  height: 24px !important;
  margin-bottom: 0px;
  background-color: #ffffff5e;
}
#sd-menu button i {
  font-size: 18px;
}
#sd-sub-menu-2 span i {
  font-size: 14px;
  left: 79px;
}
#sd-sub-menu-2 {
  margin-top: -25px;
  width: 24px !important;
  height: 24px !important;
  margin-bottom: 0px;
  background-color: #ffffff5e;
}
#sd-sub-menu-2 span i {
  font-size: 14px;
  left: 93px;
}
#sd-pdf {
  left: 51px !important;
  top: -1px;
  font-size: 17px !important;
}
#sd-word {
  left: 57px !important;
  top: -1px;
  font-size: 17px !important;
}
#sd-word-full {
  left: 78px !important;
  top: -1px;
  font-size: 17px !important;
}
.speed-dial__list button {
  width: 22px !important;
  height: 22px !important;
}
.speed-dial__list button div i {
  font-size: 10px;
}
.speed-dial__list button div span {
  opacity: 1 !important;
  color: black;
  transform: none !important;
  position: relative !important;
  left: 97px !important;
  text-align: left !important;
}
#pdfsection > p {
  padding-top: 15px;
  margin-bottom: 0px;
}
#pdfsection li {
  padding-top: 5px;
  margin-left: 10px;
  list-style-position: outside;
  padding-left: 10px;
}
#pdfsection > h2,
#pdfsection > h3,
#pdfsection > h4 {
  padding-top: 25px;
}
button.v-expansion-panel-header {
  padding: 1px;
}
span.v-expansion-panel-header {
  font-size: 14px;
}
@keyframes slidein {
  0% {
    transform: translate(0, -400px);
  }
  100% {
    transform: translate(0, 100px);
  }
}
.highlightSection {
  /* font-weight: 900; */
  background-color: lightgray;
  border: solid grey 2px;
}
li.highlightSection {
  border: none;
}
button.v-expansion-panel-header {
  min-height: 36px;
}
button > .v-expansion-panel-header--active,
button > .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 36px;
}
div.v-expansion-panel-content__wrap {
  padding: 0 14px 6px;
}
button.v-expansion-panel--active > .v-expansion-panel-header {
  max-height: 48px;
}
.scoreCard {
  border-radius: 25px !important;
  box-shadow: rgb(255, 255, 255) -2px -2px 4px 0px,
    rgba(0, 0, 0, 0.25) 8px 8px 8px 0px !important;
  display: block;
  flex: 1 1 0%;
  background-color: #ffffff26 !important;
  min-height: 500px;
  /* max-width: 350px; */
  margin-bottom: 25px;
  overflow: hidden;
  padding: 20px;
  z-index: 200;
  border: solid 2px #ffffffa8 !important;
}
.scoreTitle {
  font-size: 16px !important;
  margin-top: -14px !important;
  color: rgb(71, 71, 71) !important;
  padding: 0 !important;
}
.sectionScore {
  background: #f2f3f7;
  color: #222;
  transition: all 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.9),
    2px 2px 2px rgba(245, 245, 245, 0.9), 1px 1px 3px rgba(0, 0, 0, 0.07) !important;
}
.sectionScore:first-child {
  background: #f2f3f7;
  color: #222;
  transition: all 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: -2px -2px 2px rgba(245, 245, 245, 0.9),
    2px 2px 2px rgba(245, 245, 245, 0.9), 1px 1px 3px rgba(0, 0, 0, 0.07) !important;
}
.sectionText {
  font-size: 14px;
  padding: 11px;
}
.sectionItem {
  border: solid 1px white;
  padding: 0px 1px;
}
.sectionItem:hover {
  border: solid 1px rgb(187, 186, 186);
  cursor: grabbing;
}
.b-btn .sectionItem {
  padding: 0px 1px;
}
button.v-btn:not(.v-btn--round).v-size--x-small {
  padding: 0px 1px;
  min-width: 26px;
}
.histText {
  font-size: 12px;
}
.inclusionHeading {
  padding-left: 5px;
  word-break: break-word;
}
@media screen and (max-width: 1220px) {
  .inclusionHeading {
    max-width: 60%;
    font-size: 14px;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1220px) {
  .inclusionHeading {
    max-width: 65%;
    font-size: 14px;
  }
}
@media screen and (max-width: 1640px) and (min-width: 1400px) {
  .inclusionHeading {
    max-width: 70%;
    font-size: 14px;
  }
}
</style>