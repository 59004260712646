<template>
<div  @mouseover="hoverElement = true;" @mouseout="hoverElement = false;" style="width:100%">
  <div class="inputHolder" :style="cssVars">  
    <div 
      @focus="emitFocus" 
      @blur="lostFocus"
      :data-placeholder="getPlaceholder()" 
      :class="[getCssStyles(metadata.layout), active ? '' : 'disabled']" 
      :style="{'white-space': 'pre-wrap', 'width': disable ? '100%' : '100%', 'max-width': disable ? '100%' : '100%', 'padding-right': inputRight}"
      ref="textInput" 
      :contenteditable="focusElement" 
      @input="onInput" 
      @keydown="checkInput"
      @contextmenu.prevent="hoverInsights" 
      v-html="highlighted"
      @keydown.esc.prevent.stop="cancelEdit"
    >
    </div>
    <div v-if="(display !== 'sidebyside' && display !== 'sidebysidefull') && metadata.uses_essential_flag" style="width: 220px; padding-right: 90px; height: 32px; position: relative;">
      <v-select
          dense
          label="height=20" min-height="20"
          single-line
          item-text="text"
          item-value="value"
          v-model="is_essential"
          :items="essentialFlags"
          :disabled="disable"
      >
          <template slot="label">
            <span style="font-size:11px;">Essential?</span>
          </template>
          <template slot="selection" slot-scope="data">
            <span style="font-size:11px;">{{ data.item.text }}</span>
          </template>  
          <template slot="item" slot-scope="data">
            <span style="font-size:11px;">{{ data.item.text }}</span>
          </template> 
      </v-select>    
    </div>
    <div v-if="display !== 'sidebyside' && display !== 'sidebysidefull'" :class="[getActionsClass()]" justify-center align-content-center align="right" :style="cssVars">
      <v-icon v-if="nlpCheckInProgress" color="amber">mdi-loading mdi-spin</v-icon>
      <v-icon v-if="showActions" title="Clear" color="grey lighten-2" @mousedown="clearText">mdi-close-thick</v-icon>
      <v-icon v-if="showActions" title="Save" color="grey lighten-2" @mousedown="confirmEdit">mdi-check-bold</v-icon>
    </div>
  </div>
  <div v-if="showActions" :class="[getWordCountHolderClass()]">
    W: {{wordCount}} &nbsp; C: {{charCount}}
  </div>  
</div>
</template>

<script>
import utils from "@/common/utils.js";
import nlpUtils from "@/common/nlpUtils.js";
import viewUtils from "../document/view/utils.js";

export default {
  name: 'TextInput',
  props: {
    value: Object,
    metadata: Object,
    wordChecks: Array,
    edit: Boolean,
    spellCheck: Boolean,
    textClass: String,
    displayMode: String,
    doubleClickEdit: Boolean,
    disable: Boolean,
    active: Boolean,
    displayActions: Boolean,
    controlOffset: String,
    spellCheckLanguageCode: String,
    multiRow: Boolean,
    spellCheckOnLoad: Boolean
  },  
  data: function() {
    return {
      enablePosParsing: true,
      text: "",
      previousValue: "",
      itemClass: "comparePartText",
      highlighted: "",
      caretPosition: 0,
      caretCoords: {
        x:0,
        y:0
      },
      textItem: null,
      textStatus: null,
      debounceTimeout: null,
      debounceTime: 2500,
      hoverTimeout: null,
      hoverDebounce: 200,
      showLanguageMenu: true,
      spellCheckDebounce: 1500,
      spellCheckTimeout: null,
      nlpCheckInProgress: false,
      spellCheckData: {},
      highlightingIssues: {},
      display: this.displayMode,
      doubleClickHandle: this.doubleClickEdit ?? true,
      hasFocus:false,
      disabled: this.disable ?? false,
      hoverElement: false,
      essentialFlags: [
        {
          value: true,
          text: "Essential",
        },
        {
          value: false,
          text: "Non Essential",
        },
      ],
      spellCheckLanguage: "en"
    }
  },
  components: {
  },
  watch: {
    spellCheckLanguageCode(val){
      this.spellCheckLanguage = val;
    },
    value(newVal) {
      this.init(newVal);
    },
    textClass(val){
      if(val){
        this.itemClass = val;
      }
    },    
    displayMode(val){
      this.display = val;
    },
    doubleClickEdit(val){
      this.doubleClickHandle = val;
    }
  },  
  mounted() {

  },
  created() {
    if(this.value){
      this.init(this.value);
    }
  },
  computed: {
    charCount(){
      return [...this.text ?? ""].length ?? 0;
    },
    wordCount(){
      return this.text?.split(" ").filter(x => x !== "").length ?? 0;
    },
    focusElement(){
      if(this.disable){
        return false;
      }
      if(!this.doubleClickHandle){
        return true;
      }
      if(this.doubleClickHandle && this.edit){
        return true;
      }
      return false;
    },
    showActions(){
      if(!this.doubleClickHandle && this.hasFocus){
        return true;
      }
      if(this.doubleClickHandle && this.edit && this.hasFocus){
        return true;
      }
      return false;
    },
    textControlOffset(){
      return this.controlOffset ?? "0px";
    },
    inputRight(){
      let styles = this.getCssStyles(this.metadata.layout);
      if(styles.includes("central")){
        return "0px";
      }
      return '15px';
    },
    contentRight(){
      let styles = this.getCssStyles(this.metadata.layout);
      if(styles.includes("central") || styles.includes("text-right") || this.disabled || this.display.startsWith("sidebyside") ){
        return "0px";
      }
      return this.metadata.uses_essential_flag ? '0px' : '80px';
    },
    rightOffset(){
      let styles = this.getCssStyles(this.metadata.layout);
      if(styles.includes("central")){
        return "150px";
      } else {
        return "0px";
      }
    },
    cssVars () {
        return {
            '--control-offset': this.textControlOffset,
            '--content-right': this.contentRight,
            '--holder-right': this.rightOffset,
        }
    },
    is_essential: {
      get: function() {
        return this.value.is_essential;
      },
      set: function(newValue) {
        this.$emit("updateEssential", newValue);
      }
    },  
  },
  methods: {
    handleBackspace(ev){
      //end of string + ends are new lines
      let newContent = ev.target.textContent.slice(0, -2);
      ev.target.innerHTML = newContent;
      this.textStatus.text = newContent;
      this.text = newContent;
      this.caretPos = newContent.length;
      this.highlightLanguage(ev.target);
      this.$nextTick(() => {
        if (this.caretPos && this.hasFocus) this.setCaretPosition(ev.target, this.caretPos);
      }) 
    },    
    getPlaceholder(){
      if(this.metadata.attributes.length > 0){
        let attrId = this.metadata.attributes.find(x => x.tpa_id === this.value.tpa_id);
        return attrId ? attrId.placeholder : this.metadata.placeholder;
      } else {
        return this.metadata.placeholder;
      }
    },
    init(newVal){
      if(this.metadata && (newVal.text !== this.text && !this.edit) || newVal.forceRefresh || newVal.doc_part_id === 0){
        this.textItem = newVal;          
        this.text = newVal.text;
        this.previousValue = newVal.text;
        this.highlighted = newVal.highlightedText;
        this.spellCheckLanguage = this.spellCheckLanguageCode;
        if(this.textClass !== ''){
          this.itemClass = this.textClass;
        }        
        this.initText();
      }
    },
    emitFocus(){
      this.hasFocus = true;
      this.$emit("focus");
    },
    lostFocus(save = true){
      if(save){
        this.confirmEdit();
      }
      this.hasFocus = false;
      this.$emit("blur");
    },
    getWordCountHolderClass(){
      if(this.display.startsWith("sidebyside")){
        return "wordCountHolder";
      } else {
        return "docWordCountHolder";
      }
    },
    getActionsClass(){
      if(this.display.startsWith("sidebyside")){
        return "comparePartEditMenu";
      } else {
        return "docPartEditMenu";
      }
    },
    getCssStyles(layout){
      if(this.display === "sidebyside"){
        return "comparePartText";
      }
      else if(this.display === "sidebysidefull"){
        return "SBSFullView";
      } else {
        return viewUtils.mapCssStyles(layout, this.value.css_class_name);
      }
    },
    startEdit(ev){
      if(this.edit){
        //ev.target.focus();
        document.execCommand('selectAll', false, null);
        document.getSelection().collapseToEnd();
        this.getCaretCharOffset(ev.target, ev);
      }

    },
    emit(event, data){
      this.$emit(event, data);
    },
    closeMenu(){
      this.$nextTick(() => {
        this.showLanguageMenu = false;
      })
    },  
    hoverEvent(){

    },  
    getWordCheckMatch(word, title){
      let nodeMatch = this.wordChecks.find((x) => x.word === word && x.short_description === title);
      if(!nodeMatch){
        nodeMatch = this.wordChecks.find((x) => x.short_description === title);
      }

      return nodeMatch;
    },
    rehighlight(){

      let languageOpts = nlpUtils.getLanguageTools(this.$loginState.user);
      let highlightInit = this.runHighlighting(this.text);
      //his.highlighted = highlightInit.highlightedText;      
      let newText = utils.applyLanguageHighlights(
        highlightInit.highlightedText,
        [this.spellCheckData],
        this.value.tmpl_part_id ?? this.value.tpa_id,
        this.value.doc_part_id ?? this.value.dpa_id,
        languageOpts.spellCheck.options.dictionary
      );
      this.textStatus.highlightedText = newText;
      this.text = this.textStatus.text;
      this.highlighted = this.textStatus.highlightedText;
    },
    hoverInsights(ev) {

      if(this.disable){
        return;
      }

      if(this.edit){
        this.caretPos = this.getCaretCharOffset(ev.target, ev);
      }

      if(ev.target.localName === 'mark') {
        this.caretCoords = { 
          x: ev.clientX,
          y: ev.clientY
        }   
        let issues = [];
        let word = ev.target.textContent;
        let wordLC = word.toLowerCase();
        let correctionType = ev.target.getAttribute('data-correction-type');
        let node = ev.target;

        while(node.localName === "mark"){
          let nodeType = node.getAttribute('data-correction-type');
          let nodeLangRef = node.getAttribute('data-language-reference');
          let idx = Number(ev.target.getAttribute('data-idx') ?? 1);
          let nodeMatch = this.wordChecks.find((x) => x.word === wordLC && x.short_description === node.title);
          if(!nodeMatch){
            nodeMatch = this.wordChecks.find((x) => x.short_description === node.title);
          }
          let sourceDetails = null;
          if(nodeLangRef){
            let lang = nodeLangRef.split("_");
            sourceDetails = {
              tmpl_part_id: Number(lang[0]),
              doc_part_id: Number(lang[1]),
              idx: Number(lang[2]),
              dpa_id: this.value.dpa_id ?? null,
              tpa_id: this.value.tpa_id ?? null
            }
          } else {
            sourceDetails = {
              tmpl_part_id: this.value.tmpl_part_id ?? this.metadata.tmpl_part_id,
              doc_part_id: this.value.doc_part_id ?? this.value.dp_id,
              idx: idx,
              dpa_id: this.value.dpa_id ?? null,
              tpa_id: this.value.tpa_id ?? null
            }
          }

          let spellCheckData = null;
          if(nodeType === 'spellcheck'){
            let dp_data = this.spellCheckData.parts.find(x => x.doc_part_id === this.value.doc_part_id);
            if(dp_data){
              spellCheckData = dp_data.issues.find(x => x.word.toLowerCase() === word.toLowerCase());
            }
          }


          let obj = {
            type: node.title === '' ? correctionType : node.title,
            spellCheckData: spellCheckData,
            status: this.textStatus, 
            match: nodeType === 'spellcheck' ? null : nodeMatch,
            word: wordLC,
            source: sourceDetails,
            idx: idx
          };

          issues.push(obj);
          node = node.parentNode;
        }

        this.emit("languageIssue", { element: ev.target, word:wordLC, coords: this.caretCoords, status: this.textStatus, spellCheckData: this.spellCheckData ?? null, issueTypes: issues }) 

      } 
    },    
    autoSpellCheck(){
      this.nlpCheckInProgress = true;
      nlpUtils
        .doSpellGramCheck(this.text, this.spellCheckLanguage)
        .then((resp) => {
          this.nlpCheckInProgress = false;
          if (resp == undefined) return;

          let element = this.$refs["textInput"];
          this.applyLanguageCorrection(resp.data.Data, element);
        });
    },
    initText(){
      if(!this.metadata?.exclusions?.spell_check && this.spellCheck && this.spellCheckOnLoad && !this.disabled){
        this.autoSpellCheck();
      }
      let highlightInit = this.runHighlighting(this.text);
      //this.highlighted = utils.sanitize(highlightInit.highlightedText);
      let valid = highlightInit.errors.length === 0 && highlightInit.warnings.length === 0;
      this.highlighted = valid && this.disabled ? highlightInit.text : highlightInit.highlightedText;
    },
    clearText() {
      this.previousValue = this.text;
      this.textStatus = this.runHighlighting("");
      this.text = "";
      this.highlighted = "";
      let targetElement = this.$refs["textInput"];
      targetElement.innerHTML = "";
      if(this.text !== this.previousValue){
        this.$emit("confirm", this.textStatus);
        this.$refs["textInput"].blur(false);
      }
    },
    cancelEdit(){
      this.textStatus = this.runHighlighting(this.previousValue);
      this.text = this.textStatus.text;
      this.highlighted = this.textStatus.highlightedText;
      let targetElement = this.$refs["textInput"];
      targetElement.innerHTML = this.textStatus.highlightedText;
    },
    confirmEdit(){
      if(this.text !== this.previousValue){
        this.previousValue = this.text;
        this.$emit("confirm", this.textStatus);
        this.$refs["textInput"].blur(false);
      }
    },
    forceFocus(){
      this.$refs["textInput"].focus();
    },
    triggerSpellCheckEvent(element){
      if (this.spellCheckTimeout) {
        clearTimeout(this.spellCheckTimeout);
      }

      this.spellCheckTimeout = setTimeout(() => {
        this.runSpellCheck(element);
      }, this.spellCheckDebounce);

    },
    runSpellCheck(element){
      if(!element){ return;}
      this.nlpCheckInProgress = true;
      nlpUtils
        .doSpellGramCheck(this.text, this.spellCheckLanguage)
        .then((resp) => {
          this.nlpCheckInProgress = false;
          if (resp == undefined) return;

          this.applyLanguageCorrection(resp.data.Data, element);
        });
    },   
    applyLanguageCorrection(data,element) {
      let obj = {
        tmpl_part_id: this.value.tmpl_part_id,
        parts: [
          {
            doc_part_id: this.value.doc_part_id,
            issues: data,
          },
        ],
      };

      this.spellCheckData = obj;
      let languageOpts = nlpUtils.getLanguageTools(this.$loginState.user);

      let newText = utils.applyLanguageHighlights(
        this.textStatus.highlightedText,
        [obj],
        this.value.tmpl_part_id ?? this.value.tpa_id,
        this.value.doc_part_id ?? this.value.dpa_id,
        languageOpts.spellCheck.options.dictionary
      );
      this.textStatus.highlightedText = newText;
      this.text = this.textStatus.text;
      this.highlighted = this.textStatus.highlightedText;

      if(element){
        this.$nextTick(() => {
          if (this.caretPos && this.hasFocus) this.setCaretPosition(element, this.caretPos);
        }) 
      }
    },    
    checkInput(ev){
      if(ev){
        
        this.caretPos = this.getCaretCharOffset(ev.target, ev);
        if(ev.code === "Escape" || ev.which === 27){
          this.hasFocus = false;
          this.$emit("blur");
          return;
        }
        if ((ev.code === "KeyZ" || ev.which === 90) && ev.ctrlKey){
          this.cancelEdit();
          ev.stopPropagation();
          ev.preventDefault();
          this.caretPos = this.previousValue.length-1;
          return;
        }
        if (ev.code === "Enter" || ev.which === 13) {
          ev.stopPropagation();
          ev.preventDefault();
          if(this.multiRow){
            this.confirmEdit();
            return;
          } else {
            document.execCommand('insertHTML', false, "\n");
          }
        }
        else if(ev.code === "Backspace" || ev.which === 8){
          let sub = ev.target.textContent.substring(this.caretPos-2,this.caretPos);
          if(sub.charCodeAt(0) === 10 && sub.charCodeAt(1) === 10 && this.caretPos === ev.target.textContent.length){
            ev.stopPropagation();
            ev.preventDefault();
            this.handleBackspace(ev);
          } 
        }   

     
      }
    },
    onInput(ev){
      if(ev){
        this.caretPos = this.getCaretCharOffset(ev.target, ev);
        //this.hoverInsights(ev);
        this.highlightLanguage(ev.target);
      }
    },
    replaceText(word, text){
      let replacement = `<mark class='nlpData' attr='${word.idx}'>${word.word}</mark>`;
      text = [text.slice(0, word.index), replacement, text.slice(word.index + word.word.length)].join('');
      return text;
    },
    highlightLanguage(element){
      let text = element.textContent;
      let replace = text;

      if(!this.metadata.exclusions?.spell_check && this.spellCheck){
        this.triggerSpellCheckEvent(element);
      }

      let highlighting = this.runHighlighting(replace);
      this.highlightingIssues = highlighting;
      replace = highlighting.highlightedText;

      this.text = element.textContent;
      element.innerHTML = replace;

      if (this.caretPos && this.hasFocus) this.setCaretPosition(element, this.caretPos);
    },
    runHighlighting(text){
      this.textStatus = utils.applyHighlights(text, this.wordChecks, true, false);
      return this.textStatus;
    },
    getCaretCharOffset(element, ev) {
      var caretOffset = 0;

      if (window.getSelection) {
        var range = window.getSelection().getRangeAt(0);
        var preCaretRange = range.cloneRange();

        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      } else if (document.selection && document.selection.type != "Control") {
        var textRange = document.selection.createRange();
        var preCaretTextRange = document.body.createTextRange();

        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
      }

      let preCaret = preCaretRange.toString().length;
      let offset = 0;
      if(preCaret+1 === ev.target.textContent.length && ev.target.textContent.slice(-1) === '\n'){
        offset = -1;
      }

      return caretOffset - offset;
    },    
    setCaretPosition(el, pos){
      for(var node of el.childNodes){
        if(node.nodeType == 3) {
          if(node.length >= pos){
            const range = document.createRange(), sel = window.getSelection();
            range.setStart(node,pos);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            return -1;
          } else pos -= node.length;
        } else {
          pos = this.setCaretPosition(node,pos);
          if(pos == -1) return -1;
        }
      }

      return pos;
    },
  }
}
</script>
<style scoped lang="scss">
* {
  font-family: "Manrope" !important;
}

[contenteditable]:focus {
    outline: 0px solid transparent;
}

[contenteditable]:not([data-placeholder=""]):empty::before {
  content: attr(data-placeholder);
  color: #ed8a8a;
  cursor: pointer;
  font-style: italic;
  font-weight: 300 !important; 
}

.v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: auto!important;
  display: flex!important;
  align-items: center!important;
}

.SBSFullView{
  font-size:10pt;
}

.inputHolder{
  width:100%;
  display:inline-flex;
  padding-right: var(--content-right);
}

.slimSelect{
    font-family: "Manrope" !important;
    padding-left: 5px;
    font-size:11px; 
    min-height: 32px !important;
    max-height: 32px !important;

    .v-select__slot{
      height:10px;
    }
    
  .div{
    min-height:30px !important;
    .v-input__slot {
          min-height:30px !important;
      }  
  }


}

.docPartActionsMenu {
  right:2px;
  position: absolute;
  cursor: pointer;
}

.docPartEditMenu {
  right: var(--holder-right);
  //right: calc(110px + #{var(--control-offset)});
  position: absolute;
  cursor:pointer;
}

.docWordCountHolder{
  width: 100px;
  font-size: 11px;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  right: 5px;
  position: absolute;
  font-style: italic;
}

.wordCountHolder {
  width:100%; 
  font-size: 11px; 
  padding-left: 10px; 
  padding-bottom: 5px;
}

.highlight-orange {
  line-height: 1.5em;
  padding: 0px 5px;
  background: #f3bb6e;
  display: inline-block;
  border-radius: 5px;
}

.nlpData {
  background-color: transparent;
  text-decoration-style: wavy;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: rgb(47, 0, 255);
}

.flow-font {
    font-family: "Manrope", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;  
    color:#75838F;
    width:100%;
    padding-right: 50px;
}

.flow_panel_height {
    height: calc(100vh - 80px);
}

.flow-font-bold {
    font-family: "Manrope", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: bolder !important;
    line-height: 24px !important;  
    color:#3E3E3E !important;
}

.flow-font-bold-central {
  font-family: "Manrope", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    color: #3E3E3E;
    text-align:center;
}

.flow-font-header-central {
    font-family: "Manrope", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    color: #3E3E3E;
    text-align:center;
}

.flow-font-header {
    font-family: "Manrope", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    color: #3E3E3E;
}

.docPartInput{
  height: auto;
  min-height: 25px; 
}



</style>