<template>
  <div>
    <!-- <div v-if="!attribute.editing" @click="$emit('clicked')"> -->
    <!-- <div v-if="!editMode" @click="editMode = editable"> -->
    <v-row
      dense
      @keydown="keyDown"
      tabindex="0"
      @blur="lostFocus"
      ref="editContainer"
    >
      <v-col v-if="editMode" cols="4">
        <v-combobox
          v-if="editMode"
          v-model="selectedTags"
          :items="definition.values"
          item-value="tag_value_id"
          item-text="value"
          :label="'Select ' + definition.tag_type_name + '...'"
          hint="type to search"
          :search-input.sync="search"
          multiple
          dense
          outlined
          flat
          small-chips
          hide-details
          persistent-hint
          hide-selected
          :hide-no-data="!search"
          @change="checkNew"
          @keydown="keyDown"
          :filter="tagListSearch"
          @blur="lostFocus"
        >
          <!-- @keyup.enter="savePartTag(ct, item)" -->
          <template v-slot:no-data>
            <v-list-item v-if="allowAdd">
              <span class="subheading">Hit ENTER to create </span>
              <v-chip label small>{{ search }}</v-chip>
              <span class="subheading"> or ESCAPE to remove text</span>
            </v-list-item>
            <v-list-item v-else>
              <span class="subheading"
                >No matching items found. Hit ESCAPE to remove text</span
              >
            </v-list-item>
          </template>
          <template v-slot:selection="{}">
            <!-- <span>{{ selectedTags.length }}</span> -->
          </template>
          <template v-slot:item="{ item }">
            <v-chip dark label small>{{ tagListText(item) }}</v-chip>
          </template>
        </v-combobox></v-col
      >
      <v-col
        v-else-if="!selectedTags.length && !editMode"
        @click="editMode = editable"
        style="color: transparent"
        >|</v-col
      >
      <v-col v-if="selectedTags.length" @click="editMode = editable">
        <v-chip
          small
          label
          class="mr-2 px-2 mb-2"
          tabindex="0"
          v-for="(t, ti) in selectedTags"
          :key="'t_' + ti"
          :title="
            editMode && allowEditTag
              ? 'Click to edit ' + definition.tag_type_name + ' details'
              : null
          "
          @blur="lostFocus"
          @click="editMode && allowEditTag ? editTag(t) : null"
        >
          <v-icon
            v-if="editMode"
            small
            @click.stop="removeItem(t)"
            title="Click to Remove"
            class="pr-2"
            >mdi-delete</v-icon
          >
          <!-- <v-icon
            v-if="editMode && allowEditTag"
            small @click="editTag(t)"
            title="Edit Details"
            class="pr-2"
            >mdi-pencil</v-icon
          > -->
          <span class="pr-2">{{ t.value }}</span>
          <div
            v-if="showRating && !editMode"
            style="
              height: 100%;
              float: right;
              position: relative;
              border: 3px solid transparent;
            "
          >
            <div
              v-for="i in definition.maxRating"
              :key="'d' + i"
              :style="{
                height: (i * 100) / definition.maxRating + '%',
                backgroundColor: t.rating_number >= i ? 'green' : null,
                border: t.rating_number < i ? 'red solid 1px' : null,
                marginLeft: i > 1 ? '1px' : '6px',
                top: 100 - (i * 100) / definition.maxRating + '%',
              }"
              style="width: 5px; float: left; position: relative"
            ></div>
          </div>
          <v-menu v-if="showRating && editMode">
            <template v-slot:activator="{ on, attrs }">
              <div
                @click.stop
                v-bind="attrs"
                v-on="on"
                title="Click to change Rating"
                style="
                  height: 100%;
                  float: right;
                  position: relative;
                  border: 3px solid transparent;
                "
              >
                <div
                  v-for="i in definition.maxRating"
                  :key="'d' + i"
                  :style="{
                    height: (i * 100) / definition.maxRating + '%',
                    backgroundColor:
                      (t.rating_number || 0) >= i ? 'green' : null,
                    border: (t.rating_number || 0) < i ? 'red solid 1px' : null,
                    marginLeft: i > 1 ? '1px' : '6px',
                    top: 100 - (i * 100) / definition.maxRating + '%',
                  }"
                  style="width: 5px; float: left; position: relative"
                ></div>
              </div>
            </template>
            <v-list ref="menuContainer">
              <v-list-item
                v-if="t.tag_type_rating_id"
                dense
                @click="updateRating(t, null)"
                ><v-list-item-icon
                  ><v-icon>mdi-layers-remove</v-icon></v-list-item-icon
                >
                <v-list-item-title
                  >Remove Rating</v-list-item-title
                ></v-list-item
              >
              <v-list-item
                dense
                v-for="r in definition.ratings"
                :key="r.tag_type_rating_id"
                @click="updateRating(t, r)"
                :style="
                  r.tag_type_rating_id === t.tag_type_rating_id
                    ? 'backgroundColor: #e7f2f6;'
                    : null
                "
              >
                <v-list-item-title>{{
                  r.sequence + ":   " + r.name
                }}</v-list-item-title
                ><v-list-item-icon v-if="r.description">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-icon v-bind="attrs" v-on="on" class="float-right"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ r.description }}</span>
                  </v-tooltip></v-list-item-icon
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-chip> </v-col
      ><v-col cols="1" v-if="editMode && selectedTags.length > 1">
        <v-icon title="Remove all tags" @click="removeAll" class="float-right"
          >mdi-delete</v-icon
        ></v-col
      >
    </v-row>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>

    <Loading :isVisible="isLoading" />
    <TagValueEdit
      :tag="tagForEdit"
      :show="editTagTrigger"
      @saved="tagSaved"
      @cancel="cancelEditTag"
    ></TagValueEdit>
  </div>
</template>
  
  <script>
// import axios from "axios";
//import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
// import utils from "@/common/utils.js";
import { mapState } from "vuex";
import TagValueEdit from "../document/TagValueEdit.vue";

export default {
  name: "TagAttribute",
  components: {
    ResponseHandler,
    TagValueEdit,
    // draggable
  },
  props: {
    attribute: Object,
    editable: Boolean,
  },
  data: function () {
    return {
      response: null,
      tagForEdit: null,
      editTagTrigger: 0,
      allowAdd: false,
      allowEditTag: false,
      allowEditClassification: false,
      allowEditCategory: false,
      showRating: false,
      showClassification: false,
      showCategory: false,
      usesNotes: false,
      isLoading: false,
      //   utils: utils,
      definition: null,
      search: "",
      originalTags: [],
      selectedTags: [],
      editMode: false,
    };
  },
  watch: {
    editMode(val) {
      if (val && this.$refs.editContainer) {
        this.$refs.editContainer.focus();
      }
    },
  },
  computed: {
    ...mapState({
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagClassififcations: (state) => state.hierarchies.tagClassififcations,
      tagCategories: (state) => state.hierarchies.tagCategories,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
  },
  created() {
    //   this.fetchData();
    this.definition = this.tagTypes.find(
      (x) => x.tag_type_id === this.attribute.tag_type_id
    );
    this.originalTags = JSON.parse(JSON.stringify(this.attribute.tags));
    this.selectedTags = JSON.parse(JSON.stringify(this.attribute.tags));
    this.allowAdd = this.tagPermissions.addTag;
    this.allowEditTag = this.tagPermissions.editTag;
    this.allowEditClassification = this.tagPermissions.editClassification;
    this.allowEditCategory = this.tagPermissions.editCategory;
    this.showClassification = this.$loginState.user.settings.some(
      (s) => s.setting === "tagging_classification_used" && s.value !== "false"
    );
    this.showCategory = this.$loginState.user.settings.some(
      (s) => s.setting === "tagging_categorisation_used" && s.value !== "false"
    );
    this.showRating =
      this.definition.maxRating > 0 &&
      this.$loginState.user.settings.some(
        (s) => s.setting === "tagging_rating_used" && s.value !== "false"
      );
    this.usesNotes = this.$loginState.user.settings.some(
      (s) => s.setting === "tagging_notes_used" && s.value !== "false"
    );
  },
  methods: {
    editTag(tag) {
      this.tagForEdit = tag;
      this.editTagTrigger++;
    },
    cancelEditTag() {
      this.tagForEdit = null;
    },
    tagSaved(updated) {
      let tag = this.selectedTags.find(
        (x) => x.tag_value_id === updated.tag_value_id
      );
      if (tag) {
        tag.value = updated.value;
      }
      this.$refs.editContainer.focus();
      this.save(updated);
      this.tagForEdit = null;
    },
    tagListSearch(item, queryText) {
      const text = `${item.value} ${item.tag_classififcation_name || ""} ${
        item.tag_category_name || ""
      }`.toLowerCase();
      let search = (queryText || "")
        .toLowerCase()
        .replace(/,/g, " ")
        .split(" ")
        .filter((x) => x.length)
        .map((x) => x);
      return (
        !queryText ||
        queryText.length === 0 ||
        search.every((s) => text.indexOf(s) >= 0)
      );
    },
    tagListText(item) {
      return (
        item.value +
        (this.showClassification && item.tag_classififcation_name
          ? " / " + item.tag_classififcation_name
          : "") +
        (this.showCategory && item.tag_category_name
          ? " / " + item.tag_category_name
          : "")
      );
    },
    updateRating(item, rating) {
      item.tag_type_rating_id = rating?.tag_type_rating_id;
      item.rating = rating?.name;
      item.rating_number = rating?.sequence;
      this.save();
    },
    checkNew() {
      let newTag = this.selectedTags.find((t) => t.tag_value_id === undefined);
      if (!this.allowAdd && newTag) {
        this.selectedTags = this.selectedTags.filter((t) => t.tag_value_id);
        this.search = "";
        return;
      }
      this.selectedTags = this.selectedTags.map((t) => {
        if (t.tag_value_id !== undefined) {
          return t;
        } else {
          let val = t.toLowerCase();
          let existing = this.definition.values.find(
            (cv) => cv.value.toLowerCase() === val
          );
          let newVal = {
            tag_value_id: existing
              ? existing.tag_value_id
              : (this.definition.values.filter((x) => x.tag_value_id < 0)
                  .length +
                  1) *
                -1,
				value: existing ? existing.value : t,
				tag_type_id: this.attribute.tag_type_id,
          };
          if (!existing) this.definition.values.push(newVal);
          return newVal;
        }
      });
      this.save();
    },
    keyDown(event) {
      if (event.key === "Escape") {
        this.$emit("cancel");
        this.editMode = false;
      }
    },
    lostFocus(event) {
      if (
		!this.tagForEdit &&
        event.relatedTarget &&
        !this.$refs.editContainer.contains(event.relatedTarget) &&
        (!this.$refs.menuContainer?.length ||
          !this.$refs.menuContainer.some(x => x.$el.contains(event.relatedTarget)))
      ) {
        this.editMode = false;
      }
    },
    removeAll() {
      this.selectedTags.splice(0, this.selectedTags.length);
      this.save();
    },
    removeItem(tag) {
      this.selectedTags = this.selectedTags.filter(
        (x) => x.tag_value_id !== tag.tag_value_id
      );
      this.save();
    },
    save(updatedTag) {
      //compare with original
      let data = {
        tpa_id: this.attribute.tpa_id,
        dpa_id: this.attribute.dpa_id,
        deletedTags: this.attribute.tags.filter(
          (o) =>
            !this.selectedTags.some((c) => o.tag_value_id === c.tag_value_id)
        ),
        currentTags: this.selectedTags.map((a, ai) => {
          let ret = {
            tag_type_id: this.attribute.tag_type_id,
            dpa_id: this.attribute.dpa_id,
            dpa_tag_id: a.dpa_tag_id,
            tag_value_id: a.tag_value_id,
            tag_type_rating_id: a.tag_type_rating_id || null,
            notes: a.notes || null,
            value: a.value,
            sequence: ai + 1,
            change: "",
          };
          let original = this.attribute.tags.find(
            (x) => x.tag_value_id === a.tag_value_id
          );
          if (!original) ret.change = "added";
          else if (
            ret.sequence !== original.sequence ||
            ret.tag_type_rating_id !== original.tag_type_rating_id
          ) {
            ret.change = "updated";
            ret.dpa_tag_id = original.dpa_tag_id;
            // ret.tag_type_rating_id = original.tag_type_rating_id;
            ret.notes = original.notes;
          } else if (
            updatedTag?.tag_value_id === a.tag_value_id &&
            updatedTag?.value !== a.value
          ) {
            ret.change = "updated";
            ret.value = updatedTag.value;
          }
          return ret;
        }),
      };
      this.search = "";
      this.$emit("changed", data);
      this.$refs.editContainer.focus();
    },
  },
};
</script>
  <style scoped>
.v-list-item__action {
  margin: 3px 0;
}
.v-label {
  font-size: 0.9rem;
}
div.cellClick {
  width: 100%;
  cursor: pointer;
}
</style>
  