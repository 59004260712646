import axios from "axios";
export default {
  genericFlatten(list, childKey){
    if(list){
      const getGenericFlat = (data) => data.map(d => {
        return [d, ...getGenericFlat(d[childKey] ?? [])]
      }).flat();
      let flattened = getGenericFlat(list);
      return flattened;
    } else {
      return [];
    }
  },
  getFlatSections(sections){
    if(sections){
      const getFlat = (data) => data.map(d => [d, ...getFlat(d.sub_sections)]).flat();
      let flattened = getFlat(sections);
      return flattened;
    } else {
      return [];
    }
  },
  async generateExternalContent(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("externalContent/generate", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;  
  },
  async revertDeletePart(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("document/undoRemoveDocpart/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;  
  },
  async saveDisplayPermission(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("admin/docViewSaveDisplayPermission/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;
  },
  async processdocumentaction(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("document/processdocumentaction/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;  
  },
  async deletePart(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("document/removedocpart/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;  
  },
  async saveItemOrder(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("document/savedocpartsequence/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;    
  },
  async auditSectionChange(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("document/auditSectionChange/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;
  },
  async saveContentPart(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("document/saveDocContentPart/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;
  },
  async deleteDefaultView(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("admin/docViewDeleteDefaultPermission/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;
  },
  async saveDefaultView(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("admin/docViewSaveDefaultPermission/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;
  },
  async addSectionHrPerm(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("admin/docViewAddSectionHierarchyPermission/", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;
  },
  async updateDocPart(data) {
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("document/savedocpart/", data)
        .then((resp) => {
          resolve({ type: "Updated", request: data, result: resp.data });
        })
        .catch((error) => {
          reject(error);
        });
    });

    return processPromise;
  },
  async saveView(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .post("admin/docViewCreate/", data)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async deleteSectionPermission(tmpl_view_section_permission_id){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .delete("admin/deleteDocViewSectionPermission/" + tmpl_view_section_permission_id)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async deletePartPermission(tmpl_view_section_part_permission_id){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .delete("admin/deleteDocViewPartPermission/" + tmpl_view_section_part_permission_id)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async deletePartHrPermission(id){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .delete("admin/deleteDocViewPartHrPermission/" + id)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async deleteSectionHrPermission(id){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .delete("admin/deleteDocViewSectionHrPermission/" + id)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async deletePermission(tmpl_view_permissions_id){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .delete("admin/deleteDocViewPermission/" + tmpl_view_permissions_id)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async savePartPermission(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .post("admin/docViewAddPartPermission/", data)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async docViewSaveSectionParts(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .post("admin/docViewSaveSectionParts/", data)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async docViewSaveSection(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .post("admin/docViewSaveSection/", data)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async saveSectionPermission(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .post("admin/docViewAddSectionPermission/", data)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },  
  async savePermission(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .post("admin/docViewAddPermission/", data)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  async loadSettings(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .post("document/docViews/", data)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  }, 
  async loadWorkflowData(id){
    let processPromise = new Promise((resolve, reject) => {
      axios
      .get("workflow/admin/" + id)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  }, 
  mapCssStyles(tag, attr_styles) {
    let base = "doc-view-font";
    if (tag !== undefined && tag !== "") {
      if(Array.isArray(tag)){
        return this.mapDocViewFonts(tag);
      }
      let items = tag.split("|");
      if (Array.isArray(items)) {
        let allStyles = items.filter(x => x).map(t => {
          return this.getCssStyle(t)
        });
        let attrStyles = attr_styles ? attr_styles.split(" ").map(t => {
          return this.getCssStyle(t)
        }) : [];
        return `${base} ${allStyles.filter(x => x).join(" ")} ${attrStyles.filter(x => x).join(" ")}`;
      } else {
        return this.getCssStyle(items);
      }
    }
  },    
  mapDocViewFonts(styles){
    let base = "doc-view-font";
    if (styles) {
      let allStyles = styles.map(s => {
        return s.toLowerCase()
      });
      return `${base} ${allStyles.join(" ")}`;
    }    
  },
  getCssStyle(style) {
    switch (style) {
      case "BoldFlow":
        return "bold";
      case "HeaderPart":
        return "bold header";
      case "Title":
        return "bold header";
      case "CentralTitle":
        return "central header bold";
      case "CentralHeaderPart":
        return "central header";
      case "Bold":
        return "bold";
      case "text-right":
        return "text-right";
      default:
        return "";
    }
  },  

  flowStyling(style) {
      switch (style) {
        case "":
          return "flow-font";
        case "bold":
          return "flow-font-bold";
        case "header":
          return "flow-font-header";
        case "line":
          return "flow-font-line-spacer";
        default:
          return style;
      }
   
  },  
};