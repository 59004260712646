import { render, staticRenderFns } from "./cUserList.vue?vue&type=template&id=2fc4c4f2&scoped=true"
import script from "./cUserList.vue?vue&type=script&lang=js"
export * from "./cUserList.vue?vue&type=script&lang=js"
import style0 from "./cUserList.vue?vue&type=style&index=0&id=2fc4c4f2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc4c4f2",
  null
  
)

export default component.exports