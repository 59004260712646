<template>
  <div>
    <v-dialog v-model="editedTag.show" persistent width="500">
      <v-card class="pt-5" v-if="tagType">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>{{ isNew ? "Add New Tag" : "Edit Tag" }}</h3>
            </v-col>
          </v-row>
          <v-row dense v-if="showClassification">
            <v-col cols="11" v-if="allowEditClassification">
              <v-text-field
                v-if="editedTag.classificationNew"
                v-model="editedTag.classificationNewName"
                label="New Classification Name"
                required
                outlined
                append-icon="mdi-close-circle-outline"
                @click:append="editedTag.classificationNew = false"
              ></v-text-field>
              <v-select
                v-else
                v-model="editedTag.tag_classification_id"
                outlined
                label="Select Classification"
                required
                :items="tagClassififcations"
                item-value="tag_classification_id"
                item-text="name"
                append-outer-icon="mdi-plus-circle-outline"
                @click:append-outer="editedTag.classificationNew = true"
              ></v-select>
            </v-col>
            <v-col v-else
              >Classification: {{ editedTag.classificationName }}</v-col
            >
          </v-row>
          <v-row dense v-if="showCategory">
            <v-col cols="11" v-if="allowEditCategory">
              <v-text-field
                v-if="editedTag.categoryNew"
                v-model="editedTag.categoryNewName"
                label="New Category Name"
                required
                outlined
                append-icon="mdi-close-circle-outline"
                @click:append="editedTag.categoryNew = false"
              ></v-text-field>
              <v-select
                v-else
                v-model="editedTag.tag_category_id"
                outlined
                label="Select Category"
                required
                :items="tagCategories"
                item-text="name"
                item-value="tag_category_id"
                append-outer-icon="mdi-plus-circle-outline"
                @click:append-outer="editedTag.categoryNew = true"
              ></v-select>
            </v-col>
            <v-col v-else>Category: {{ editedTag.categoryName }}</v-col>
          </v-row>
          <v-row dense v-if="!isNew">
            <v-col>
              <v-alert dense type="warning"
                >NOTE: Updates to the tag value will affect any documents where
                the tag is present</v-alert
              >
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              tagType.values.some(
                (x) =>
                  x.value.toLowerCase() ===
                    editedTag.value.trim().toLowerCase() &&
                  x.tag_value_id !== editedTag.tag_value_id
              )
            "
          >
            <v-col>
              <v-alert type="error">Tage Type already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editedTag.value"
                outlined
                label="Tag Value"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-textarea
                v-model="editedTag.description"
                outlined
                label="Description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn color="blue darken-1" text @click="deleteTag">Delete</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveTag"
            :disabled="
              !editedTag.value.trim() ||
              tagType.values.some(
                (x) =>
                  x.value.toLowerCase() ===
                    editedTag.value.trim().toLowerCase() &&
                  x.tag_value_id !== editedTag.tag_value_id
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </div>
</template>
  
  <script>
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
// import utils from "@/common/utils.js";
import { mapState } from "vuex";

export default {
  name: "TagAttribute",
  components: {
    ResponseHandler,
  },
  props: {
    tag: Object,
    show: Number,
  },
  data: function () {
    return {
      response: null,
      editedTag: {
        show: false,
        value: null,
        description: "",
        tag_value_id: null,
        tag_classification_id: null,
        classificationNew: false,
        classificationNewName: "",
        classificationName: "",
        tag_category_id: null,
        categoryNew: false,
        categoryNewName: "",
        categoryName: "",
      },
      allowAdd: false,
      allowEditTag: false,
      allowEditClassification: false,
      allowEditCategory: false,
      //   showRating: false,
      showClassification: false,
      showCategory: false,
      usesNotes: false,
      isLoading: false,
      isNew: false,
      tagType: null,
    };
  },
  watch: {
    show(val) {
      if (val) this.editTag();
    },
  },
  computed: {
    ...mapState({
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagClassififcations: (state) => state.hierarchies.tagClassififcations,
      tagCategories: (state) => state.hierarchies.tagCategories,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
  },
  created() {},
  methods: {
    setup() {
      //   let definition = this.tagTypes.find(
      //     (x) => x.tag_type_id === this.tag.tag_type_id
      //   );
      this.original = JSON.parse(JSON.stringify(this.tag));
      this.allowAdd = this.tagPermissions.addTag;
      this.allowEditTag = this.tagPermissions.editTag;
      this.allowEditClassification = this.tagPermissions.editClassification;
      this.allowEditCategory = this.tagPermissions.editCategory;
      this.showClassification = this.$loginState.user.settings.some(
        (s) =>
          s.setting === "tagging_classification_used" && s.value !== "false"
      );
      this.showCategory = this.$loginState.user.settings.some(
        (s) =>
          s.setting === "tagging_categorisation_used" && s.value !== "false"
      );
      //   this.showRating =
      //     definition.maxRating > 0 &&
      //     this.$loginState.user.settings.some(
      //       (s) => s.setting === "tagging_rating_used" && s.value !== "false"
      //     );
      this.usesNotes = this.$loginState.user.settings.some(
        (s) => s.setting === "tagging_notes_used" && s.value !== "false"
      );
    },
    editTag() {
      this.setup();
      let tagDef = this.tagTypes
        .find((x) => x.tag_type_id === this.tag.tag_type_id)
        ?.values.find((x) => x.tag_value_id === this.tag.tag_value_id);
        this.isNew = !tagDef;
      if (this.isNew) {
        tagDef = this.tag;
      }
      this.tagType = this.tagTypes.find(
        (x) => x.tag_type_id === tagDef.tag_type_id
      );

      this.editedTag.show = true;
      this.editedTag.tag_value_id = tagDef.tag_value_id || -1;
      this.editedTag.value = tagDef.value || "";
      this.editedTag.description = tagDef.description || "";
      this.editedTag.tag_classification_id = tagDef.tag_classification_id;
      this.editedTag.tag_category_id = tagDef.tag_category_id;
      this.editedTag.classificationNew = this.tagClassififcations.length === 0;
      this.editedTag.categoryNew = this.tagCategories.length === 0;
      this.editedTag.classificationNewName = "";
      this.editedTag.categoryNewName = "";
      this.editedTag.classificationName = tagDef.tag_classififcation_name;
      this.editedTag.categoryName = tagDef.tag_category_name;
    },
    cancel() {
      this.editedTag.show = false;
      this.$emit("cancel");
    },
    saveTag() {
      let data = {
        tag_type_id: this.tag.tag_type_id,
        tag_value_id: this.editedTag.tag_value_id,
        value: this.editedTag.value,
        description: this.editedTag.description,
        tag_classification_id: this.editedTag.tag_classification_id || null,
        tag_classification_name: this.editedTag.classificationNewName.trim(),
        tag_classification_desc: "",
        tag_category_id: this.editedTag.tag_category_id || null,
        tag_category_name: this.editedTag.categoryNewName.trim(),
        tag_category_desc: "",
      };
      this.$store.dispatch("hierarchies/saveTag", data).then((resp) => {
        if (
          resp.data.Status === "OK" &&
          resp.data.Data.length &&
          resp.data.Data[0].length
        ) {
          this.$emit("saved", resp.data.Data[0][0]);
          this.editedTag.show = false;
        }
        this.response = resp.data;
        this.isLoading = false;
      }),
        (error) => {
          console.error(error);
          this.isLoading = false;
        };
    },
  },
};
</script>
  <style scoped>
</style>
  